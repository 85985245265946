import React, { useState, useEffect, useRef } from 'react'
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import remiderIcon from 'Assets/NoteModule/remider.svg';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import Modal from 'react-bootstrap/Modal';
import filter from 'Assets/LessonPlanModule/filter.png';
import copy from 'Assets/LessonPlanModule/copy.svg';
import downloads from 'Assets/LessonPlanModule/downloads.svg';
import shareLesson from 'Assets/LessonPlanModule/shareLesson.svg';
import timerProcess from 'Assets/LessonPlanModule/timerProcess.svg';
import shareAll from 'Assets/LessonPlanModule/shareAll.svg';
import sharePrivate from 'Assets/LessonPlanModule/sharePrivate.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import importRosterIcon from 'Assets/ClassModule/importRoster.png';
import addRosterIcon from 'Assets/RosterModule/plus.png';
import { getFormattedDate } from "Utils/Helper";
import ImportLessonPlan from "./ImportLessonPlan";
import ShareLessonPlanTecherList from "./ShareLessonPlanTecherList";
import * as routes from "Router/RoutesURL";
import './ClassLessonPlanDashboard.scss'
import warningIcon from "Assets/NoteModule/warning-icon.png";
import Card from 'react-bootstrap/Card';
import back from 'Assets/CommonComponent/back.png';
import SelectSearch from 'react-select-search';
import search from 'Assets/AttendanceModule/search.png'
import arrow from 'Assets/AttendanceModule/arrow.png';

// import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { setClassLessonPlanState } from 'Actions/ClassAction/classAction';
import { getClassList } from 'Actions/ClassAction/classAction';
const ClassLessonPlanDashboard = (props) => {
    const {
        getViewLessonPlanList,
        lessonPlanListAll,
        deleteLessonPlan,
        copyLessonPlan,
        addLessonPlanList,
        deleteLessonPlanState,
        copyLessonPlanState,
        importLessonPlan,
        viewLessonPlanDetailsState,
        viewLessonPlanDetails,
        importLessonPlanState,
        saveEvaluationLessonPlanState,
        saveExecutionLessonPlanState,
        getViewImportLessonPlanList,
        getImportTopicList,
        saveImportLessonPlanRating,
        getViewImportLessonPlanListState,
        getImportTopicListState,
        saveImportLessonPlanRatingState,
        getLessonPlanSchoolTeacherState,
        shareLessonPlanState,
        getLessonPlanSchoolTeacher,
        shareLessonPlan,
        history,
        state
    } = props;
    const dispatch = useDispatch()
    const [expanded, setExpanded] = useState("");
    const [filterType, setFilterType] = useState("all");
    const [lessonListToday, setLessonListToday] = useState([]);
    const [lessonListAll, setLessonListAll] = useState([]);
    const [totalPlane, setTotalPlan] = useState(0)
    const [importViewPage, setImportViewPage] = useState(false);
    const [showSharePopUp, setShowSharePopUp] = useState(false);
    const [showTeacherListComp, setShowTeacherListComp] = useState(false);
    const [selectedTopicPlan, setSelectedTopicPlan] = useState("");
    const [selectedSharePlanId, setSelectedSharePlanId] = useState("");
    const [showPublishAll, setShowPublishAll] = useState(false);
    const { classDetail, academicYearId, academicYear, classId, classPermission, BackPage } = history?.location?.state || {};
    
    const signDate = sessionStorage.getItem('separatorFormat')
    const formatDate = sessionStorage.getItem('dateFormat')
    const { loader } = state?.classLessonPlan || {};
    const [LpId, setLpId] = useState("");
    const [selectIndex, setSelectIndex] = useState(-1);
    const [deletePopover, setDeletePopover] = useState("");
    const [isDeleted, setIsDeleted] = useState(false);
    const [searchText, setSearchText] = useState('')
    const [filterClassNoteData, setFilterClassNoteData] = useState([])

    const [chooseClass, setChooseClass] = useState("");
    const [chooseClassName, setChooseClassName] = useState("");
    const [searchInputRub, setSearchInputRub] = useState('');
    const [showClassConfirm, setShowClassConfirm] = useState(false);
    const [ClassData, setClassdata] = useState({});
    const [classDetaill, setClassdetail] = useState({});
    const [classList, setClassList] = useState();
    const [selectedClass, setSelectedClass] = useState("");
    const [selectedClassName, setSelectedClassName] = useState("");
    const [allAcademicYearList, setAllAcademicYearList] = useState([])
    const [classObj, setclassObj] = useState({
        class_id : "",
        class_name: "",
        assessment_tag_count: "",
        cc_add_on: "",
        cc_class_color_wheel_display: "",
        cc_class_grade: "",
        cc_class_id: "",
        cc_class_name: "",
        cc_class_section: "",
        cc_class_share_class_id: "",
        cc_class_share_permission: "",
        cc_class_share_user_id: "",
        cc_modified_on: "",
        cc_ref_academic_year_id: "",
        cc_ref_class_id: "",
        cc_ref_id: "",
        class_core_standard: "",
        class_grading_event_count: "",
        class_note_count: "",
        created_by: "",
        is_active: "",
        is_deleted: "",
        is_shared: "",
        recent_shared_permission: "",
        school_id: "",
        student_count: "",
        tag_count: "",
        user_type: "",
    })
    const [classSharePermission, setClassSharePermission] = useState('')

    // const lpData = useSelector((state) => state?.academic?.academic_Roster?.data?.response_data)
    const lpData = useSelector((state) => state?.academic?.academic_Roster?.data?.class_data)
    const firstIndexClass = useSelector((state) =>state?.academic?.academic_Data?.data?.response_data?.[0]?.class_data?.[0])

    useEffect(() => {
        if(lpData) {
            setAllAcademicYearList(lpData)
            } else {
                let classArr = []
                classArr.push(firstIndexClass)
                setAllAcademicYearList(classArr)
            }
    },[lpData])

    useEffect(() => {
        if(history?.location?.state?.BackPage == "Academic Year") {
        const value = {
            filter_by: 'both',
            // page_no: 'full',
            class_academic_year: history?.location?.state?.AcademicData?.year_ids
        }
        dispatch(getClassList(value))
    }
    },[])

    const fullClassListData = useSelector((state) => state?.class?.class_Data?.data?.[0])

    const classDataList = fullClassListData && fullClassListData.length > 0 ? fullClassListData : history?.location?.state?.AcademicData?.class_data
    const searchInput = useRef();
    const newClassDataList = classDataList?.map((obj, i) => (
        {
            ...obj,
            name: classDataList[i].cc_class_name,
            value: classDataList[i].cc_class_id,
            details: classDataList[i]
        }

    ));
    const newArr = newClassDataList?.map((name, i) => ({ ['name']: newClassDataList[i].cc_class_name, ['value']: newClassDataList[i].cc_class_id, ['details']: newClassDataList[i] }));
    const options = [
        {
            type: "group",
            name: "",
            items: newArr !== undefined ? newArr : [{ name: "", value: "" },],

        }
    ];

    useEffect(() => {
        window.sessionStorage.removeItem('LID')
        setSelectedClassName(classDetail?.cc_class_name || classObj?.class_name);
        if (BackPage != "Academic Year") {
            const apiData = {
                "class_id": classDetail?.cc_class_id || classObj?.class_id,
                "plan_status": filterType,
                "order_by": "recent_modified",
                "search_data" : searchText
            }
            getViewLessonPlanList(apiData)
        } else {
            if(sessionStorage.getItem('Class_ID_for_LP') > 0) {
                let data = newClassDataList.find((item) => item.cc_class_id == sessionStorage.getItem('Class_ID_for_LP'))
                    setclassObj({
                        ...classObj,
                        student_count: data?.student_count,
                    })
                    const apiData = {
                        "class_id": sessionStorage.getItem('Class_ID_for_LP'),
                        "plan_status": filterType,
                        "order_by": "recent_modified",
                        "search_data" : searchText
                    }
                    getViewLessonPlanList(apiData)
                }

        }
    
    }, [])

    const handleChanges = (value, ...args) => {
        sessionStorage.setItem("selected_class_name", args[0].name)
        let selectedClassData = {}
        if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
            selectedClassData = classDataList?.find((item) => item?.cc_class_id == args[0].value)
            if(selectedClassData) {
                setClassSharePermission(selectedClassData?.cc_class_share_permission)
                sessionStorage.setItem('classSharePaermission', selectedClassData?.cc_class_share_permission)
            }
        } else {
        selectedClassData = allAcademicYearList?.find((item) => item?.cc_class_id == args[0].value)
        if(selectedClassData) {
            setClassSharePermission(selectedClassData?.cc_class_share_permission)
            sessionStorage.setItem('classSharePaermission', selectedClassData?.cc_class_share_permission)
        }
    }
        // let selectedYear = allAcademicYearList.filter((item) => item.academic_year_id == history?.location?.state?.AcademicData?.academic_year_id)
        // console.log('selectedYear',selectedYear)
sessionStorage.setItem("Class_ID_for_LP", value)

setclassObj({
    class_id : value,
    class_name : args[0].name,
    assessment_tag_count: args[0].details.assessment_tag_count,
    cc_add_on: args[0].details.cc_add_on,
    cc_class_color_wheel_display: args[0].details.cc_class_color_wheel_display,
    cc_class_grade: args[0].details.details.cc_class_grade,
    cc_class_id: args[0].details.details.cc_class_id,
    cc_class_name: args[0].details.details.cc_class_name,
    cc_class_section: args[0].details.details.cc_class_section,
    cc_class_share_class_id: args[0].details.cc_class_share_class_id,
    cc_class_share_permission: selectedClassData?.cc_class_share_permission,
    cc_class_share_user_id: args[0].details.cc_class_share_user_id,
    cc_modified_on: args[0].details.cc_modified_on,
    cc_ref_academic_year_id: args[0].details.cc_ref_academic_year_id,
    cc_ref_class_id: args[0].details.cc_ref_class_id,
    cc_ref_id: args[0].details.cc_ref_id,
    class_core_standard: args[0].details.class_core_standard,
    class_grading_event_count: args[0].details.class_grading_event_count,
    class_note_count: args[0].details.class_note_count,
    created_by: args[0].details.created_by,
    is_active: args[0].details.details.is_active,
    is_deleted: args[0].details.details.is_deleted,
    is_shared: args[0].details.is_shared,
    recent_shared_permission: args[0].details.details.recent_shared_permission,
    school_id: args[0].details.details.school_id,
    student_count: args[0].details.details.student_count,
    tag_count: args[0].details.details.tag_count,
    user_type: args[0].details.details.user_type


})

setLessonListAll([])
setLessonListToday([])
setTotalPlan(0)

setSelectedClassName(args[0].name);
    if (filterType !== "all") {
        
        // setRubricsListAll([]);
        setSelectedClassName(args[0].name);
        setChooseClass(args[0].value);
        setChooseClassName(args[0].name);
        setClassdata(args[0].data);
        setSearchInputRub("");
        setShowClassConfirm(true);
        const apiData = {
            "class_id": value,
            "plan_status": filterType,
            "order_by": "none",
            "search_data" : searchText
        }
        getViewLessonPlanList(apiData)
    } else {
        // setRubricsListAll([]);
        // sessionStorage.setItem("rubricsSelectedClass", JSON.stringify(args[0].data));
        setSelectedClass(args[0].value);
        setClassdetail(args[0].data);
        setSelectedClassName(args[0].name);
        setClassdata(args[0].data);
        setSearchInputRub("");
        
            const apiData = {
                "class_id": value,
                "plan_status": filterType,
                "order_by": "recent_modified",
                "search_data" : searchText
            }
            getViewLessonPlanList(apiData)
        
    }
    };


    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    useEffect(() => {
        sessionStorage.setItem("showPublish", false);
         
        setSelectedClassName(classDetail?.cc_class_name || classObj.class_name);
     
if (BackPage == "Academic Year") {
if (sessionStorage.getItem('Class_ID_for_LP') > 0 || classObj?.class_id > 0) {
    if (filterType === "all") {
        const apiData = {
            "class_id": sessionStorage.getItem('Class_ID_for_LP') > 0 ? sessionStorage.getItem('Class_ID_for_LP') :  (classDetail?.cc_class_id || Number(classObj.class_id)),
            "plan_status": filterType,
            "order_by": "recent_modified",
            "search_data" : searchText
        }
        
        getViewLessonPlanList(apiData)
    } else {
        const apiData = {
            "class_id": sessionStorage.getItem('Class_ID_for_LP') > 0 ? sessionStorage.getItem('Class_ID_for_LP') :  (classDetail?.cc_class_id || Number(classObj.class_id)),
            "plan_status": filterType,
            "order_by": "none",
            "search_data" : searchText
        }
        

        getViewLessonPlanList(apiData)
    }
}

}
       
      
       
else {
const apiData = {
    "class_id": classDetail?.cc_class_id || classObj.class_id,
    "plan_status": filterType,
    "order_by": "none",
    "search_data" : searchText
}
getViewLessonPlanList(apiData)
}
    }, [filterType])

    useEffect(() => {
        
        if (deleteLessonPlanState && deleteLessonPlanState.status == 200) {
            dispatch(setClassLessonPlanState())
            if (filterType === "all") {
                const apiData = {
                    "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                    "plan_status": filterType,
                    "order_by": "recent_modified",
                    "search_data" : searchText
                }
                getViewLessonPlanList(apiData)
            } else {
                const apiData = {
                    "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                    "plan_status": filterType,
                    "order_by": "none",
                    "search_data" : searchText
                }
                getViewLessonPlanList(apiData)
            }
        }
    }, [deleteLessonPlanState])

    useEffect(() => {
        if (saveExecutionLessonPlanState && saveExecutionLessonPlanState.message === "Lesson Plan Execution saved successfully") {
            if (filterType === "all") {
                const apiData = {
                    "class_id": classDetail?.cc_class_id  || sessionStorage.getItem("Class_ID_for_LP"),
                    "plan_status": filterType,
                    "order_by": "recent_modified",
                    "search_data" : searchText
                }
                getViewLessonPlanList(apiData)
            } else {
                const apiData = {
                    "class_id": classDetail?.cc_class_id  || sessionStorage.getItem("Class_ID_for_LP"),
                    "plan_status": filterType,
                    "order_by": "none",
                    "search_data" : searchText
                }
                getViewLessonPlanList(apiData)
            }
        }
    }, [saveExecutionLessonPlanState])
    
    useEffect(() => {
        if (saveEvaluationLessonPlanState && saveEvaluationLessonPlanState.message === "Lesson Plan Evaluation saved successfully") {
            if (filterType === "all") {
                const apiData = {
                    "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                    "plan_status": filterType,
                    "order_by": "recent_modified",
                    "search_data" : searchText
                }
                getViewLessonPlanList(apiData)
            } else {
                const apiData = {
                    "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                    "plan_status": filterType,
                    "order_by": "none",
                    "search_data" : searchText
                }
                getViewLessonPlanList(apiData)
            }
        }
    }, [saveEvaluationLessonPlanState])

    useEffect(() => {
        if (addLessonPlanList && addLessonPlanList.message === "Lesson Plan saved successfully") {
            if (filterType === "all") {
                const apiData = {
                    "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                    "plan_status": filterType,
                    "order_by": "recent_modified",
                    "search_data" : searchText
                }
                getViewLessonPlanList(apiData)
            } else {
                const apiData = {
                    "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                    "plan_status": filterType,
                    "order_by": "none",
                    "search_data" : searchText
                }
                getViewLessonPlanList(apiData)
            }
        }
    }, [addLessonPlanList])

    useEffect(() => {
        
        if (copyLessonPlanState && copyLessonPlanState.status == 200) {
            if (filterType === "all") {
                const apiData = {
                    "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                    "plan_status": filterType,
                    "order_by": "recent_modified",
                    "search_data" : searchText
                }
                getViewLessonPlanList(apiData)
            } else {
                const apiData = {
                    "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                    "plan_status": filterType,
                    "order_by": "none",
                    "search_data" : searchText
                }
                getViewLessonPlanList(apiData)
            }
        }
    }, [copyLessonPlanState])

    useEffect(() => {
        if (showPublishAll && shareLessonPlanState && shareLessonPlanState.message === 'Lesson Plan shared successfully') {
            setShowPublishAll(false);
        }
    }, [shareLessonPlanState])

    useEffect(() => {
        if (showPublishAll && shareLessonPlanState && !loader) {
            setShowPublishAll(false);
        }
    }, [shareLessonPlanState])

    useEffect(() => {
        
        if (lessonPlanListAll) {
            if(searchText.length == 0 && filterType == 'all') {
            setTotalPlan(lessonPlanListAll.all_other_plans.length + lessonPlanListAll.today_plans.length)
            }
            if (lessonPlanListAll.all_other_plans) {
                let lessonData =
                    lessonPlanListAll && lessonPlanListAll.all_other_plans.map(lesson => {
                        let lessonValue = { ...lesson }
                        lessonValue = {
                            ...lessonValue,
                            editOpen: false
                        }
                        return lessonValue
                    })
                setLessonListAll(lessonData);
            }
            if (lessonPlanListAll.today_plans) {
                let lessonData =
                    lessonPlanListAll && lessonPlanListAll.today_plans.map(lesson => {
                        let lessonValue = { ...lesson }
                        lessonValue = {
                            ...lessonValue,
                            editOpen: false
                        }
                        return lessonValue
                    })
                setLessonListToday(lessonData);
                // setLessonListAll(lessonData)
            }
        }
    }, [lessonPlanListAll])

    const BackTOHome = () => {
        history.goBack()
    }
    const BackToDash = () => {
        history.push("/home/academic")
    }
    const viewImportPage = () => {
        setImportViewPage(true);
    }
    const deletePopoverHandler = (value) => {
        setDeletePopover(value);
        setIsDeleted(true);
        // this.setState({
        //     deletePopover: value
        // })
      };
    const closeImportPage = () => {
        setImportViewPage(false);
        if (filterType === "all") {
            const apiData = {
                "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                "plan_status": filterType,
                "order_by": "recent_modified",
                "search_data" : searchText
            }
            getViewLessonPlanList(apiData)
        } else {
            const apiData = {
                "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                "plan_status": filterType,
                "order_by": "none",
                "search_data" : searchText
            }
            getViewLessonPlanList(apiData)
        }
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const copyLessonPlanValue = (item, index) => {
        const apiData = {
            "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
            "lesson_plan_id": item.cc_lp_id,
        }
        copyLessonPlan(apiData)

    };

    const deleteLessonPlanValue = (value, index) => {
        const apiData = {
            "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
            "lesson_plan_id": LpId,
        }
        deleteLessonPlan(apiData)
       
        setDeletePopover("");
    setIsDeleted(false);
    };

    const editLesson = (val) => {
        if (expanded === "panel2") {
            let lessonData =
                lessonListAll && lessonListAll.map((lesson, index) => {
                    let lessonValue = { ...lesson }
                    if (val === index) {
                        if (lessonValue.editOpen === false) {
                            lessonValue = {
                                ...lessonValue,
                                editOpen: true
                            }
                            return lessonValue
                        } else {
                            lessonValue = {
                                ...lessonValue,
                                editOpen: false
                            }
                            return lessonValue
                        }
                    } else {
                        lessonValue = {
                            ...lessonValue,
                            editOpen: false
                        }
                        return lessonValue
                    }
                })
            setLessonListAll(lessonData)
        }
        if (expanded === "panel1") {
            let lessonData =
                lessonListToday && lessonListToday.map((lesson, index) => {
                    let lessonValue = { ...lesson }
                    if (val === index) {
                        if (lessonValue.editOpen === false) {
                            lessonValue = {
                                ...lessonValue,
                                editOpen: true
                            }
                            return lessonValue
                        } else {
                            lessonValue = {
                                ...lessonValue,
                                editOpen: false
                            }
                            return lessonValue
                        }
                    } else {
                        lessonValue = {
                            ...lessonValue,
                            editOpen: false
                        }
                        return lessonValue
                    }
                })
            setLessonListToday(lessonData)
        }

    }

    const showSharePopUps = (value) => {
        setShowSharePopUp(!showSharePopUp);
        setSelectedTopicPlan(value.cc_lp_title);
        setSelectedSharePlanId(value.cc_lp_id);
    }

    const hideTeacherListComp = () => {
        setShowTeacherListComp(false);
        setSelectedTopicPlan("");
        setSelectedSharePlanId("");
    }


    const shareOptionDeclare = (value) => {
        setShowSharePopUp(false);
        if (value === "privately") {
            setShowTeacherListComp(true);
        } else {
            setShowPublishAll(true);
            const apiData = {
                'lesson_plan_id': JSON.stringify(selectedSharePlanId),
                'sharing_type': "public_plan",
                'shared_user_ids': ""
            }
            shareLessonPlan(apiData);
        }
    }
    const handleSearchText = (event) => {
        setSearchText(event.target.value)
        if(event.target.value.length == 0) {
            setSearchText('')
            const apiData = {
                "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                "plan_status": filterType,
                "order_by": "recent_modified",
                "search_data" : ""
            }
            getViewLessonPlanList(apiData)
        }
        if(event.target.value.length > 2) {
            const apiData = {
                "class_id": classDetail?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
                "plan_status": filterType,
                "order_by": "recent_modified",
                "search_data" : event.target.value
            }
            getViewLessonPlanList(apiData)
    }
    }
    const goBCKandEmptyLS = () => {
        window.sessionStorage.removeItem('selected_class_name')
        history.push("/home/academic")
    }
    return (<div>{!importViewPage && !showTeacherListComp &&
        <div data-testid = "maindivmock" className='classLessonContainer'>
            <div className='LessonPlanMainDiv'>
                {/* <div className='LessonPlanDetailsMain'>
                    <div className='ClassBackDiv'
                        onClick={BackTOHome}
                    >
                        <i className='lessonBackIcon material-icons'>chevron_left</i>
                        <p className='lessonClassBactText'>Classes</p>
                    </div>
                    {/* <div className='lessonDetailTextDiv'>
                        <p className='lessonClassName'> Total Plans: {lessonListAll.length + lessonListToday.length}</p>
                    </div> */}
                {/* </div> */} 
                <Col xs="5" className=" note-header-title " style={{    display: "-webkit-box", marginLeft: "0px" , marginRight : "0px" ,  maxWidth: "20.666667%"}}>
                            <div className='stdNoteBackDiv' onClick={() =>  window.sessionStorage.removeItem('selected_class_name')}>
                                <img className='calIcon' src={back} alt='' width='60' height='40' onClick={() => {BackToDash(); 
                                 sessionStorage.removeItem('LPPlaiingQuestion')
                                 sessionStorage.removeItem('LPAudioList')
                                 sessionStorage.removeItem('LPTopic')
                                 sessionStorage.removeItem('LPTitle')
                                 sessionStorage.removeItem('LPUrllist')
                                 sessionStorage.removeItem('LPReminderTime')
                                 sessionStorage.removeItem('LPNotification')
                                 sessionStorage.removeItem('LPFormatDate')
                                 sessionStorage.removeItem('LPDocList')
                                 sessionStorage.removeItem('LPMinutes')
                                 sessionStorage.removeItem('LPPictureVideoList') }} />
                            </div>
                            <div className='stdNoteDetailTextDiv'>
                            <div className='stdNoteDisplayFlex stdNoteDisplayFlex-p0' style={{whiteSpace : "nowrap"}}>
                                  
                                  <p style={{whiteSpace : "no-wrap"}} className='currentYear currentYear-p0' onClick={() => {BackPage != "Academic Year" ?  window.history.go(-2)
                                  
                                  : goBCKandEmptyLS();
                                  sessionStorage.removeItem('LPPlaiingQuestion')
                                  sessionStorage.removeItem('LPAudioList')
                                  sessionStorage.removeItem('LPTopic')
                                  sessionStorage.removeItem('LPTitle')
                                  sessionStorage.removeItem('LPUrllist')
                                  sessionStorage.removeItem('LPReminderTime')
                                  sessionStorage.removeItem('LPNotification')
                                  sessionStorage.removeItem('LPFormatDate')
                                  sessionStorage.removeItem('LPDocList')
                                  sessionStorage.removeItem('LPMinutes')
                                  sessionStorage.removeItem('LPPictureVideoList') 
                                  } }><b>{history?.location?.state?.academicYear || history?.location?.state?.AcademicData?.academic_year}</b></p>
 
                                      <i style={"Class Roster" == "Class Roster" ? {display : "block"} : {display : "none"}} className="classForIcon material-icons">chevron_right</i>
                                      {BackPage != "Academic Year" &&
                                      <p onClick={() => {history.goBack(); 
                                        sessionStorage.removeItem('LPPlaiingQuestion')
                                        sessionStorage.removeItem('LPAudioList')
                                        sessionStorage.removeItem('LPTopic')
                                        sessionStorage.removeItem('LPTitle')
                                        sessionStorage.removeItem('LPUrllist')
                                        sessionStorage.removeItem('LPReminderTime')
                                        sessionStorage.removeItem('LPNotification')
                                        sessionStorage.removeItem('LPFormatDate')
                                        sessionStorage.removeItem('LPDocList')
                                        sessionStorage.removeItem('LPMinutes')
                                        sessionStorage.removeItem('LPPictureVideoList') }}  className='stdNoteGrade1 currentYear-p0'>Class</p>  }
                                      {BackPage != "Academic Year" &&
                                      <i className="classForIcon material-icons">chevron_right</i> }
                                      
                                      <p style={{color : "black" , whiteSpace : "no-wrap"}} className='stdNoteGrade2 currentYear-p0'>Lesson Plan</p>
                                  </div>
                                {/* <div className='stdNoteDisplayFlex stdNoteDisplayFlex-p0'>
                                  
								<p className='currentYear currentYear-p0' onClick={() => {window.history.go(-2)}}><b>{history.location.state.academicYear}</b></p>

                                    <i style={"Class Roster" == "Class Roster" ? {display : "block"} : {display : "none"}} className="classForIcon material-icons">chevron_right</i>
                                    
                                    <p onClick={() => history.goBack()}  className='stdNoteGrade1 currentYear-p0'>Classes</p>
                                    <i className="classForIcon material-icons">chevron_right</i>
                                    
                                    <p style={{color : "black"}} className='stdNoteGrade2 currentYear-p0'>Lesson Plan</p>
                                </div> */}
                            </div>
                        </Col>

                         {BackPage == "Academic Year" &&        
                        <div className= {selectedClassName ? "note-select-dropdown-main-container-NSCC" : "note-select-dropdown-main-container-NSC"} >
                                <img src={search} alt='' className='searchImage' />
                                <SelectSearch
                                    ref={searchInput}
                                    options={options}
                                    filterOptions={handleFilter}
                                    value={{ name: selectedClassName || sessionStorage.getItem("selected_class_name"), value: 0 }}
                                    name="Workshop"
                                    placeholder="Search class*"
                                    search
                                    onChange={handleChanges}
                                    disabled = {BackPage != "Academic Year"}
                                />
                            </div>
}
                
                <div className='buttonMarginTop'>
                    {/* <Button className='filterPlan'
                        id="PopoverClick"
                    >
                        <img className='lessonFilterIcon' src={filter} alt='' />
                    </Button> */}
                    <div className='filterPop'>
                        {/* <UncontrolledPopover
                            trigger="click"
                            placement="left"
                            target="PopoverClick"
                            className='filterPopOver'
                        >
                            <PopoverBody>
                                <div className="notificationSubBlock">
                                    <div className='filterTimeDiv' onClick={() => { setFilterType("all") }}>
                                        <i className="checkBoxfilter material-icons">{filterType === 'all' ? 'check_circle' : 'radio_button_unchecked'}</i>
                                        <p className='filterText'>Recent Modified</p>
                                    </div>
                                    <div className='filterTimeDiv' onClick={() => { setFilterType("planning") }}>
                                        <i className="checkBoxfilter material-icons">{filterType === 'planning' ? 'check_circle' : 'radio_button_unchecked'}</i>
                                        <p className='filterText'>Planned</p>
                                    </div>
                                    <div className='filterTimeDiv' onClick={() => { setFilterType("execution") }}>
                                        <i className="checkBoxfilter material-icons">{filterType === 'execution' ? 'check_circle' : 'radio_button_unchecked'}</i>
                                        <p className='filterText'>Executed</p>
                                    </div>
                                    <div className='filterTimesDiv' onClick={() => { setFilterType("evaluation") }}>
                                        <i className="checkBoxfilter material-icons">{filterType === 'evaluation' ? 'check_circle' : 'radio_button_unchecked'}</i>
                                        <p className='filterText'>Evaluated</p>
                                    </div>
                                </div>
                            </PopoverBody>
                        </UncontrolledPopover> */}
                    </div>
                    <Button className='addNewLesson'
                    // style={{width: "110px", height: "45px" , display: "flex"}}
                    style={selectedClassName || sessionStorage.getItem("selected_class_name") ? {width: "110px", height: "45px" , display: "flex", alignItems: "center", visibility: "visible"} : {visibility: "hidden"}}
                        onClick={() => {
                          
                            history.push(routes.ADDNEWLESSONPLAN, {
                                ClassDetails: classDetail || classObj,
                                selectionType: "addNew",
                                lessonPlanId: "",
                                academicYear : history?.location?.state?.AcademicData?.academic_year,
                                BackPage : BackPage == "Academic Year" ?  "LP" : "CLP",
                            })
                            if(viewLessonPlanDetailsState) {
                            Object.keys(viewLessonPlanDetailsState?.data).forEach(key => (viewLessonPlanDetailsState?.data)[key]= null);
                            }
                        }}
                        disabled={((BackPage == "Academic Year" && (classObj.student_count < 1 || classSharePermission == 'read' || sessionStorage.getItem('classSharePaermission') == 'read')) || (BackPage != 'Academic Year' && (history?.location?.state?.classDetail?.student_count < 1 || history?.location?.state?.classDetail?.cc_class_share_permission == 'read'))) ? true : false}
                    >
                        <img className='lessonIcon' src={addRosterIcon} alt='' />
                        <span className='viewAttText' style={{fontSize : '1.2rem', position : 'relative', top : 1}}>Plan</span>
                    </Button>

                    {/* <Button className='addNewLesson'
                    style={{width: "120px", height: "45px" , display: "flex", alignItems: "center"}}
                        onClick={viewImportPage}
                    >
                        <img className='lessonIcon' src={importRosterIcon} alt='' />
                        <span className='viewAttText'>Import</span>
                    </Button> */}
                     {selectedClassName ||  sessionStorage.getItem("selected_class_name") ?
                    <Button className='addNewLesson'
                    style={selectedClassName || sessionStorage.getItem("selected_class_name") ? {width: "120px", height: "45px" , display: "flex", alignItems: "center", visibility: "visible"} : {visibility: "hidden"}}
                        onClick={viewImportPage}
                    disabled={((BackPage == "Academic Year" && (classObj.student_count < 1 || classSharePermission == 'read' || sessionStorage.getItem('classSharePaermission') == 'read')) || (BackPage != 'Academic Year' && (history.location.state.classDetail.student_count < 1 || history.location?.state?.classDetail?.cc_class_share_permission == 'read'))) ? true : false}    
                    >
                        <img className='lessonIcon' src={importRosterIcon} alt='' />
                        <span className='viewAttText'>Import</span>
                    </Button> : ""
}
                </div>
            </div>
            {selectedClassName  || sessionStorage.getItem("selected_class_name") ?
            <Col data-testid = "loadermock" md="12" lg="12" className="main-section-CND" style={{paddingLeft : "0px" , paddingRight : "0px"}}>
                    {/* ------ Class add group Note----- */}
                    {/* <Card className="header-CND"> */}
                        {/* <Card.Body> */}
                            {/* <Row className="header-title-row-CND"> */}
                                {/* <Col xs="12" md="5" className="header-title-CND"> */}
                                    {/* <div className="border-right-CND">
                                        <img className='back-image-CND' src={back} alt='' width='60' height='40' onClick={() => { BackToDash() }} />
                                    </div>
                                    <span className="year-CND" onClick={() => { BackToDash() }}>{academicYear}
                                        <i className="classForIcon-CND material-icons">chevron_right</i>
                                        <b id='Note-CND'>Note</b>
                                    </span>
                                </Col>
                                <Col xs="12" md="7" className="m-auto dropdown-column-CND">
                                    <div className='header-dropdown-main-container-CND'>
                                        <div className="dropdown-main-container-CND">
                                            <img src={search} alt='' className='header-dropdown-main-container-CND dropdown-search-icon-CND' />
                                            <SelectSearch
                                                ref={searchInput}
                                                options={options}
                                                filterOptions={handleFilter}
                                                value={isSelected ? { name: selectedClassName2, value: 0 } : { name: selectedClassName, value: 0 }}
                                                name="Workshop"
                                                placeholder="Search class*"
                                                search
                                                onChange={handleChange}
                                            />
                                        </div>
                                    // </div> */}
                                {/* </Col> */}
                            {/* </Row> */}
                        {/* </Card.Body> */}
                    {/* </Card > */}

                    {/* ------ Class add group Note----- */}

                    {/* ------ Class Filter Div----- */}
                    <Card data-testid = "loadermock" className="refresh-section-CND mt-15 br-8">
                        <Card.Body>
                            <Row className="filterRow-CND">
                                <Col xs="12" md="5" lg="6" className="desc-search-col-CND">
                                    <span className="total-student-CND total-student-CLPD"> Total Plans: {totalPlane} </span>
                                    <div className='totalNoteSearchDiv-CND'>
                                        <div className="searchSearchStylediv-CND searchSearchStylediv-CLPD">
                                            <div className="searchSearchdiv-CND">
                                                <div className="input-group-prepend border-0">
                                                    <button id="button-addon4" type="button" className="btn btn-link text-info searchIconDiv-CND"><i className="fa fa-search searchIcon"></i></button>
                                                </div>
                                                <input
                                                    type='text'
                                                    autoComplete='off'
                                                    margin="normal"
                                                    value={searchText}
                                                    onChange={handleSearchText}
                                                    className='searchStudent-CND'
                                                    placeholder="Enter Keywords…"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="12" md="7" lg="6" className="sort-by-col-CND justify-content-end">
                                    {/* <span className='sort-by-CND'><p>Sort By :</p></span> */}
                                    <div className='Button-div-2-CND Button-div-2-CND-PaddingTop0'>
                                        <button type="button" className={filterType === "all" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => { setFilterType("all") }}>
                                            Recent Modified</button>
                                        {/* <button type="button" className={filterType === "sort_by_name" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => { sortSet("sort_by_name") }}> */}
                                            {/* Last Name</button> */}
                                        <button type="button" className={filterType === "planning" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => { setFilterType("planning") }}>
                                            Planned</button>
                                        <button type="button" className={filterType === "execution" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => { setFilterType("execution") }} >
                                            Executed</button>
                                        <button type="button" className={filterType === "evaluation" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => { setFilterType("evaluation") }}>
                                            Evaluated</button>
                                        {/* <button type="button" className={filterType === "sort_by_note_reminder_date" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => { sortSet("sort_by_note_reminder_date") }}>
                                            Note Status</button> */}
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    {/* ------ Class Filter Div----- */}
                </Col>  : ""}
            <div>
                {loader && <div data-testid = "loadermock">
                    <img src={loaderImag} alt='' className='loaderIconCss' />
                </div>}
                <div style={BackPage == "Academic Year" &&  !sessionStorage.getItem("selected_class_name") ? { visibility : "visible" , display : "flex" } : {visibility : "hidden" , display : "none"}} className='rubContainersDiv'>
                        <img src={arrow} alt='' className='rubricsArrowImage' />
                        <div className='rubContainerTextDiv'>
                            <span className='rubContainerText'>Please select class to view or create Lesson Plans</span>
                        </div>
                    </div>
                    {selectedClassName || sessionStorage.getItem("selected_class_name") ?
                    <>
                <div className="groupPanels lessonPlanPanels">
                    <Accordion expanded={ (BackPage == "Academic Year" && classObj.student_count < 1) || (BackPage != 'Academic Year' && history.location.state.classDetail.student_count < 1) ? false : expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="groupName"
                        >
                            <div className="MuiTypography-root MuiTypography-body1">
                                <div className="title w-166">
                                    <p>Today's plan</p>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {!lessonListToday.length > 0 ?
                                <div className='noPlansDiv'><p className='noPlantext'>No Plans for Today</p></div> :
                                lessonListToday && lessonListToday.map((item, index) => {
                                    return (
                                        <div className='classCardDiv' key={index}>
                                            <div className='classDetailsCard'>
                                                <div style={{width : "337px"}}  className='textLeft'
                                                    onClick={() => {
                                                      
                                                        history.push(routes.ADDNEWLESSONPLAN, {
                                                            ClassDetails: classDetail || classObj,
                                                            selectionType: "edit",
                                                            lessonPlanId: item.cc_lp_id,
                                                            academicYearId: academicYearId,
                                                            academicYear : history?.location?.state?.AcademicData?.academic_year,
                                                            classId: classId || sessionStorage.getItem("Class_ID_for_LP"),
                                                            classPermission: classPermission,
                                                            BackPage : BackPage == "Academic Year" ?  "LP" : "CLP",
                                                            classPermission: classPermission || sessionStorage.getItem('classSharePaermission')
                                                        })
                                                    
                                                    }}
                                                >
                                                    <p className='lessonTitle'>{item.cc_lp_title}</p>
                                                    <p className='classGradeAndSectionText'>{item.cc_lp_topic}</p>
                                                    {item.cc_lp_status === "planning" ?
                                                        <div className='lessonPlan'><p className='statusTextPlan'>planned</p></div> :
                                                        item.cc_lp_status === "current status" ?
                                                            <div className='lessonCurrent'><p className='statusTextPlan'>{item.cc_lp_status}</p></div> :
                                                            item.cc_lp_status === "evaluation" ?
                                                                <div className='lessonEval'><p className='statusTextEval'>evaluated</p></div> :
                                                                item.cc_lp_status === "execution" ?
                                                                    <div className='lessonExe'><p className='statusTextExe'>executed</p></div> : ""}
                                                </div>
                                                <div className='textRight'>
                                                    <div className={`CLPD-threedots ${((BackPage == "Academic Year" && classSharePermission == 'read' || sessionStorage.getItem('classSharePaermission') == 'read') || (BackPage != 'Academic Year' && history.location?.state?.classDetail?.cc_class_share_permission == 'read')) && 'd-none'}`}>
                                                        <div id={`PopoverLegacy${index}`} type="button" className='classMenuIcon'
                                                            onClick={() => editLesson(index)}
                                                        >
                                                            <i className="material-icons">more_vert</i>
                                                            <UncontrolledPopover
                                                                isOpen={item.editOpen}
                                                                target={`PopoverLegacy${index}`}
                                                                // toggle={() => editLessonp(index)}
                                                                trigger="legacy"
                                                                placement="left"
                                                                className='popoverLessonPlan'
                                                                style={{ width: 100 }}>
                                                                <PopoverBody className='popoverLessonPlan'>
                                                                    <div className='editClasspopOver'
                                                                        onClick={() => {
                                                                           
                                                                            history.push(routes.ADDNEWLESSONPLAN, {
                                                                                ClassDetails: classDetail || classObj,
                                                                                selectionType: "edit",
                                                                                lessonPlanId: item.cc_lp_id,
                                                                                academicYearId: academicYearId,
                                                                                academicYear: academicYear,
                                                                                classId: classId || sessionStorage.getItem("Class_ID_for_LP"),
                                                                                classPermission: classPermission || sessionStorage.getItem('classSharePaermission')
                                                                            })
                                                                        
                                                                        }}
                                                                    >
                                                                        <i className="editClassIcon material-icons">edit</i>
                                                                        <span className='editTextClassPopOver'>Edit</span>
                                                                    </div>
                                                                    <div className='editClasspopOver' onClick={() => {
                                                                        // if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                                                                        //     return;
                                                                        // } else {
                                                                        copyLessonPlanValue(item, index)
                                                                        // }
                                                                    }}>
                                                                        <img className='viewLpIcons' src={copy} alt='' />
                                                                        <span className='editTextClassPopOver'>Copy</span>
                                                                    </div>
                                                                    <div className='editClasspopOver' onClick={() => {
                                                                        // if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                                                                        //     return;
                                                                        // } else {
                                                                        showSharePopUps(item) 
                                                                        // }
                                                                        }}>
                                                                        <img className='viewLpIcons' src={shareLesson} alt='' />
                                                                        <span className='editTextClassPopOver'>Share</span>
                                                                    </div>
                                                                    {/* <div className='editClasspopOver' onClick={() => {
                                                                        // this.editClassOpen(item) 
                                                                    }}>
                                                                        <img className='viewLpIcons' src={downloads} alt='' />
                                                                        <span className='editTextClassPopOver'>Download</span>
                                                                    </div> */}
                                                                    <div
                                                                    //  onClick={() => {
                                                                    //     deleteLessonPlanValue(item, index)
                                                                    // }}
                                                                    onClick={() => {
                                                                        // if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                                                                        //     return;
                                                                        // } else {
                                                                        deletePopoverHandler(
                                                                            item.cc_lp_id
                                                                        );
                                                                        setLpId(
                                                                            item.cc_lp_id
                                                                        );
                                                                        setSelectIndex(index);
                                                                        // }
                                                                        // this.setState({selectStudentId: item.cc_student_id});
                                                                        // this.setState({selectIndex: index}); }}
                                                                      }}
                                                                     className='deleteClassPopOver'>
                                                                        <i className="editClassIcon material-icons">delete</i>
                                                                        <span className='deleteTextClassPopOver'>Delete</span>
                                                                    </div>
                                                                </PopoverBody>
                                                            </UncontrolledPopover>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='classTimeline'>
                                                <div className='headingTitles'>
                                                    <p className='attTitleName'> Created on: </p>
                                                    <p className='head'>{getFormattedDate(item.cc_lp_created_at, formatDate, signDate)}</p>
                                                </div>
                                                {item.cc_lp_planned_at ?
                                                    <div className='headingTitlesRight'>
                                                        <p className='head'>{getFormattedDate(item.cc_lp_planned_at, formatDate, signDate)}</p>
                                                        <p className='head'>{item.cc_lp_notification_fixed_time}</p><img src={remiderIcon} alt="" width="18" height="18" />
                                                    </div> :
                                                    <div className='headingTitlesRight'>
                                                        <p className='attTitleName'> Reminder: </p>
                                                        <p className='head'>{item.cc_lp_notification_fixed_time}</p><img src={remiderIcon} alt="" width="18" height="18" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={ (BackPage == "Academic Year" && classObj.student_count < 1) || (BackPage != 'Academic Year' && history.location.state.classDetail.student_count < 1) ? false : expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="groupName"
                        >
                            <div className="MuiTypography-root MuiTypography-body1">
                                <div className="title w-166"><p>All plans</p></div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {!lessonListAll.length > 0 ?
                                <div className='noPlansDiv'><p className='noPlantext'>No Plans in All Plans</p></div> :
                                lessonListAll && lessonListAll.map((item, index) => {
                                    return (
                                        <div className='classCardDiv' key={index}>
                                            <div className='classDetailsCard'>
                                                <div style={{width : "337px"}}  className='textLeft'
                                                    onClick={() => {
                                                        // if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                                                        //     return;
                                                        // } else {
                                                        history.push(routes.ADDNEWLESSONPLAN, {
                                                            ClassDetails: classDetail || classObj,
                                                            selectionType: "edit",
                                                            lessonPlanId: item.cc_lp_id,
                                                            academicYearId: academicYearId,
                                                            academicYear: academicYear,
                                                            classId: classId || sessionStorage.getItem("Class_ID_for_LP"),
                                                            classPermission: classPermission || sessionStorage.getItem('classSharePaermission')
                                                        })
                                                    // }
                                                    }}
                                                >
                                                    <p className='lessonTitle'>{item.cc_lp_title}</p>
                                                    <p className='classGradeAndSectionText'>{item.cc_lp_topic}</p>
                                                    {item.cc_lp_status === "planning" ?
                                                        <div className='lessonPlan'><p className='statusTextPlan'>planned</p></div> :
                                                        item.cc_lp_status === "current status" ?
                                                            <div className='lessonCurrent'><p className='statusTextPlan'>{item.cc_lp_status}</p></div> :
                                                            item.cc_lp_status === "evaluation" ?
                                                                <div className='lessonEval'><p className='statusTextEval'>evaluated</p></div> :
                                                                item.cc_lp_status === "execution" ?
                                                                    <div className='lessonExe'><p className='statusTextExe'>executed</p></div> : ""}
                                                </div>
                                                <div className='textRight'>
                                                    <div className={`CLPD-threedots ${((BackPage == "Academic Year" && classSharePermission == 'read' || sessionStorage.getItem('classSharePaermission') == 'read') || (BackPage != 'Academic Year' && history.location?.state?.classDetail?.cc_class_share_permission == 'read')) && 'd-none'}`}>
                                                        <div id={`PopoverLegacy${index}`} type="button" className='classMenuIcon'
                                                            onClick={() => { 
                                                                // if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") { return; } else {
                                                                    editLesson(index)
                                                                // }
                                                            }}
                                                        >
                                                            <i className="material-icons">more_vert</i>
                                                            <UncontrolledPopover
                                                                isOpen={item.editOpen}
                                                                target={`PopoverLegacy${index}`}
                                                                // toggle={() => editLessonp(index)}
                                                                trigger="legacy"
                                                                placement="left"
                                                                className='popoverLessonPlan'
                                                                style={{ width: 100 }}>
                                                                <PopoverBody className='popoverLessonPlan'>
                                                                    <div className='editClasspopOver'
                                                                        onClick={() => {
                                                                            if(((BackPage == "Academic Year" && classSharePermission == 'read') || (BackPage != 'Academic Year' && history.location?.state?.classDetail?.cc_class_share_permission == 'read'))) {
                                                                                return;
                                                                            } else {
                                                                            history.push(routes.ADDNEWLESSONPLAN, {
                                                                                ClassDetails: classDetail || classObj,
                                                                                selectionType: "edit",
                                                                                lessonPlanId: item.cc_lp_id,
                                                                                academicYearId: academicYearId,
                                                                                academicYear: academicYear,
                                                                                classId: classId || sessionStorage.getItem("Class_ID_for_LP"),
                                                                                classPermission: classPermission
                                                                            })
                                                                        }
                                                                        }}
                                                                        style={{
                                                                            opacity : ((BackPage == "Academic Year" && classSharePermission == 'read') || (BackPage != 'Academic Year' && history.location?.state?.classDetail?.cc_class_share_permission == 'read')) && '0.5'
                                                                        }}
                                                                    >
                                                                        <i className="editClassIcon material-icons">edit</i>
                                                                        <span className='editTextClassPopOver'>Edit</span>
                                                                    </div>
                                                                    <div className='editClasspopOver' onClick={() => {
                                                                        // if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                                                                        //     return;
                                                                        // } else {
                                                                        copyLessonPlanValue(item, index)
                                                                        // }
                                                                    }}>
                                                                        <img className='viewLpIcons' src={copy} alt='' />
                                                                        <span className='editTextClassPopOver'>Copy</span>
                                                                    </div>
                                                                    <div className='editClasspopOver' onClick={() => { 
                                                                        // if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") { return;}   
                                                                        // else {
                                                                            showSharePopUps(item)
                                                                            // }
                                                                             }}>
                                                                        <img className='viewLpIcons' src={shareLesson} alt='' />
                                                                        <span className='editTextClassPopOver'>Share</span>
                                                                    </div>
                                                                    {/* <div className='editClasspopOver' onClick={() => {
                                                                        // this.editClassOpen(item) 
                                                                    }}>
                                                                        <img className='viewLpIcons' src={downloads} alt='' />
                                                                        <span className='editTextClassPopOver'>Download</span>
                                                                    </div> */}
                                                                    <div
                                                                    //  onClick={() => {
                                                                    //     deleteLessonPlanValue(item, index)
                                                                    // }} 
                                                                    onClick={() => {
                                                                        // if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                                                                        //     return;
                                                                        // } else {
                                                                        deletePopoverHandler(
                                                                            item.cc_lp_id
                                                                        );
                                                                        setLpId(
                                                                            item.cc_lp_id
                                                                        );
                                                                        setSelectIndex(index)
                                                                    // }
                                                                }}
                                                                    className='deleteClassPopOver'>
                                                                        <i className="editClassIcon material-icons">delete</i>
                                                                        <span className='deleteTextClassPopOver'>Delete</span>
                                                                    </div>
                                                                </PopoverBody>
                                                            </UncontrolledPopover>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='classTimeline'>
                                                <div className='headingTitles'>
                                                    <p className='attTitleName'> Created on: </p>
                                                    <p className='head'>{getFormattedDate(item.cc_lp_created_at, formatDate, signDate)}</p>
                                                </div>
                                                <div className='headingTitlesRight'>

                                                    <p className='attTitleName'> Reminder: </p>
                                                    <p className='head'>{item.cc_lp_notification_fixed_time}</p><img src={remiderIcon} alt="" width="18" height="18" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </AccordionDetails>
                    </Accordion>
                </div>
                {(history?.location?.state?.classDetail?.student_count >= 1 || classObj.student_count >= 1) && totalPlane == 0  ?
                     <p className='noGrpanelbookDataText'>Get your Lesson plan started by clicking the "+ Plan" above!</p> : ""
                   }
                
                { ((BackPage == "Academic Year" && classObj.student_count < 1) || (BackPage != 'Academic Year' && history.location.state.classDetail.student_count < 1)) &&
                    <div>
                    <p className='noneStudents' style={{color : "#aaa", font : "normal normal 600 20px/14px Open Sans", paddingTop: 120}}>  No students enrolled.  Please enroll students to begin creating Lesson Plan.</p>
                </div>
                }
                </>
                : "" }
            </div>
        </div>}

        {isDeleted === true && (
        <Modal centered className="delete-modal-warning br-8" show={isDeleted}>
          <Modal.Header>
            <Modal.Title>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img
                  src={warningIcon}
                  alt=""
                  style={{ width: "60px", height: "60px" }}
                />
                <span
                  style={{
                    fontSize: "24px",
                    color: "#ff1f1f",
                    marginTop: "10px",
                  }}
                >
                  Wait!
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure want to delete all Data associated with this Lesson Plan?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              className="delete-modal-warning cancel-btn"
              onClick={() => {
                // editStudent(selectIndex);
                // this.setState({ isDelete: false })
                setIsDeleted(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              className="delete-modal-warning delete-btn"
              style={{ borderRadius: "8px" }}
            //   onClick={() => {
            //     deleteStudentHandler(selectStudentId, selectIndex);
            //   }}
            // onClick = {() => alert(LpId)}
            onClick = {() => deleteLessonPlanValue(LpId)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
        <Modal centered className="share-modal-popUp br-8" show={showSharePopUp} >
            <Modal.Header>
                <Modal.Title>Share With</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='bothDivMain'>
                    <div className="imgTextDiv">
                        <img className='lessonFilterIcon' src={shareAll} onClick={() => { shareOptionDeclare("privately") }} alt='' width="60" height="60" />
                        <p className='textDiv'>Privately</p>
                    </div>
                    <div className="imgTextDiv">
                        <img className='lessonFilterIcon' src={sharePrivate} onClick={() => { shareOptionDeclare("all share") }} alt='' width="60" height="60" />
                        <p className='textDiv'>Publish for All</p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        <Modal centered className="publishAll-modal-popUp br-8" show={showPublishAll} >
            <Modal.Header>
                <Modal.Title>Share Plan</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='bothDivMain'>
                    <div className="imgTextDiv">
                        <img className='lessonFilterIcon' src={timerProcess} onClick={() => { shareOptionDeclare("all share") }} alt='' width="60" height="60" />
                        <p className='textDiv'>Your plan has been publishing for everyone.</p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        {showTeacherListComp && <ShareLessonPlanTecherList
            getLessonPlanSchoolTeacherState={getLessonPlanSchoolTeacherState}
            shareLessonPlanState={shareLessonPlanState}
            getLessonPlanSchoolTeacher={getLessonPlanSchoolTeacher}
            shareLessonPlan={shareLessonPlan}
            loader={loader}
            classDetail={classDetail}
            classId={classId}
            hideTeacherListComp={hideTeacherListComp}
            selectedTopicPlan={selectedTopicPlan}
            selectedSharePlanId={selectedSharePlanId}
            setShowTeacherListComp={setShowTeacherListComp}
        />}

        {!showTeacherListComp && importViewPage && <ImportLessonPlan
            classDetail={classDetail}
            classId={classId || classObj.class_id}
            state={state}
            viewLessonPlanDetailsState={viewLessonPlanDetailsState}
            closeImportPage={closeImportPage}
            getViewImportLessonPlanList={getViewImportLessonPlanList}
            getImportTopicList={getImportTopicList}
            saveImportLessonPlanRating={saveImportLessonPlanRating}
            importLessonPlan={importLessonPlan}
            viewLessonPlanDetails={viewLessonPlanDetails}
            getViewImportLessonPlanListState={getViewImportLessonPlanListState}
            getImportTopicListState={getImportTopicListState}
            saveImportLessonPlanRatingState={saveImportLessonPlanRatingState}
            importLessonPlanState={importLessonPlanState}
            history={history}
            setShowTeacherListComp={setShowTeacherListComp}
            setImportViewPage={setImportViewPage}
        />}
    </div>)
}
export default ClassLessonPlanDashboard;

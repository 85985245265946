import { useState, useEffect } from "react";

export function useSelection(readerRef, tooltipRef) {
    const [selection, setSelection] = useState('');
    const [timer, setTimer] = useState(null);
    const resetSelection = () => {
        setSelection(null);
        if (tooltipRef) {
            tooltipRef.style.display = 'none';
        }
    }

    function handler(event) {
        // get the selection
        const anotherselection = window.getSelection();

    if (anotherselection.rangeCount > 0) {
      const range = anotherselection.getRangeAt(0);

      // Get the offsets
      const anchorOffset = range.startOffset;
      const focusOffset = range.endOffset;
    }
        const selectionObject = document.getSelection();
        // console.log(document.getElementById('customHeader').innerText)

        if (selectionObject && selectionObject.toString().length > 0 && event?.toElement?.id == 'newFormId') {
            // get the range
            const range = selectionObject.getRangeAt(0);
            const boundedRect = range.getBoundingClientRect();
            const selectionDeimensions = range.getBoundingClientRect();
            setSelection(selectionObject.toString());
            if (tooltipRef) {
                tooltipRef.style.display = 'flex';
                const tooltipDimensions = tooltipRef.getBoundingClientRect();
                tooltipRef.style.top = `${tooltipDimensions.height + 50}px`;
                // tooltipRef.style.top = `${selectionDeimensions.top - tooltipDimensions.height}px`;
                // tooltipRef.style.left = `${selectionDeimensions.left + ((boundedRect.right - boundedRect.left)/2) - (tooltipDimensions.width/2)}px`;
            }
            // if (tooltipRef) {
            //     clearTimeout(timer); // Clear the existing timer
            //     setTimer(
            //       setTimeout(() => {
            //         tooltipRef.style.display = "block";
            //         const tooltipDimensions = tooltipRef.getBoundingClientRect();
            //         console.log(selectionDeimensions, tooltipDimensions)
            //         // tooltipRef.style.top = `${selectionDeimensions.top - tooltipDimensions.height}px`;
            //         tooltipRef.style.top = `${tooltipDimensions.height + 50}px`;
            //         // tooltipRef.style.left = `${selectionDeimensions.left + (boundedRect.right - boundedRect.left) / 2 - tooltipDimensions.width / 2}px`;
            //       }, 800) // Adjust the delay time as needed
            //     );
            //   }
        }  else {
            resetSelection();
        }
    }

    useEffect(() => {
        if (readerRef) {
            readerRef.addEventListener('mouseup', handler);
            document.addEventListener("selectionchange", handler);
        }

        return () => {
            // cleanup
            // removeEventListener('mouseup', handler);
            if (readerRef) {
                readerRef.removeEventListener('mouseup', handler);
                document.removeEventListener("selectionchange", handler);
              }
        }
    }, [tooltipRef, readerRef]);

    return { selection }
}

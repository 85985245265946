import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { register, registerReset } from 'Actions/AuthAction/registerAction';
import { placeSearch, selectPlace } from 'Actions/AuthAction/placeSearchAction';
import * as routes from "Router/RoutesURL";
import { createNotification } from 'Config/notificationtoast';
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from 'libphonenumber-js';
import { TermConditionPage } from '../Term&Condition/TermConditionPage';
import Logo from 'Assets/LoginModule/mainLogo.png';
import NextArrow from 'Assets/LoginModule/nextArrow.png'
import StepBack from 'Assets/LoginModule/step-back.png'
import './RegisterPage.scss';
import 'react-phone-input-2/lib/style.css'
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core/styles'
import TextField from "@material-ui/core/TextField";
import IconButton from '@mui/material/IconButton';
import validator from 'validator';

const RegisterPage = (props) => {
    const { history, register, registerReset, placeSearch, selectPlace, userStatusData, schoolSearchData } = props;
    const [isSubmit, setIsSubmit] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');
    const [InvalidMessageEmail, setInvalidMessageEmail] = useState('');
    const [InvalidMessageConEmail, setInvalidMessageConEmail] = useState('');
    const [InvalidMessagePhone, setInvalidMessagePhone] = useState('');
    const [InvalidMessagePass, setInvalidMessagePass] = useState('');

    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [ConfirmEmail, setConfirmEmail] = useState('');
    const [PhoneNo, setPhoneNo] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(true)

    const [SchoolName, setSchoolName] = useState('');
    const [City, setCity] = useState('');
    const [State, setState] = useState('');
    const [Country, setCountry] = useState('');
    const [ZipCode, setZipCode] = useState('');
    const [StreetNumber, setStreetNumber] = useState('');
    const [phoneCountry, setPhoneCountry] = useState('us');
    const [phoneConCountry, setPhoneConCountry] = useState('us');

    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [show, setShow] = useState('Password');
    const [confirmShow, setConfirmShow] = useState('Password');
    const [isVisible, setIsVisible] = useState(false);

    const [auto_complete_status, setAuto_complete_status] = useState("1");
    const [addresFieldDisable, setAddresFieldDisable] = useState(true)
    const [addresFieldDisableZip, setAddresFieldDisableZip] = useState(false);
    const [addresFieldDisablePhone, setAddresFieldDisablePhone] = useState(true);
    const [addresFieldDisableCountry, setAddresFieldDisableCountry] = useState(false);
    const [addresFieldDisableCity, setAddresFieldDisableCity] = useState(false);
    const [addresFieldDisableState, setAddresFieldDisableState] = useState(false);
    const [AutoSearchArray, setAutoSearchArray] = useState([]);

    const [sessiontoken, setSessiontoken] = useState('');
    const [isTermModal, setIsTermModal] = useState(false);
    const [agreeTerm, setAgreeTerm] = useState(false);

    const [step, setStep] = useState(true);
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [nextStepBtn, setNextStepBtn] = useState(true);
    const [createAccountBtn, setCreateAccountBtn] = useState(true);

    const [errorFirstName, setErrorFirstName] = useState(false);
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');

    const [errorLastName, setErrorLastName] = useState(false);
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');

    const [errorEmail, setErrorEmail] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');

    const [errorPassword, setErrorPassword] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

    const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');

    const [errorCountry, setErrorCountry] = useState(false);
    const [countryErrorMessage, setCountryErrorMessage] = useState('');

    const [errorState, setErrorState] = useState(false);
    const [stateErrorMessage, setStateErrorMessage] = useState('');

    const [errorCity, setErrorCity] = useState(false);
    const [cityErrorMessage, setCityErrorMessage] = useState('');

    const [errorZipCode, setErrorZipCode] = useState(false);
    const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState('');

    useEffect(() => {
        // ---------PLACE_SEARCH_SUCCESS-------
        if (schoolSearchData?.placeSearch_Data) {

            if (schoolSearchData?.action === 'PLACE_SEARCH_SUCCESS') {
                if (schoolSearchData?.placeSearch_Data.length === 0 || schoolSearchData?.placeSearch_Data === null) {
                    setCity('');
                    setState('');
                    setCountry('');
                    setZipCode('');
                    setPhoneNo('');
                    setAddresFieldDisable(false);
                    setAuto_complete_status("1");
                } else {
                    setAutoSearchArray(schoolSearchData.placeSearch_Data.school_details)
                }
            }
        }
        // ---------PLACE_SEARCH_SUCCESS-------
        // ---------SELECT_PLACE_SUCCESS-------
        if (schoolSearchData?.selectedPlace_Data) {
            if (schoolSearchData?.action === 'SELECT_PLACE_SUCCESS') {
                if (schoolSearchData?.selectedPlace_Data?.length === 0 || schoolSearchData?.selectedPlace_Data === null) {
                    setAddresFieldDisable(false);
                } else {
                    converData(schoolSearchData.selectedPlace_Data.address_components);
                }
            }
        }
        // ---------SELECT_PLACE_SUCCESS-------
        // ---------REGISTER_SUCCESS-------
        if (userStatusData) {
            if (userStatusData?.action === 'REGISTER_SUCCESS') {
                registerReset()
                sessionStorage.setItem("RegisterId", userStatusData?.register_Data?.user_id)
                sessionStorage.setItem("RegisterEmail", Email)
                history.push(routes.VERIFICATION, { email: Email });
            }
        }
        // ---------REGISTER_SUCCESS-------
    }, [userStatusData, schoolSearchData])

    // ---------converData to get Address-------
    const converData = (address_components) => {
        let Address = address_components;
        var componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'long_name',
            country: 'long_name',
            postal_code: 'short_name',
        };
        var country, locality, route, streetNumber, state, zipcode;

        for (var i = 0; i < Address.length; i++) {
            var addressType = Address[i].types[0];
            if (componentForm[addressType]) {

                if (addressType === 'street_number') {
                    streetNumber = address_components[i][componentForm[addressType]];
                }

                if (addressType === 'route') {
                    route = address_components[i][componentForm[addressType]];
                }

                if (addressType === 'locality') {
                    locality = address_components[i][componentForm[addressType]];
                }

                if (addressType === 'administrative_area_level_1') {
                    state = address_components[i][componentForm[addressType]];
                }

                if (addressType === 'country') {
                    country = address_components[i][componentForm[addressType]];
                }

                if (addressType === 'postal_code') {
                    zipcode = address_components[i][componentForm[addressType]];
                }
            }
        }

        // ------convertData to get country-------
        if (country === undefined || country === 'undefined') {
            setAddresFieldDisableCountry(false);
            setCountry('');
        } else {
            setCountry(country);
            setAddresFieldDisableCountry(true);
        }

        // ------convertData to get ZipCode-------
        if (zipcode === undefined || zipcode === 'undefined') {
            setAddresFieldDisableZip(false);
            setZipCode('');
        } else {
            setAddresFieldDisableZip(true);
            setZipCode(zipcode);
        }

        // ------converData to get State-------
        if (state === undefined || state === 'undefined') {
            setAddresFieldDisableState(false);
            setState('');
        } else {
            setAddresFieldDisableState(true);
            setState(state);
        }

        // ------converData to get City-------
        if (locality === undefined || locality === 'undefined') {
            if (route === undefined || route === 'undefined') {
                setAddresFieldDisableCity(false);
                setCity(City + '');
            } else {
                setAddresFieldDisableCity(false);
                setCity('');
            }
        } else {
            setAddresFieldDisableCity(true);
            setCity(locality);
        }
        setStreetNumber(streetNumber);
    }

    // ---------converData to get Address-------

    // ---------get Random UUID-------
    const generateUUID = () => { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
        });
    }
    // ---------get Random UUID--------

    // ---------OnSelectChange API Call-------
    const handleChangeForSearch = event => {
        setErrorCity(false);
        setErrorState(false);
        setErrorCountry(false);
        setErrorZipCode(false);
        setCityErrorMessage('');
        setStateErrorMessage('');
        setCountryErrorMessage('');
        setZipCodeErrorMessage('');

        if (event.target.value === "") {
            setAddresFieldDisableCity(true);
            setAddresFieldDisableState(true);
            setAddresFieldDisableCountry(true);
            setAddresFieldDisableZip(true);
            setState('');
            setCity('');
            setCountry('');
            setZipCode('');
            return
        }
        else {
            setErrorMessage('');
            setInvalidMessageEmail('');
            setInvalidMessagePass('');
            setAddresFieldDisableCity(false);
            setAddresFieldDisableState(false);
            setAddresFieldDisableCountry(false);
            setAddresFieldDisableZip(false);
            setIsSubmit(false);

            // [event.target.name]: event.target.value,
        }

        let DummyUDID = sessiontoken;
        if (sessiontoken === '') {
            DummyUDID = generateUUID()
            setSessiontoken(DummyUDID);
        }
        let data = {
            value: SchoolName,
            sessiontoken: DummyUDID
        }
        if (SchoolName) {
            setCity('');
            setState('');
            setCountry('');
            setZipCode('');
            placeSearch(data);
        } else {
            setAuto_complete_status("1");
            setCity('');
            setState('');
            setCountry('');
            setZipCode('');
        }
    }
    // ---------OnSelectChange API Call-------
    const handleOnChange = (value, data, event, formattedValue) => {
        const phoneNumber = "+" + value
        setErrorMessage('');
        setIsSubmit(false);
        setInvalidMessageEmail('');
        setInvalidMessagePass('');
        setPhoneNo(phoneNumber);
        // const isValid = isValidPhoneNumber(value, country);
        // if (isValid) {
        //   setPhoneNumber(value);
        // }
    };
    const handleConfirmOnChange = (value, data, event, formattedValue) => {
        if (PhoneNo.length > 1) {
            setErrorMessage('');
            setIsSubmit(false);
            setInvalidMessageEmail('');
            setInvalidMessagePass('');
        }
    };
    // ---------OnSelectChange-------
    const onSchoolSelect = (value) => {
        let DummyUDID = sessiontoken
        setSessiontoken('');
        setAddresFieldDisable(true);
        setSchoolName(value.school_name);
        setAuto_complete_status("0");
        // let data = {
        //     place_id: value.place_id,
        //     sessiontoken: DummyUDID
        // }
        // selectPlace(data);

    }
    // ---------OnSelectChange-------

    // ---------Register API Call-------

    const Validation1 = (event) => {
        const alphabetRegex = /^^[a-zA-z]+([\s][a-zA-Z]+)*$/;
        // const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

        

        if(event.target.name === "FirstName" ) {
            if(event.target.value.length < 1) {
                    setErrorFirstName(true);
                    setFirstNameErrorMessage('*First Name is required');
                    setFirstName(event.target.value)
            } else if(!alphabetRegex.test(event.target.value)) {
                    setErrorFirstName(true);
                    setFirstNameErrorMessage('*Should be alphabet only');
                    setFirstName(event.target.value)
            } else {
                    setErrorFirstName(false);
                    setFirstName(event.target.value)
            }
        }
        if(event.target.name === "LastName" ) {
            if(event.target.value.length < 1) {
                    setErrorLastName(true);
                    setLastNameErrorMessage('*Last Name is required');
                    setLastName(event.target.value)
            } else if(!alphabetRegex.test(event.target.value)) {
                    setErrorLastName(true);
                    setLastNameErrorMessage('*Should be alphabet only');
                    setLastName(event.target.value)
            } else {
                    setErrorLastName(false);
                    setLastName(event.target.value)
            }
        }
        if(event.target.name === "Email" ) {
            if(event.target.value.length < 1) {
                    setErrorEmail(true);
                    setEmailErrorMessage('*Email ID is required');
            } else if (!validator.isEmail(event.target.value)) {
                    setErrorEmail(true);
                    setEmailErrorMessage('*Please enter valid email address');
            } else {
                    setErrorEmail(false);
            }
        }
        if(event.target.name === "Password" ) {
            if(event.target.value.length < 1) {
                    setErrorPassword(true);
                    setPasswordErrorMessage('*Password is required');
            } else if (!passwordRegex.test(event.target.value)) {
                    setErrorPassword(true);
                    setPasswordErrorMessage('*Please Enter Valid Password');
            } else {
                setPasswordErrorMessage('');
                setErrorPassword(false);
            }
        }

        if(event.target.name === "ConfirmPassword") {
            if(event.target.value.length < 1) {
                    setErrorConfirmPassword(true);
                    setConfirmPasswordErrorMessage('*Confirm Password is required');
            } else if (event.target.value !== Password) {
                    setErrorPassword(true);
                    setErrorConfirmPassword(true);
                    setPasswordErrorMessage('*Password not matched');
                    setConfirmPasswordErrorMessage('*Password not matched');
            } else {
                    setErrorPassword(false);
                    setErrorConfirmPassword(false);
            }
        }

        // if(firstNameErrorMessage.length > 0 && lastNameErrorMessage.length > 0 && errorFirstName === false && errorLastName === false) {
        //     setNextStepBtn(false)
        // }
    }

    const nextStep = () => {
        const alphabetRegex = /^^[a-zA-z]+([\s][a-zA-Z]+)*$/;
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;



          
            if(FirstName === "") {
                    setErrorFirstName(true);
                    setFirstNameErrorMessage('*First Name is required');
            } else if(!alphabetRegex.test(FirstName)) {
                    setErrorFirstName(true);
                    setFirstNameErrorMessage('*Should be alphabet only');
            } else if (LastName === "") {
                    setErrorLastName(true);
                    setLastNameErrorMessage('*Last Name is required');
            } else if(!alphabetRegex.test(LastName)) {
                    setErrorLastName(true);
                    setLastNameErrorMessage('*Should be alphabet only');
            } else if (Email === "") {
                    setErrorEmail(true);
                    setEmailErrorMessage('*Email ID is required');
            } else if (!(validator.isEmail(Email))) {
                    setErrorEmail(true);
                    setEmailErrorMessage('*Please enter valid email address');
            } else  if(!isValidPhone) {
                return
            } 
            else if (Password === "") {
                    setErrorPassword(true);
                    setPasswordErrorMessage('*Password is required');
            } else if (!passwordRegex.test(Password)) {
                    setErrorPassword(true);
                    setPasswordErrorMessage('*Please Enter Valid Password');
            } else if(ConfirmPassword === "") {
                    setErrorConfirmPassword(true);
                    setConfirmPasswordErrorMessage('*Confirm Password is required');
            } else if (ConfirmPassword !== Password) {
                    setErrorPassword(true);
                    setErrorConfirmPassword(true);
                    setPasswordErrorMessage('*Password not matched');
                    setConfirmPasswordErrorMessage('*Password not matched');
            } else {

                setStep(false);
                setStep1(false);
                setStep2(true);
            }
        }

    const backStep = () => {
        setStep(true);
        setStep1(true);
        setStep2(false);
    }
    const radioFunction = () => {

        if (agreeTerm === false) {
            setAgreeTerm(true)
        }
        else {
            setAgreeTerm(false)
        }
    }

    const Validation2 = (event) => {
        const alphabetRegex = /^^[a-zA-z]+([\s][a-zA-Z]+)*$/;
        const zipCodeRegex = /^[0-9]{5,6}$/;

        if(event.target.name === "State") {
            if(event.target.value.length < 1) {
                setErrorState(true);
                setStateErrorMessage('*State is required')
            } else
            if(!alphabetRegex.test(event.target.value)) {
                setErrorState(true);
                setStateErrorMessage('*Should be alphabet only')
            } else {
                setErrorState(false);
            }
        }

        if(event.target.name === "City") {
            if(event.target.value.length < 1) {
                setErrorCity(true);
                setCityErrorMessage('*City is required')
            } else
            if(!alphabetRegex.test(event.target.value)) {
                setErrorCity(true);
                setCityErrorMessage('*Should be alphabet only')
            } else {
                setErrorCity(false);
            }
        }

        if(event.target.name === "Country") {
            if(event.target.value.length < 1) {
                setErrorCountry(true);
                setCountryErrorMessage('*Country is required')
            } else
            if(!alphabetRegex.test(event.target.value)) {
                setErrorCountry(true);
                setCountryErrorMessage('*Should be alphabet only')
            } else {
                setErrorCountry(false);
            }
        }

        if(event.target.name === "ZipCode") {
            if(event.target.value.length < 1) {
                setErrorZipCode(true);
                setZipCodeErrorMessage('*Zip Code is required')
            } else
            if(!zipCodeRegex.test(event.target.value)) {
                setErrorZipCode(true);
                setZipCodeErrorMessage('*Zip Code should be numeric and of 5 or 6 digits only')
            } else  {
                setErrorZipCode(false);
            }
        }
    }

    const RegisterUser = () => {
        const alphabetRegex = /^^[a-zA-z]+([\s][a-zA-Z]+)*$/;
        const zipCodeRegex = /^[0-9]{5,6}$/;

            if(State === "") {
                setErrorState(true);
                setStateErrorMessage('*State is required')
            } else if(!alphabetRegex.test(State)) {
                setErrorState(true);
                setStateErrorMessage('*Should be alphabet only')
            } else if(City === "") {
                setErrorCity(true);
                setCityErrorMessage('*City is required')
            } else if(!alphabetRegex.test(City)) {
                setErrorCity(true);
                setCityErrorMessage('*Should be alphabet only')
            } else if(Country === "") {
                setErrorCountry(true);
                setCountryErrorMessage('*Country is required')
            } else if(!alphabetRegex.test(Country)) {
                setErrorCountry(true);
                setCountryErrorMessage('*Should be alphabet only')
            } else if(ZipCode === "") {
                setErrorZipCode(true);
                setZipCodeErrorMessage('*Zip Code is required')
            } else if(!zipCodeRegex.test(ZipCode)) {
                setErrorZipCode(true);
                setZipCodeErrorMessage('*Zip Code should be numeric and of 5 or 6 digits only')
            } else if (agreeTerm === false) {
            // setAgreeTerm()
            setErrorMessage('*Please agree to the Terms of Service and Privacy Policy')
            setInvalidMessagePass('')
            createNotification('*Please agree to the Terms of Service and Privacy Policy');
            // return false;
        } else {
            setIsSubmit(true);

            const sendRequest = {
                // -------Basic Information------
                user_first_name: FirstName,
                user_last_name: LastName,
                user_email_id: Email,
                user_phone_number: PhoneNo,

                // -------School / University Details------
                user_school_name: SchoolName,
                school_state: State,
                school_city: City,
                user_country: Country,
                school_zipcode: ZipCode,
                auto_complete_status: "1",

                // -------Create Password------
                user_password: Password,
            };
            // console.log("sendRequest----",sendRequest)
            sessionStorage.setItem("RegisterEmail", Email)
            sessionStorage.setItem("Password", Password)
        register(sendRequest);
        }
    }
    // ---------Register API Call-------

    // ---------AutoSearch Value Selectl-------
    const onTagsChange = (event, v) => {
        setSchoolName(v)
        setErrorCity(false);
        setErrorState(false);
        setErrorCountry(false);
        setErrorZipCode(false);
        setCityErrorMessage('');
        setStateErrorMessage('');
        setCountryErrorMessage('');
        setZipCodeErrorMessage('');
        setAddresFieldDisableCity(false);
                setAddresFieldDisableState(false);
                setAddresFieldDisableCountry(false);
                setAddresFieldDisableZip(false);
        for (let item of AutoSearchArray)

            if (item.school_name === v) {
                setSchoolName(v)
                setCity(item.city);
                setState(item.state)
                setCountry(item.country)
                setZipCode(item.zip_code)
                setAddresFieldDisableCity(true);
                setAddresFieldDisableState(true);
                setAddresFieldDisableCountry(true);
                setAddresFieldDisableZip(true);
                // onSchoolSelect(item);
                return;
            }
    }
    const onTextUpadte = (v) => {
        if (v.length === 0) {
            setState('');
            setCity('');
            setCountry('');
            setZipCode('');
            setAddresFieldDisableCity(true);
            setAddresFieldDisableState(true);
            setAddresFieldDisableCountry(true);
            setAddresFieldDisableZip(true);
        }
    }
    // ---------AutoSearch Value Selectl-------

    // ---------Show password -------
    const showPassWord = (value) => {
        setShow(value);
    }
    // ---------Show password -------

    // ---------Show password -------
    const showConfirmPassWord = (value) => {
        setConfirmShow(value);
    }
    // ---------Show password -------

    // --------- Start Input Handle change-------
    const handleChange = (event) => {
        setErrorMessage('');
        setIsSubmit(false);
        setInvalidMessageEmail('');
        setInvalidMessagePass('');
        //[event.target.name]: event.target.value,
    };
    // ---------End Input Handle change-------

    const handleChangeNumberMobile = (value, country, formattedValue, props) => {
        // Remove the dial code from the input string
  const stringWithoutDialCode = props.replace(country?.dialCode, "");

  // Remove the characters from the format other than dots (.) and digits (\d)
  const digitsAndDotsFormat = country?.format.replace(/[^\d.]/g, "");
  // Remove any non-digit characters from the input string (leaving only digits and dots)
  const extractedNumbers = stringWithoutDialCode.replace(/[^\d.]/g, "");
        setPhoneNo(props);

        // if(/[()\-\u2013\u2014]/.test(country?.format))

        if(extractedNumbers.length > 0) {
        if(value.length > 0) {
        if(props?.includes(`+${country?.dialCode}`)) {

        if(/[()\-\u2013\u2014]/.test(country?.format)) {

        if((country?.format.match(/\./g) || []).length == value.length) {
            setIsValidPhone(true)
        } else {
            setIsValidPhone(false)
        }
    } else {
        if(country?.format.split('.').length - 1 == value.length) {
            setIsValidPhone(true)
        } else {
            setIsValidPhone(false)
        }
    }
} else {
    setIsValidPhone(false)
}
        }
    }
        // if (value.startsWith(country.dialCode)) {
        //     value = value.substring(country.dialCode.length);
        // }
        // let customnumber = "+" + country.dialCode + - + value
        // console.log(customnumber)
        // if(country?.dialCode == '91') {
        //     console.log(703, value.length)
        //     console.log(704, 'if')
        //     if(value.length > 10 || value.length < 10) {
        //         setIsValidPhone(false);
        //     } else {
        //         console.log(708, 'else')
        //         setIsValidPhone(true);
        //     }
        // } else {
        // const isValid = isValidPhoneNumber(value, country.countryCode);
        // console.log(isValid)
        // setIsValidPhone(isValid);
        // }
      };

      useEffect(() => {
        console.log(isValidPhone)
      },[isValidPhone])

    const theme = createTheme({
        overrides: {
            MuiFilledInput: {
                root: {
                    backgroundColor: "transparent",
                    //   marginLeft: '-10px !important',
                    "&:hover": {
                        backgroundColor: "transparent",
                        // marginLeft: '-10px !important',
                    },
                    "&$focused": {
                        backgroundColor: "transparent",
                        // marginLeft: '-10px !important',
                    },
                    "&$disabled": {
                        backgroundColor: "transparent",
                        // marginLeft: '-10px !important',
                    },
                },
                underline: {
                    "&:before": {
                        borderBottomColor: "transparent",
                        width: '95%',
                        marginLeft: '10px !important',
                        marginBottom: '1px !important'
                    },
                    "&:hover:not(.Mui-focused):before": {
                        borderBottomColor: "transparent",
                        width: '95%',
                        marginLeft: '10px !important',
                        marginBottom: '1px !important'
                    },
                    "&:after": {
                        // focused
                        borderBottomColor: "#386CB5",
                        width: '95%',
                        marginLeft: '10px !important',
                        marginBottom: '1px !important'
                    }
                }
            },
            MuiInputLabel: {
                filled: {
                    color: "#222222",
                    //   marginLeft: '-10px !important',
                    "&$focused": {
                        color: "#386CB5",
                        // marginLeft: '-10px !important',
                    },
                    ".MuiFormControl-root:hover &:not($focused)": {
                        color: "#222222",
                        // marginLeft: '-10px !important',
                    }

                },
                field: {
                    margin: '10px 0',
                    backgroundColor: 'red'
                },
            },
            MuiFormHelperText: {
                contained: {
                    marginLeft: '-1px',
                    marginTop: '-3px'
                },
            },
        },
    });

    // const phoneCon= ["mTop20Register phoneNoDiv", PhoneNo.length > 1?"":"phoneNone"];

    return (
        <div className='RegistrationMainContainer'>
            <div className='RegistrationSubContainer'>
                <div>
                    <img alt="" src={Logo} className="img-div" />
                </div>
            </div>
            <div className='RegistrationSubContainer'>
                {step === true ?
                    <div className='RegistrationCardDiv1'>
                        <div className="RegistrationTextDiv">
                            <p className="RegistrationText"> REGISTRATION</p>
                        </div>
                        <div className='divForRegScrollFirst'>
                            <div className='divForRegScrollSec'>
                                <div className='stepMainDiv'>
                                    <div className='stepSubDiv'>
                                        {step === true ?
                                            <>
                                                <p className="stepText" style={{ color: '#386CB5' }}> STEP 01</p>
                                                <div className='bottomLine' style={{ backgroundColor: '#386CB5' }}></div>
                                            </>
                                            :
                                            <>
                                                <p className="stepText" style={{ color: '#AFC4E1' }}> STEP 01</p>
                                                <div className='bottomLine' style={{ backgroundColor: '#AFC4E1' }}></div>
                                            </>
                                        }
                                    </div>
                                    <div className='stepSubDiv'>
                                        {step === true ?
                                            <>
                                                <p className="stepText" style={{ color: '#AFC4E1' }}> STEP 02</p>
                                                <div className='bottomLine' style={{ backgroundColor: '#AFC4E1' }}></div>
                                            </>
                                            :
                                            <>
                                                <p className="stepText" style={{ color: '#386CB5' }}> STEP 02</p>
                                                <div className='bottomLine' style={{ backgroundColor: '#386CB5' }}></div>
                                            </>
                                        }

                                    </div>
                                </div>
                                {/*  Input Div First/Last Name*/}
                                <div className='FirstNameDiv'>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '47%' }}>
                                        <div className='FirstNameInputDiv'>
                                            <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <FormControl sx={{ mr: 1, my: 0.25, mx: 1 }} variant="standard">
                                                    <MuiThemeProvider theme={theme}>
                                                        <TextField
                                                            name="FirstName"
                                                            label="First Name"
                                                            id="input-with-sx"
                                                            value={FirstName}
                                                            variant="filled"
                                                            autoComplete='off'
                                                            size="small"
                                                            shrink={true}
                                                            disableUnderline={isVisible}
                                                            onFocus={() => setIsVisible(false)}
                                                            onBlur={() => setIsVisible(true)}
                                                            onChange={event => { setFirstName(event.target.value); Validation1(event) }}
                                                            error={errorFirstName === true ? true : false}
                                                            helperText={errorFirstName === true ? firstNameErrorMessage : ''}
                                                        />
                                                    </MuiThemeProvider>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '47%' }}>
                                        <div className='FirstNameInputDiv'>
                                            <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <FormControl sx={{ mr: 1, my: 0.25, mx: 1 }} variant="standard">
                                                    <MuiThemeProvider theme={theme}>
                                                        <TextField
                                                            name="LastName"
                                                            label="Last Name"
                                                            id="input-with-sx"
                                                            value={LastName}
                                                            variant="filled"
                                                            autoComplete='off'
                                                            size="small"
                                                            shrink={true}
                                                            disableUnderline={isVisible}
                                                            onFocus={() => setIsVisible(false)}
                                                            onBlur={() => setIsVisible(true)}
                                                            onChange={event => { setLastName(event.target.value); Validation1(event) }}
                                                            error={errorLastName === true ? true : false}
                                                            helperText={errorLastName === true ? lastNameErrorMessage : ''}
                                                        />
                                                    </MuiThemeProvider>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                                {/*  Input Div First/Last Name*/}
                                {/*  Input Email Div First*/}
                                <div className="RegistrationInputDiv">
                                    <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <FormControl fullWidth sx={{ mr: 1, my: 0.25, mx: 1,  }} variant="standard">
                                            <MuiThemeProvider theme={theme}>
                                                <TextField
                                                    name="Email"
                                                    label="Email ID"
                                                    id="input-with-sx"
                                                    type="Email"
                                                    value={Email}
                                                    variant="filled"
                                                    autoComplete='off'
                                                    size="small"
                                                    shrink={true}
                                                    disableUnderline={isVisible}
                                                    onFocus={() => setIsVisible(false)}
                                                    onBlur={() => setIsVisible(true)}
                                                    onChange={event => { setEmail(event.target.value); Validation1(event) }}
                                                    // onChange={() => nextStep()}
                                                    error={errorEmail === true ? true : false}
                                                    helperText={errorEmail === true ? emailErrorMessage : ''}
                                                />
                                            </MuiThemeProvider>
                                        </FormControl>
                                    </Box>

                                </div>
                                {/*  Input Email Div First*/}
                                {/*  Input Mobile Div*/}
                                {/* <div className="RegistrationInputDiv"> */}
                                <PhoneInput
                                    inputExtraProps={{ required: true, autoFocus: false, }}
                                    className={`${!isValidPhone ? "registerInputTypeMarginBottm0" : "registerInputType"}`}
                                    country={'us'}
                                    value={PhoneNo}
                                    placeholder='Mobile Number'
                                    inputStyle={{ width: '100%', height: '45px', background: '#F7FAFF', marginTop: '20px !important', marginBottom: '20px !important', borderRadius: '8px !important' }}
                                    // onChange={event => {console.log(event); handleOnChange(event)}}
                                    onChange={(value, country, formattedValue, prop) => {handleChangeNumberMobile(value, country, formattedValue, prop)}}
                                    // isValid={(v) => console.log(v)}
                                    {...props}
                                />
                                 {(!isValidPhone) && <p style={{color:'red', fontSize : "0.75rem", textAlign : 'left', marginBottom : 30}}>{'Please enter valid phone number.'}</p> }
                                {/* <div className="RegistrationInputDiv">
                        <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <FormControl fullWidth sx={{ mr: 1, my: 0.25, mx: 1, width: '36ch',}} variant="standard">
                                <MuiThemeProvider theme={theme}>
                                <PhoneInput
                                    inputExtraProps={{ required: true, autoFocus: false, }}
                                    className="registerInputType"
                                    country={'us'}
                                    value={this.state.PhoneNo}
                                    placeholder='Mobile Number'
                                    inputStyle={{width: '100%', height:'45px', background: '#F7FAFF', marginTop: '20px !important', marginBottom: '20px !important', borderRadius: '8px !important'}}
                                    onChange={this.handleOnChange}
                                /> */}
                                {/* <ReactPhoneInput
                                        name="PhoneNo"
                                        label="Mobile Number"
                                        id="input-with-sx"
                                        value={this.state.PhoneNo}
                                        variant="standard"
                                        country={'us'}
                                        size="small"
                                        shrink={true}
                                        disableUnderline={this.state.isVisible}
                                        onFocus={()=>this.setState({isVisible: false})}
                                        onBlur={()=>this.setState({isVisible: true})}
                                        onChange={this.handleOnChange}
                                        error={this.state.errorFname === true ? true : false}
                                        helperText={this.state.errorFname === true ? this.state.fNameErrorMessage : ''}
                                        // inputClass={classes.field}
                                        // dropdownClass={classes.countryList}
                                        component={TextField}
                                        search={true}
                                    /> */}
                                {/* </MuiThemeProvider> */}
                                {/* <MuiPhoneNumber
                                    // defaultCountry={"us"}
                                    name="PhoneNo"
                                        label="Mobile Number"
                                        id="input-with-sx"
                                        value={this.state.PhoneNo}
                                        variant="standard"
                                        country={'us'}
                                        // disableAreaCodes
                                    /> */}
                                {/* </FormControl>
                        </Box>
                    </div> */}
                                {/* <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <FormControl fullWidth sx={{ mr: 1, my: 0.25, mx: 1}} variant="standard">
                                <InputLabel htmlFor="component-helper">Mobile Number</InputLabel>
                                <Input size="small" disableUnderline={this.state.isVisible} onFocus={()=>this.setState({isVisible: false})} onBlur={()=>this.setState({isVisible: true})} id="input-with-sx" label="Email ID" variant="filled" value={this.state.PhoneNo} onChange={this.handleChange} name="PhoneNo" />
                            </FormControl>
                        </Box> */}

                                {/* </div> */}
                                {/* <div className="registerErrorMessageDiv">
                        {!this.state.Email && <p className="errormessage">{this.state.ErrorMessageEmail}</p>}
                        {(this.state.Email && this.state.InvelidMessageEmail) && <p className="errormessage">{this.state.InvelidMessageEmail}</p>}

                    </div> */}
                                {/*  Input Mobile Div*/}
                                <div className="RegistrationTextDiv">
                                    <p className="CreatePasswordText"> Create Password</p>
                                    <p className="CreatePasswordSubText">Password must contain one special character, one capital <br />letter and be at least 6 characters long.</p>
                                </div>
                                {/*  Input Password Div Second*/}
                                <div className="RegistrationInputDiv">
                                    <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <FormControl fullWidth sx={{ mr: 1, my: 0.25, mx: 1 }} variant="standard">
                                            <MuiThemeProvider theme={theme}>
                                                <TextField
                                                    name="Password"
                                                    label="Enter Password"
                                                    id="standard-adornment-password"
                                                    type={show}
                                                    value={Password}
                                                    variant="filled"
                                                    autoComplete='off'
                                                    size="small"
                                                    shrink={true}
                                                    disableUnderline={isVisible}
                                                    onFocus={() => setIsVisible(false)}
                                                    onBlur={() => setIsVisible(true)}
                                                    onChange={event => { setPassword(event.target.value); Validation1(event) }}
                                                    error={errorPassword === true ? true : false}
                                                    helperText={errorPassword === true ? passwordErrorMessage : ''}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    style={{ marginRight: '5px' }}
                                                                    onClick={() => { show === 'text' ? showPassWord('Password') : showPassWord('text') }}
                                                                >
                                                                    {show === 'text' ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </MuiThemeProvider>
                                        </FormControl>
                                    </Box>

                                </div>
                                {/*  Input Password Div Second*/}
                                {/*  Input Password Div Second*/}
                                <div className="RegistrationInputDiv">
                                    <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <FormControl fullWidth sx={{ mr: 1, my: 0.25, mx: 1 }} variant="standard">
                                            <MuiThemeProvider theme={theme}>
                                                <TextField
                                                    name="ConfirmPassword"
                                                    label="Confirm Password"
                                                    id="input-with-sx"
                                                    type={confirmShow}
                                                    value={ConfirmPassword}
                                                    variant="filled"
                                                    autoComplete='off'
                                                    size="small"
                                                    shrink={true}
                                                    disableUnderline={isVisible}
                                                    onFocus={() => setIsVisible(false)}
                                                    onBlur={() => setIsVisible(true)}
                                                    onChange={event => { setConfirmPassword(event.target.value); Validation1(event) }}
                                                    error={errorConfirmPassword === true ? true : false}
                                                    helperText={errorConfirmPassword === true ? confirmPasswordErrorMessage : ''}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    style={{ marginRight: '5px' }}
                                                                    onClick={() => { confirmShow === 'text' ? showConfirmPassWord('Password') : showConfirmPassWord('text') }}
                                                                >
                                                                    {confirmShow === 'text' ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </MuiThemeProvider>
                                        </FormControl>
                                    </Box>

                                </div>
                                {/*  Input Password Div Second*/}
                                {/*  NextStep Button*/}
                                <div className='NextStepBtnDiv'>
                                    <Button className="NextStepBtn" onClick={() => nextStep()}
                                    // style={ nextStepBtn === true ? { cursor: "default" } : { cursor: "pointer" }}  disabled={FirstName.length > 0 && LastName.length > 0 && firstNameErrorMessage.length === 0 && lastNameErrorMessage.length === 0 ? false : true}
                                     >
                                        <p className='NextStepText'>Next Step</p>
                                        <img alt="" src={NextArrow} className="NextBtnImg" />
                                    </Button>
                                </div>
                                {/*  NextStep Button*/}
                                <div className='LineSeparator'></div>
                                {/*  Login*/}
                                <div className='RegistrationLoginBtnDiv'>
                                    <p className="registerText">Do have an account?</p>
                                    <div className='RegistrationLoginBtn' onClick={() => {
                                        history.push(routes.LOGIN);
                                    }}>
                                        <span className="RegistrationLoginBtnText">Sign In</span>
                                    </div>
                                </div>
                                {/*  Login*/}
                            </div>
                        </div>
                    </div>

                    :
                    <div className='RegistrationCardDiv' style={{ width: '500px', height: '620px' }}>
                        <div className="RegistrationTextDiv">
                            <p className="RegistrationText"> REGISTRATION</p>
                        </div>
                        <div className='stepMainDiv'>
                            <div className='stepSubDiv'>
                                {step === true ?
                                    <>
                                        <p className="stepText" style={{ color: '#386CB5' }}> STEP 01</p>
                                        <div className='bottomLine' style={{ backgroundColor: '#386CB5' }}></div>
                                    </>
                                    :
                                    <>
                                        <p className="stepText" style={{ color: '#AFC4E1' }}> STEP 01</p>
                                        <div className='bottomLine' style={{ backgroundColor: '#AFC4E1' }}></div>
                                    </>
                                }
                            </div>
                            <div className='stepSubDiv'>
                                {step === true ?
                                    <>
                                        <p className="stepText" style={{ color: '#AFC4E1' }}> STEP 02</p>
                                        <div className='bottomLine' style={{ backgroundColor: '#AFC4E1' }}></div>
                                    </>
                                    :
                                    <>
                                        <p className="stepText" style={{ color: '#386CB5' }}> STEP 02</p>
                                        <div className='bottomLine' style={{ backgroundColor: '#386CB5' }}></div>
                                    </>
                                }

                            </div>
                        </div>
                        {/*  Input School Div First*/}
                        <div className="RegistrationInputDiv">
                            <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <FormControl fullWidth sx={{ mr: 1, my: 0.25, mx: 1 }} variant="standard">
                                    <MuiThemeProvider theme={theme}>
                                        <Autocomplete
                                            id="free-solo-demo"
                                            freeSolo
                                            options={AutoSearchArray.map((option) => option.school_name)}
                                            onChange={(event, v) => onTagsChange(event,v)}
                                            onInputChange={(e, v) => onTextUpadte(v)}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                style = {{color : "gray"}}
                                                    name="SchoolName"
                                                    label="School / University / Institute Name*"
                                                    id="input-with-sx"
                                                    value={SchoolName}
                                                    variant="filled"
                                                    // autoComplete='off'
                                                    size="small"
                                                    shrink={true}
                                                    disableUnderline={isVisible}
                                                    onChange={event => { setSchoolName(event.target.value); handleChangeForSearch(event) }}
                                                    error={false}
                                                    helperText={''}
                                                />
                                            )}
                                        />
                                    </MuiThemeProvider>
                                </FormControl>
                            </Box>
                        </div>
                        {/*  Input School Div First*/}

                        {/*  Input State Div*/}
                        <div className="RegistrationInputDiv">
                            <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <FormControl fullWidth sx={{ mr: 1, my: 0.25, mx: 1 }} variant="standard">
                                    <MuiThemeProvider theme={theme}>
                                        <TextField
                                            size="small"
                                            disableUnderline={isVisible}
                                            name="State"
                                            id="input-with-sx"
                                            shrink={true}
                                            label="State*"
                                            variant="filled"
                                            autoComplete='off'
                                            value={State}
                                            onFocus={() => setIsVisible(false)}
                                            onBlur={() => setIsVisible(true)}
                                            onChange={event => { setState(event.target.value); Validation2(event) }}
                                            error={errorState === true ? true : false}
                                            helperText={errorState === true ? stateErrorMessage : ''}
                                            disabled={addresFieldDisableState}
                                        />
                                    </MuiThemeProvider>
                                </FormControl>
                            </Box>

                        </div>
                        {/*  Input State Div*/}
                        {/*  Input City Div*/}
                        <div className="RegistrationInputDiv">
                            <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <FormControl fullWidth sx={{ mr: 1, my: 0.25, mx: 1 }} variant="standard">
                                    <MuiThemeProvider theme={theme}>
                                        <TextField
                                            name="City"
                                            size="small"
                                            disableUnderline={isVisible}
                                            id="input-with-sx"
                                            shrink={true}
                                            label="City*"
                                            variant="filled"
                                            autoComplete='off'
                                            value={City}
                                            onFocus={() => setIsVisible(false)}
                                            onBlur={() => setIsVisible(true)}
                                            onChange={event => { setCity(event.target.value); Validation2(event) }}
                                            error={errorCity === true ? true : false}
                                            helperText={errorCity === true ? cityErrorMessage : ''}
                                            disabled={addresFieldDisableCity}
                                        />
                                    </MuiThemeProvider>
                                </FormControl>
                            </Box>

                        </div>
                        {/*  Input City* Div*/}
                        {/*  Input Country* Div*/}
                        <div className="RegistrationInputDiv">
                            <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <FormControl fullWidth sx={{ mr: 1, my: 0.25, mx: 1 }} variant="standard">
                                    <MuiThemeProvider theme={theme}>
                                        <TextField
                                            name="Country"
                                            size="small"
                                            disableUnderline={isVisible}
                                            id="input-with-sx"
                                            shrink={true}
                                            label="Country*"
                                            variant="filled"
                                            autoComplete='off'
                                            value={Country}
                                            onFocus={() => setIsVisible(false)}
                                            onBlur={() => setIsVisible(true)}
                                            onChange={event => { setCountry(event.target.value); Validation2(event) }}
                                            error={errorCountry === true ? true : false}
                                            helperText={errorCountry === true ? countryErrorMessage : ''}
                                            disabled={addresFieldDisableCountry}
                                        />
                                    </MuiThemeProvider>
                                </FormControl>
                            </Box>

                        </div>
                        {/*  Input Country* Div*/}
                        {/*  Input Zip Code* Div*/}
                        <div className="RegistrationInputDiv">
                            <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <FormControl fullWidth sx={{ mr: 1, my: 0.25, mx: 1 }} variant="standard">
                                    <MuiThemeProvider theme={theme}>
                                        <TextField
                                            name="ZipCode"
                                            size="small"
                                            disableUnderline={isVisible}
                                            id="input-with-sx"
                                            shrink={true}
                                            label="Zip Code*"
                                            variant="filled"
                                            autoComplete='off'
                                            value={ZipCode}
                                            maxLength="6"
                                            onFocus={() => setIsVisible(false)}
                                            onBlur={() => setIsVisible(true)}
                                            onChange={event => { setZipCode(event.target.value); Validation2(event) }}
                                            error={errorZipCode === true ? true : false}
                                            helperText={errorZipCode === true ? zipCodeErrorMessage : ''}
                                            disabled={addresFieldDisableZip}
                                        />
                                    </MuiThemeProvider>
                                </FormControl>
                            </Box>

                        </div>
                        {/*  Input Zip Code* Div*/}
                        {/* I Agree to the Terms */}
                        <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'flex-start', marginTop: '20px' }}>
                            <i onClick={() => radioFunction()} className="checkBoxColor material-icons"
                                style={{ color: agreeTerm === true ? '#2DD152' : '#000000', cursor: 'pointer' }}
                            >
                                {agreeTerm === false ? 'radio_button_unchecked' : 'check_circle'}
                            </i>

                            <span className="privacyText" style={{ marginLeft: '5px' }}>
                                <span style={{
                                    color: "black", textAlign: "left",
                                    font: "normal normal normal 16px/22px Open Sans",
                                    letterSpacing: "0px",
                                    color: "#00000080",
                                }}>I agree to the
                                </span>
                                <span className="privacyTextColour" style={{ color: "#386CB5", fontWeight: '600', cursor: 'pointer' }} onClick={() => setIsTermModal(true)}> Terms of Service </span>
                                <span style={{
                                    color: "black", textAlign: "left",
                                    font: "normal normal normal 16px/22px Open Sans",
                                    letterSpacing: "0px",
                                    color: "#00000080",
                                    cursor: 'auto',
                                }}> and </span>
                                <span className="privacyTextColour" style={{ color: "#386CB5", fontWeight: '600', cursor: 'pointer' }} onClick={() => setIsTermModal(true)}> Privacy Policy. </span>
                                {/* </span> */}
                            </span>
                        </div>
                        <div className="registerErrorMessageDiv">
                            {/* {!agreeTerm && <p className="registerErrorMessage">{'*Please agree to the Terms of Service and Privacy Policy'}</p>} */}
                            {agreeTerm === false && <p className="registerErrorMessage">{ErrorMessage}</p>}
                        </div>
                        {/* I Agree to the Terms */}
                        {/*  NextStep Button*/}
                        <div className='CreateStepBtnDiv'>
                            <div className='BackStepBtn' onClick={() => backStep()} >
                                <img alt="" src={StepBack} className="BackBtnImg" />
                                <p className='BackStepText'>Step Back</p>
                            </div>
                            <Button className="CreateStepBtn" onClick={() => RegisterUser()}
                            // style={ createAccountBtn === true ? { cursor: "default" } : { cursor: "pointer" }}  disabled={createAccountBtn}
                            >
                                <p className='CreateStepText'>Create Account</p>
                            </Button>
                        </div>

                    </div>
                }
                <div className='bottomDiv'>
                    <p className='text-1'>© 2022 All rights reserved | Built for Chronicle Cloud.</p>
                    <p className='text-2'>Powered with Synapse Xtreme Engine (SXE)</p>
                </div>

            </div>
            {/* Modal  Div */}
            <div>
                <Modal size="lg" isOpen={isTermModal} centered={true}>
                    <ModalHeader className='termsModalHeader'>
                        Terms and Condition
                        <i onClick={() => setIsTermModal(false)} className="closeModalIcon material-icons" >close</i>
                    </ModalHeader>
                    <ModalBody className='StepBarModal'>
                        <div style={{ width: '100%' }}>
                            <TermConditionPage />
                        </div>
                    </ModalBody>
                </Modal>
            </div>
            {/* Modal  Div */}
        </div >
    );

};

const mapStateToProps = state => ({
    state: state,
    userStatusData: state.register,
    schoolSearchData: state.placeSearch,

});
const mapDispatchToProps = dispatch => {
    return {
        register: data => dispatch(register(data)),
        placeSearch: data => dispatch(placeSearch(data)),
        selectPlace: data => dispatch(selectPlace(data)),
        registerReset: () => dispatch(registerReset()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    RegisterPage
);




import React, { useState } from "react";
import PropTypes from 'prop-types';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import tickIcon from 'Assets/FormativeAssessmentModule/tick.svg';
import ClassGradeBookColorFooter from '../ClassGradeBookColorFooterModule/ClassGradeBookColorFooter';
import Form from "react-bootstrap/Form";
import { ColourCode } from 'Config/Constant'
import "./ClassGradeBookAddStudent.scss";
import CommonTextField from "Components/CommonTextField/CommonTextField";

const ClassGradeBookAddStudent = (props) => {
    const { studentsList, updateClassGradebookColorRequest, updateClassGradebookInputRequest, updateClassGradebookAllColorRequest,ClassData, GradebookAddError } = props
    const _ = require('lodash');
    const [score, setScore] = useState(studentsList.grading_event_student_score > 0 ? studentsList.grading_event_student_score : '');
    const [numberValidation, setNumberValidation] = useState(false)
    const [isErrorShow, setIsErrorShown] = useState(false)

    const [comment, setComment] = useState(studentsList.grading_event_student_comments);

    // -----------Update Single Color-----
    const selectColor = (color, index) => {
        const data = {
            colorCode: color,
            index: index
        }
        updateClassGradebookColorRequest(data)
    }
    // -----------Update Single Color-----

    // -----------Update InputType-----
    const changeInput = (e, type, index) => {
        // console.log(e)
        if (type === "score") {
            let scoreValue = /^\d+$/;
            if(e.target.value.length === 0) {
                // setSaveDisable(true)
                // setScore(e.target.value)
            }
            if (e.target.value.match(scoreValue)) {
                const data = {
                    value: (e.target.value.slice(0, 3)),
                    type: type === "score" ? true : false,
                    index: index
                }
                // setScore(e.target.value)
                // setSaveDisable(false)
                updateClassGradebookInputRequest(data)
            }
        } else {
            const data = {
                value: e.target.value,
                type: type === "score" ? true : false,
                index: index
            }
            // setComment(e.target.value)
            updateClassGradebookInputRequest(data)
        }

    }

    const handleChangeScore = (event,type, index) => {
        
        // let isnum = /^[A-F][+-]$/.test(event.target.value);
        // console.log(isnum)
        if(event.target.value.length == 0) {
            setScore(event.target.value)
            const data = {
                value: (event.target.value),
                type: type === "score" ? true : false,
                index: index
            }
            updateClassGradebookInputRequest(data)
        } else {
            // CHECK IF NUMBER 
        var pattern = /^[0-9]+/;
        if(pattern.test(event.target.value[0])) {
            const scoreValue = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;
                // const scoreValue = /^\d{1,3}(?:\.\d+)?(?:$|[+-]?\s*[A-Za-z][+-]?$)|(?:[A-Za-z][+-]?)$/;
                // console.log(scoreValue.test('1A+'))
                if ((!event.target.value || scoreValue.test(event.target.value))) {
                    // if(event.target.value.length < 4 && /^[0-9]+(\.[0-9]+)?$/.test(event.target.value)) {
                    setScore(event.target.value)
                    const data = {
                        value: (event.target.value),
                        type: type === "score" ? true : false,
                        index: index
                    }
                    // setScore(e.target.value)
                    // setSaveDisable(false)
                    updateClassGradebookInputRequest(data)
                    // setModalScore(event.target.value);
                }
        } else {
            console.log(96)
            const data = {
                value: event.target.value,
                type: type === "score" ? true : false,
                index: index,
                inputType: 'grades'
              };
              updateClassGradebookInputRequest(data);   
        }
    }
        // setScore(event.target.value)
        //     const data = {
        //         value: (event.target.value),
        //         type: type === "score" ? true : false,
        //         index: index
        //     }
        //     updateClassGradebookInputRequest(data)

    //     if(numberValidation) {
    //     const scoreValue = /^[0-9]{0,3}(\.[0-9]{0,1})?$/;
    //     // const scoreValue = /^\d{1,3}(?:\.\d+)?(?:$|[+-]?\s*[A-Za-z][+-]?$)|(?:[A-Za-z][+-]?)$/;
    //     // console.log(scoreValue.test('1A+'))
    //     if ((!event.target.value || scoreValue.test(event.target.value))) {
    //         // if(event.target.value.length < 4 && /^[0-9]+(\.[0-9]+)?$/.test(event.target.value)) {
    //         setScore(event.target.value)
    //         const data = {
    //             value: (event.target.value),
    //             type: type === "score" ? true : false,
    //             index: index
    //         }
    //         // setScore(e.target.value)
    //         // setSaveDisable(false)
    //         updateClassGradebookInputRequest(data)
    //         // setModalScore(event.target.value);
    //     }
    // } else {

    //     // GRADES VALIDATION REGEX
    //         const scoreValue =  /^[A-F][+-]$/;
    //         console.log(scoreValue.test('a+'))
    //         if ((!event.target.value || scoreValue.test(event.target.value))) {
    //             // if(event.target.value.length < 4 && /^[0-9]+(\.[0-9]+)?$/.test(event.target.value)) {
    //             setScore(event.target.value)
    //             const data = {
    //                 value: (event.target.value),
    //                 type: type === "score" ? true : false,
    //                 index: index
    //             }
    //             // setScore(e.target.value)
    //             // setSaveDisable(false)
    //             updateClassGradebookInputRequest(data)
    //             // setModalScore(event.target.value);
    //         }
    // }
        // setModalScore(event.target.value);
    }

    const inputGrades = (event, type, index) => {
        console.log(event.target.value, typeof event.target.value, event.target.value.length);
        const scoreValue = /^[A-Z][+-]$/;
        // if (scoreValue.test(event.target.value)) {
          const data = {
            value: event.target.value,
            type: type === "score" ? true : false,
            index: index,
            inputType: 'grades'
          };
          updateClassGradebookInputRequest(data);
        // }
    }

// -----------Update InputType-----
    return (
        <Col xs="12" className="addStudents-grading-container">
            <Card className="addStudentsGrading br-8" style={{ position: 'relative' }}>
                <Card.Body>
                    <div className='overFlowClass'>
                        {studentsList && studentsList.length > 0 && studentsList.map((item, index) => {
                            let errorMsg = []
                            if(item && item?.grading_event_student_score_error && item?.grading_event_student_score_error.length > 0) {
                              
                                errorMsg = [item?.grading_event_student_score_error,...errorMsg]
                            }
                            if(errorMsg.length > 0) {
                                GradebookAddError(true)
                            } else {
                                GradebookAddError(false)
                            }
                            return (
                                <div className="singleDiv" key={index}>
                                    <div className="left-section">
                                        <div className="profile">
                                            {item.cc_student_pic_url !== "" ?
                                                <img
                                                    style={{ border: item.grading_event_student_color_code && `1px solid ${item.grading_event_student_color_code}` }}
                                                    className="profile-img"
                                                    src={item.cc_student_pic_url}
                                                    alt=""
                                                    width="50"
                                                    height="50"
                                                />
                                                :
                                                <div style={{ border: item.grading_event_student_color_code && `1px solid ${item.grading_event_student_color_code}` }} className='imageDumyGradesProfile'>
                                                    <h5 className='gradebookProfileImageText'>{(item.cc_student_first_name || "").charAt(0) || ""}{(item.cc_student_last_name || "").charAt(0) || ""}</h5>
                                                </div>
                                            }
                                            <label className="d-flex flex-wrap flex-column justify-content-center mb-0">
                                                <span>
                                                {item.cc_student_last_name?.replace(/(.{13})..+/, "$1…")}, {item.cc_student_first_name?.replace(/(.{13})..+/, "$1…")}
                                                </span>
                                                <span className="email">
                                                    {item.cc_student_email_id !== "" && _.truncate(item.cc_student_email_id, { 'length': 28, 'omission': '...' })}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="right-section number-arrow">
                                        <div className="inputs">
                                            <div className="colorsInput">
                                                {/* score input */}
                                                <div className="scoreBoxGrade flex-wrap">
                                                    {/* <Form.Control
                                                        className="score-input text-center"
                                                        placeholder="score"
                                                        type="number"
                                                        // value={score}
                                                        value={item.grading_event_student_score ? item.grading_event_student_score : ''}
                                                        // value={item.grading_event_student_score ? item.grading_event_student_score : ''}
                                                        // defaultValue={item.grading_event_student_score ? item.grading_event_student_score : ''}
                                                        onChange={(e) => changeInput(e, "score", index) }
                                                        style={{ border: item.grading_event_student_color_code && `1px solid ${item.grading_event_student_color_code}`, color: item.grading_event_student_color_code, background: `${item.grading_event_student_color_code!==""?`${item.grading_event_student_color_code}18`:""}` }}
                                                    /> */}
                                                      <CommonTextField
                                    // margin="normal"
                                    variant="outlined"
                                    // type='number'
                                    value={item.grading_event_student_score ? item.grading_event_student_score : ''}
                                    // onChange={(e) => changeInput(e, "score", index)}
                                    onChange={(event) => {handleChangeScore(event,"score", index)}}
                                    name="grading_event_student_score"
                                    // className="InfoInputType"
                                    className="score-input text-center"
                                    placeholder="Score" 
                                    disabled={ClassData?.cc_class_share_permission == 'read' ? true : false}
                                    />
                                    
                                     {/* <CommonTextField
                                    // margin="normal"
                                    variant="outlined"
                                    // type='number'
                                    value={item.grading_event_student_score ? item.grading_event_student_score : ''}
                                    // onChange={(e) => changeInput(e, "score", index)}
                                    onChange={(event) => {inputGrades(event,"score", index)}}
                                    name="grading_event_student_score"
                                    // className="InfoInputType"
                                    className="score-input text-center"
                                    placeholder="Score" 
                                    disabled={ClassData?.cc_class_share_permission == 'read' ? true : false}
                                    />
                                    <br />
                                    <span className="text-danger">
                                        {item?.grading_event_student_score_error && item?.grading_event_student_score_error.length > 0 ? item?.grading_event_student_score_error : ""}
                                        </span> */}
                                                </div>
                                                <div className="colorBoxGrade">
                                                    {/* score input */}
                                                    {ColourCode.map((colorCode, i) => (
                                                        <Button
                                                            key={i}
                                                            variant="default"
                                                            className={item.grading_event_student_color_code === colorCode ? 'color-btn-selected' : 'color-btn'}
                                                            style={{ background: colorCode }}
                                                            onClick={() => { selectColor(colorCode, index) }}
                                                            disabled={ClassData?.cc_class_share_permission == 'read' ? true : false}
                                                        >
                                                            {colorCode === item.grading_event_student_color_code ? <img src={tickIcon} alt='' /> : ""}
                                                        </Button>))}
                                                </div>
                                            </div>
                                    {/* <span className="text-danger d-block text-left" style={{fontSize : 12, fontWeight : 'bold', marginTop : 5, marginLeft : 3}}>
                                        {item?.grading_event_student_score_error && item?.grading_event_student_score_error.length > 0 ? item?.grading_event_student_score_error : ""}
                                        </span> */}
                                            <div className="commentBoxGrade">
                                                <Form.Control
                                                    as="textarea"
                                                    rows="2"
                                                    className="commentBox"
                                                    placeholder="Enter comment"
                                                    value={item.grading_event_student_comments}
                                                    // value={item.grading_event_student_comments}
                                                    onChange={(e) => { changeInput(e, "comment", index) }}
                                                    disabled={ClassData?.cc_class_share_permission == 'read' ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Card.Body>
            </Card>
            <ClassGradeBookColorFooter updateClassGradebookAllColorRequest={updateClassGradebookAllColorRequest} />

        </Col>
    );

}
ClassGradeBookAddStudent.propTypes = {
    updateClassGradebookColorRequest: PropTypes.func,
    updateClassGradebookInputRequest: PropTypes.func,
};

export default ClassGradeBookAddStudent

import React from 'react';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import DatePicker from "react-datepicker";
import { getFormattedDate } from "Utils/Helper";
import calendarIcon from 'Assets/ClassGradeBookModule/calendar.svg';
import './ClassGradeBookDatePicker.scss'

const ClassGradeBookDatePicker = (props) => {
    const { addGradebookPage, isDragDisabled, refreshCall, reorderState, categoryName, eventName, date, changeValueOfHeader, selectDateCheck, typeOfGradebook } = props;
    const signDate = sessionStorage.getItem('separatorFormat')
    const formatDate = sessionStorage.getItem('dateFormat')
    const todayDate = new Date();
    /* --------------- Refresh State------------ */
    const onRefreshCall = () => {
        if (!isDragDisabled) {
            refreshCall()
        }
    }
    /* --------------- Refresh State------------ */

    /* --------------- Reorder table State------------ */
    const onReorderState = () => {
        reorderState()
    }
    /* --------------- Reorder table State------------ */


    /* ---------------Change Category/Event State------------ */
    const changeInput = (e, type) => {
        const data = {
            value: e.target.value,
            type: type === 'cat' ? true : false
        }
        changeValueOfHeader(data)
    }
    /* ---------------Change Category/Event State------------ */

    /* ---------------Change Date State------------ */
    const changeDate = (value) => {
        selectDateCheck(value)
    }
    /* ---------------Change Date State------------ */


    return (
        <Card.Body>
            {addGradebookPage && <Row>
                <Col xs="12" className="selection-title">
                    <div className="grade-datepicker">
                        <DatePicker
                            onChange={e => changeDate(e)}
                            customInput={<div className="date">
                                <img className="calIcon" src={calendarIcon} alt="" width="18" height="18" />
                                {typeOfGradebook === "editCat" ? getFormattedDate(todayDate, formatDate, signDate) : getFormattedDate(date, formatDate, signDate)}
                            </div>}
                        />
                    </div>
                    <div className="grade-events-boxes">
                        <FormControl
                            // disabled={typeOfGradebook === 'editEvent' || typeOfGradebook === 'editCat' ? true : false}
                            disabled={typeOfGradebook === 'editCat' ? true : false}
                            placeholder="Enter Event Category*"
                            aria-label="event-category"
                            className="input"
                            value={categoryName}
                            onChange={(e) => { changeInput(e, "cat") }}
                        />
                        <FormControl
                            placeholder="Enter Event Name*"
                            aria-label="event-name"
                            className="input"
                            value={eventName}
                            onChange={(e) => { changeInput(e, "eve") }}
                        />
                    </div>
                </Col>
            </Row>}
        </Card.Body>
    )
}
ClassGradeBookDatePicker.propTypes = {
    addGradebookPage: PropTypes.bool,
    isDragDisabled: PropTypes.bool,
    isEventDragDisabled: PropTypes.bool,
    refreshCall: PropTypes.func,
    changeValueOfHeader: PropTypes.func,
    reorderEventState: PropTypes.func,
    selectDateCheck: PropTypes.func,
    typeOfGradebook: PropTypes.string,
    categoryName: PropTypes.string,
    eventName: PropTypes.string,
};

export default ClassGradeBookDatePicker 
import React, {  useState, useEffect, useLayoutEffect } from "react";
import {  useDispatch, useSelector } from "react-redux";
import { gapi } from "gapi-script";
import "./RosterDashboard.scss";
import CommonTextField from "../../../Components/CommonTextField/CommonTextField";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import googleDriveIcon from "Assets/RosterModule/googleDrive.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import addRosterIcon from "Assets/RosterModule/plus.png";
import rosterImport from "Assets/RosterModule/import-icon-2.png";
import cancelBlack from "Assets/RosterModule/cancelBlack.png";
import userFallbackImg from "Assets/RosterModule/userFallbackImg.png"
import camera from "Assets/RosterModule/camera.png";
import classIcon from "Assets/RosterModule/classIcon.png";
import noteIcon from "Assets/RosterModule/noteIcon.png";
import gradebookIcon from "Assets/RosterModule/gradeBookIcon.png";
import userShareIcon from "Assets/RosterModule/userShareIcon.png";
import profileIcon from "Assets/RosterModule/profileIcon.png";
import deleteIcon from "Assets/RosterModule/deleteIcon.png";
import back from "Assets/CommonComponent/back.png";
import UpgradePopUp from "../../upgradePopUp/upgradePopUp";
import localFolderIcon from "Assets/RosterModule/localFolder.png";
import loaderImag from "Assets/ProfileModule/PepperyMedium.gif";
import DivLoader from "Components/LoadingComponent/DivLoader";
import addAcademicImage from "Assets/AcademicModule/addAcademic.png";
import { createNotification } from "Config/notificationtoast";
import { getRandomName } from "Utils/Helper";
import {
  FirebseStroageFolder,
  API_KEY,
  CLIENT_ID,
  DISCOVERY_DOCS,
  SCOPES,
} from "Config/Constant";
import { storage } from "../../../firebase/firebase";
import {
  getRosterList,
  addRoster,
  deleteRoster,
  searchRoster,
  importRoster,
  setRosterSidebarOpen,
} from "Actions/RosterAction/rosterAction";
import { getStudentGradebookNullRequest } from "Actions/GradebookAction/studentGradebookAction";
import { getImage, getRosterImage } from "Config/commonFirebaseImage";
import * as routes from "Router/RoutesURL";
import ReactFileReader from "react-file-reader";
// import { gapi } from "gapi-script";
import closeIcon from "Assets/NoteModule/close.svg";
import tickIcon from "Assets/NoteModule/tick.svg";
import driveFolderIcon from "Assets/RosterModule/folder.png";
import driveCSVIcon from "Assets/RosterModule/filecsv_icon.png";
import iconSearch from "Assets/RosterModule/icon-search.png";
import listView from "Assets/RosterModule/listView.png";
import gridView from "Assets/RosterModule/gridView.png";
import listView2 from "Assets/RosterModule/listView2.png";
import gridView2 from "Assets/RosterModule/gridView2.png";
import iconNotes from "Assets/RosterModule/icon-notes.png";
import iconClass from "Assets/RosterModule/icon-class.png";
import iconGradeBook from "Assets/RosterModule/icon-gradebook.png";
import iconShare from "Assets/RosterModule/icon-share.png";
import iconProfile from "Assets/RosterModule/icon-profile.png";
import readOnlyIcon from "Assets/RosterModule/read-only-icon.png";
import readWriteIcon from "Assets/RosterModule/read-write-icon.png";
import collaborateIcon from "Assets/RosterModule/collaborate-icon.png";
import warningIcon from "Assets/NoteModule/warning-icon.png";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
// import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScroll from 'react-infinite-scroller';

const RosterDashboard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [academicYear, setAcademicYear] = useState("");
  const [searchText, setSearchText] = useState("");
  const [academic_year_id, setAcademicYearId] = useState("");
  const [classIdForGradebook, setClassIdForGradebook] = useState("");
  const [UserProfile, setUserProfile] = useState("");
  const [UserProfileSize, setUserProfileSize] = useState("");
  const [UserProfileURL, setUserProfileURL] = useState("");
  const [showLoaderUpload, setShowLoaderUpload] = useState(false);
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [isError, setIsError] = useState("");
  const [isAddStudentSubmit, setIsAddStudentSubmit] = useState(false);
  const [tabButton, setTabButton] = useState("");
  const [InvelidMessageEmail, setInvelidMessageEmail] = useState("");
  const [searchBar, setSearchBar] = useState(false);
  const [filter, setFilter] = useState("both");
  const [nameFilter, setNameFilter] = useState("firstName");
  const [StudentList, setStudentList] = useState([]);
  const [studentListLoading, setStudentListLoading] = useState(false)
  const [StudentOverAllCount, setStudentOverAllCount] = useState([]);
  const [StudentsWithoutSearch, setStudentsWithoutSearch] = useState([]);
  const [expandPanel, setExpandPanel] = useState(-1);
  const [loadMoreCountByClass, setLoadMoreCountByClass] = useState(1);
  const [loadMoreCountStatusByClass, setLoadMoreCountStatusByClass] =
    useState(false);
  const [deleteStudent, setDeleteStudent] = useState(false);
  const [UploadFrom, setUploadFrom] = useState("drive");
  const [ShowPopOver, setShowPopOver] = useState(false);
  const [deletePopover, setDeletePopover] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDeletedIndex, setIsDeletedIndex] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreCount, setLoadMoreCount] = useState(1);
  const [loadMoreCountStatus, setLoadMoreCountStatus] = useState(false);
  const [gridViewData, setGridViewData] = useState(true);
  const [listViewData, setListViewData] = useState(false);
  const [GoogleAuth, setGoogleAuth] = useState(
    JSON.parse(sessionStorage.getItem("GoogleAuth"))
  );
  const [GoogleAuthItem, setGoogleAuthItem] = useState(
    JSON.parse(sessionStorage.getItem("GoogleAuthItem"))
  );
  const [GoogleFiles, setGoogleFiles] = useState([]);
  const [googleSelectedFile, setGoogleSelectedFile] = useState("");
  const [SelectFileModal, setSelectFileModal] = useState(false);
  const [GetFileLoader, setGetFileLoader] = useState(false);
  const [googleDrivePath, setGoogleDrivePath] = useState([]);
  const [name, setName] = useState("");
  const [importLoader, setImportLoader] = useState(false);
  const [class_id, setClassId] = useState("");
  const [Total_Count, setTotalCount] = useState(0);
  const [selectStudentId, setSelectStudentId] = useState("");
  const [selectIndex, setSelectIndex] = useState(-1);
  const [height, setHeight] = useState(0);
  const [numBoxes, setNumBoxes] = useState(100);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false)
  const [studentDivHeight, setStudentDivHeight] = useState(0)
  const [driveEmail, setDriveEmail] = useState('')
  const [groupByClassId, setGroupByClassId] = useState(null)
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  // const divRef = useRef(null);
  const scollToRef = useRef();
  const ref = useRef(null);
  const DataRef = useRef(null);
  const groupRef = useRef(null)
  let noteStrinf = "(Home>Classes>Roster)";
  //         const { location = {} } = this.props
  //         const { state = {} } = location;
  const { data = "" } = history.location.state;
  const _ = require("lodash");
  let userLocalData = JSON.parse(sessionStorage.getItem("UserData"));
  const { finalClasses } = history.location.state;

  const rosterData = useSelector((state) => state?.roster);
  const loader = useSelector((state) => state?.roster?.loader);
  const deleteStatus = useSelector((state) => state?.roster?.delete_roster?.status)
  const pattern2 = /^[a-zA-Z]+$/i;
  const saveButtonNW = [(FirstName != "" && LastName != "") ? 'saveButton align-items-center' : 'saveButton saveButtonlessOpac align-items-center'];
  const addAnotherButtonNW = [(FirstName != "" && LastName != "") ? 'addAnotherButton align-items-center' : 'addAnotherButton saveButtonlessOpac align-items-center'];
 
  useOnClickOutside(groupRef, () => setGroupByClassId(null));

  function useOnClickOutside(groupRef, handler) {

    useEffect(
      () => {
        
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (event.target.closest('.optionBtnDiv-RD') || event.target.closest('.shareOptionDiv-RD')) {
            return;
          }
          handler(event)
         
          
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      [groupRef, handler]
    );
  }

  useEffect(() => {
    // const boxHeight = 80; // height of each box in pixels
    // const windowHeight = window.innerHeight;
    // const numBoxes = Math.floor(windowHeight / boxHeight);
    // console.log(windowHeight, numBoxes)
    // setNumBoxes(numBoxes);
    const boxWidth = 403; // width of each box in pixels
    const boxHeight = 80; // height of each box in pixels
    const divWidth = ref?.current?.offsetWidth;
    // const divHeight = window.innerHeight
    const divHeight = ref?.current?.offsetHeight;
    const numBoxesWidth = Math.floor(divWidth / boxWidth);
    // const numBoxesWidth = 403
    const numBoxesHeight = Math.floor(divHeight / boxHeight);
    // const numBoxes = numBoxesWidth * numBoxesHeight;
    // setNumBoxes(numBoxes);
  }, []);
  const ref2 = useRef();
  const [num2Boxes, setNum2Boxes] = useState(100)
  useEffect(() => {

    const box2Width = 308; // width of each box in pixels
    const box2Height = 90; // height of each box in pixels
    const div2Width = ref2?.current?.offsetWidth;
    // const divHeight = window.innerHeight
    const div2Height = ref2?.current?.offsetHeight;
    const num2BoxesWidth = Math.floor(div2Width / box2Width);
    // const numBoxesWidth = 403
    const num2BoxesHeight = Math.floor(div2Height / box2Height);
    const num2Boxes = num2BoxesWidth * num2BoxesHeight;
    // setNum2Boxes(num2Boxes);
  }, [ref2?.current]);
  useEffect(() => {
    if((numBoxes != 'NaN' && numBoxes > 0 || (num2Boxes != 'NaN' && num2Boxes > 0))) {
    const { data = "" } = history.location.state;
    let classId = "";
    if (data && data.class_data && data.class_data.length > 0) {
      classId = data.class_data[0].cc_class_id;
    }
    setAcademicYear(data.academic_year);
    setAcademicYearId(data.academic_year_id);
    setClassIdForGradebook(classId);
    const value = {
      class_id: "",
      filter_by: filter,
      name_filter_by: nameFilter,
      page_no: loadMoreCount > 1 ? "full" : loadMoreCount,
      limit : numBoxes || num2Boxes,
      academic_year: data.year_ids,
      // academic_year: data.academic_year_id,
    };
    dispatch(getRosterList(value));
  }
  },[numBoxes, num2Boxes])
  // useEffect(() => {
  //   console.log(251, num2Boxes)
  //   if(num2Boxes != 'NaN' && num2Boxes > 0) {
  //   const { data = "" } = history.location.state;
  //   let classId = "";
  //   if (data && data.class_data && data.class_data.length > 0) {
  //     classId = data.class_data[0].cc_class_id;
  //   }
  //   setAcademicYear(data.academic_year);
  //   setAcademicYearId(data.academic_year_id);
  //   setClassIdForGradebook(classId);
  //   const value = {
  //     class_id: "",
  //     filter_by: filter,
  //     name_filter_by: nameFilter,
  //     page_no: loadMoreCountByClass,
  //     limit : num2Boxes,
  //     academic_year: data.year_ids,
  //     // academic_year: data.academic_year_id,
  //   };
  //   dispatch(getRosterList(value));
  // }
  // },[num2Boxes])
  const handleScroll2 = (event) => {
    const {scrollTop, clientHeight, scrollHeight } = event.target;
 
    setStudentDivHeight(clientHeight)
  
    if (rosterData?.roster_Data?.data?.[0] && rosterData?.roster_Data?.data?.[0]?.length > 0 ) {
    if((StudentList && StudentList.length !=  StudentOverAllCount?.[0]?.count)) {
      
    if (Math.round(scrollTop + clientHeight) == scrollHeight) {
      // console.log('ifscroll')
      // console.log(num2Boxes , "numBoxes")
      // console.log(223,loadMoreCount)
      // const { data = "" } = history.location.state;
      // let pageNoData = loadMoreCountByClass;
      // pageNoData++;
      // console.log(227,pageNoData)
      // setLoadMoreCountByClass(pageNoData)
      // setLoadMoreCountStatusByClass(true);
      // setLoadMoreLoader(true)

      // const value = {
      //   class_id: data.class_data[expandPanel].cc_class_id,
      //   filter_by: filter,
      //   name_filter_by: nameFilter,
      //   page_no: pageNoData,
      //   limit: num2Boxes,
      //   // academic_year: data.year_ids,
      //   academic_year: data.academic_year_id,
      // };
      // console.log(2432, value)
      // dispatch(getRosterList(value));
      
      const { data } = history.location.state;
      let value = loadMoreCountByClass;
      value++;
      setLoadMoreCountByClass(value);
      setLoadMoreCountStatusByClass(true);

      // this.setState({
      //     loadMoreCountByClass: value,
      //     loadMoreCountStatusByClass: true,
      // }, () => {
      const payloadData = {
        class_id: data.class_data[expandPanel].cc_class_id,
        filter_by: filter,
        name_filter_by: nameFilter,
        page_no: value,
        academic_year: data.year_ids,
        // limit : 16
      };
      
      // this.props.getRosterList(value);
      dispatch(getRosterList(payloadData));

    }
  }
}
    
  }
  const handleScroll = () => {
    console.log('handlescroll')
    const { scrollTop, clientHeight, scrollHeight } = DataRef.current;
  
    setStudentDivHeight(clientHeight)
    if (rosterData?.roster_Data?.data?.[0] && rosterData?.roster_Data?.data?.[0].length > 0 ) {
    if(StudentList && StudentList.length != StudentOverAllCount?.[0]?.count) {
    if (parseInt(scrollTop ) + clientHeight == scrollHeight) {
   
      const { data = "" } = history.location.state;
         let pageNoData = loadMoreCount;
      pageNoData++;
      
      setLoadMoreCount(pageNoData)
      setLoadMoreCountStatus(true);
      setLoadMoreLoader(true)
      const value = {
        class_id: "",
        filter_by: filter,
        name_filter_by: nameFilter,
        page_no: pageNoData,
        limit: numBoxes,
        academic_year: data.year_ids,
        // academic_year: data.academic_year_id,
      };
      
      dispatch(getRosterList(value));
      
     
    }
  }
}
  }

//    const fetchData = async () => {
//     console.log('fettchdata')
//     const { data = "" } = history.location.state;
//     let pageNoData = loadMoreCount;
//  pageNoData++;
//  console.log(227,pageNoData)
//  setLoadMoreCount(pageNoData)
//  setLoadMoreCountStatus(true);
//  const value = {
//    class_id: "",
//    filter_by: filter,
//    name_filter_by: nameFilter,
//    page_no: pageNoData,
//    limit: numBoxes,
//    // academic_year: data.year_ids,
//    academic_year: data.academic_year_id,
//  };
//  console.log(271, value)
//  dispatch(getRosterList(value));
//       };

  useEffect(() => {

  }, [DataRef]);

  // useEffect(() => {
  //   if (hasScrolledToBottom && StudentList.length < 64) {
  //     handleScrollFunc();
  //     setHasScrolledToBottom(false);
  //   }
  // }, [hasScrolledToBottom, StudentList]);



  useEffect(() => {
    if(deleteStatus == 200) {
      setIsDeleted(false)
    }
  },[deleteStatus])

  // useEffect(() => {
  //   setHeight(ref?.current?.clientHeight);
  // });

  useEffect(() => {
    if (GoogleAuthItem.hasOwnProperty("wt")) {
      setDriveEmail(GoogleAuthItem.wt.cu)
    } else if (GoogleAuthItem.hasOwnProperty("Qt")) {
      setDriveEmail(GoogleAuthItem.Qt.zu)
    }
  }, [GoogleAuthItem]);

  // ---------Open Sidebar by Defult-------
  useEffect(() => {
    if (rosterData?.sideMenu) {
      window.$("#myModal2").modal("show");
      dispatch(setRosterSidebarOpen(false));
    }
  }, []);
  // ---------Open Sidebar by Defult-------

  // ---------UNSAFE_componentWillMount-------
  useEffect(() => {
    // if(numBoxes > 0) {
    getRoster();
    // }
  }, [filter, nameFilter]);
  // ---------UNSAFE_componentWillMount-------

  // useEffect(() => {

  // },[filter])
  // useLayoutEffect(() => {
  //   if(num2Boxes > 0) {
  //     getRostergbc();
  //   }
  // }, [filter, nameFilter]);
  const BackTOHome = () => {
    history.goBack();
  };
  // ---------BackTOHome-------

  //get roster will reciev props
  useEffect(() => {
    if (rosterData?.action === "GET_ROSTER_LIST_SUCCESS") {
      if (rosterData?.roster_Data && rosterData?.roster_Data?.status === 200) {
        if (filter !== "by_class") {
          
          if (
            rosterData?.roster_Data?.data?.[0] &&
            rosterData?.roster_Data?.data?.[0].length > 0
          ) {
            let getRosterData = [];
            // fetchRosterImages(rosterData?.roster_Data?.data?.[0]);
            if (loadMoreCount > 1) {
              // ---get Roster LoadMore---
              if (loadMoreCountStatus) {
                getRosterData = StudentList;
                for (let value of rosterData?.roster_Data?.data?.[0]) {
                  getRosterData.push(value);
                }
                
                setStudentList(getRosterData);
                setStudentsWithoutSearch(getRosterData);
                setStudentOverAllCount(rosterData?.roster_Data?.data?.[1]);
                setLoadMoreCountStatus(false);
                setLoadMoreLoader(false)
                // this.setState({
                //     StudentList: getRosterData,
                //     StudentsWithoutSearch: getRosterData,
                //     StudentOverAllCount: nextProps.rosterData.roster_Data.response_data[1],
                //     loadMoreCountStatus: false
                // })
              } else {
                getRosterData = StudentList;
              }
              // ---get Roster LoadMore---
            } else {
              console.log(485 ,'else')
              fetchRosterImages(rosterData?.roster_Data?.data?.[0])
              if(StudentList?.length == 0) {
              setStudentList(rosterData?.roster_Data?.data?.[0]);
              }
              setStudentsWithoutSearch(rosterData?.roster_Data?.data?.[0]);
              //   setStudentOverAllCount(rosterData?.roster_Data?.data?.[1])
              // this.setState({
              //     StudentList: nextProps.rosterData.roster_Data.response_data[0],
              //     StudentsWithoutSearch: nextProps.rosterData.roster_Data.response_data[0],
              //     StudentOverAllCount: nextProps.rosterData.roster_Data.response_data[1]
              // })
              //   getRosterData = rosterData?.roster_Data?.data?.[0];
            }
            // ---Fatch Firebase Image---
            // (async () => {
            //   for (let rosterItem of getRosterData) {

            //     if (rosterItem.cc_student_pic_url) {
            //         if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
            //             rosterItem.cc_student_pic_url = await getImage(rosterItem.cc_student_pic_url);
            //         }
            //     }
            // }
            // // ---Fatch Firebase Image---
            // setStudentList(getRosterData)
            // setStudentsWithoutSearch(getRosterData)
            // setStudentOverAllCount(rosterData?.roster_Data?.data?.[1])
            // // this.setState({
            // //     StudentList: getRosterData,
            // //     StudentsWithoutSearch: getRosterData,
            // //     StudentOverAllCount: nextProps.rosterData.roster_Data.response_data[1]
            // // })
            // })()
          } else {
            setStudentList([]);
            setStudentsWithoutSearch([]);
            setStudentOverAllCount([]);
            // this.setState({
            //     StudentList: [],
            //     StudentsWithoutSearch: [],
            //     StudentOverAllCount: []
            // })
          }
        } else {
          if (
            rosterData?.roster_Data?.data?.[0] &&
            rosterData?.roster_Data?.data?.[0].length > 0
          ) {
            let getRosterData = [];
            if (loadMoreCountByClass > 1) {
              // ---get Roster LoadMore---
              if (loadMoreCountStatusByClass) {
                getRosterData = StudentList;
                for (let value of rosterData?.roster_Data?.data?.[0]) {
                  getRosterData.push(value);
                }
                
                setStudentList(getRosterData);
                setStudentsWithoutSearch(rosterData?.roster_Data?.data?.[1]);
                setStudentOverAllCount(rosterData?.roster_Data?.data?.[1]);
                setLoadMoreCountStatusByClass(false);
                // this.setState({
                //     StudentList: getRosterData,
                //     StudentsWithoutSearch: getRosterData,
                //     StudentOverAllCount: nextProps.rosterData.roster_Data.response_data[1],
                //     loadMoreCountStatusByClass: false
                // })
              } else {
                getRosterData = StudentList;
              }
              // ---get Roster LoadMore---
            } else {
              fetchRosterImages(rosterData?.roster_Data?.data?.[0]);
              setStudentList(rosterData?.roster_Data?.data?.[0]);
              setStudentsWithoutSearch(rosterData?.roster_Data?.data?.[0]);
              // setStudentOverAllCount(rosterData?.roster_Data?.data?.[1])
              // this.setState({
              //     StudentList: nextProps.rosterData.roster_Data.data[0],
              //     StudentsWithoutSearch: nextProps.rosterData.roster_Data.data[0],
              //     StudentOverAllCount: nextProps.rosterData.roster_Data.data[1]
              // })
              //   getRosterData = rosterData?.roster_Data?.data?.[0];
            }
            // ---Fatch Firebase Image---
            
            //   (async () => {
            //     for (let rosterItem of getRosterData) {

            //       if (rosterItem.cc_student_pic_url) {
            //           if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
            //               rosterItem.cc_student_pic_url = await getImage(rosterItem.cc_student_pic_url);
            //           }
            //       }
            //   }
            //   // ---Fatch Firebase Image---
            //   setStudentList(getRosterData)
            //   setStudentsWithoutSearch(getRosterData)
            //   setStudentOverAllCount(rosterData?.roster_Data?.data?.[1])
            //   // this.setState({
            //   //     StudentList: getRosterData,
            //   //     StudentsWithoutSearch: getRosterData,
            //   //     StudentOverAllCount: nextProps.rosterData.roster_Data.response_data[1]
            //   // })
            //   })()
          } else {
            setStudentList([]);
            setStudentsWithoutSearch([]);
            setStudentOverAllCount([]);
            // this.setState({
            //     StudentList: [],
            //     StudentsWithoutSearch: [],
            //     StudentOverAllCount: []
            // })
          }
        }
      }
    }

    //ADD ROSTER SUCCESS

    if (
      (rosterData && rosterData?.action === "ADD_ROSTER_FAILURE") ||
      "IMPORT_ROSTER_FAILURE"
    ) {
      if (
        rosterData?.error &&
        (rosterData?.error?.message ===
          "Note limit has been exceeded. Please upgrade the plan" ||
          rosterData?.error?.message ===
            "Media limit has been exceeded. Please upgrade the plan" ||
          rosterData?.error?.message ===
            "Please update your subscription plan")
      ) {
        setShowPopUp(true);
        // this.setState({
        //     showPopUp: true
        // })
      }
    }
    // ---------get Roster WillReceiveProps-------
    if (rosterData?.action === "ADD_ROSTER_SUCCESS") {
      if (rosterData?.add_roster?.status === 200) {
        if (tabButton === "addAnother") {
          setUserProfile("");
          setUserProfileURL("");
          setUserProfileSize("");
          setShowLoaderUpload(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setIsError("");
          setIsAddStudentSubmit(false);
          // this.setState({
          //     UserProfile: '',
          //     UserProfileURL: '',
          //     UserProfileSize: '',
          //     showLoaderUpload: false,
          //     FirstName: '',
          //     LastName: '',
          //     Email: '',
          //     isError: '',
          //     isAddStudentSubmit: false,
          // })
          window.$("#myModal2").modal("show");
        }
      }
    }

    // ---------get search Roster-------
    // if (this.state.searchBar) {
    const fetchRosterSearchData = async () => {
      if (rosterData.action === "SEARCH_ROSTER_SUCCESS") {
        if (rosterData.search_roster !== null) {
          if (searchText.length == 0) {
            setStudentList(StudentsWithoutSearch);
          } else {
            if (rosterData.search_roster.status === 200) {
              if (rosterData.search_roster.data?.roster_data?.[0]) {
                setStudentList(rosterData.search_roster.data?.roster_data);
                setStudentOverAllCount(
                  rosterData.search_roster.data?.roster_count
                );
                // this.setState({
                //     StudentList: rosterData.search_roster.data?.roster_data,
                //     StudentOverAllCount: rosterData.search_roster.data?.roster_count,
                // })
                // let getRosterData = rosterData.search_roster.data?.roster_data;
                // ---Fatch Firebase Image---
                // fetchRosterImages(rosterData.search_roster.data?.roster_data, rosterData.search_roster.data?.roster_count)
                fetchSearchRosterImage(
                  rosterData.search_roster.data?.roster_data,
                  rosterData.search_roster.data,
                  rosterData.search_roster.data?.roster_data,
                  rosterData.search_roster.data?.roster_count
                );
                // const fetchSearchRosterImage = async () => {
                //     for (let rosterItem of getRosterData) {
                //     if (rosterItem.cc_student_pic_url) {
                //         if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                //             rosterItem.cc_student_pic_url = await getImage(rosterItem.cc_student_pic_url);
                //         }
                //     }
                // }

                // this.setState({
                //     StudentList: getRosterData
                // })

                // }
                // fetchSearchRosterImage()
                // setStudentList(getRosterData)

                // for (let rosterItem of getRosterData) {
                //     if (rosterItem.cc_student_pic_url) {
                //         if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                //             rosterItem.cc_student_pic_url = await getImage(rosterItem.cc_student_pic_url);
                //         }
                //     }
                // }
                // setStudentList(getRosterData)
                // // this.setState({
                // //     StudentList: getRosterData
                // // })
                // ---Fatch Firebase Image---
              } else {
                let DummyArray = [];
                setStudentList(DummyArray);
                // this.setState({
                //     StudentList: DummyArray
                // })
              }
            }
          }
        } else {
          let DummyArray = [];
          setStudentList(DummyArray);
          // this.setState({
          //     StudentList: DummyArray
          // })
        }
      }
    };
    fetchRosterSearchData();
    // }
    // ---------get search Roster-------
  }, [rosterData]);
  //end get roster will receive props

  const fetchRosterImages = async (data, count) => {
    setStudentList(data)
    let dataArr = data;
    for (let rosterItem of dataArr) {
      // if (rosterItem.cc_student_pic_url) {
        if (!rosterItem?.cc_student_pic_url?.includes("https")) {
          rosterItem.cc_student_pic_url = await getRosterImage(
            rosterItem.cc_student_pic_url
          );
        }
      // }
    }
    setStudentList(dataArr)
    // setStudentList(dataArr);
    setStudentsWithoutSearch(dataArr);
    setStudentOverAllCount(count ? count : rosterData?.roster_Data?.data?.[1]);
  };

  useEffect(() => {
    if(StudentList.length > 0) {
    fetchRosterImages(StudentList);
    }
  },[StudentList])

  const getAllRosterImages = async (url) => {
    let rosterImageUrl = await getRosterImage(url);
    return rosterImageUrl
    
  }

  const fetchSearchRosterImage = async (data, count) => {
    let dataArr = data;
    for (let rosterItem of dataArr) {
      if (rosterItem.cc_student_pic_url) {
        if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
          rosterItem.cc_student_pic_url = await getRosterImage(
            rosterItem.cc_student_pic_url
          );
        }
      }
    }
    // setStudentList(dataArr)
    setStudentList(dataArr);
    setStudentOverAllCount(count);
  };

  const handleClosePopUp = (e) => {
    setShowPopUp(false);
    // this.setState({
    //     showPopUp: false,
    // })
  };
  const getRostergbc = () => {
    // const { location = {} } = this.props
    // const { state = {} } = location;
    // const { data = '' } = state;
    const { data = "" } = history.location.state;
    let classId = "";
    if (data && data.class_data && data.class_data.length > 0) {
      classId = data.class_data[0].cc_class_id;
    }
    setAcademicYear(data.academic_year);
    setAcademicYearId(data.academic_year_id);
    setClassIdForGradebook(classId);
    // this.setState({
    //     academicYear: data.academic_year,
    //     academic_year_id: data.academic_year_id,
    //     classIdForGradebook: classId
    // })
    const value = {
      class_id: "",
      filter_by: filter,
      name_filter_by: nameFilter,
      page_no: loadMoreCountByClass,
      limit : num2Boxes,
      academic_year: data.year_ids,
      // academic_year: data.academic_year_id,
    };
    dispatch(getRosterList(value));
    // this.props.getRosterList(value)
  };
  // ---------getRosterList-------
  const getRoster = () => {
    setStudentListLoading(true)
    // setStudentList([])
    const { data = "" } = history.location.state;
    let classId = "";
    if (data && data.class_data && data.class_data.length > 0) {
      classId = data.class_data[0].cc_class_id;
    }
    setAcademicYear(data.academic_year);
    setAcademicYearId(data.academic_year_id);
    setClassIdForGradebook(classId);
    const value = {
      class_id: "",
      filter_by: filter,
      name_filter_by: nameFilter,
      page_no: loadMoreCount,
      limit : numBoxes,
      academic_year: data.year_ids,
      showLoader: true
      // academic_year: data.academic_year_id,
    };
    dispatch(getRosterList(value));
  };

  // ---------getRosterList-------

  // ---------Load More List-------
  const loadMoreFunction = () => {
    const { data = "" } = history.location.state;
    const value = {
      class_id: "",
      filter_by: filter,
      name_filter_by: nameFilter,
      page_no: "full",
      academic_year: data.year_ids,
      // academic_year: data.academic_year_id,
    };
    dispatch(getRosterList(value));
    //   let value = loadMoreCount;
    //   value++;
    //   setLoadMoreCount(value);
    //   setLoadMoreCountStatus(true);
    //   getRoster();
    // this.setState({
    //     loadMoreCount: value,
    //     loadMoreCountStatus: true,
    // }, () => {
    //     this.getRoster()
    // })
  };
  const loadMoreByClass = () => {
    // const { location = {} } = this.props
    // const { state = {} } = location;
    // const { data } = state;
    const { data } = history.location.state;
    let value = loadMoreCountByClass;
    value++;
    setLoadMoreCountByClass(value);
    setLoadMoreCountStatusByClass(true);
    // this.setState({
    //     loadMoreCountByClass: value,
    //     loadMoreCountStatusByClass: true,
    // }, () => {
    const payloadData = {
      class_id: data.class_data[expandPanel].cc_class_id,
      filter_by: filter,
      name_filter_by: nameFilter,
      page_no: value,
      academic_year: data.year_ids,
      // academic_year: data.academic_year_id,
    };
    // this.props.getRosterList(value);
    dispatch(getRosterList(payloadData));
    // })
  };
  // ---------Load More List-------

  //    const handleClosePopUp = (e) => {
  //     setShowPopUp(false)
  //     // this.setState({
  //     //     showPopUp: false,
  //     // })
  // }

  // ---------getRoster by Filter-------
  const filterSelect = (value) => {
    setFilter(value);
    setLoadMoreCount(1);
    setLoadMoreCountStatus(false);
    //   getRoster();
    // this.setState({
    //     filter: value,
    //     loadMoreCount: 1,
    //     loadMoreCountStatus: false,
    // }, () => {
    //     this.getRoster()
    // })
  };
  const filterSelectByClass = (value) => {
    setFilter(value);
    setLoadMoreCount(1);
    setLoadMoreCountStatus(false);
    // this.setState({
    //     filter: value,
    //     loadMoreCount: 1,
    //     loadMoreCountStatus: false,
    // }, () => {
    //     // this.getRoster()
    // })
  };

  const filterNameSelect = (value) => {
    if (filter === "by_class") {
      setNameFilter(value);
      setExpandPanel(expandPanel);
      setStudentList([]);
      setLoadMoreCountStatusByClass(false);
      setLoadMoreCountByClass(1);
      const { data } = history.location.state;

      // this.setState({
      //     nameFilter: value,
      //     expandPanel: this.state.expandPanel,
      //     StudentList: [],
      //     loadMoreCountStatusByClass: false,
      //     loadMoreCountByClass: 1
      // }, () => {
      //     const { location = {} } = this.props
      //     const { state = {} } = location;
      //     const { data } = state;
      const ApiValue = {
        class_id: class_id,
        filter_by: filter,
        name_filter_by: nameFilter,
        page_no:
          loadMoreCountByClass > 1
            ? "full"
            : loadMoreCountByClass,
            academic_year: data.year_ids,
            // academic_year: data.academic_year_id,
      };
      dispatch(getRosterList(ApiValue));
      // })
    } else {
      setNameFilter(value);
      setLoadMoreCount(1);
      setLoadMoreCountStatus(false);
      // getRoster();
      // this.setState({
      //     nameFilter: value,
      //     loadMoreCount: 1,
      //     loadMoreCountStatus: false,
      // }, () => {
      //     this.getRoster()
      // })
    }
  };
  // ---------getRoster by Filter-------

  // ---------Open Delete According to Id-------
  const deletePopoverHandler = (value) => {
    setDeletePopover(value);
    setIsDeleted(true);
    // this.setState({
    //     deletePopover: value
    // })
  };
  // ---------Open Delete According to Id-------

  // ---------Delete Student-------
  const deleteStudentHandler = (id, index) => {
    // const { location = {} } = this.props
    // const { state = {} } = location;
    // const { data = '' } = state;
    const { data } = history.location.state;
    setIsDeletedIndex(index);
    setLoadMoreCount(1);
    setLoadMoreCountStatus(false);
    setStudentList([])
    // this.setState({
    //     isDeletedIndex: index,
    //     loadMoreCount: 1,
    //     loadMoreCountStatus: false,
    // }, () => {
    // const { filter, loadMoreCount } = state;
    const value = {
      student_id: id,
      class_id: "",
      filter_by: filter,
      page_no: 1,
      academic_year: data.academic_year_id,
      limit : numBoxes
    };
    dispatch(deleteRoster(value));
    setDeletePopover("");
    // setIsDeleted(false);
    // this.setState({
    //     deletePopover: '',
    //     isDeleted: false,
    // })

    // })
  };
  // ---------Delete Student-------

  // ---------Edit PopOver Open According to Id-------
  const editStudent = (value) => {
    setStudentList((prevState) => {
      return prevState.map((item) => {
        //gets everything that was already in item, and updates "done"
        //else returns unmodified item
        return item.cc_student_id === value
          ? { ...item, editOpen: !item.editOpen }
          : item;
      });
    });
    // let ltudentlist = StudentList;
    // ltudentlist[index].editOpen = !ltudentlist[index].editOpen;
    // setStudentList(StudentList);
    // this.setState(ltudentlist)
  };
  // ---------Edit PopOver Open According to Id-------

  

  const resetPopOver = () => {
    setStudentList((prevState) => {
      return prevState.map((item) => {
        //gets everything that was already in item, and updates "done"
        //else returns unmodified item
        // return item.cc_student_id === value
        //   ? { ...item, editOpen: !item.editOpen }
        //   : item;
        return {...item, editOpen : false}
      });
    });
  }

  // ---------Create New Roster-------
  const createNewRoster = (tab) => {
    if (showLoaderUpload) {
      createNotification("error", "The image is still uploading.");
      return;
    }
    // const { location = {} } = this.props
    // const { state = {} } = location;
    // const { data = '' } = state;
    const { data } = history.location.state;
    setIsAddStudentSubmit(true);
    setTabButton(tab);
    // this.setState({
    //     isAddStudentSubmit: true,
    //     tabButton: tab
    // })
    if ((!FirstName || (FirstName && FirstName.trim().length <= 0)) || (!LastName || (LastName && LastName.trim().length <= 0))) {
      return false;
    }
    const pattern2 = /^[a-zA-Z]*$/;
    if ((!pattern2.test(FirstName)) || !pattern2.test(LastName)) {
      return;
    }
    if (Email) {
      const valid = /^(?![.])[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(?:\.[A-Za-z]{2,5})?$/;
      if (!valid.test(Email)) {
        setInvelidMessageEmail("Please enter valid email*");
        // this.setState({
        //     InvelidMessageEmail: 'Please enter valid email*',
        // });
        return false;
      }
    }
    // const { UserProfile, FirstName, LastName, Email, filter, UserProfileSize } = this.state;
    const value = {
      academic_year_id: data.academic_year_id,
      student_pic_size: UserProfileSize,
      student_first_name: FirstName,
      student_last_name: LastName,
      student_registration_email_id: Email,
      student_secondary_email_id: "",
      student_pic_url: UserProfile,
      student_id: "",
      class_id: "",
      by_class_sharing: "",
      filter_by: filter,
      page_no: 1,
      academic_year: data.academic_year_id,
      limit: numBoxes
    };
    
    
    setLoadMoreCount(1);
    setLoadMoreCountStatus(false);
    // this.setState({
    //     loadMoreCount: 1,
    //     loadMoreCountStatus: false,
    // })
    window.$("#myModal2").modal("hide");
    // this.props.addRoster(value)
    dispatch(addRoster(value));
  };
  // ---------Create New Roster-------

  // ---------Image Upload on Firebase-------
  const handleChangeFile = (e) => {
    if (e.length === 0) {
      return false;
    }
    const data = JSON.parse(sessionStorage.getItem("UserData"));
    const imageAsFile = e[0];
    setIsAddStudentSubmit(false);
    setInvelidMessageEmail("");
    setShowLoaderUpload(true);
    // this.setState({
    //     isAddStudentSubmit: false,
    //     InvelidMessageEmail: '',
    //     showLoaderUpload: true
    // })

    // ---------Image Type-------
    const ImageTypeArray = imageAsFile.name.split(".");
    const ImageType = ImageTypeArray[ImageTypeArray.length - 1];
    const media_Size = imageAsFile.size / 1000000;
    // ---------Image Type-------

    // ---------DummyName-------
    const GetRendomName = getRandomName();
    const DummyName = GetRendomName + "." + ImageType;
    // ---------DummyName-------

    // ---------FolderName-------
    const FinfFolderNameArray = data.cc_user_email_id.split("@");
    const FolderName = FinfFolderNameArray[0];
    // ---------FolderName-------
    
    const uploadTask = storage

      .ref(`${FirebseStroageFolder}/${FolderName}/roster/${DummyName}`)
      .put(imageAsFile);
    uploadTask.on(
      "state_changed",
      (snapShot) => {},
      (err) => {
        setShowLoaderUpload(false);
        // this.setState({
        //     showLoaderUpload: false
        // })
        createNotification("success", "The image was not uploaded. Please try again.");
      },
      () => {
        storage
          .ref(`${FirebseStroageFolder}/${FolderName}/roster/`)
          .child(DummyName)
          .getDownloadURL()

          .then((fireBaseUrl) => {
            setShowLoaderUpload(false);
            // this.setState({
            //     showLoaderUpload: false
            // })
            createNotification("success", "Great! The Image Upload was successful!");
            setUserProfileURL(fireBaseUrl);
            setUserProfile(`${FirebseStroageFolder}/${FolderName}/roster/${DummyName}`);
            setUserProfileSize(media_Size);
            // this.setState({
            //     UserProfileURL: fireBaseUrl,
            //     UserProfile: DummyName,
            //     UserProfileSize: media_Size,
            // }, () => {
            // })
          });
      }
    );

  };
  // ---------Image Upload on Firebase-------

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  // ---------handleChange-------
  const handleChange = (event) => {
    setIsAddStudentSubmit(false);
    setInvelidMessageEmail("")[event.target.name] = event.target.value;
    // this.setState(
    //     {
    //         isAddStudentSubmit: false,
    //         InvelidMessageEmail: '',
    //         [event.target.name]: event.target.value,
    //     },
    //     () => { }
    // );
  };
  // ---------handleChange-------

  // --------- search Roster  -------
  const handleSearchText = (event) => {
    setIsAddStudentSubmit(false);
    setInvelidMessageEmail("");
    if (event.target.value.length > 2) {
      let value = {
        search_string: event.target.value,
        academic_year_id: data.academic_year_id,
        page: loadMoreCount > 1 ? "full" : loadMoreCount,
        limit: 500,
        platform: "web",
      };
      dispatch(searchRoster(value));
    } else {
      setStudentList(rosterData?.roster_Data?.data?.[0]);
    }
  };
  // --------- search Roster  -------

  // --------- handleImportFiles Roster  -------
  const handleImportFiles = (files) => {
    if(files?.[0]?.name.split('.')?.[1] == 'csv') {
    try {
      var reader = new FileReader();
      const { data } = history.location.state;
      reader.onload = (e) => {
        if (
          reader.result === undefined ||
          reader.result === "undefined" ||
          reader.result === ""
        ) {
          createNotification(
            "error",
            "Selected file is empty. Please select another csv file having content in it"
          );
          return;
        }
        const str = reader.result;
        const reg = new RegExp(/(\r\n?|\n|\t)/g);
        const student_Data = str.replace(reg, ",#*#");
        setLoadMoreCount(1);
        setLoadMoreCountStatus(false);
        const value = {
          class_id: "",
          filter_by: filter,
          page_no: loadMoreCount,
          academic_year_id: data.academic_year_id,
          student_data: student_Data,
        };
        importCSVFile(value);
      };
      reader.readAsText(files[0]);
    } catch (e) {
      createNotification(
        "error",
        "Something Went Wrong, Not able to read File"
      );
      return;
    }
  } else {
    createNotification(
      "error",
      "Kindly upload the correct file format with .csv extension"
    );
  }
  };
  // --------- handleImportFiles Roster  -------

  // ----------To Get Load For SignIN---------
  const handleClientLoad = async () => {
    let GoogleAuth = "";
 
    await gapi.load("client:auth2", () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          scope: SCOPES,
          discoveryDocs: DISCOVERY_DOCS,
        })
        .then(() => {
          GoogleAuth = gapi.auth2.getAuthInstance();

          GoogleAuth.isSignedIn.listen(updateSigninStatus());
        }).catch((error) => console.log(error));
    });
  };
  // ----------To Get Load For SignIN---------

  // ----------To SignIn In Google Drive---------
  const handleAuthClick = (event) => {
    try {
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then((res) => {
          setSignedInUser(res);
        });
    } catch (e) {
      createNotification("error", "Something Went Wrong, Not able Login");
      return;
    }
  };
  // ----------To SignIn In Google Drive---------

  const expandPanelSub = (item, index) => {
    // const { location = {} } = this.props
    // const { state = {} } = location;
    // const { data } = state;
    const { data } = history.location.state;
    setExpandPanel(expandPanel === index ? -1 : index);
    setStudentList([]);
    setLoadMoreCountByClass(1);
    setLoadMoreCountStatusByClass(false);
    setClassId(item.cc_class_id);
    //   this.setState({
    //     expandPanel: expandPanel === index ? -1 : index,
    //     StudentList: [],
    //     loadMoreCountByClass: 1,
    //     loadMoreCountStatusByClass: false,
    //     class_id: item.cc_class_id,
    //   });

    const value = {
      class_id: item.cc_class_id,
      filter_by: filter,
      name_filter_by: nameFilter,
      page_no: 1,
      academic_year: data.year_ids,
      // academic_year: data.academic_year_id,
      limit: '12'
    };
    // this.props.getRosterList(value);
    dispatch(getRosterList(value));
  };

  // ----------To Update SignIn In Google Drive---------
  const updateSigninStatus = (isSignedIn) => {
    
    try {
      if (isSignedIn) {
        setSignedInUser(gapi.auth2.getAuthInstance().currentUser.je);
      } else {
        handleAuthClick();
      }
    } catch (e) {
      createNotification("error", "Something Went Wrong, Not able Login");
      return;
    }
  };
  // ----------To Update SignIn In Google Drive---------

  // ----------To SignIn Auth Save for Local Google Drive---------
  const setSignedInUser = (value) => {
    try {
      setGoogleAuth(true);
      setGoogleAuthItem(value);
      // this.setState({
      //     GoogleAuth: true,
      //     GoogleAuthItem: value
      // })
      sessionStorage.setItem("GoogleAuth", true);
      sessionStorage.setItem("GoogleAuthItem", JSON.stringify(value));
    } catch (e) {
      createNotification("error", "Something Went Wrong, Not able Login");
      return;
    }
  };
  // ----------To SignIn Auth Save for Local Google Drive---------

  // ----------To Get Load For SignOut---------
  const handleSignOutClick = (event) => {
    try {
      gapi.load("client:auth2", () => {
        gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            scope: SCOPES,
            discoveryDocs: DISCOVERY_DOCS,
          })
          .then(() => {
            var auth2 = gapi.auth2.getAuthInstance();
            auth2.disconnect();
            setGoogleAuth(false);
            // this.setState({
            //     GoogleAuth: false,

            // })
            sessionStorage.setItem("GoogleAuth", false);
          });
      });
    } catch (e) {
      createNotification("error", "Something Went Wrong, Not able Logout");
      return;
    }
  };
  // ----------To Get Load For SignOut---------

  // ----------To Get File From Drive ---------
  const handleFileLoad = () => {
    window.$("#myModalupload").modal("hide");
    const path = {
      name: "My drive",
      id: "root",
    };
    setGoogleDrivePath([]);
    getFolderFiles(path);
    // this.setState({
    //     googleDrivePath: []
    // }, () => {
    //     this.getFolderFiles(path);
    // })
  };
  // ----------To Get File From Drive ---------

  // ----------To Get File From Path ---------
  const getFolderFilesPath = (item) => {
    let GoogleDrivePath = googleDrivePath;
    let CreatePath = [];
    for (let value of GoogleDrivePath) {
      if (value.id === item.id) {
        break;
      } else {
        const path = {
          name: value.name,
          id: value.id,
        };
        CreatePath.push(path);
      }
    }
    setGoogleDrivePath(CreatePath);
    getFolderFiles(item);
    // this.setState({
    //     googleDrivePath: CreatePath
    // }, () => {
    //     this.getFolderFiles(item);
    // })
  };
  // ----------To Get File From Path ---------

  // ----------To Get File From Folder ---------
  const getFolderFiles = (value) => {
    try {
      gapi.load("client:auth2", async () => {
        let query = `'${value.id}'  in parents and trashed=false`;
        let path = {
          name: value.name,
          id: value.id,
        };
        let googleDrivePathData = googleDrivePath;
        googleDrivePathData.push(path);
        const uniqueArray = Array.from(
          new Set(googleDrivePathData.map((item) => item.id))
        ).map((id) => {
          return googleDrivePathData.find((item) => item.id === id);
        });
        const finalBreadCrumbArr = uniqueArray.slice(
          0,
          uniqueArray.findIndex((item) => item.id === path.id) + 1
        );
        setSelectFileModal(true);
        setGetFileLoader(true);
        setGoogleSelectedFile("");
        setGoogleFiles([]);
        setGoogleDrivePath(finalBreadCrumbArr);
        // this.setState({
        //     SelectFileModal: true,
        //     GetFileLoader: true,
        //     googleSelectedFile: '',
        //     GoogleFiles: [],
        //     googleDrivePath: googleDrivePath
        // })
        await gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            scope: SCOPES,
            discoveryDocs: DISCOVERY_DOCS,
          })
          .then(() => {
            gapi.client.drive.files
              .list({
                pageSize: 1000,
                fields:
                  "nextPageToken, files(id,size, name, mimeType, fileExtension, modifiedTime, webViewLink)",
                q: query,
              })
              .then((res) => {
                let GoogleFiles = [];
                for (let item of res.result.files) {
                  if (item.fileExtension) {
                    if (item.fileExtension === "csv") {
                      GoogleFiles.push(item);
                    }
                  }
                  const type = item.mimeType;
                  const typeCheck = type.split(".");
                  if (typeCheck[typeCheck.length - 1] === "folder") {
                    item.fileExtension = "folder";
                    GoogleFiles.push(item);
                  }
                }
                setGoogleFiles(GoogleFiles);
                setGetFileLoader(false);
                // this.setState({
                //     GoogleFiles: GoogleFiles,
                //     GetFileLoader: false,
                // }, () => {
                // })
              });
          });
      });
    } catch (e) {
      setGoogleFiles([]);
      setGetFileLoader(false);
      // this.setState({
      //     GoogleFiles: [],
      //     GetFileLoader: false,
      // })
    }
  };
  // ----------To Get File From Folder ---------

  // ----------To Get File Read from Drive ---------
  const getFileDetails = () => {
    try {
      setImportLoader(true);
      // this.setState({
      //     importLoader: true
      // })
      gapi.load("client:auth2", () => {
        let data = googleSelectedFile;
        gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            scope: SCOPES,
            discoveryDocs: DISCOVERY_DOCS,
          })
          .then(async () => {
            const file = data;
            const url = `https://www.googleapis.com/drive/v2/files/${data.id}?alt=media`;
            if (file.webViewLink) {
              var accessToken = gapi.auth.getToken().access_token;
              var xhr = new XMLHttpRequest();
              xhr.open("GET", url);
              xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
              xhr.onload = () => {
                if (xhr.status === 200) {
                  const str = xhr.responseText;
                  setImportLoader(false);
                  // this.setState({

                  //     importLoader: false
                  // })
                  try {
                    if (
                      str === undefined ||
                      str === "undefined" ||
                      str === ""
                    ) {
                      createNotification(
                        "error",
                        "Selected file is empty. Please select another csv file having content in it"
                      );
                      setGoogleSelectedFile("");
                      // this.setState({
                      //     googleSelectedFile: ''
                      // })
                      return;
                    }

                    const reg = new RegExp(/(\r\n?|\n|\t)/g);
                    const student_Data = str.replace(reg, ",#*#");
                    setLoadMoreCount(1);
                    setLoadMoreCountStatus(false);
                    const value = {
                      class_id: "",
                      filter_by: filter,
                      page_no: loadMoreCount,
                      academic_year_id: academic_year_id,
                      student_data: student_Data,
                    };
                    importCSVFile(value);
                    // this.setState({
                    //     loadMoreCount: 1,
                    //     loadMoreCountStatus: false,
                    // }, () => {
                    //     const { filter, loadMoreCount, academic_year_id } = this.state;
                    //     const value = {
                    //         class_id: '',
                    //         filter_by: filter,
                    //         page_no: loadMoreCount,
                    //         academic_year_id: academic_year_id,
                    //         student_data: student_Data
                    //     }
                    //     this.importCSVFile(value)
                    // })
                  } catch (e) {
                    createNotification(
                      "error",
                      "Something Went Wrong, Not able to read File"
                    );
                    return;
                  }
                } else {
                  createNotification(
                    "error",
                    "Something Went Wrong, Not able to fetch file Detail"
                  );
                  setGetFileLoader(false);
                  setImportLoader(false);
                  // this.setState({
                  //     GetFileLoader: false,
                  //     importLoader: false
                  // })
                  return;
                }
              };
              xhr.onerror = function () {};
              xhr.send();
            } else {
              createNotification(
                "error",
                "Something Went Wrong, Not able to fetch file Detail"
              );
              setGetFileLoader(false);
              setImportLoader(false);
              // this.setState({
              //     GetFileLoader: false,
              //     importLoader: false
              // })
              return;
            }
          });
      });
    } catch (e) {
      createNotification(
        "error",
        "Something Went Wrong, Not able to fetch file Detail"
      );
      setGetFileLoader(false);
      setImportLoader(false);
      // this.setState({
      //     GetFileLoader: false,
      //     importLoader: false
      // })
      return;
    }
  };
  // ----------To Get File Read from Drive ---------

  // ----------To Send File Read Data In API ---------
  const importCSVFile = (value) => {
    window.$("#myModalupload").modal("hide");
    setSelectFileModal(false);
    setGetFileLoader(false);
    setGoogleSelectedFile("");
    setGoogleFiles([]);
    setGoogleDrivePath([]);
    // this.setState({
    //     SelectFileModal: false,
    //     GetFileLoader: false,
    //     googleSelectedFile: '',
    //     GoogleFiles: [],
    //     googleDrivePath: []
    // })
    // this.props.importRoster(value)
    let data = value
    data.limit = numBoxes
    dispatch(importRoster(data));
  };
  // ----------To Send File Read Data In API ---------

  const emailHandler = (e) => {
    setEmail(e.target.value);
    setInvelidMessageEmail("");
  };

  return (
    <div className="RosterMainContainer-RD ClassRosterModal">
      {/* ---------Roster Add and Import------ */}
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          background: "#f0f0f0",
          height: 176,
        }}
        className="sticky-header"
      >
        <div className="rosterYearMainDiv-RD">
          <div className="display-inline">
            <div className="rosterYearBackDiv-RD">
              <img
                className="calIcon-RD"
                src={back}
                alt=""
                width="60"
                height="40"
                onClick={() => {
                  BackTOHome();
                }}
              />
            </div>
            <div className="totalStudentDiv-RD">
              <div className="attRosterYearDiv-RD">
                <p
                  className="attClassGrade-RD"
                  onClick={() => {
                    BackTOHome();
                  }}
                >
                  {academicYear}
                </p>
                <i className="rosterForIcon-RD material-icons">chevron_right</i>
                <p className="attClassGrade2-RD"> Roster </p>
              </div>
            </div>
          </div>
          <div className= {JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? "d-none" : "d"}>
            <Button
              onClick={() => {
                setUserProfile("");
                setUserProfileURL("");
                setShowLoaderUpload(false);
                setFirstName("");
                setLastName("");
                setEmail("");
                setIsError("");
                setIsAddStudentSubmit(false);
                setTabButton("");
                setInvelidMessageEmail("");
                // this.setState({
                //     UserProfile: '',
                //     UserProfileURL: '',
                //     showLoaderUpload: false,
                //     FirstName: '',
                //     LastName: '',
                //     Email: '',
                //     isError: '',
                //     isAddStudentSubmit: false,
                //     tabButton: '',
                //     InvelidMessageEmail: '',
                // })
              }}
              className={`addNewRoster-RD ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? 'd-none' : ''}`}
              color="primary"
              data-toggle="modal"
              // data-target="#myModal2"
              data-target={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? '' : '#myModal2'}
            >
              <img className="addNewRosterIcon-RD" src={addRosterIcon} alt="" />
              <span className="buttonText-RD"> Student</span>
            </Button>
            <Button
              // className="importRoster-RD"
              className={`importRoster-RD ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? 'd-none' : ''}`}
              color="primary"
              data-toggle="modal"
              // data-target="#myModalupload"
              data-target={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? '' : '#myModalupload'}
            >
              <img className="addNewRosterIcon-RD" src={rosterImport} alt="" />
              <span className="buttonText-RD">Import</span>
            </Button>
          </div>
        </div>
        {/* ---------Roster Add and Import------ */}

        <div style={{ height: "0.9375rem", background: "#f0f0f0" }}></div>
        {/* ---------------Filter and total student------------ */}
        {/* <div style={{background  :"#f0f0f0", height: 80}}> */}
        <Card className="rst-refresh-section-RD br-8">
          <Card.Body>
            <Row className="rst-filterRow-RD">
              <Col xs="12" md="5" lg="6" className="rst-desc-search-col-RD">
                <span className="rst-total-student-RD">
                  Total Students :{" "}
                  {rosterData?.roster_Data?.data?.[1]?.[0]?.count || 0}
                </span>
                <div className="btnContainer-RD">
                  <div
                    className="gridViewBtn-RD"
                    style={{
                      background: gridViewData === true ? "#487FCC" : "#FFFFFF",
                    }}
                    onClick={() => {
                      setGridViewData(true);
                      setListViewData(false);
                      // this.setState({
                      //     gridView: true,
                      //     listView: false,
                      // })
                    }}
                  >
                    <img
                      className="gridViewBtnIcon-RD"
                      src={gridViewData === true ? gridView : gridView2}
                      alt=""
                    />
                  </div>
                  <div
                    className="listViewBtn-RD"
                    style={{
                      background: listViewData === true ? "#487FCC" : "#FFFFFF",
                    }}
                    onClick={() => {
                      setGridViewData(false);
                      setListViewData(true);
                      resetPopOver()
                      // this.setState({
                      //     gridView: false,
                      //     listView: true,
                      // })
                    }}
                  >
                    <img
                      className="listViewBtnIcon-RD"
                      src={listViewData === true ? listView2 : listView}
                      alt=""
                    />
                  </div>
                </div>
                {/* ---------------Filters------------ */}
                <div
                  className="searchFilter-RD"
                  onClick={() => {
                    // this.setState({
                    //     // searchBar: true,
                    //     // searchText: ''
                    // })
                  }}
                >
                  <img
                    className="searchFilterIcon-RD"
                    src={iconSearch}
                    alt=""
                  />
                  <CommonTextField
                    margin="normal"
                    // variant="outlined"
                    type="FirstName"
                    value={searchText}
                    onChange={(event) => {
                      handleSearchText(event);
                      setSearchText(event.target.value);
                    }}
                    name="searchText"
                    className="searchFilterInputBox-RD"
                    placeholder="Enter Keywords…"
                  />
                </div>
              </Col>
              <Col xs="12" md="7" lg="6" className="rst-sort-by-col-RD">
                <div className="rosterfilterDiv-RD">
                  <div className="rosterfilterDiv2-RD">
                    <div className="totalStudentsDiv-RD">
                      <div>
                        <p className="RosterYearSortNameText-RD">Sort By : </p>
                      </div>
                    </div>
                    {nameFilter === "firstName" ? (
                      <div
                        className="firstNameBlueActiveBtn-RD"
                        onClick={() => {
                          filterNameSelect("firstName");
                        }}
                      >
                        <p className="listAllActiveText-RD">First Name</p>
                      </div>
                    ) : (
                      <div
                        className="firstName-RD"
                        onClick={() => {
                          filterNameSelect("firstName");
                        }}
                      >
                        <p className="listAllText-RD">First Name</p>
                      </div>
                    )}
                    {nameFilter === "lastName" ? (
                      <div
                        className="lastNameBlueActiveBtn-RD"
                        onClick={() => {
                          filterNameSelect("lastName");
                        }}
                      >
                        <p className="listAllActiveText-RD">Last Name</p>
                      </div>
                    ) : (
                      <div
                        className="lastName-RD"
                        onClick={() => {
                          filterNameSelect("lastName");
                        }}
                      >
                        <p className="listAllText-RD">Last Name</p>
                      </div>
                    )}
                  </div>
                  {filter === "both" ? (
                    <div
                      className="listAllActiveBlueBtn-RD"
                      onClick={() => {
                        filterSelect("both");
                      }}
                    >
                      <p className="listAllActiveText-RD">List All</p>
                    </div>
                  ) : (
                    <div
                      className="listAll-RD"
                      onClick={() => {
                        filterSelect("both");
                      }}
                    >
                      <p className="listAllText-RD">List All</p>
                    </div>
                  )}
                  {filter === "created_by_me" ? (
                    <div
                      className="myRosterDivBlueActiveBtn-RD"
                      onClick={() => {
                        filterSelect("created_by_me");
                      }}
                    >
                      <p className="listAllActiveText-RD">My Roster</p>
                    </div>
                  ) : (
                    <div
                      className="myRosterDiv-RD"
                      onClick={() => {
                        filterSelect("created_by_me");
                      }}
                    >
                      <p className="listAllText-RD">My Roster</p>
                    </div>
                  )}
                  {filter === "shared_to_me" ? (
                    <div
                      className="sharedRosterDivBlueActiveBtn-RD"
                      onClick={() => {
                        filterSelect("shared_to_me");
                      }}
                    >
                      <p className="listAllActiveText-RD">Shared Roster</p>
                    </div>
                  ) : (
                    <div
                      className="sharedRosterDiv-RD"
                      onClick={() => {
                        filterSelect("shared_to_me");
                      }}
                    >
                      <p className="listAllText-RD">Shared Roster</p>
                    </div>
                  )}
                  {filter === "by_class" ? (
                    <div
                      className="sharedRosterDivBlueActiveBtn-RD"
                      onClick={() => {
                        filterSelectByClass("by_class");
                      }}
                    >
                      <p className="listAllActiveText-RD">Group By Class</p>
                    </div>
                  ) : (
                    <div
                      className="sharedRosterDiv-RD"
                      onClick={() => {
                        filterSelectByClass("by_class");
                      }}
                    >
                      <p className="listAllText-RD">Group By Class</p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* <div style={{background  :"#f0f0f0"}}></div> */}
        {/* </div> */}
      </div>
      {/* ---------------Filter------------ */}

      {/* ---------------Filter and total student------------ */}

      {/* ---------------Search Filter ------------ */}
      {searchBar && (
        <div className="totalStudentSearchDiv-RD">
          <div style={{ width: "90%" }}>
            <div className="searchStudentdiv-RD">
              <div className="input-group-prepend border-0">
                <button
                  id="button-addon4"
                  type="button"
                  className="btn btn-link text-info searchIconDiv-RD"
                >
                  <i className="fa fa-search searchIcon-RD"></i>
                </button>
              </div>
              <input
                autoFocus
                margin="normal"
                type="text"
                value={searchText}
                onChange={(event) => {
                  handleSearchText(event);
                  setSearchText(event.target.value);
                }}
                name="searchText"
                className="searchStudent-RD"
                placeholder="Search"
              ></input>
            </div>
          </div>
          <div>
            <img
              src={cancelBlack}
              alt=""
              className="closeIconSearch-RD"
              onClick={() => {
                setSearchBar(false);
                setStudentList(StudentsWithoutSearch);
                // this.setState({
                //     searchBar: false,
                //     StudentList: this.state.StudentsWithoutSearch
                // })
              }}
            />
          </div>
        </div>
      )}
      {/* ---------------Search Filter ------------ */}

      {/* ------ Loader Div----- */}
      {/* {(loader && filter !== "by_class" && !loadMoreLoader && (StudentList && StudentList.length > 0)) && (
        <div>
          <DivLoader />
        </div>
      )} */}
      {/* ------ Loader Div----- */}

      {/* ---------------Student Details Cards------------ */}
      {filter === "by_class" && finalClasses ? (
        <div ref={ref2} className="panels-div-RD">
          {finalClasses &&
            finalClasses.map((item, index) => {
              const titleName = ["title", expandPanel === index ? "w-166" : ""];
              return (
                <Accordion expanded={index === expandPanel} key={index}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        onClick={() => {
                          expandPanelSub(item, index);
                        }}
                      />
                    }
                  >
                    <div className="MuiTypography-root MuiTypography-body1-RD">
                      <div className={titleName.join(" ")}>
                        <p>
                          {" "}
                          {item.cc_class_name.replace(/(.{15})..+/, "$1…")}
                        </p>
                      </div>
                    </div>
                  </AccordionSummary>
                  {/* {loader && (
                    <div>
                      <DivLoader />
                    </div>
                  )} */}
                  <AccordionDetails ref={DataRef} 
          // // style={{height : "calc(100vh - 110px)"}}
          style={{
            // height : StudentList.length > 0 ? `70vh` : "82vh", 
            height : StudentList.length > 0 ? `calc(100vh - 200px)` : "82vh",
            overflowY : 'scroll',
            overflowX : 'hidden',
            padding : '0 5px'
            }}  
            onScroll={handleScroll2}  
            className="detail-section-RD">
                    <Row className="m-auto">
                      {StudentList &&
                        StudentList.map((studentDetail, j) => {
                          const details = [
                            "details-RD",
                            studentDetail.cc_student_email_id.length < 25
                              ? "marginGroup-RD"
                              : "",
                          ];
                          return (
                            <Col
                              xs="12"
                              sm="6"
                              md="4"
                              lg="3"
                              style={{
                                width: "100% !important",
                                justifyContent: "flexStart !important",
                              }}
                              className="StudentProfiles2-RD"
                              key={j}>
                                     <Card className="br-8">
                                <Card.Body>
                                  <Row className="profile-RD">
                                    <Col xs="12">
                                      {studentDetail.cc_student_pic_url ? (
                                        <div className="img-RD">
                                          <img
                                            className="withImg-RD"
                                            variant="top"
                                            alt=""
                                            src={
                                              studentDetail.cc_student_pic_url
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <div className="img-RD">
                                          <h5
                                            style={{
                                              color: "white",
                                              textTransform: "uppercase",
                                              marginTop: "13px",
                                              cursor: "default",
                                            }}
                                          >
                                            {studentDetail.cc_student_first_name.charAt(
                                              0
                                            )}
                                            {studentDetail.cc_student_last_name.charAt(
                                              0
                                            )}
                                          </h5>
                                        </div>
                                      )}
                                      <div className={details.join(" ")}>
                                        <span className="name-RD">
                                          {studentDetail.cc_student_first_name}{" "}
                                          {studentDetail.cc_student_last_name}{" "}
                                        </span>
                                        <br></br>
                                        {studentDetail.cc_student_email_id
                                          .length > 20 ? (
                                          <p
                                            data-title={
                                              studentDetail.cc_student_email_id
                                            }
                                          >
                                            {_.truncate(
                                              studentDetail.cc_student_email_id,
                                              {
                                                length: 30,
                                                omission: "...",
                                              }
                                            )}
                                          </p>
                                        ) : (
                                          <span className="mail-RD">
                                            {studentDetail.cc_student_email_id}
                                          </span>
                                        )}
 <div
                              id={`PopoverLegacy${index}`}
                              type="button"
                              // onClick={() => editStudent(item.cc_student_id)}
                              onClick={() => {
                                if(groupByClassId > 0) {
                                  setGroupByClassId(null)
                                } else {
                                setGroupByClassId(studentDetail?.cc_student_id)
                                }
                              }}
                              className="studentMenuIcon-RD"
                              // style={{ display: this.state.isHovered[index] ? this.state.isHovered[index] : 'none' }}
                            >
                              <i className="material-icons">more_vert</i>
                              {
                                  studentDetail.cc_student_id == groupByClassId &&
                                  <div className="editInputDiv-RD groupRosterPopover" 
                                 
                                  >
                                     <div class="arrow"></div>
  <div class="arrow-cover"></div>
                                  <div className="inputMaskUpdateParentDiv-RD"  ref={groupRef}>
                                    <div className="inputMaskUpdateDiv-RD">
                                      <div className="studentOptionDiv-RD">
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {/* ------------Attendance Div----------  */}
                                          {/* <div className='optionBtnDiv-RD attOptionBtn-RD' style={{opacity: '0.30'}}
                                                                                      onClick={() => {
                                                                                          if (item.is_associated_with_class === 'no') {
                                                                                              createNotification('error', 'This student is not associated with any Class.');
                                                                                              return;
                                                                                          }
                                                                                      }}
                                                                                  >
                                                                                      <img src={iconAtt} alt='' className='optionIcons-RD' />
                                                                                      <p className='optionText-RD'>Attendance</p>

                                                                                  </div> */}

                                          {/* ------------Attendance Div----------  */}

                                          {/* ------------Notes Div----------  */}
                                          <div
                                            className="optionBtnDiv-RD noteOptionBtn-RD"
                                            onClick={() => {
                                              setGroupByClassId(studentDetail?.cc_student_id)
                                              if (
                                                studentDetail.is_associated_with_class ===
                                                "no"
                                              ) {
                                                // createNotification(
                                                //   "error",
                                                //   "This student is not associated with any Class."
                                                // );
                                                return;
                                              }
                                              history.push(
                                                routes.STUDENTNOTE,
                                                {
                                                  studentInfo: studentDetail,
                                                  academicYear: academicYear,
                                                  academicYearIDFromClassNote : data?.academic_year_id,
                                                  pageBYClass: false,
                                                  classId:
                                                    classIdForGradebook,
                                                  BackPage: "Roster",
                                                  classPermission:
                                                    studentDetail.cc_student_share_permission,
                                                }
                                              );
                                            }}
                                          >
                                            <img
                                              src={iconNotes}
                                              alt=""
                                              className="optionIcons-RD"
                                              style={{opacity : studentDetail.is_associated_with_class === "no" && '0.5'}}
                                            />
                                            <p className="optionText-RD">
                                              Notes
                                            </p>
                                          </div>

                                          {/* ------------Notes Div----------  */}

                                          {/* ------------Class Div----------  */}
                                          <div
                                            className="optionBtnDiv-RD classOptionBtn-RD"
                                            onClick={() => {
                                              if (
                                                studentDetail.is_associated_with_class ===
                                                "no"
                                              ) {
                                                createNotification(
                                                  "error",
                                                  "This student is not associated with any Class."
                                                );
                                                return;
                                              }
                                              history.push(
                                                routes.ROSTERENRILLED,
                                                {
                                                  academicYear: academicYear,
                                                  studentInfo: studentDetail,
                                                  classId:
                                                    classIdForGradebook,
                                                  BackPage: "Roster",
                                                  classPermission:
                                                    studentDetail.cc_student_share_permission,
                                                }
                                              );
                                            }}
                                          >
                                            <img
                                              src={iconClass}
                                              alt=""
                                              className="optionIcons-RD"
                                            />
                                            <p className="optionText-RD">
                                              Classes
                                            </p>
                                          </div>

                                          {/* ------------Class Div----------  */}

                                          {/* ------------GradeBook Div----------  */}
                                          <div
                                            className="optionBtnDiv-RD gradebookOptionBtn-RD"
                                            onClick={() => {
                                              if (
                                                studentDetail.is_associated_with_class ===
                                                "no"
                                              ) {
                                                createNotification(
                                                  "error",
                                                  "This student is not associated with any Class."
                                                );
                                                return;
                                              }
                                              getStudentGradebookNullRequest();
                                              history.push(
                                                routes.STUDENTGRADEBOOK,
                                                {
                                                  id: studentDetail.cc_student_id,
                                                  academicYear: academicYear,
                                                  classId:
                                                    classIdForGradebook,
                                                  studentInfo: studentDetail,
                                                  BackPage: "Roster",
                                                  classPermission:
                                                    studentDetail.cc_student_share_permission,
                                                }
                                              );
                                            }}
                                          >
                                            <img
                                              src={iconGradeBook}
                                              alt=""
                                              className="optionGreadeBookIcons-RD"
                                            />
                                            <p className="optionText-RD">
                                              GradeBook
                                            </p>
                                          </div>

                                          {/* ------------GradeBook Div----------  */}
                                        </div>
                                        {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}> */}
                                        {/* ------------Lesson Div----------  */}
                                        {/* <div className='optionBtnDiv-RD lessonPlanOptionBtn-RD' style={{opacity: '0.30'}}
                                                                                      // onClick={() => {
                                                                                      //     if (item.is_associated_with_class === 'no') {
                                                                                      //         createNotification('error', 'This student is not associated with any Class.');
                                                                                      //         return;
                                                                                      //     }
                                                                                      //     this.props.history.push(routes.ROSTERENRILLED, { academicYear: this.state.academicYear, studentInfo: item, classId: this.state.classIdForGradebook, BackPage: 'Roster', classPermission: item.cc_student_share_permission })
                                                                                      // }}
                                                                                  >
                                                                                      <img src={iconLessonPlan} alt='' className='optionIcons-RD' />
                                                                                      <p className='optionText-RD'>Lesson Plan</p>

                                                                                  </div> */}

                                        {/* ------------Lesson Div----------  */}

                                        {/* ------------Rubrics Div----------  */}
                                        {/* <div className='optionBtnDiv-RD rubricOptionBtn-RD' style={{opacity: '0.30'}}
                                                                                      // onClick={() => {
                                                                                      //     if (item.is_associated_with_class === 'no') {
                                                                                      //         createNotification('error', 'This student is not associated with any Class.');
                                                                                      //         return;
                                                                                      //     }
                                                                                      //     this.props.history.push(routes.ROSTERENRILLED, { academicYear: this.state.academicYear, studentInfo: item, classId: this.state.classIdForGradebook, BackPage: 'Roster', classPermission: item.cc_student_share_permission })
                                                                                      // }}
                                                                                      disabled='true'
                                                                                  >
                                                                                      <img src={iconRubric} alt='' className='optionIcons-RD' />
                                                                                      <p className='optionText-RD'>Rubrics</p>

                                                                                  </div> */}

                                        {/* ------------Rubrics Div----------  */}

                                        {/* ------------GradeBook Div----------  */}
                                        {/* <div className='optionBtnDiv-RD gradebookOptionBtn-RD' onClick={() => {
                                                                                      if (item.is_associated_with_class === 'no') {
                                                                                          createNotification('error', 'This student is not associated with any Class.');
                                                                                          return;
                                                                                      }
                                                                                      getStudentGradebookNullRequest()
                                                                                     history.push(routes.STUDENTGRADEBOOK, { id: item.cc_student_id, academicYear: academicYear, classId: classIdForGradebook, studentInfo: item, BackPage: 'Roster', classPermission: item.cc_student_share_permission })
                                                                                  }}>
                                                                                      <img src={iconGradeBook} alt='' className='optionGreadeBookIcons-RD' />
                                                                                      <p className='optionText-RD'>GradeBook</p>
                                                                                  </div> */}

                                        {/* ------------GradeBook Div----------  */}
                                        {/* </div> */}
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {/* ------------Share Div----------  */}
                                          {/* {(item.cc_student_share_permission !==
                                            null &&
                                          (item.cc_student_share_permission ===
                                            "read" || item.cc_student_share_permission == "collaborate")) ? (
                                            <div
                                              // className={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? "disShareOptionDiv-RD schltchroption" : "disShareOptionDiv-RD" }
                                              className={"disShareOptionDiv-RD" }
                                              style={{ margin: "5px" }}
                                            >
                                              <img
                                                src={iconShare}
                                                alt=""
                                                className="optionIcons-RD"
                                              />
                                              <p className="optionText-RD">
                                                Share
                                              </p>
                                            </div>
                                          ) : ( */}
                                            <div
                                              // className= {JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? "shareOptionDiv-RD schltchroption" : "shareOptionDiv-RD" }
                                              className= {"shareOptionDiv-RD"}
                                              style={{
                                                margin: "6px",
                                                height: "60.8px",
                                              }}
                                              onClick={() => {
                                                history.push(
                                                  routes.STUDENTSHARE,
                                                  {
                                                    studentInfo:
                                                      studentDetail.cc_student_id,
                                                      studentInfoData: studentDetail
                                                  }
                                                );
                                              }}
                                            >
                                              <img
                                                src={iconShare}
                                                alt=""
                                                className="optionIcons-RD"
                                              />
                                              <p className="optionText-RD">
                                                Share
                                              </p>
                                            </div>
                                          {/* )} */}
                                          {/* ------------Share Div----------  */}

                                          {/* ------------Profile Div----------  */}
                                          <div
                                            className="optionBtnDiv-RD profileOptionBtn-RD"
                                            onClick={() => {
                                              // if (item.is_associated_with_class === 'no') {
                                              //     createNotification('error', 'This student is not associated with any Class.');
                                              //     return;
                                              // }
                                              history.push(
                                                routes.STUDENTPROFILE,
                                                {
                                                  id: studentDetail.cc_student_id,
                                                  studentPermission:
                                                    studentDetail.cc_student_share_permission,
                                                }
                                              );
                                            }}
                                          >
                                            <img
                                              src={iconProfile}
                                              alt=""
                                              className="optionIcons-RD"
                                            />
                                            <p
                                              className="optionText-RD"
                                              style={{ color: "#386CB5" }}
                                            >
                                              Profile
                                            </p>
                                          </div>

                                          {/* ------------Profile Div----------  */}

                                          {/* ------------Delete Div----------  */}
                                          {(studentDetail.cc_student_share_permission !==
                                            null &&
                                          studentDetail.cc_student_share_permission ===
                                            "read")  ? (
                                            <div className={`optionBtnDiv-RD deleteOptionBtn-RD ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? 'd-none' : ''}`}
                                            style={{
                                              opacity : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || studentDetail.cc_student_share_permission ===  "read" && '0.5',
                                              cursor : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || studentDetail.cc_student_share_permission ===  "read" && 'default'}}
                                            >
                                              <img
                                                src={deleteIcon}
                                                alt=""
                                                className="optionIcons-RD"
                                              />
                                              <p
                                                className="optionText-RD"
                                                style={{ color: "#FF4444" }}
                                              >
                                                Delete
                                              </p>
                                            </div>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                                                  return
                                                } else {
                                                deletePopoverHandler(
                                                  studentDetail.cc_student_id
                                                );
                                                setSelectStudentId(
                                                  studentDetail.cc_student_id
                                                );
                                                setSelectIndex(j);
                                                }
                                                // this.setState({selectStudentId: item.cc_student_id});
                                                // this.setState({selectIndex: index}); }}
                                              }}
                                              // className="optionBtnDiv-RD deleteOptionBtn-RD"
                                              className={`optionBtnDiv-RD deleteOptionBtn-RD ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? 'd-none' : ''}`}
                                              style={{
                                                opacity : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || studentDetail.cc_student_share_permission ===  "read" && '0.5',
                                                cursor : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || studentDetail.cc_student_share_permission ===  "read") && 'default'}}
                                            >
                                              <img
                                                src={deleteIcon}
                                                alt=""
                                                className="optionIcons-RD"
                                              />
                                              <p
                                                className="optionText-RD"
                                                style={{ color: "#FF4444" }}
                                              >
                                                Delete
                                              </p>
                                            </div>
                                          )}

                                          {/* ------------Delete Div----------  */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              </div>

                                      </div>
                                      

                                    </Col>
                                  </Row>
                                  
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })}
                    </Row>
                    {/* {!searchBar && filter === "by_class" && (
                      <div>
                        {StudentOverAllCount.length > 0 &&
                          StudentList.length > 0 && (
                            <div style={{ padding: 5 }}>
                              {loadMoreCountStatusByClass ? (
                                <Button
                                  className="loadMoreRoster-RD lmb"
                                  color="primary"
                                >
                                  <img
                                    src={loaderImag}
                                    alt=""
                                    className="loaderIconCssAlreadySelected"
                                  />
                                </Button>
                              ) : (""
                                // null
                                // <div>
                                //   {StudentOverAllCount &&
                                //     StudentOverAllCount[0].count >
                                //       StudentList.length && (
                                //       <Button
                                //         style={{borderRadius : "0px !important"}}
                                //         onClick={() => {
                                //           loadMoreByClass();
                                //         }}
                                //         className="loadMoreRoster-RD lmb"
                                //         color="primary"
                                //       >
                                //         <i
                                //           style={{ fontSize: 20 }}
                                //           className="material-icons"
                                //         >
                                //           autorenew
                                //         </i>
                                //         <span className="LoadButtonText-RD">
                                //           {" "}
                                //           Load More
                                //         </span>
                                //       </Button>
                                //     )}
                                // </div>
                              )}
                            </div>
                          )}
                      </div>
                    )} */}
                  </AccordionDetails>
                  
                  {loader && (
                    <div  style={{backgroundColor : "#f0f0f0"}}>
                      <  DivLoader />
                    </div>
                  )}
                </Accordion>
              );
            })}
        </div>
      ) : StudentList.length > 0 && !loader ? (
        <>
      
          <div 
          ref={DataRef} 
          // // style={{height : "calc(100vh - 110px)"}}
          style={{
            // height : StudentList.length > 0 ? `70vh` : "82vh", 
            height : StudentList.length > 0 ? `calc(100vh - 200px)` : "82vh",
            overflowY : 'scroll',
            overflowX : 'hidden',
            padding : '0 5px'
            }}  
           onScroll={handleScroll}
          // id="scrollableDiv" style={{ height: 300, overflow: "auto" }}
          >
            <div className="StudentProfiles-RD" id="studentHeight">
              {StudentList &&
                StudentList.length > 0 &&
                StudentList.map((item, index) => {
                 return (<div
                    style={{
                      width:
                        gridViewData === true && listViewData === false
                          ? "25%"
                          : "100%", 
                    }}
                    key={index}
                  >
                    {/* -----------Student Details Cards--------- */}
                    <div
                      style={{
                        width:
                          gridViewData === true && listViewData === false
                            ? "94%"
                            : "100%",
                      }}
                    >
                      {gridViewData === true && listViewData === false ? (
                        <div className="studentCardMain-RD">
                          {((item?.is_shared == 'yes' && item?.recent_shared_permission == 'read') || item.cc_student_share_permission == "read") ? (
                            <div
                              style={{
                                // zIndex: 9,
                                position: "relative",
                                width: "30px",
                                height: "30px",
                                top: "-10px",
                                left: "-10px",
                              }}
                            >
                              <img
                                src={readOnlyIcon}
                                style={{ width: "30px", height: "30px" }}
                              />
                            </div>
                          ) : ((item?.is_shared == 'yes' && item?.recent_shared_permission == 'read_write') || item.cc_student_share_permission ==
                            "read_write") ? (
                            <div
                              style={{
                                // zIndex: 9,
                                position: "relative",
                                width: "30px",
                                height: "30px",
                                top: "-10px",
                                left: "-10px",
                              }}
                            >
                              <img
                                src={readWriteIcon}
                                style={{ width: "30px", height: "30px" }}
                              />
                            </div>
                          ) : ((item?.is_shared == 'yes' && item?.recent_shared_permission == 'collaborate') ||item.cc_student_share_permission ==
                            "collaborate") ? (
                            <div
                              style={{
                                // zIndex: 9,
                                position: "relative",
                                width: "30px",
                                height: "30px",
                                top: "-10px",
                                left: "-10px",
                              }}
                            >
                              <img
                                src={collaborateIcon}
                                style={{ width: "30px", height: "30px" }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                // zIndex: 9,
                                position: "relative",
                                width: "30px",
                                height: "30px",
                                top: "-10px",
                                left: "-10px",
                              }}
                            />
                          )}
                          <div
                            className="studentDetailsCard-RD"
                            // onMouseEnter={() => this.handleMouseEnter(index)}
                            // onMouseLeave={() => this.handleMouseLeave(index)}
                            key={index}
                          >
                            <div className="studentProfileImageDiv-RD">
                              {item?.cc_student_pic_url?.includes('https') ? (
                                <img
                                  src={item?.cc_student_pic_url || userFallbackImg}
                                  // src={getAllRosterImages(item.cc_student_pic_url)}
                                  alt=""
                                  className="studentProfileImage-RD"
                                />
                              ) : (
                                <div className="imageDummyRosterProfile-RD">
                                  <h5 className="rosterProfileImageText-RD">
                                    {item.cc_student_first_name.charAt(0)}
                                    {item.cc_student_last_name.charAt(0)}
                                  </h5>
                                </div>
                              )}
                            </div>
                            <div className="studentTextInfoDiv-RD">
                              <p className="studentName-RD" style={item.cc_student_email_id == "" ? {paddingTop : "10px"} : {paddingTop : "0px"}}>
                                {item.cc_student_last_name}{" "}
                                {item.cc_student_first_name}
                              </p>
                              <p className="studentEmail-RD">
                                {item.cc_student_email_id}
                              </p>

                              {/* {item.cc_student_share_user_id === null ?
                                                            userLocalData.cc_user_id == item.cc_student_user_id ?
                                                                item.is_shared == "yes" ?
                                                                    item.recent_shared_permission == "read" ?
                                                                        <div className='classSharedViewOnlyDiv-RD'><p className='classViewOnlyText-RD'>Shared by: View Only</p></div>
                                                                        : item.recent_shared_permission == "read_write" ?
                                                                            <div className='classSharedViewOnlyDiv-RD'><p className='classViewOnlyText-RD'>Shared by: Create Copy</p></div>
                                                                            : item.recent_shared_permission == "collaborate" ?
                                                                                <div className='classSharedViewOnlyDiv-RD'><p className='classViewOnlyText-RD'>Shared by: Collaborate</p></div>
                                                                                : "" : "" : ""
                                                            : item.cc_student_share_permission == "read" ? <div className='classViewOnlyDiv-RD'><p className='classViewOnlyText-RD'>View Only</p></div>
                                                                : item.cc_student_share_permission == "read_write" ? <div className='classViewOnlyDiv-RD'><p className='classViewOnlyText-RD'>Create Copy</p></div>
                                                                    : item.cc_student_share_permission == "collaborate" ? <div className='classViewOnlyDiv-RD'><p className='classViewOnlyText-RD'>Collaborate</p></div> : ""
                                                        } */}
                            </div>

                            <div
                              id={`PopoverLegacy${index}`}
                              type="button"
                              onClick={() => editStudent(item.cc_student_id)}
                              className="studentMenuIcon-RD"
                              // style={{ display: this.state.isHovered[index] ? this.state.isHovered[index] : 'none' }}
                            >
                              <i className="material-icons">more_vert</i>
                              <UncontrolledPopover
                                trigger="legacy"
                                placement="right-start"
                                isOpen={item.editOpen}
                                target={`PopoverLegacy${index}`}
                                toggle={() => editStudent(item.cc_student_id)}
                                style={{ borderRadius: 20 }}
                                className="popover academicPopOver-RD"
                              >
                                <PopoverBody>
                                  <div className="editInputDiv-RD">
                                    <div className="inputMaskUpdateParentDiv-RD">
                                      <div className="inputMaskUpdateDiv-RD">
                                        <div className="studentOptionDiv-RD">
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {/* ------------Attendance Div----------  */}
                                            {/* <div className='optionBtnDiv-RD attOptionBtn-RD' style={{opacity: '0.30'}}
                                                                                        onClick={() => {
                                                                                            if (item.is_associated_with_class === 'no') {
                                                                                                createNotification('error', 'This student is not associated with any Class.');
                                                                                                return;
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <img src={iconAtt} alt='' className='optionIcons-RD' />
                                                                                        <p className='optionText-RD'>Attendance</p>

                                                                                    </div> */}

                                            {/* ------------Attendance Div----------  */}

                                            {/* ------------Notes Div----------  */}
                                            <div
                                              className="optionBtnDiv-RD noteOptionBtn-RD"
                                              onClick={() => {
                                                if (
                                                  item.is_associated_with_class ===
                                                  "no"
                                                ) {
                                                  // createNotification(
                                                  //   "error",
                                                  //   "This student is not associated with any Class."
                                                  // );
                                                  return;
                                                }
                                                history.push(
                                                  routes.STUDENTNOTE,
                                                  {
                                                    studentInfo: item,
                                                    academicYear: academicYear,
                                                    academicYearIDFromClassNote : data?.academic_year_id,
                                                    pageBYClass: false,
                                                    classId:
                                                      classIdForGradebook,
                                                    BackPage: "Roster",
                                                    classPermission:
                                                      item.cc_student_share_permission,
                                                  }
                                                );
                                              }}
                                              style={{opacity : item.is_associated_with_class === "no" && '0.5', cursor : item.is_associated_with_class === "no" && 'default'}}
                                            >
                                              <img
                                                src={iconNotes}
                                                alt=""
                                                className="optionIcons-RD"
                                              />
                                              <p className="optionText-RD">
                                                Notes
                                              </p>
                                            </div>

                                            {/* ------------Notes Div----------  */}

                                            {/* ------------Class Div----------  */}
                                            <div
                                              className="optionBtnDiv-RD classOptionBtn-RD"
                                              onClick={() => {
                                                if (
                                                  item.is_associated_with_class ===
                                                  "no"
                                                ) {
                                                  // createNotification(
                                                  //   "error",
                                                  //   "This student is not associated with any Class."
                                                  // );
                                                  return;
                                                }
                                                history.push(
                                                  routes.ROSTERENRILLED,
                                                  {
                                                    academicYear: academicYear,
                                                    studentInfo: item,
                                                    classId:
                                                      classIdForGradebook,
                                                    BackPage: "Roster",
                                                    academicYearIDFromClassNote : data?.academic_year_id,
                                                    classPermission:
                                                      item.cc_student_share_permission,
                                                  }
                                                );
                                              }}
                                              style={{opacity : item.is_associated_with_class === "no" && '0.5', cursor : item.is_associated_with_class === "no" && 'default'}}
                                            >
                                              <img
                                                src={iconClass}
                                                alt=""
                                                className="optionIcons-RD"
                                              />
                                              <p className="optionText-RD">
                                                Classes
                                              </p>
                                            </div>

                                            {/* ------------Class Div----------  */}

                                            {/* ------------GradeBook Div----------  */}
                                            <div
                                              className="optionBtnDiv-RD gradebookOptionBtn-RD"
                                              onClick={() => {
                                                if (
                                                  item.is_associated_with_class ===
                                                  "no"
                                                ) {
                                                  // createNotification(
                                                  //   "error",
                                                  //   "This student is not associated with any Class."
                                                  // );
                                                  return;
                                                }
                                                getStudentGradebookNullRequest();
                                                history.push(
                                                  routes.STUDENTGRADEBOOK,
                                                  {
                                                    id: item.cc_student_id,
                                                    academicYear: academicYear,
                                                    classId:
                                                      classIdForGradebook,
                                                    studentInfo: item,
                                                    BackPage: "Roster",
                                                    academicYearIDFromClassNote : data?.academic_year_id,
                                                    classPermission:
                                                      item.cc_student_share_permission,
                                                  }
                                                );
                                              }}
                                              style={{opacity : item.is_associated_with_class === "no" && '0.5', cursor : item.is_associated_with_class === "no" && 'default'}}
                                            >
                                              <img
                                                src={iconGradeBook}
                                                alt=""
                                                className="optionGreadeBookIcons-RD"
                                              />
                                              <p className="optionText-RD">
                                                GradeBook
                                              </p>
                                            </div>

                                            {/* ------------GradeBook Div----------  */}
                                          </div>
                                          {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}> */}
                                          {/* ------------Lesson Div----------  */}
                                          {/* <div className='optionBtnDiv-RD lessonPlanOptionBtn-RD' style={{opacity: '0.30'}}
                                                                                        // onClick={() => {
                                                                                        //     if (item.is_associated_with_class === 'no') {
                                                                                        //         createNotification('error', 'This student is not associated with any Class.');
                                                                                        //         return;
                                                                                        //     }
                                                                                        //     this.props.history.push(routes.ROSTERENRILLED, { academicYear: this.state.academicYear, studentInfo: item, classId: this.state.classIdForGradebook, BackPage: 'Roster', classPermission: item.cc_student_share_permission })
                                                                                        // }}
                                                                                    >
                                                                                        <img src={iconLessonPlan} alt='' className='optionIcons-RD' />
                                                                                        <p className='optionText-RD'>Lesson Plan</p>

                                                                                    </div> */}

                                          {/* ------------Lesson Div----------  */}

                                          {/* ------------Rubrics Div----------  */}
                                          {/* <div className='optionBtnDiv-RD rubricOptionBtn-RD' style={{opacity: '0.30'}}
                                                                                        // onClick={() => {
                                                                                        //     if (item.is_associated_with_class === 'no') {
                                                                                        //         createNotification('error', 'This student is not associated with any Class.');
                                                                                        //         return;
                                                                                        //     }
                                                                                        //     this.props.history.push(routes.ROSTERENRILLED, { academicYear: this.state.academicYear, studentInfo: item, classId: this.state.classIdForGradebook, BackPage: 'Roster', classPermission: item.cc_student_share_permission })
                                                                                        // }}
                                                                                        disabled='true'
                                                                                    >
                                                                                        <img src={iconRubric} alt='' className='optionIcons-RD' />
                                                                                        <p className='optionText-RD'>Rubrics</p>

                                                                                    </div> */}

                                          {/* ------------Rubrics Div----------  */}

                                          {/* ------------GradeBook Div----------  */}
                                          {/* <div className='optionBtnDiv-RD gradebookOptionBtn-RD' onClick={() => {
                                                                                        if (item.is_associated_with_class === 'no') {
                                                                                            createNotification('error', 'This student is not associated with any Class.');
                                                                                            return;
                                                                                        }
                                                                                        getStudentGradebookNullRequest()
                                                                                       history.push(routes.STUDENTGRADEBOOK, { id: item.cc_student_id, academicYear: academicYear, classId: classIdForGradebook, studentInfo: item, BackPage: 'Roster', classPermission: item.cc_student_share_permission })
                                                                                    }}>
                                                                                        <img src={iconGradeBook} alt='' className='optionGreadeBookIcons-RD' />
                                                                                        <p className='optionText-RD'>GradeBook</p>
                                                                                    </div> */}

                                          {/* ------------GradeBook Div----------  */}
                                          {/* </div> */}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {/* ------------Share Div----------  */}
                                            {/* {(item.cc_student_share_permission !==
                                              null &&
                                            (item.cc_student_share_permission ===
                                              "read" || item.cc_student_share_permission == "collaborate")) ? (
                                              <div
                                                // className={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? "disShareOptionDiv-RD schltchroption" : "disShareOptionDiv-RD" }
                                                className={"disShareOptionDiv-RD" }
                                                style={{ margin: "5px" }}
                                              >
                                                <img
                                                  src={iconShare}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p className="optionText-RD">
                                                  Share
                                                </p>
                                              </div>
                                            ) : ( */}
                                              <div
                                                // className= {JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? "shareOptionDiv-RD schltchroption" : "shareOptionDiv-RD" }
                                                className= {"shareOptionDiv-RD"}
                                                style={{
                                                  margin: "6px",
                                                  height: "60.8px",
                                                }}
                                                onClick={() => {
                                                  history.push(
                                                    routes.STUDENTSHARE,
                                                    {
                                                      studentInfo:
                                                        item.cc_student_id,
                                                        studentInfoData: item
                                                    }
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={iconShare}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p className="optionText-RD">
                                                  Share
                                                </p>
                                              </div>
                                            {/* )} */}
                                            {/* ------------Share Div----------  */}

                                            {/* ------------Profile Div----------  */}
                                            <div
                                              className="optionBtnDiv-RD profileOptionBtn-RD"
                                              onClick={() => {
                                                // if (item.is_associated_with_class === 'no') {
                                                //     createNotification('error', 'This student is not associated with any Class.');
                                                //     return;
                                                // }
                                                history.push(
                                                  routes.STUDENTPROFILE,
                                                  {
                                                    id: item.cc_student_id,
                                                    studentPermission:
                                                      item.cc_student_share_permission,
                                                  }
                                                );
                                              }}
                                            >
                                              <img
                                                src={iconProfile}
                                                alt=""
                                                className="optionIcons-RD"
                                              />
                                              <p
                                                className="optionText-RD"
                                                style={{ color: "#386CB5" }}
                                              >
                                                Profile
                                              </p>
                                            </div>

                                            {/* ------------Profile Div----------  */}

                                            {/* ------------Delete Div----------  */}
                                            {(item.cc_student_share_permission !==
                                              null &&
                                            item.cc_student_share_permission ===
                                              "read")  ? (
                                              <div className={`optionBtnDiv-RD deleteOptionBtn-RD ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? 'd-none' : ''}`}
                                              style={{
                                                opacity : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || item.cc_student_share_permission ===  "read" && '0.5',
                                                cursor : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || item.cc_student_share_permission ===  "read" && 'default'}}
                                              >
                                                <img
                                                  src={deleteIcon}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p
                                                  className="optionText-RD"
                                                  style={{ color: "#FF4444" }}
                                                >
                                                  Delete
                                                </p>
                                              </div>
                                            ) : (
                                              <div
                                                onClick={() => {
                                                  if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                                                    return
                                                  } else {
                                                  deletePopoverHandler(
                                                    item.cc_student_id
                                                  );
                                                  setSelectStudentId(
                                                    item.cc_student_id
                                                  );
                                                  setSelectIndex(index);
                                                  }
                                                  // this.setState({selectStudentId: item.cc_student_id});
                                                  // this.setState({selectIndex: index}); }}
                                                }}
                                                // className="optionBtnDiv-RD deleteOptionBtn-RD"
                                                className={`optionBtnDiv-RD deleteOptionBtn-RD ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? 'd-none' : ''}`}
                                                style={{
                                                  opacity : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || item.cc_student_share_permission ===  "read" && '0.5',
                                                  cursor : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || item.cc_student_share_permission ===  "read") && 'default'}}
                                              >
                                                <img
                                                  src={deleteIcon}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p
                                                  className="optionText-RD"
                                                  style={{ color: "#FF4444" }}
                                                >
                                                  Delete
                                                </p>
                                              </div>
                                            )}

                                            {/* ------------Delete Div----------  */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </PopoverBody>
                              </UncontrolledPopover>
                            </div>
                          </div>
                          {/* ------------Student Info Div----------  */}

                          {/* ------------options Div----------  */}

                          {/* ------------options Div----------  */}
                        </div>
                      ) : (
                        <div className="studentCardMain2-RD">
                          {/* ------------Student Info Div----------  */}
                          {item.cc_student_share_permission == "read" ? (
                            <div
                              style={{
                                // zIndex: 9,
                                position: "relative",
                                width: "30px",
                                height: "30px",
                                top: "-10px",
                                left: "-10px",
                              }}
                            >
                              <img
                                src={readOnlyIcon}
                                style={{ width: "30px", height: "30px" }}
                              />
                            </div>
                          ) : item.cc_student_share_permission ==
                            "read_write" ? (
                            <div
                              style={{
                                // zIndex: 9,
                                position: "relative",
                                width: "30px",
                                height: "30px",
                                top: "-10px",
                                left: "-10px",
                              }}
                            >
                              <img
                                src={readWriteIcon}
                                style={{ width: "30px", height: "30px" }}
                              />
                            </div>
                          ) : item.cc_student_share_permission ==
                            "collaborate" ? (
                            <div
                              style={{
                                // zIndex: 9,
                                position: "relative",
                                width: "30px",
                                height: "30px",
                                top: "-10px",
                                left: "-10px",
                              }}
                            >
                              <img
                                src={collaborateIcon}
                                style={{ width: "30px", height: "30px" }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                // zIndex: 9,
                                position: "relative",
                                width: "30px",
                                height: "30px",
                                top: "-10px",
                                left: "-10px",
                              }}
                            />
                          )}
                          <div className="studentDetailsCard2-RD">
                            <div className="studentDetailSubDiv-RD">
                              <div className="studentProfileDiv-RD">
                                <div className="studentProfileImageDiv-RD">
                                  {item?.cc_student_pic_url?.includes('https') ? (
                                    <img
                                      src={item.cc_student_pic_url}
                                      alt=""
                                      className="studentProfileImage-RD"
                                    />
                                  ) : (
                                    <div className="imageDummyRosterProfile-RD">
                                      <h5 className="rosterProfileImageText-RD">
                                        {item.cc_student_first_name.charAt(0)}
                                        {item.cc_student_last_name.charAt(0)}
                                      </h5>
                                    </div>
                                  )}
                                </div>
                                <div className="studentTextInfoDiv2-RD">
                                  <p className="studentName-RD">
                                    {" "}
                                    {item.cc_student_last_name}{" "}
                                    {item.cc_student_first_name}
                                  </p>

                                  {/* {item.cc_student_share_user_id === null ?
                                                                userLocalData.cc_user_id == item.cc_student_user_id ?
                                                                    item.is_shared == "yes" ?
                                                                        item.recent_shared_permission == "read" ?
                                                                            <div className='classSharedViewOnlyDiv-RD'><p className='classViewOnlyText-RD'>Shared by: View Only</p></div>
                                                                            : item.recent_shared_permission == "read_write" ?
                                                                                <div className='classSharedViewOnlyDiv-RD'><p className='classViewOnlyText-RD'>Shared by: Create Copy</p></div>
                                                                                : item.recent_shared_permission == "collaborate" ?
                                                                                    <div className='classSharedViewOnlyDiv-RD'><p className='classViewOnlyText-RD'>Shared by: Collaborate</p></div>
                                                                                    : "" : "" : ""
                                                                : item.cc_student_share_permission == "read" ? <div className='classViewOnlyDiv-RD'><p className='classViewOnlyText-RD'>View Only</p></div>
                                                                    : item.cc_student_share_permission == "read_write" ? <div className='classViewOnlyDiv-RD'><p className='classViewOnlyText-RD'>Create Copy</p></div>
                                                                        : item.cc_student_share_permission == "collaborate" ? <div className='classViewOnlyDiv-RD'><p className='classViewOnlyText-RD'>Collaborate</p></div> : ""
                                                            } */}
                                </div>

                                                          

                              </div>
                              <div className="studentEmailDiv2-RD">
                                <p className="studentEmail-RD">
                                  {item.cc_student_email_id}
                                </p>
                              </div>
                            </div>
                            <div className="studentDetailSubDiv2">
                              <div className="studentOptionDiv2-RD">
                                {/* ------------Class Div----------  */}
                                <div
                                  className="optionsDiv-RD"
                                  onClick={() => {
                                    if (
                                      item.is_associated_with_class === "no"
                                    ) {
                                      // createNotification(
                                      //   "error",
                                      //   "This student is not associated with any Class."
                                      // );
                                      return;
                                    }
                                    history.push(routes.ROSTERENRILLED, {
                                      academicYear: academicYear,
                                      studentInfo: item,
                                      classId: classIdForGradebook,
                                      academicYearIDFromClassNote : data?.academic_year_id,
                                      BackPage: "Roster",
                                      classPermission:
                                        item.cc_student_share_permission,
                                    });
                                  }}
                                  style={{cursor : item.is_associated_with_class === "no" && 'pointer'}}
                                >
                                  <img
                                    src={classIcon}
                                    alt=""
                                    className="optionIcons-RD"
                                    style={{opacity : item.is_associated_with_class === "no" && '0.5'}}
                                  />
                                  <p
                                    className="optionText-RD"
                                    style={{ color: "#FFB239" }}
                                  >
                                    Classes
                                  </p>
                                </div>

                                {/* ------------Class Div----------  */}
                                {/* ------------Notes Div----------  */}
                                <div
                                  className="optionsDiv-RD"
                                  onClick={() => {
                                    if (
                                      item.is_associated_with_class === "no"
                                    ) {
                                      // createNotification(
                                      //   "error",
                                      //   "This student is not associated with any Class."
                                      // );
                                      return;
                                    }
                                    history.push(routes.STUDENTNOTE, {
                                      studentInfo: item,
                                      academicYear: academicYear,
                                      academicYearIDFromClassNote : data?.academic_year_id,
                                      pageBYClass: false,
                                      classId: classIdForGradebook,
                                      BackPage: "Roster",
                                      classPermission:
                                        item.cc_student_share_permission,
                                    });
                                  }}
                                  style={{cursor : item.is_associated_with_class === "no" && 'pointer'}}
                                >
                                  <img
                                    src={noteIcon}
                                    alt=""
                                    className="optionIcons-RD"
                                    style={{opacity : item.is_associated_with_class === "no" && '0.5'}}
                                  />
                                  <p
                                    className="optionText-RD"
                                    style={{ color: "#767DEC" }}
                                  >
                                    Notes
                                  </p>
                                </div>

                                {/* ------------Notes Div----------  */}

                                {/* ------------GradeBook Div----------  */}
                                <div
                                  className="optionsDiv-RD"
                                  onClick={() => {
                                    if (
                                      item.is_associated_with_class === "no"
                                    ) {
                                      // createNotification(
                                      //   "error",
                                      //   "This student is not associated with any Class."
                                      // );
                                      return;
                                    }
                                    getStudentGradebookNullRequest();
                                    history.push(routes.STUDENTGRADEBOOK, {
                                      id: item.cc_student_id,
                                      academicYear: academicYear,
                                      classId: classIdForGradebook,
                                      studentInfo: item,
                                      academicYearIDFromClassNote : data?.academic_year_id,
                                      BackPage: "Roster",
                                      classPermission:
                                        item.cc_student_share_permission,
                                    });
                                  }}
                                  style={{cursor : item.is_associated_with_class === "no" && 'pointer'}}
                                >
                                  <img
                                    src={gradebookIcon}
                                    alt=""
                                    className="optionGreadeBookIcons-RD"
                                    style={{opacity : item.is_associated_with_class === "no" && '0.5'}}
                                  />
                                  <p
                                    className="optionText-RD"
                                    style={{ color: "#09B697" }}
                                  >
                                    GradeBook
                                  </p>
                                </div>

                                {/* ------------GradeBook Div----------  */}
                                {/* ------------Attendance Div----------  */}
                                {/* {item.cc_student_share_permission !== null && item.cc_student_share_permission === 'read' ?
                                                            <div className='optionsDiv-RD' style={{opacity: '0.30'}}>
                                                                <img src={attandanceIcon} alt='' className='optionIcons-RD' />
                                                                <p className='optionText-RD' style={{ color: '#AD54D1' }}>Attendance</p>
                                                            </div> :
                                                            <div className='optionsDiv-RD' style={{opacity: '0.30'}}  >
                                                                <img src={attandanceIcon} alt='' className='optionIcons-RD' />
                                                                <p className='optionText-RD' style={{ color: '#AD54D1' }}>Attendance</p>
                                                            </div>} */}
                                {/* ------------Attendance Div----------  */}
                                {/* ------------Rubric Div----------  */}
                                {/* {item.cc_student_share_permission !== null && item.cc_student_share_permission === 'read' ?
                                                            <div className='optionsDiv-RD' style={{opacity: '0.30'}}>
                                                                <img src={rubricIcon} alt='' className='optionIcons-RD' />
                                                                <p className='optionText-RD' style={{ color: '#CA4378' }}>Rubrics</p>
                                                            </div> :
                                                            <div className='optionsDiv-RD' style={{opacity: '0.30'}}>
                                                                <img src={rubricIcon} alt='' className='optionIcons-RD' />
                                                                <p className='optionText-RD' style={{ color: '#CA4378' }}>Rubrics</p>
                                                            </div>} */}
                                {/* ------------Rubric Div----------  */}
                                {/* ------------Lesson Plan Div----------  */}
                                {/* {item.cc_student_share_permission !== null && item.cc_student_share_permission === 'read' ?
                                                            <div className='optionsDiv-RD' style={{opacity: '0.30'}}>
                                                                <img src={lessonPlanIcon} alt='' className='optionIcons-RD' />
                                                                <p className='optionText-RD' style={{ color: '#F48429' }}>Lesson Plan</p>
                                                            </div> :
                                                            <div className='optionsDiv-RD' style={{opacity: '0.30'}}>
                                                                <img src={lessonPlanIcon} alt='' className='optionIcons-RD' />
                                                                <p className='optionText-RD' style={{ color: '#F48429' }}>Lesson Plan</p>
                                                            </div>} */}
                                {/* ------------Lesson Plan Div----------  */}
                                {/* ------------Share Div----------  */}
                                {/* {
                                (item.cc_student_share_permission === "read" || item.cc_student_share_permission === "collaborate")
                                //  || (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") 
                                  ? (
                                  <div className="optionsDiv-RD">
                                    <img
                                      src={userShareIcon}
                                      alt=""
                                      className="optionIcons-RD"
                                      style={{opacity : '0.5'}}
                                    />
                                    <p
                                      className="optionText-RD"
                                      style={{ color: "#497FCA" }}
                                    >
                                      Share
                                    </p>
                                  </div>
                                ) : ( */}
                                  <div
                                    // className= {JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? "optionsDiv-RD schltchroption" : "optionsDiv-RD"}
                                    className= {"optionsDiv-RD"}
                                    onClick={() => {
                                      history.push(routes.STUDENTSHARE, {
                                        studentInfo: item.cc_student_id,
                                        studentInfoData: item
                                      });
                                    }}
                                  >
                                    <img
                                      src={userShareIcon}
                                      alt=""
                                      className="optionIcons-RD"
                                    />
                                    <p
                                      className="optionText-RD"
                                      style={{ color: "#497FCA" }}
                                    >
                                      Share
                                    </p>
                                  </div>
                                {/* )} */}
                                {/* ------------Share Div----------  */}
                                {/* ------------Profile Div----------  */}
                                <div
                                  className="optionsDiv-RD"
                                  onClick={() => {
                                    history.push(routes.STUDENTPROFILE, {
                                      id: item.cc_student_id,
                                      studentPermission:
                                        item.cc_student_share_permission,
                                    });
                                  }}
                                  style={{borderRight : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'none'}}
                                >
                                  <img
                                    src={profileIcon}
                                    alt=""
                                    className="optionIcons-RD"
                                  />
                                  <p
                                    className="optionText-RD"
                                    style={{ color: "#386CB5" }}
                                  >
                                    Profile
                                  </p>
                                </div>

                                {/* ------------Profile Div----------  */}
                                {/* ------------Delete Div----------  */}
                                {item.cc_student_share_permission !== null &&
                                item.cc_student_share_permission === "read" ? (
                                  <div
                                  style={{
                                    opacity : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || (item.cc_student_share_permission !== null &&
                                      item.cc_student_share_permission === "read"))  && '0.5',
                                    cursor : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || (item.cc_student_share_permission !== null &&
                                      item.cc_student_share_permission === "read"))  && 'default'}}
                                  
                                    className={`optionsDiv2-RD ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? 'd-none' : ''}`}
                                    
                                    onClick={() => {
                                      // deletePopoverHandler(item.cc_student_id);
                                      // setSelectStudentId(item.cc_student_id);
                                      // setSelectIndex(index);
                                      // this.setState({selectStudentId: item.cc_student_id});
                                      // this.setState({selectIndex: index});
                                      if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || (item.cc_student_share_permission !== null &&
                                        item.cc_student_share_permission === "read")) {
                                        return
                                      } else {
                                        deletePopoverHandler(item.cc_student_id);
                                        setSelectStudentId(item.cc_student_id);
                                        setSelectIndex(index);
                                      }
                                    }}
                                  >
                                    <img
                                      src={deleteIcon}
                                      alt=""
                                      className="optionIcons-RD"
                                    />
                                    <p
                                      className="optionText-RD"
                                      style={{ color: "#F94445" }}
                                    >
                                      Delete
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                  style={{
                                    opacity : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher"  && '0.5',
                                    cursor : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher"  && 'default'}}
                                  
                                    onClick={() => {
                                      if(JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher") {
                                        return
                                      } else {
                                        deletePopoverHandler(item.cc_student_id);
                                        setSelectStudentId(item.cc_student_id);
                                        setSelectIndex(index);
                                      }
                                    }
                                      // deletePopoverHandler(item.cc_student_id);
                                      // setSelectStudentId(item.cc_student_id);
                                      // setSelectIndex(index);
                                    }
                                    // this.setState({selectStudentId: item.cc_student_id});
                                    // this.setState({selectIndex: index}); }}
                                    // className="optionsDiv2-RD"
                                    className={`optionsDiv2-RD ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? 'd-none' : ''}`}
                                  >
                                    <img
                                      src={deleteIcon}
                                      alt=""
                                      className="optionIcons-RD"
                                    />
                                    <p
                                      className="optionText-RD"
                                      style={{ color: "#F94445" }}
                                    >
                                      Delete
                                    </p>
                                  </div>
                                )}
                                {/* ------------Delete Div----------  */}
                              </div>
                            </div>
                          </div>
                          {/* ------------Student Info Div----------  */}
                        </div>
                      )}
                    </div>
                    {/* ----------Student Details Cards-------- */}
                  </div>
                                  )})}
            </div>
            
          </div>

          {loadMoreLoader && filter !== "by_class" && (
        <div>
          <DivLoader />
        </div>
      )}
         
        </>
      ) : (
        <div className="noRoster-RD"
        ref={ref}
        >
          {/* {loader && filter !== "by_class" && !loadMoreLoader ? ( */}
          {loader ? (
            <div>
            <DivLoader />
          </div>
          ) : (
            <div data-toggle="modal" 
            data-target={`${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? '' : '#myModal2'}`}
            >
              <p className={`tapToAddText-RD ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'd-none'}`}>
                {searchBar ? "No Roster" : "Tap to add New Student"}
              </p>

              {!searchBar && (
                <img
                  src={addAcademicImage}
                  alt=""
                  className={`addAcademicImage-RD ${JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'd-none'}`}
                />
              )}
            </div>
          )}
        </div>
      )}

      {!searchBar && filter !== "by_class" && (
        <div>
          {/* {StudentOverAllCount.length > 0 && StudentList.length > 0 && (
            <div style={{ padding: 5 }}>
              {loadMoreCountStatus ? (
                <Button style={{borderRadius : "0px !important"}} className="loadMoreRoster-RD lmb d-none" color="primary">
                  <img
                    src={loaderImag}
                    alt=""
                    className="loaderIconCssAlreadySelected"
                  />
                </Button>
              ) : (
                <div>
                  {StudentOverAllCount &&
                    StudentOverAllCount[0].count > StudentList.length && (
                      <Button
                      style={{borderRadius : "0px !important"}}
                        onClick={() => {
                          loadMoreFunction();
                        }}
                        className="loadMoreRoster-RD lmb d-none"
                        color="primary"
                      >
                        <i style={{ fontSize: 20 }} className="material-icons">
                          autorenew
                        </i>
                        <span className="LoadButtonText-RD"> Load More</span>
                      </Button>
                    )}
                </div>
              )}
            </div>
          )} */}
        </div>
      )}
      {/* ---------------Student Details Cards------------ */}

      {/* ---------------Modal------------ */}

      {/* ---------------Modal Add Student------------ */}
      <div
        className="modal right fade ClassRosterModal"
        id="myModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
          <div className="modal-header">
              <h4 className="modal-title Addstudentmodal" id="myModalLabel2">
                Add Student
              </h4>
              <button
                type="button"
                className="close closeround"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="cancelText" aria-hidden="true">
                  {" "}
                  <img
                    src={cancelBlack}
                    alt=""
                    className="closeIconInModal"
                  />{" "}
                 
                </span>
              </button>
            </div>

            <div className="modal-body">
              {/* -----------Add Student Profile----------- */}
              {UserProfileURL === "" || UserProfileURL === undefined ? (
                <div className="RosterImageMainDiv">
                  <div className="addStudentImageDiv">
                    {showLoaderUpload ? (
                      <img src={loaderImag} alt="" className="loaderIconCss" />
                    ) : (
                      <label htmlFor="file">
                        <img src={camera} alt="" className="cameraIconCss" />
                      </label>
                    )}
                  </div>
                  <input
                    className="custom-file-input"
                    onChange={(e) => handleChangeFile(e.target.files)}
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    type="file"
                    id="file"
                    name="file"
                    accept="image/*"
                  />
                </div>
              ) : (
                <div
                  style={{ backgroundImage: `url(${UserProfileURL})` }}
                  className="RosterImageMainSelectedDiv"
                >
                  <div className="addStudentImageDiv">
                    <img
                      src={UserProfileURL}
                      alt=""
                      className="RosterImageWhenSelect"
                    />
                  </div>
                  <Button className="changeButton">
                    {showLoaderUpload ? (
                      <img
                        src={loaderImag}
                        alt=""
                        className="loaderIconCssAlreadySelected"
                      />
                    ) : (
                      <label htmlFor="file" className="changeButtonText">
                        Change
                      </label>
                    )}
                  </Button>
                  <input
                    className="custom-file-input"
                    onChange={(e) => handleChangeFile(e.target.files)}
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    type="file"
                    id="file"
                    name="file"
                    accept="image/*"
                  />
                </div>
              )}

              {/* -----------Add Student Profile----------- */}

              {/* -----------All Input Type----------- */}
              <div className="allInputMainDiv">
                <div className="studentAddInputDiv mt-0">
                  {/* <h5 className="studentInputLableInfo">First name*</h5> */}
                  <CommonTextField
                    margin="normal"
                    variant="outlined"
                    type="FirstName"
                    value={FirstName}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    name="FirstName"
                    className="studentAddInput"
                    placeholder="First Name*"
                  />
                  {(!FirstName || (FirstName && FirstName.trim().length <= 0)) && isAddStudentSubmit && (
                    <p className="errormessageRegister">
                      Please enter first name
                    </p>
                  )}
                  {FirstName && (FirstName && FirstName.trim().length != 0) && !pattern2.test(FirstName) && isAddStudentSubmit && (
                   <p className="errormessageRegister">Please enter only letters</p>
                     )}
                </div>
                <div className="studentAddInputDiv mt-0">
                  {/* <h5 className="studentInputLableInfo">Last name*</h5> */}
                  <CommonTextField
                    margin="normal"
                    variant="outlined"
                    type="LastName"
                    value={LastName}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    name="LastName"
                    className="studentAddInput"
                    placeholder="Last Name*"
                  />
                  {(!LastName || (LastName && LastName.trim().length <= 0)) && isAddStudentSubmit && (
                    <p className="errormessageRegister">
                      Please enter last name
                    </p>
                  )}
                
                  {LastName &&(LastName.trim().length != 0) && !pattern2.test(LastName) && isAddStudentSubmit && (
                   <p className="errormessageRegister">Please enter only letters</p>
                     )}
                </div>
                <div className="studentAddInputDiv mt-0">
                  {/* <h5 className="studentInputLableInfo">Email ID (Optional)</h5> */}
                  <CommonTextField
                    margin="normal"
                    variant="outlined"
                    type="Email"
                    value={Email}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      emailHandler(e);
                    }}
                    name="Email"
                    className="studentAddInput"
                    placeholder="Email ID"
                  />
                  {Email && InvelidMessageEmail && (
                    <p className="errormessageRegister">
                      Please enter valid email*
                    </p>
                  )}
                </div>
                {/* -----------All Input Type----------- */}

                {/* -----------All Button----------- */}
                <div className="modalButtonClass addRosterModalButtonClass modalNW">
                  <div>
                    <Button
                      onClick={() => {
                        createNewRoster("no");
                      }}
                      className= {saveButtonNW.join(' ')}
                      color="primary"
                    >
                      <span className="saveText"> Save</span>
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        createNewRoster("addAnother");
                      }}
                      className= {addAnotherButtonNW.join(' ')}
                      color="primary"
                    >
                      <span className="saveText"> Save and add another</span>
                    </Button>
                  </div>
                </div>
                {/* -----------All Button----------- */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------Modal Add Student------------ */}

      {/* ---------------Modal Upload------------ */}
      <div
        className="modal right fade"
        id="myModalupload"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* <div className="modal-header">
              <div className="uploadDocHeaderDiv">
                <div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="cancelText" aria-hidden="true">
                      {" "}
                      <img
                        src={cancelBlack}
                        alt=""
                        className="closeIconInModal"
                      />{" "}
                      Cancel
                    </span>
                  </button>
                </div>

                <div>
                 
                </div>
              </div>
            </div> */}
 <div style={{paddingTop: "9px", paddingLeft: "9px"}} className="modal-header">
              <div  className="uploadDocHeaderDiv" >
                <div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{padding: "-1px" , paddingLeft : "0px"}}
                  >
                    <span className="cancelText" aria-hidden="true">
                      {" "}
                      <img
                      style={{paddingRight : "5px" , width : "16px"}}
                        src={cancelBlack}
                        alt=""
                        className="closeIconInModal"
                      />{" "}
                      Import Roster
                    </span>
                  </button>
                </div>
              </div>
             
            </div>
            {UploadFrom !== "drive" ? (
                    <Button
                      disabled={true}
                      className="importRosterButton importdrivebtn"
                      color="primary"
                      style={{
                        position : 'absolute',
                        top : 13,
                        roght : 10,
                        left : 'auto',
                        right: 5
                      }}
                    >
                      <span className="importRosterText"> Import</span>
                    </Button>
                  ) : (
                    <Button
                      disabled={GoogleAuth ? false : true}
                      onClick={() => {
                        handleFileLoad();
                      }}
                      className="importRosterButton importdrivebtn"
                      color="primary"
                      style={{
                        position : 'absolute',
                        top : 13,
                        roght : 10,
                        left : 'auto',
                        right: 5
                      }}
                    >
                      <span className="importRosterText"> Import</span>
                    </Button>
                  )}

            <div className="modal-body">
              {/* ---------------Note option------------ */}
              <div className="uploadNodeDiv">
                <p
                  className="uploadNoteText"
                  style={{ fontWeight: 600, marginBottom: 10 }}
                >
                  Note
                </p>
                <p className="uploadNoteText">1. Open and name a spreadsheet</p>
                <p className="uploadNoteText">
                  2. Input students (first column = First Name, Second column =
                  Last Name)
                </p>
                <p className="uploadNoteText">
                  3. Download spreadsheet as a CSV file (comma separated values)
                </p>
                <p className="uploadNoteText">
                  4. Return here, authorize your account, choose the CSV file,
                  and import!
                </p>
                <p style={{ marginTop: 15 }} className="uploadNoteText">
                  P.S. - You can also import your roster directly into a class{" "}
                  {noteStrinf}
                </p>
              </div>
              {/* ---------------Note option------------ */}

              {/* --------------- Upload option------------ */}
              <div className="selectMainDivforUpload">
                <div className="selectOptionInTag">
                  <div
                    className="checkbozCircle"
                    onClick={() => {
                      // this.setState({ UploadFrom: 'drive' })
                      setUploadFrom("drive");
                    }}
                  >
                    {UploadFrom === "drive" && (
                      <div className="checkbozCircleSelected"></div>
                    )}
                  </div>
                  <img
                    src={googleDriveIcon}
                    alt=""
                    className="selectoptionUploadImage"
                  />
                  {!GoogleAuth ? (
                    <p className="selectTagText">Google Drive</p>
                  ) : (
                    <p className="loginEmailText">{driveEmail}</p>
                  )}
                  <div>
                    {!GoogleAuth ? (
                      <Button
                        disabled={UploadFrom === "drive" ? false : true}
                        className="autharizedButton"
                        color="primary"
                        onClick={() => handleClientLoad()}
                      >
                        <span className="autharizedText"> Authorize</span>
                      </Button>
                    ) : (
                      <Button
                        disabled={UploadFrom === "drive" ? false : true}
                        className="logoutButton"
                        color="primary"
                        onClick={() => handleSignOutClick()}
                      >
                        <span className="logoutText"> Logout</span>
                      </Button>
                    )}
                  </div>
                </div>

                <div className="selectOptionInTag">
                  <div
                    className="checkbozCircle"
                    onClick={() => {
                      //  this.setState({ UploadFrom: 'drop' })
                      setUploadFrom("drop");
                    }}
                  >
                    {UploadFrom !== "drive" && (
                      <div className="checkbozCircleSelected"></div>
                    )}
                  </div>
                  <img
                    src={localFolderIcon}
                    alt=""
                    className="selectoptionUploadImageforDrop"
                  />
                  <p className="selectTagText">File App</p>
                  <div>
                    <ReactFileReader
                      disabled={UploadFrom !== "drive" ? false : true}
                      handleFiles={handleImportFiles}
                      fileTypes={".csv"}
                    >
                      <Button
                        disabled={UploadFrom !== "drive" ? false : true}
                        className="autharizedButton"
                        color="primary"
                      >
                        <span className="autharizedText"> Choose</span>
                      </Button>
                    </ReactFileReader>
                  </div>
                </div>
              </div>
              {/* ---------------Upload option------------ */}
            </div>
          </div>
        </div>
      </div>
      {/* ---------------Modal Add Student------------ */}

      {/* ---------------Import File From Drive Modal------------ */}
      <Modal
        centered
        className="select-DriveFile-modal br-8"
        show={SelectFileModal}
      >
        <Modal.Header>
          <Modal.Title>Google Drive Import</Modal.Title>
          {googleSelectedFile && (
            <Button
              className="modalSaveTagButton"
              variant="success"
              onClick={() => {
                getFileDetails();
              }}
            >
              <img src={tickIcon} alt="" height={20} width={20} />
            </Button>
          )}
        </Modal.Header>
        <Modal.Body>
          {importLoader ? (
            <div style={{ width: "100%", textAlign: "center" }}>
              <img src={loaderImag} alt="" className="importDriveLoader" />
            </div>
          ) : (
            <div>
              <div className="inline-Path">
                <ul className="breadcrumb">
                  {googleDrivePath.map((value, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        getFolderFilesPath(value);
                      }}
                    >
                      <span className="cursor">{value.name}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="modal-list-body">
                {GetFileLoader && (
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={loaderImag} alt="" className="loaderIconCss" />
                  </div>
                )}
                {GoogleFiles && GoogleFiles.length > 0 ? (
                  <>
                    {GoogleFiles &&
                      GoogleFiles.length > 0 &&
                      GoogleFiles.map((item, index) => (
                        <div className="file_listDiv" key={index}>
                          {item.fileExtension === "csv" ? (
                            <div style={{ display: "inline-flex" }}>
                              <img
                                src={driveCSVIcon}
                                alt=""
                                className="selectoptionUploadFolder"
                              />

                              <p className="fileName">{item.name}</p>
                            </div>
                          ) : (
                            <div
                              className="selectFileCursor"
                              onClick={() => {
                                getFolderFiles(item);
                              }}
                            >
                              <img
                                src={driveFolderIcon}
                                alt=""
                                className="selectoptionUploadFolder"
                              />

                              <p className="fileName">{item.name}</p>
                            </div>
                          )}
                          {item.fileExtension === "csv" && (
                            <i
                              onClick={() => {
                                setGoogleSelectedFile(item);
                                // this.setState({
                                //     googleSelectedFile: item
                                // })
                              }}
                              className={`${
                                googleSelectedFile.id === item.id
                                  ? "checkBoxSelect"
                                  : "unCheckBoxSelect"
                              } material-icons`}
                            >
                              {googleSelectedFile.id === item.id
                                ? "check_circle"
                                : "radio_button_unchecked"}
                            </i>
                          )}
                        </div>
                      ))}
                  </>
                ) : (
                  <>
                    {GetFileLoader ? (
                      <div></div>
                    ) : (
                      <div style={{ textAlign: "center", marginTop: 20 }}>
                        <p className="unSelectedText">No File Avaliable</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="modalcancleTagButton"
            variant="danger"
            onClick={() => {
              setSelectFileModal(false);
              // this.setState({
              //     SelectFileModal: false
              // })
            }}
          >
            <img src={closeIcon} alt="" height={40} width={30} />
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ---------------Import File From Drive Modal------------ */}

      {/* ---------------model------------ */}
      {/* Delete Modal */}
      {isDeleted === true && (
        <Modal centered className="delete-modal-warning br-8" show={isDeleted}>
          <Modal.Header>
            <Modal.Title>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img
                  src={warningIcon}
                  alt=""
                  style={{ width: "60px", height: "60px" }}
                />
                <span
                  style={{
                    fontSize: "24px",
                    color: "#ff1f1f",
                    marginTop: "10px",
                  }}
                >
                  Wait!
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure want to delete all Data associated with the student?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              className="delete-modal-warning cancel-btn"
              onClick={() => {
                editStudent(selectIndex);
                // this.setState({ isDelete: false })
                setIsDeleted(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              className="delete-modal-warning delete-btn"
              style={{ borderRadius: "8px" }}
              onClick={() => {
                deleteStudentHandler(selectStudentId, selectIndex);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {showPopUp && <UpgradePopUp handleClosePopUp={handleClosePopUp} />}
    </div>
  );
  // }
};
// const mapStateToProps = state => ({
//     state: state,
//     rosterData: state.roster,
//     loader: state.roster.loader
// });
// const mapDispatchToProps = dispatch => {
//     return {
//         getRosterList: (data) => dispatch(getRosterList(data)),
//         addRoster: (data) => dispatch(addRoster(data)),
//         deleteRoster: (data) => dispatch(deleteRoster(data)),
//         searchRoster: (data) => dispatch(searchRoster(data)),
//         importRoster: (data) => dispatch(importRoster(data)),
//         setRosterSidebarOpen: (data) => dispatch(setRosterSidebarOpen(data)),
//         getStudentGradebookNullRequest: (data) => dispatch(getStudentGradebookNullRequest(data))

//     };
// };
// export default connect(mapStateToProps, mapDispatchToProps)(
//     RosterDashboard
// );

export default RosterDashboard;

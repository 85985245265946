

import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'


/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    getClassTag: ['data'],
    getClassTagSuccess: ['data'],
    getClassTagFailure: ['error'],

    deleteClassTag: ['data'],
    deleteClassTagSuccess: ['data'],
    deleteClassTagFailure: ['error'],

    editClassTag: ['data'],
    editClassTagSuccess: ['data'],
    editClassTagFailure: ['error'],


    getSpeedNote: ['data'],
    getSpeedNoteSuccess: ['data'],
    getSpeedNoteFailure: ['error'],


    importSpeedNote: ['data'],
    importSpeedNoteSuccess: ['data'],
    importSpeedNoteFailure: ['error'],

   

})

export const UserClassTagReducer = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    Tag_Data: null,
    Edit_Tag_Data: null,
    Delete_Tag_Data: null,
    Get_Speed_Note: null,
    import_Speed_Note: null,
    error: null,
    exists: null,
    loader: null,
    speedTagloader: null,
    action: null,
    note_cores_data : "",
})

/* ------------- Reducers ------------- */

/* getNotSelectedRosters */
export const getClassTag = (state) => {
    return {
        ...state, fetching: true, error: null, Tag_Data: null, loader: true
    }
}
export const getClassTagSuccess = (state, action) => {

    const { data } = action
    
    return { ...state, fetching: false, error: null, Tag_Data: data.data, action: action.type, exists: true, loader: false }
}
export const getClassTagFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_CLASS_TAG_FAILURE', loader: false }
}

/* deleteClassTag */

export const deleteClassTag = (state) => {
    return {
        ...state, fetching: true, error: null, Delete_Tag_Data: null, loader: true
    }
}
export const deleteClassTagSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Delete_Tag_Data: data.response_data, action: action.type, exists: true, loader: false }
}
export const deleteClassTagFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'DELETE_CLASS_TAG_FAILURE', loader: false }
}


/* deleteClassTag */

export const editClassTag = (state) => {
    return {
        ...state, fetching: true, error: null, Edit_Tag_Data: null, loader: true
    }
}
export const editClassTagSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, Edit_Tag_Data: data.response_data, action: action.type, exists: true, loader: false }
}
export const editClassTagFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'EDIT_CLASS_TAG_FAILURE', loader: false }
}


/* getSpeedNote */

export const getSpeedNote = (state) => {
    return {
        ...state, fetching: true, error: null, Get_Speed_Note: null, speedTagloader: true
    }
}
export const getSpeedNoteSuccess = (state, action) => {

    const { data } = action
    
    return { ...state, fetching: false, error: null, Get_Speed_Note: data.data, action: action.type, exists: true, speedTagloader: false }
}
export const getSpeedNoteFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'GET_SPEED_NOTE_FAILURE', speedTagloader: false }
}


/* importSpeedNote */

export const importSpeedNote = (state) => {
    return {
        ...state, fetching: true, error: null, import_Speed_Note: null, loader: true
    }
}
export const importSpeedNoteSuccess = (state, action) => {

    const { data } = action
    return { ...state, fetching: false, error: null, import_Speed_Note: data.response_data, action: action.type, exists: true, loader: false }
}
export const importSpeedNoteFailure = (state, { error }) => {
    return { ...state, fetching: false, error, exists: false, action: 'IMPORT_SPEED_NOTE_FAILURE', loader: false }
}


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {

    [Types.GET_CLASS_TAG]: getClassTag,
    [Types.GET_CLASS_TAG_SUCCESS]: getClassTagSuccess,
    [Types.GET_CLASS_TAG_FAILURE]: getClassTagFailure,

    [Types.DELETE_CLASS_TAG]: deleteClassTag,
    [Types.DELETE_CLASS_TAG_SUCCESS]: deleteClassTagSuccess,
    [Types.DELETE_CLASS_TAG_FAILURE]: deleteClassTagFailure,


    [Types.EDIT_CLASS_TAG]: editClassTag,
    [Types.EDIT_CLASS_TAG_SUCCESS]: editClassTagSuccess,
    [Types.EDIT_CLASS_TAG_FAILURE]: editClassTagFailure,


    [Types.GET_SPEED_NOTE]: getSpeedNote,
    [Types.GET_SPEED_NOTE_SUCCESS]: getSpeedNoteSuccess,
    [Types.GET_SPEED_NOTE_FAILURE]: getSpeedNoteFailure,


    [Types.IMPORT_SPEED_NOTE]: importSpeedNote,
    [Types.IMPORT_SPEED_NOTE_SUCCESS]: importSpeedNoteSuccess,
    [Types.IMPORT_SPEED_NOTE_FAILURE]: importSpeedNoteFailure,

    
})

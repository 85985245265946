import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import cancelBlack from 'Assets/RosterModule/cancelBlack.png'

const NotificationList = (props) => {
    const { item,  onAccept, onCancel, onDelete } = props;
    const alignEnd = [ '',  item.rejected_msg === null ? 'alignEnd' : '' ]
    return (
        <Card className='notificationCard'>
            <div>
                <button type="button" className="close closeButtonInNotification" onClick = {()=>onDelete(item)}><img src={cancelBlack} alt='' className='closeIconInNotification' /> </button>
            </div>
            <Row>
                <Col md={3} lg={3}>
                    {item.cc_user_pic ?<div>
                        <Card.Img className="notificationProfile" variant="top" alt='' src={item.cc_user_pic} />
                    </div>
                    : <div className="circleCentre">
                        <h5 className="notificationProfileText">{item.cc_user_first_name.charAt(0)}{item.cc_user_last_name.charAt(0)}</h5>
                    </div>}
                    {item.current_status === "accept" && <i className='checkBoxfilter material-icons' >
                        {'check_circle'}
                    </i>}
                </Col>
                <Col md={9} lg={9} className='notificationTextCol'>
                    <p className='notificationText'>{item.cc_noti_details}</p>
                    <Row className = {`${alignEnd.join(' ')} ${!item.noti_since && 'justify-content-start'}`}>
                        {item.cc_noti_status === 1  ? <Col md={8} lg={8} className='notificationButtonCol'>
                            <Button className='notificationAcceptButton' onClick = {()=>onAccept(item)}><p className='notificationButtonText'>Accept</p></Button>
                            <Button className='notificationRejectButton' onClick = {()=>onCancel(item)}><p className='notificationButtonText'>Reject</p></Button>
                        </Col> : ""}
                        {item.cc_noti_view_status === "unread" &&  item.noti_since !== null ? <Col md={4} lg={4}>
                            <p className='notificationTime'>
                            {`${item.noti_since} ago`}
                                </p>
                        </Col>:
                         item.current_status === "decline"? <Col md={8} lg={8}>
                            <p className='notificationTime'> {item.rejected_msg}</p>
                         </Col>:""
                        }

                    </Row>
                </Col>
            </Row>
        </Card>
    )
}
NotificationList.propTypes = {
    item: PropTypes.object,
    onAccept: PropTypes.func,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
  };
export default NotificationList;
import React, { useState, useEffect } from 'react';
import addRosterIcon from 'Assets/AcademicModule/addRoster.png';
import addClassIcon from 'Assets/AcademicModule/addClass2.png';
import profileGo from 'Assets/AcademicModule/profileGo.png';
import attendBtn from 'Assets/AcademicModule/attendBtn.png';
import disAttendBtn from 'Assets/AcademicModule/disAttendBtn.png';
import notesBtn from 'Assets/AcademicModule/notesBtn.png';
import assessmentBtn from 'Assets/AcademicModule/assessmentBtn.png';
import disGradeBkIcon from 'Assets/AcademicModule/disGradeBkIcon.png';
import disgradeBkBtn from 'Assets/AcademicModule/disgradeBkBtn.png';
import gradeBkIcon from 'Assets/AcademicModule/gradeBkIcon.png';
import gradeBkBtn from 'Assets/AcademicModule/gradeBkBtn.png';
import formativeAss from 'Assets/AcademicModule/formativeAss.png';
import disFormAsIcon from 'Assets/AcademicModule/disFormAsIcon.png';
import groupNotesIcon from 'Assets/AcademicModule/groupNotesIcon.png';
import groupNotesBtn from 'Assets/AcademicModule/groupNotesBtn.png';
import disRubricsIcon from 'Assets/AcademicModule/disRubricsIcon.png';
import rubricsIcon from 'Assets/AcademicModule/rubricsIcon.png';
import rubricsBtn from 'Assets/AcademicModule/rubricsBtn.png';
import disGroupNotesIcon from 'Assets/AcademicModule/disGroupNotesIcon.png';
import attendIcon from 'Assets/AcademicModule/attendIcon.png';
import disAttendIcon from "Assets/AcademicModule/disAttendIcon.png";
import standardsIcon from "Assets/AcademicModule/standards.svg";
import notesIcon from 'Assets/AcademicModule/notesIcon.png';
import disNotesIcon from 'Assets/AcademicModule/disNotesIcon.svg';
import * as routes from "Router/RoutesURL";
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import { createNotification } from 'Config/notificationtoast';
import { addRoster, setRosterSidebarOpen } from 'Actions/RosterAction/rosterAction';
import { addClass, setClassSidebarOpen } from 'Actions/ClassAction/classAction';
import { getClassNoteNullRequest } from 'Actions/NoteAction/classNoteAction';
import { updateClassGradebookNullRequest } from 'Actions/GradebookAction/classGradebookAction';
import { getStudentNote, getStudentClasses, } from 'Actions/NoteAction/studentNoteAction';
import ReactTooltip from 'react-tooltip';
import camera from 'Assets/RosterModule/camera.png'
import CommonTextField from '../../../Components/CommonTextField/CommonTextField';
import './AcademicYears.scss';
import {  getRosterImage } from "Config/commonFirebaseImage";
import { getRandomName } from 'Utils/Helper';
import { FirebseStroageFolder } from 'Config/Constant';
import { storage } from "../../../firebase/firebase"
import cancelBlack from "Assets/RosterModule/cancelBlack.png";
import LPIcon from 'Assets/AcademicModule/LPIcon.png';

const AcademicYears = (props) => {
    const {
        history,
        location,
        yearData,
        addRoster,
        setRosterSidebarOpen,
        addClass,
        setClassSidebarOpen,
        getClassNoteNullRequest,
        updateClassGradebookNullRequest,
        getStudentNote,
        getStudentClasses,
        academicRosterClassData,
        selectedAcademicYearData,
        Notesloader,
        classLoader,
        rosterLoader,
        rosterDataFinal,
        rosterData,
        notesData,
        rosterCountData,
        classData,
        classCountData,
        rosterAddAction,
        academicYearId
    } = props;
    const pattern = /^[a-z0-9 ]+$/i;
    const pattern2 = /^[a-z]+$/i;
    const [fullName, setFullName] = useState('');
    const [onCount, setOnCount] = useState(0);
    const [onSecondTime, setOnSecondTime] = useState(false);
    const [EnrolledData, setEnrolledData] = useState('');
    const [class_id, setClass_id] = useState('');
    const [NotesData, setNotesData] = useState([]);
    const [totalNoteCount, setTotalNoteCount] = useState('');
    const [StudentAllNoteDetails, setStudentAllNoteDetails] = useState([]);
    const [class_core_standard, setClass_core_standard] = useState([]);
    const [student_details, setStudent_details] = useState([]);
    const [student_enrolled_classes, setStudent_enrolled_classes] = useState([]);
    const [page_no, setPage_no] = useState(1);
    const [filter_by, setFilter_by] = useState('both');
    const [pageBYClass, setPageBYClass] = useState(false);
    const [rosterClassId, setRosterClassId] = useState('');
    const [selectedProfile, setSelectedProfile] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [openType, setOpenType] = useState('NewDashboard');
    const [UserProfile, setUserProfile] = useState('');
    const [UserProfileURL, setUserProfileURL] = useState('');
    const [showLoaderUpload, setShowLoaderUpload] = useState(false);
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [isError, setIsError] = useState('');
    const [isAddStudentSubmit, setIsAddStudentSubmit] = useState(false);
    const [ClassName, setClassName] = useState('');
    const [ClassSection, setClassSection] = useState('');
    const [ClassGrade, setClassGrade] = useState('');
    const [AddClassType, setAddClassType] = useState('add');
    const [isAddClassSubmit, setIsAddClassSubmit] = useState(false);
    const [RosterDataFinal, setRosterDataFinal] = useState([]);
    const [loadMoreCount, setLoadMoreCount] = useState(1);
    const [loadMoreCountStatus, setLoadMoreCountStatus] = useState(false);
    const [InvelidMessageEmail, setInvelidMessageEmail] = useState('');
    const [UserProfileSize, setUserProfileSize] = useState("");
    const [tabButton, setTabButton] = useState("");
    const [finalRosters, setFinalRosters] = useState(academicRosterClassData?.roster_data)
    const [rosterCount, setRosterCount] = useState(academicRosterClassData?.roster_count)
    const [finalClasses, setFinalClasses] = useState(academicRosterClassData?.class_data)
    const [classCount, setClassCount] = useState(academicRosterClassData?.class_count)
    const saveButtonNW = [(FirstName != "" && LastName != "") ? 'saveButton align-items-center' : 'saveButton saveButtonlessOpac align-items-center'];
    const addAnotherButtonNW = [(FirstName != "" && LastName != "") ? 'addAnotherButton align-items-center' : 'addAnotherButton saveButtonlessOpac align-items-center'];
    // const [academic_data, setAcademic_data] = useState(yearData);
    // const [linkType, setLinkType] = useState('Group Notes');
    // const stateData = useSelector((state) => console.log(state))

    // -----------Get Image From Firebase--------
    useEffect(() => {
        const fetchImageData = async () => {
            let DummyRoster = academicRosterClassData.roster_data;
            if (DummyRoster && DummyRoster.length > 0) {
                for (let rosterItem of DummyRoster) {
                    if (rosterItem.cc_student_pic_url) {
                        if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                            rosterItem.cc_student_pic_url = await getRosterImage(rosterItem.cc_student_pic_url);
                        }
                    }

                }
            }
            setRosterDataFinal(DummyRoster);
        }
        fetchImageData()
            .catch(console.error);
    }, [academicRosterClassData]);
    // -----------Get Image From Firebase--------

    useEffect(() => {
        if(finalRosters) {
        const fetchImageData = async () => {
            let DummyRoster = finalRosters;
            if (DummyRoster && DummyRoster.length > 0) {
                for (let rosterItem of DummyRoster) {
                    if (rosterItem.cc_student_pic_url) {
                        if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                            rosterItem.cc_student_pic_url = await getRosterImage(rosterItem.cc_student_pic_url);
                        }
                    }

                }
            }
            setRosterDataFinal(DummyRoster);
        }
        fetchImageData()
            .catch(console.error);
    }
    },[finalRosters])

    useEffect(() => {
        let count = onCount;
        if (onSecondTime) {
            // ---------get Roster Class -------
            if (notesData?.action === 'GET_STUDENT_CLASSES_SUCCESS') {
                if (notesData?.Get_Student_Classes) {
                    if (notesData?.Get_Student_Classes?.length > 0) {
                        let setId = ''
                        if (pageBYClass) {
                            setId = rosterClassId
                        } else {
                            setId = notesData.Get_Student_Classes[0].cc_class_id
                        }
                        setEnrolledData(notesData?.Get_Student_Classes);
                        setClass_id(setId);
                    }
                }
            }
            if (notesData?.action === 'GET_STUDENT_NOTE_SUCCESS') {
                if (notesData?.Get_Student_Note) {
                    if (notesData?.Get_Student_Note?.status === "Success") {
                        let AttachemtData = []
                        if (page_no > 1) {
                            let AllNoteData = NotesData;
                            for (let item of notesData.Get_Student_Note.response_data) {
                                AllNoteData.push(item);
                            }
                            setNotesData(AllNoteData);
                            setTotalNoteCount(notesData?.Get_Student_Note?.total_count);
                            AttachemtData = AllNoteData;
                        } else {
                            setStudentAllNoteDetails(notesData.Get_Student_Note);
                            setClass_core_standard(notesData.Get_Student_Note.class_core_standard);
                            setNotesData(notesData.Get_Student_Note.response_data);
                            setStudent_details(notesData.Get_Student_Note.student_details);
                            setStudent_enrolled_classes(notesData.Get_Student_Note.student_enrolled_classes)
                            // setClass_id(notesData.Get_Student_Note.student_enrolled_classes[0].cc_class_id);
                            setTotalNoteCount(notesData.Get_Student_Note.total_count);
                            AttachemtData = notesData.Get_Student_Note.response_data;
                        }
                    }
                }
            }
            // ---------get Roster Note-------
        } else {
            count++;
            setOnCount(count);
            if (count > 1) {
                setOnSecondTime(true);
            }
        }

        if (notesData?.action === 'GET_STUDENT_NOTE_SUCCESS' && openType === 'btnCreate') {
            if (notesData?.Get_Student_Classes && notesData?.Get_Student_Classes?.length > 0) {
                if (NotesData && student_enrolled_classes && StudentAllNoteDetails) {
                    history.push(routes.STUDENTADDNOTE,
                        {
                            pageType: 'New',
                            data: '',
                            studentInfo: JSON.parse(sessionStorage.getItem('selectedprofile')),
                            academicYear: sessionStorage.getItem('selectedYear'),
                            student_enrolled_classes: notesData.Get_Student_Note.student_enrolled_classes,
                            NotesData: notesData.Get_Student_Note.response_data,
                            StudentAllNoteDetails: notesData.Get_Student_Note,
                            class_id: class_id,
                            directAdd: "directAdd",
                            BackPage : "Academic Year"
                        })
                } else {
                    createNotification('error', "The students are not enrolled. Please enrol students to begin creating Notes.");
                }
            }
        }

        if (rosterAddAction?.status == 200) {
            if (tabButton === 'addAnother') {
                // setRosterSidebarOpen(true)
                window.$('#myModal2').modal('show');
            }
        }

    }, [notesData, rosterAddAction]);
    // ---------componentWillReceiveProps-------

    const openStudentNoteAdd = (value, year) => {
        sessionStorage.setItem("selectedprofile", JSON.stringify(value))
        sessionStorage.setItem("selectedYear", JSON.stringify(year))
        setOpenType('btnCreate');
        setSelectedProfile(value);
        setSelectedYear(year);

        const { state = {} } = location;
        const data = {
            "student_id": value.cc_student_id,
            "page_no": page_no,
            "class_id": class_id,
            "filter_by": filter_by,
            "pageBYClass": '',
            "rosterClassId": ''
        }
        getStudentClasses(data);
    }
    // ---------handleChange-------
    const handleClassChange = (event) => {
        setIsAddClassSubmit(false);
        // [event.target.name]: event.target.value,
    }
    // ---------handleChange-------

    // ---------handleChange-------
    const handleGradeChange = (event) => {
        setIsAddClassSubmit(false);
        // [event.target.name]: event.target.value,
    }
    // ---------handleChange-------
    // ---------Add New Class-------
    const [btnval, setbtnval] = useState(false)

    const addNewClass = (data) => {
        setbtnval(true)
        setIsAddClassSubmit(true);
        setLoadMoreCount(1);
        if (!ClassName || (ClassName && ClassName.trim().length <= 0)) {
            return;
        }
        const alphanumericRegex = /^[a-zA-Z ]*$/;
    if (!alphanumericRegex.test(ClassName)) {
      return;
    }
        if (ClassSection && ClassSection.length > 2) {
            setInvelidMessageEmail('Section has a maximum of 2 characters');
            return false;
        } else if (ClassGrade && ClassGrade.length > 2) {
            setInvelidMessageEmail('Grade name should be of 2 characters');
            return false;
        } else {
            let classData = {
                "class_section": ClassSection,
                "class_name": ClassName,
                "class_grade": ClassGrade,
                "class_academic_year": data?.academic_year,
                "class_academic_year_Id": data?.academic_year_id,
                "filter_by": filter_by,
                "page_no": 1,
                "year_ids" : props?.yearData?.year_ids
            }
            window?.$?.('#addClassModal')?.modal('hide');
            addClass(classData)
            setClassGrade('')
            setClassSection('')
            setClassName('')
            setIsAddClassSubmit(false);
            setInvelidMessageEmail('')
            return true;
        }
    }
    // ---------Add New Class-------

    // ---------handleChange-------
    const handleChange = (event) => {
        setIsAddStudentSubmit(false);
        setInvelidMessageEmail('');
        // [event.target.name]: event.target.value
    }
    // ---------handleChange-------

    const handleClick = (event) => {
        const { target = {} } = event || {};
        target.value = "";
    };

    // ---------Image Upload on Firebase-------
    const handleChangeFile = (e) => {
        if (e.length === 0) {
            return false;
        }
        const data = JSON.parse(sessionStorage.getItem("UserData"));
        const imageAsFile = e[0];
        setIsAddStudentSubmit(false);
        // setInvelidMessageEmail("");
        setShowLoaderUpload(true);

        // ---------Image Type-------
        const ImageTypeArray = imageAsFile.name.split(".");
        const ImageType = ImageTypeArray[ImageTypeArray.length - 1];
        const media_Size = imageAsFile.size / 1000000;
        // ---------Image Type-------

        // ---------DummyName-------
        const GetRendomName = getRandomName();
        const DummyName = GetRendomName + "." + ImageType;
        // ---------DummyName-------

        // ---------FolderName-------
        const FinfFolderNameArray = data.cc_user_email_id.split("@");
        const FolderName = FinfFolderNameArray[0];
        // ---------FolderName-------

        const uploadTask = storage
            .ref(`${FirebseStroageFolder}/${FolderName}/roster/${DummyName}`)
            .put(imageAsFile);
        uploadTask.on(
            "state_changed",
            (snapShot) => { },
            (err) => {
                setShowLoaderUpload(false);
                createNotification("success", "The image was not uploaded. Please try again.");
            },
            () => {
                storage
                    .ref(`${FirebseStroageFolder}/${FolderName}/roster/`)
                    .child(DummyName)
                    .getDownloadURL()

                    .then((fireBaseUrl) => {
                        setShowLoaderUpload(false);
                        createNotification("success", "Great! The Image Upload was successful!");
                        setUserProfileURL(fireBaseUrl);
                        setUserProfile(`${FirebseStroageFolder}/${FolderName}/roster/${DummyName}`);
                        setUserProfileSize(media_Size);
                    });
            }
        );
    };
    // ---------Image Upload on Firebase-------

const [rostertrue, setrostertrue] = useState(false)
    // ---------Create New Roster-------
    const createNewRoster = (tab, data) => {
        setrostertrue(true)
        // const { state = {} } = location;
        // const { data = '' } = state;
        const pattern2 = /^[a-zA-Z]*$/;
   
        if (showLoaderUpload) {
            createNotification('error', 'The image is still uploading.');
            return;
        }
        setIsAddStudentSubmit(true);
        setTabButton(tab)
        if ((!FirstName || (FirstName && FirstName.trim().length <= 0)) || !LastName || (LastName && LastName?.trim().length <= 0)) {
            return false;
        }
        if ((!pattern2?.test(FirstName)) || !pattern2?.test(LastName)) {
            return;
          }
        if (Email) {
                const valid = /^(?![.])[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(?:\.[A-Za-z]{2,5})?$/;
            if (!valid?.test(Email)) {
                setInvelidMessageEmail('Please enter valid email*');
                return false;
            }
        }

        const value = {
            "academic_year_id": data?.academic_year_id,
            "student_pic_size": UserProfileSize,
            "student_first_name": FirstName,
            "student_last_name": LastName,
            "student_registration_email_id": Email,
            "student_secondary_email_id": '',
            "student_pic_url": UserProfile,
            "student_id": '',
            "class_id": '',
            "by_class_sharing": '',
            "filter_by": filter_by,
            "page_no": 1,
            "academic_year": data?.academic_year_id,
        }
        setLoadMoreCount(1);
        setLoadMoreCountStatus(false);
        // this.setState({
        //     loadMoreCount: 1,
        //     loadMoreCountStatus: false,
        // })
        window?.$?.('#myModal2')?.modal('hide');
        setInvelidMessageEmail('')
        setFirstName('')
        setLastName('')
        setEmail('')
        setUserProfile('')
        setUserProfileURL('')

        props.addRoster(value);
        setIsAddStudentSubmit(false)
    }
    // ---------Create New Roster-------
    const data = selectedAcademicYearData !== undefined && selectedAcademicYearData?.length !== 0 ? selectedAcademicYearData[0] : yearData

    const year_data = JSON.stringify(yearData);
    // let finalRosters = []
    // let finalClasses = []
    // finalRosters = rosterDataFinal !== null || undefined || '' ? rosterDataFinal : RosterDataFinal;
    // finalRosters = RosterDataFinal;
    // finalClasses = academicRosterClassData.class_data;
    // finalRosters = academicRosterClassData.roster_data;
    // const classCount = academicRosterClassData.class_count;

    const getBackGroundColor = (index) => {
        let color = ['#D7EEFB', '#EDE6FB', '#E2F4DB', '#FAEAC9', '#D3E3FD', '#FFE5D9', '#FBF9D6'];;
        if (index == 0) color = '#BAE5F499'
        if (index == 1) color = '#E1D3FE80'
        if (index == 2) color = '#CCF0BE80'
        if (index == 3) color = '#FCE1AA99'
        if (index == 4) color = '#D3E3FD'
        return color;
    }

    useEffect(() => {
        if (rosterData && rosterData.length > 0) {
            setFinalRosters(rosterData)
            setRosterCount(rosterCountData?.[0]?.count)
        }
        if (classData && classData.length > 0) {
            setFinalClasses(classData)
            setClassCount(classCountData?.[0]?.count)
        }
        // else {
        // setFinalRosters(academicRosterClassData.roster_data)
        // }
    }, [rosterData, classData])

    useEffect(() => {
        if (academicRosterClassData?.roster_data) {
            setFinalRosters(academicRosterClassData?.roster_data)
            if (academicRosterClassData?.roster_count >= 0) {
                setRosterCount(academicRosterClassData?.roster_count)
            } else {
                setRosterCount(academicRosterClassData?.roster_data.length)
            }
        }
        if (academicRosterClassData?.class_data) {
            setFinalClasses(academicRosterClassData?.class_data)
            if (academicRosterClassData?.class_count >= 0) {
                setClassCount(academicRosterClassData?.class_count)
            } else {
                setClassCount(academicRosterClassData?.class_data.length)
            }
        }
    }, [academicRosterClassData])

    return (
        <div className='academicYearsMainContainer'>
             <p style={{display : "none"}} data-testid = "truebtn"
                    onClick={() => {setClassName("abc")
                  }}
            ></p>
            <div> {classLoader || rosterLoader || Notesloader ? <img src={loaderImag} alt='' className='loaderIconCss' /> : ""}</div>
            {/* ---------------Class Main Div------------ */}
            <div style={{ textAlign: 'left' }}>
                {classCount > 0 && finalClasses && finalClasses.length !== 0 && finalClasses.length > 0 ?
                    <div className='addClassListDiv'>
                        <div className='addTextBox'>
                            <div>
                                <p className='addTextTitle'>Classes </p>
                            </div>
                            <div className='addTextSubBox' >
                                <p className='addTextSubTitle' onClick={() => { window.sessionStorage.removeItem('UserInitialLogin'); history.push(routes.CLASS, { data: data, BackPage: 'Academic Year' }) }} >{classCount} Classes</p>
                                <img src={profileGo} alt='' className='addTextSubTitleImage' />
                            </div>
                        </div>
                        <div className='academicListSeprator'></div>
                        {JSON.parse(sessionStorage.getItem('UserData'))?.user_type != "school_teacher" &&
                        <div className='addClassImageDiv'
                        data-toggle="modal"
                        //  data-target="#addClassModal"
                        data-target={JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? '' : '#addClassModal'}
                        onClick={() => {
                            // this.props.setClassSidebarOpen(true)
                            // history.push(routes.CLASS, { data: data, BackPage: 'Academic Year' });
                        }}
                        >
                        <img src={addClassIcon} alt='' className='addClassImage' />
                        </div>
                        }
                       
                        {classCount > 0 && finalClasses && <div className='classListDiv'>
                            {/* ---------------Class List Card------------ */}
                            {finalClasses.slice(0, 5).map((item, index) => (
                                <div key={index} className='classListCard' style={{ backgroundColor: getBackGroundColor(index) }}>
                                    {/* ------------Class Info Div----------  */}
                                    <div className='classDetailsDiv'
                                        onClick={() => {
                                            history.push(routes.SINGLECLASS, { data: item, AcademicData: data, BackPage: 'Academic Year', academicYear: data.academic_year, isClasses: true });
                                            sessionStorage.setItem("cLsIdForSingleClass", item.cc_class_id)
                                            sessionStorage.setItem("cLsIdForGBSC", item.cc_class_id)
                                            window.sessionStorage.removeItem('UserInitialLogin')
                                        }}
                                    >
                                        <div>
                                            <p className='classNameTitle' >{item?.cc_class_name?.replace(/(.{13})..+/, "$1…")}</p>
                                        </div>
                                        <div style={{ display: 'flex', position: 'relative', top: 2 }}>
                                            <p className='classOtherDetails'>{item.cc_class_section === '' ? '-' : item.cc_class_section}</p>
                                            <div className='academicListSeprator3'></div>
                                            <p className='classOtherDetails' style={{}}> {item.cc_class_grade === '' ? '-' : item.cc_class_grade}</p>
                                        </div>
                                    </div>
                                    {/* ------------Class Info Div----------  */}
                                </div>))}
                            {/* ---------------Class List Card------------ */}
                        </div>}
                    </div>
                    :
                    <div className='addClassListDiv'>
                        <div className='addTextBox'>
                            <div>
                                <p className='addTextTitle'>Classes </p>
                            </div>
                            <div className='addTextSubBox' >
                                <p className='addTextSubTitle' onClick={() => {window.sessionStorage.removeItem('userInitialLogin');  history.push(routes.CLASS, { data: data, BackPage: 'Academic Year' }) }} >0 Classes</p>
                                <img src={profileGo} alt='' className='addTextSubTitleImage' />
                            </div>
                        </div>
                        <div className='academicListSeprator'></div>
                        <div className='addClassImageDiv'
                            data-toggle="modal"
                            // data-target="#addClassModal"
                            data-target={JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? '' : '#addClassModal'}
                            onClick={() => {
                                // this.props.setClassSidebarOpen(true)
                                // history.push(routes.CLASS, { data: data, BackPage: 'Academic Year' });
                            }}
                        >
                            <img src={addClassIcon} alt='' className='addClassImage' />
                        </div>
                        <p className='addClassText'>Tap + icon to add classes</p>
                        {/* </div> */}
                    </div>
                }
            </div>
            {/* ---------------Class Main Div------------ */}

            {/* ---------------Roster Main Div------------ */}
            <div style={{ textAlign: 'left', marginTop: 10, position: 'relative' }}>
                {finalRosters && finalRosters.length > 0 ?
                    <div className='addRosterListDiv'>
                        <div className='addTextBox2'>
                            <div>
                                <p className='addTextTitle'>Roster </p>
                            </div>
                            <div className='addTextSubBox' >
                                <p className='addTextSubTitle' onClick={() => { window.sessionStorage.removeItem('UserInitialLogin'); history.push(routes.ROSTER, { data: data, BackPage: 'Academic Year', finalClasses: academicRosterClassData?.class_data }) }}>{rosterCount} Students</p>
                                <img src={profileGo} alt='' className='addTextSubTitleImage' />
                            </div>
                        </div>
                        <div className='academicListSeprator2'></div>
                        {finalRosters && finalRosters.length > 0 && <div className='rosterListDiv'>
                           {JSON.parse(sessionStorage.getItem('UserData'))?.user_type != "school_teacher" &&
                            <div className='addRosterImageDiv'
                                data-toggle="modal"
                                // data-target="#myModal2"
                                data-target={JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? '' : '#myModal2'}
                            >
                                <img style={JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ?{cursor : "default"} : {cursor : "pointer"}} src={addRosterIcon} alt='' className='addRosterImage'
                                    onClick={() => {
                                        // setRosterSidebarOpen(true)
                                        // history.push(routes.ROSTER, { data: data, BackPage: 'Academic Year', finalClasses: academicRosterClassData?.class_data });
                                    }}
                                />
                            </div>}
                            {/* ---------------Roster List Card------------ */}
                            {finalRosters && finalRosters.slice(0, 9).map((item, index) => {
                                var name = item.cc_student_last_name + ' ' + item.cc_student_first_name
                                return (
                                    <div key={index}>
                                        <div className='rosterProfileDiv' data-tip={name.replace(/(.{14})..+/, "$1…")} onClick={() => { 
                                            item.is_associated_with_class === "no" ? createNotification('error', "The students are not enrolled. Please enrol students to begin creating Notes.") : history.push(routes.STUDENTNOTE, { studentInfo: item, academicYear: data.academic_year, academicYearIDFromClassNote : data?.academic_year_id, From: "Academic", BackPage: 'Academic Year', directAdd: 'directAddNo' })
                                            
                                            }}>
                                            {item?.cc_student_pic_url?.includes("https") ?
                                                <img src={item.cc_student_pic_url} alt='' className='imageDummyDiv' />
                                                : <div className='imageDummyDivProfileInRoster' >
                                                    <h5 className='profileImageDummyTextRoster'>{item.cc_student_first_name.charAt(0)}{item.cc_student_last_name.charAt(0)}</h5>
                                                </div>}
                                            <ReactTooltip effect="solid" className="example" />
                                        </div>

                                    </div>)
                            })}
                            {/* ---------------Roster List Card------------ */}
                        </div>}

                    </div> :
                    <div className='addRosterListDiv'>
                        <div className='addTextBox2'>
                            <div>
                                <p className='addTextTitle'>Roster </p>
                            </div>
                            <div className='addTextSubBox' >
                                <p className='addTextSubTitle' onClick={() => { window.sessionStorage.removeItem('UserInitialLogin'); history.push(routes.ROSTER, { data: data, BackPage: 'Academic Year', finalClasses: academicRosterClassData?.class_data }) }}>0 Students</p>
                                <img src={profileGo} alt='' className='addTextSubTitleImage' />
                            </div>
                        </div>
                        <div className='academicListSeprator2'></div>
                        <div className='rosterListDiv'>
                            <div className='addRosterImageDiv'
                               style={JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ?{cursor : "default" , opacity : 0.5} : {cursor : "pointer" , opacity : 1}}
                                data-toggle="modal"
                                data-target={JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? '' : '#myModal2'}
                                // data-target="#myModal2"

                            >
                                <img style={JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ?{cursor : "default"} : {cursor : "pointer"}} src={addRosterIcon} alt='' className='addRosterImage'
                                    onClick={() => {
                                        // setRosterSidebarOpen(true)
                                        // history.push(routes.ROSTER, { data: data, BackPage: 'Academic Year', finalClasses: academicRosterClassData?.class_data });
                                    }}
                                />
                            </div>
                            <p className='addRosterText'>Tap + icon to add students</p>
                        </div>
                    </div>
                }
            </div>
            {/* </div> */}
            {/* ---------------Roster Main Div------------ */}
            <div className='lineSeprator'></div>
            {/* ---------------Attendance/Notes Main Div------------ */}
            <div className='bottomButtonsMainDiv'>
                {finalClasses && finalClasses.length > 0 && classCount > 0 ?
                    <div className='buttonsMainBox attendanceBtn' onClick={() => { history.push(routes.ATTENDANCE, { data: data, BackPage: 'Academic Year' }) }}>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={attendIcon} alt='' className='bottomIcon' />
                                <p className='bottomBoxText'>Attendance</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={attendBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div> :
                    <div className='buttonsMainBox disAttendanceBtn'>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={disAttendIcon} alt='' className='disBottomIcon' />
                                <p className='bottomBoxDisText'>Attendance</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={disAttendBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div>

                }
                {finalClasses && finalClasses.length > 0 && classCount > 0 ?
                    <div className='buttonsMainBox notesBtn'
                        onClick={() => { history.push(routes.NOTESELECTCLASS, { data: data, BackPage: 'Academic Year', studentInfo: finalRosters[0], academic_year: year_data, class_Data: finalClasses[0], academic_data: data, linkType: 'Notes' }) }}
                    >
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={notesIcon} alt='' className='bottomIcon' />
                                <p className='bottomBoxText'>Notes</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={notesBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div> :
                    <div className='buttonsMainBox disNotesBtn'>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={disNotesIcon} alt='' className='disBottomIcon' />
                                <p className='bottomBoxDisText'>Notes</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={disAttendBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div>
                }
                {finalClasses && finalClasses.length > 0 && classCount > 0 ?
                    <div className='buttonsMainBox grpNotesBtn'
                        onClick={() => { history.push(routes.NOTESELECTCLASS, { data: data, BackPage: 'Academic Year', studentInfo: finalRosters[0], academic_year: year_data, academicYearId : props?.yearData?.academic_year_id, class_Data: finalClasses[0], academic_data: data, linkType: 'Group Notes' }) }}
                    >
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={groupNotesIcon} alt='' className='bottomIcon' />
                                <p className='bottomBoxText'>Group Notes</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={groupNotesBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div> :
                    <div className='buttonsMainBox disgrpNotesBtn'>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={disGroupNotesIcon} alt='' className='disBottomIcon' />
                                <p className='bottomBoxDisText'>Group Notes</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={disAttendBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div>
                }
               {finalClasses && finalClasses.length > 0 && classCount > 0 ?
                    <div className='buttonsMainBox formAssesmentBtn' onClick={() => { 
                        
                        history.push(routes.CLASSLESSONPLAN, { AcademicData: data, BackPage: 'Academic Year' })
                         }}>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={LPIcon} alt='' className='bottomIcon' />
                                <p className='bottomBoxText'>Lesson Plan</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={assessmentBtn} alt='Lesson Plan' className='bottomBtn' />
                            </div>
                        </div>
                    </div> :
                    <div className='buttonsMainBox disFormAssesmentBtn'>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={disFormAsIcon} alt='' className='disBottomIcon' />
                                <p className='bottomBoxDisText'>Lesson Plan</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={disAttendBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div>
                }
                {finalClasses && finalClasses.length > 0 && classCount > 0 ?
                    <div className='buttonsMainBox rubricsDashBtn' onClick={() => { history.push(routes.CLASSRUBRICS, { AcademicData: data, BackPage: 'Academic Year' }) }}>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={rubricsIcon} alt='' className='bottomIcon' />
                                <p className='bottomBoxText'>Rubrics</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={rubricsBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div> :
                    <div className='buttonsMainBox disFormAssesmentBtn'>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={disRubricsIcon} alt='' className='disBottomIcon' />
                                <p className='bottomBoxDisText'>Rubrics</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={disAttendBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div>
                }
                {finalClasses && finalClasses.length > 0 && classCount > 0 ?
                    <div className='buttonsMainBox formAssesmentBtn' onClick={() => { history.push(routes.FORMATIVEASSESSMENT, { AcademicData: data, BackPage: 'Academic Year' }) }}>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={formativeAss} alt='' className='bottomIcon' />
                                <p className='bottomBoxText'>Formative Assessment</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={assessmentBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div> :
                    <div className='buttonsMainBox disFormAssesmentBtn'>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={disFormAsIcon} alt='' className='disBottomIcon' />
                                <p className='bottomBoxDisText'>Formative Assessment</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={disAttendBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div>
                }
                {finalClasses && finalClasses.length > 0 && classCount > 0 ?
                    <div className='buttonsMainBox gradeBkBtn' onClick={() => { history.push(routes.CLASSGRADEBOOK, { AcademicData: data, BackPage: 'Academic Year' }) }}>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={gradeBkIcon} alt='' className='bottomIcon' />
                                <p className='bottomBoxText'>Gradebook</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={gradeBkBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div> :
                    <div className='buttonsMainBox disGradeBkBtn'>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={disGradeBkIcon} alt='' className='disBottomIcon' />
                                <p className='bottomBoxDisText'>Gradebook</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={disgradeBkBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div>
                }
                  {finalClasses && finalClasses.length > 0 && classCount > 0 ?
                    <div className='buttonsMainBox notesBtn'
                        onClick={() => { history.push(routes.AllSTANDARDS, { data: data, BackPage: 'Academic Year', studentInfo: finalRosters[0], academic_year: year_data, class_Data: finalClasses[0], academic_data: data, linkType: 'Notes' }) }}
                    >
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={standardsIcon} alt='' className='bottomIcon' />
                                <p className='bottomBoxText'>Standards</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={notesBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div> :
                    <div className='buttonsMainBox disNotesBtn'>
                        <div className='btnMainDiv'>
                            <div className='btnSubDiv'>
                                <img src={standardsIcon} alt='' className='disBottomIcon' style={{filter : 'grayscale(1)', opacity : "0.5"}} />
                                <p className='bottomBoxDisText'>Standards</p>
                            </div>
                            <div className='btnSubDiv2'>
                                <img src={disAttendBtn} alt='' className='bottomBtn' />
                            </div>
                        </div>
                    </div>
                }
                <div className='buttonsMainBox'>
                </div>
                <div className='buttonsMainBox'></div>
                <div className='buttonsMainBox'></div>
                <div className='buttonsMainBox'></div>
                <div className='buttonsMainBox'></div>
                <div className='buttonsMainBox'></div>
            </div>
            {/* ---------------Attendance/Notes Main Div------------ */}

            {/* ---------------Modal Add Student------------ */}
            <div className="modal right fade ClassRosterModalAY" id="myModal2" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {/* <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel2">Add Students</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span className='cancelText' aria-hidden="true">  <img src={cancelBlack} alt='' className='closeIconInModal' /> Cancel</span></button>
                        </div> */}
                     <div className="modal-header rosterheader">
              <h4 style={{fontSize : "17px"}} className="modal-title Addstudentmodal" id="myModalLabel2">
                Add Student
              </h4>
              <button
                type="button"
                className="close closeround"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="cancelText d-flex flex-wrap justify-content-center align-items-center" aria-hidden="true">
                  {" "}
                  <img
                    src={cancelBlack}
                    alt=""
                    className="closeIconInModal"
                    style={{position : 'relative', left: "0.3px"}}
                  />{" "}
                 
                </span>
              </button>
            </div>
                        <div className="modal-body">
                            {/* -----------Add Student Profile----------- */}
                            {UserProfileURL === '' || UserProfileURL === undefined ? <div className='RosterImageMainDiv'>
                                <div className='addStudentImageDiv'>
                                    {showLoaderUpload ? <img src={loaderImag} alt='' className='loaderIconCss' /> :
                                        <label htmlFor="file"><img src={camera} alt='' className='cameraIconCss' /></label>}
                                </div>
                                <input className="custom-file-input"
                                    onChange={(e) => handleChangeFile(e.target.files)}
                                    onClick={() => handleClick()}
                                    type="file" id="file" name="file" accept="image/*" />
                            </div> :
                                <div style={{ backgroundImage: `url(${UserProfileURL})` }} className='RosterImageMainSelectedDiv'>
                                    <div className='addStudentImageDiv'>
                                        <img src={UserProfileURL} alt='' className='RosterImageWhenSelect' />
                                    </div>
                                    <Button className='changeButton'>
                                        {showLoaderUpload ? <img src={loaderImag} alt='' className='loaderIconCssAlreadySelected' /> :
                                            <label htmlFor="file" className='changeButtonText'>Change</label>}
                                    </Button>
                                    <input className="custom-file-input"
                                        onChange={(e) => handleChangeFile(e.target.files)}
                                        onClick={() => handleClick()}
                                        type="file" id="file" name="file" accept="image/*" />
                                </div>}
                            {/* -----------Add Student Profile----------- */}

                            {/* -----------All Input Type----------- */}
                            <div className='allInputMainDiv'>
                                <div className='studentAddInputDiv mt-1'>
                                    {/* <h5 className='studentInputLableInfo'>First name*</h5> */}
                                    <CommonTextField
                                        dataTestId = "FirstNameFld"
                                        margin="normal"
                                        variant="outlined"
                                        type='FirstName'
                                        value={FirstName}
                                        onChange={event => { setFirstName(event.target.value); handleChange() }}
                                        name="FirstName"
                                        className="studentAddInput"
                                        placeholder="First Name*" />
                                    {((!FirstName || (FirstName && FirstName.trim().length <= 0)) && isAddStudentSubmit) && <p className="errormessageRegister">Please enter first name</p>}

                                    {FirstName && !pattern2.test(FirstName) && isAddStudentSubmit && (
                   <p className="errormessageRegister">Please enter only letters</p>
                     )}
                                </div>
                                <div className='studentAddInputDiv mt-1'>
                                    {/* <h5 className='studentInputLableInfo'>Last name*</h5> */}
                                    <CommonTextField
                                    dataTestId = "LastNameFld"
                                        margin="normal"
                                        variant="outlined"
                                        type='LastName'
                                        value={LastName}
                                        onChange={event => { setLastName(event.target.value); handleChange() }}
                                        name="LastName"
                                        className="studentAddInput"
                                        placeholder="Last Name*" />
                                    {((!LastName || (LastName && LastName.trim().length <= 0)) && isAddStudentSubmit) && <p className="errormessageRegister">Please enter last name</p>}

                                    {LastName && !pattern2.test(LastName) && isAddStudentSubmit && (
                   <p className="errormessageRegister">Please enter only letters</p>
                     )}
                                </div>
                                <div className='studentAddInputDiv mt-1'>
                                    {/* <h5 className='studentInputLableInfo'>Email ID (Optional)</h5> */}
                                    <CommonTextField
                                    dataTestId = "EmailFld"
                                        margin="normal"
                                        variant="outlined"
                                        type='Email'
                                        value={Email}
                                        onChange={event => { setEmail(event.target.value); handleChange() }}
                                        name="Email"
                                        className="studentAddInput"
                                        placeholder="Email ID" />
                                    {(Email && InvelidMessageEmail) && <p className="errormessageRegister">Please enter valid email*</p>}
                                </div>
                                {/* -----------All Input Type----------- */}

                                {/* -----------All Button----------- */}
                                <div className="modalButtonClass addRosterModalButtonClass modalNW">
                                    <div>
                                        <Button data-testid = "addRosterbtn" value={rostertrue} onClick={() => { createNewRoster('no', data) }} className= {saveButtonNW.join(' ')} color="primary" >
                                            <span className='saveText' > Save</span>
                                        </Button>
                                    </div>
                                    <div>
                                        <Button value={rostertrue} data-testid = "addAnotherRosterbtn" onClick={() => { createNewRoster('addAnother', data) }} className={addAnotherButtonNW.join(' ')} color="primary" >
                                            <span className='saveText' > Save and Add Another</span>
                                        </Button>
                                    </div>
                                </div>
                                {/* -----------All Button----------- */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div data-testid = "addClassModal" className="modal right fade addOfClass" id="addClassModal" tabIndex="-1" role="dialog" aria-labelledby="addClassModal"  >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="addClassModalHeaderTitle" id="addClassModal">
                                Add Class
                            </h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span className="cancelClassText d-flex flex-wrap justify-content-center align-items-center rounded-circle" aria-hidden="true" style={{width : 30, height : 30}}>
                                    {" "}
                                    <img
                                        src={cancelBlack}
                                        alt=""
                                        className="closeIconInClassModal"
                                        style={{marginTop : 0}}
                                    />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* -----------All Input Type----------- */}
                            <div className='allInputClassMainDiv'>
                                <div className='classAddInputDiv'>
                                    {/* <h5 className='classInputLableInfo'>Class name</h5> */}
                                    <CommonTextField
                                       dataTestId="classNameField"
                                        margin="normal"
                                        variant="outlined"
                                        type='ClassName'
                                        value={ClassName}
                                        onChange={event => { setClassName(event.target.value); handleClassChange() }}
                                        name="ClassName"
                                        className="classAddInput"
                                        placeholder="Enter Class name" />
                                    {((!ClassName || (ClassName && ClassName.trim().length <= 0) ) && isAddClassSubmit) && <p className="errormessageRegister">Please enter class name</p>}
                                   
                                    {ClassName && (ClassName.trim().length != 0) && !pattern.test(ClassName) && isAddClassSubmit && (
                             <p className="errormessageRegister">Please enter only letters and numbers</p>
                                                            )}
                                </div>
                                <div className='classAddInputDiv'>
                                    {/* <h5 className='classInputLableInfo'>Section</h5> */}
                                    <CommonTextField
                                    dataTestId = "classSection"
                                        margin="normal"
                                        variant="outlined"
                                        type='ClassSection'
                                        value={ClassSection}
                                        onChange={event => { setClassSection(event.target.value); handleClassChange() }}
                                        name="ClassSection"
                                        className="classAddInput"
                                        placeholder="Enter Section"
                                        maxLength={2} />
                                    {( (ClassSection && ( ClassSection.length > 2 )) )&& <p className="errormessageRegister">Section has a maximum of 2 characters</p>}
                                </div>
                                <div className='classAddInputDiv'>
                                    {/* <h5 className='classInputLableInfo'>Grade</h5> */}
                                    <CommonTextField
                                    dataTestId = "classGrade"
                                        margin="normal"
                                        variant="outlined"
                                        type='ClassGrade'
                                        value={ClassGrade}
                                        onChange={(event) => { setClassGrade(event.target.value); handleGradeChange() }}
                                        name="ClassGrade"
                                        className="classAddInput"
                                        placeholder="Enter Grade"
                                        maxLength={2}
                                    />
                                    {( ClassGrade && (ClassGrade.length > 2 ) ) && <p className="errormessageRegister">Grade name should be of 2 characters</p>}
                                </div>
                                {/* -----------All Input Type----------- */}

                                {/* -----------All Button----------- */}
                                <div className="modalButtonClassPage">
                                    <div>
                                        <Button value={btnval} data-testid = "saveclsbtn" onClick={() => { addNewClass(data) }} className={`saveButtonClass`} disabled={(ClassName.length > 0 || ClassSection.length > 0 || ClassGrade.length > 0) ? false : true} color="primary" >
                                            <span className='saveTextClass'> Save</span>
                                        </Button>
                                    </div>

                                </div>
                                {/* -----------All Button----------- */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ---------------Modal Add Class------------ */}
        </div>
    )
}

const mapStateToProps = state => ({
    state: state,
    notesData: state.studentNote,
    Notesloader: state.studentNote.Notesloader,
    loader: state.studentNote.loader,
    rosterLoader: state.roster.loader,
    classLoader: state.class.loader,
    rosterData: state?.roster?.roster_Data?.data?.[0],
    rosterCountData: state?.roster?.roster_Data?.data?.[1],
    rosterAddAction: state?.roster?.add_roster,
    classData: state?.class?.class_Data?.data?.[0],
    classCountData: state?.class?.class_Data?.data?.[1],
});
const mapDispatchToProps = dispatch => {
    return {
        setRosterSidebarOpen: (data) => dispatch(setRosterSidebarOpen(data)),
        setClassSidebarOpen: (data) => dispatch(setClassSidebarOpen(data)),
        getClassNoteNullRequest: (data) => dispatch(getClassNoteNullRequest(data)),
        updateClassGradebookNullRequest: (data) => dispatch(updateClassGradebookNullRequest(data)),
        getStudentClasses: (data) => dispatch(getStudentClasses(data)),
        getStudentNote: (data) => dispatch(getStudentNote(data)),
        addClass: (data) => dispatch(addClass(data)),
        addRoster: (data) => dispatch(addRoster(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    AcademicYears
);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MicRecorder from 'mic-recorder-to-mp3';
import StudentNoteProgressbarComponent from '../StudentNoteProgressbarComponent/StudentNoteProgressbar';
import StudentNoteCommentCardComponent from '../StudentNoteCommentCardComponent/StudentNoteCommentCard';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Timer from 'react-compound-timer'
import playIcon from 'Assets/NoteModule/play.svg';
import closeIcon from 'Assets/NoteModule/close.svg';
import tickIcon from 'Assets/NoteModule/tick.svg';
// import tickIcon from 'Assets/NoteModule/tick.svg';
import closeMediaIcon from 'Assets/NoteModule/closeMedia.svg';
import addTransperent from 'Assets/NoteModule/addTransperent.svg';
import note_pdf from 'Assets/NoteModule/note_pdf.png';
import note_doc from 'Assets/NoteModule/note_doc.png';
import note_csv from 'Assets/NoteModule/note_csv.png';
import note_ppt from 'Assets/NoteModule/note_ppt.png';
import note_xls from 'Assets/NoteModule/note_xls.png';
import audioWave from 'Assets/NoteModule/audioWave.png';
import halfAudio from 'Assets/NoteModule/halfAudio.png'
import attachedIcon from 'Assets/NoteModule/paperclip.svg';
import tick from 'Assets/NoteModule/tick.svg';
import calendarIcon from 'Assets/NoteModule/calendar.svg';
import back from 'Assets/CommonComponent/back.png'
import remiderIconPink from 'Assets/NoteModule/remiderIconPink.svg';
import starYellow from 'Assets/NoteModule/starYellow.svg';
import starFilled from 'Assets/NoteModule/starFilled.svg';
import starYellowBorder from 'Assets/NoteModule/starYellowBorder.svg';
import startRecordingIcon from 'Assets/NoteModule/startRecording.svg';
import stopIcon from 'Assets/NoteModule/stop.svg';
import { getImage } from "Config/commonFirebaseImage";
import * as moment from 'moment';
import { formatAudioDuration, getFormattedDate } from "Utils/Helper";
import { FirebseStroageFolder, firebaseBucket } from 'Config/Constant';
import { storage } from "../../../../firebase/firebase";
import { getRandomName, getDateFormat } from "Utils/Helper";
import { createNotification } from 'Config/notificationtoast';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import UpgradePopUp from '../../../upgradePopUp/upgradePopUp'
import IframeGoogleDoc from '../IframeGoogleDoc'
import { getClassTag } from 'Actions/ClassAction/classTagAction';
import { addStudentNote, editStudentNote, getAudioFromVertex, getAudioSummaryFromVertex, resetStudentNotes } from 'Actions/NoteAction/studentNoteAction';
import { setMinutes, setHours } from "date-fns";
import 'react-vertical-timeline-component/style.min.css';
import './StudentNoteAdd.scss';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import warningIcon from 'Assets/NoteModule/warning-icon.png'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { IconButton } from '@material-ui/core';
import RadioCardLabel from './RadioCardLabel';
import TranscribeNote from './TranscribeNote';

class StudentNoteAdd extends Component {

    constructor(props) {
        super(props);
        let quality_note = JSON.parse(sessionStorage.getItem('quality_note'))
        const signDate = sessionStorage.getItem('separatorFormat')
        const formatDate = sessionStorage.getItem('dateFormat')
        const formatClock = sessionStorage.getItem('clockFormat')
        let stateDate = getFormattedDate(new Date(), formatDate, signDate);
        const classDataList = this.props?.history?.location?.state?.classList;

        const newClassDataList = classDataList?.map((obj, i) => (
            {
                ...obj,
                name: classDataList[i],
                value: classDataList[i]
            }

        ));


        this.state = {

            // onLoad parameters.....
            class_id: '',
            pageType: '',
            StudentAllNoteDetails: [],
            modalDocSelect: {},
            noteData: [],
            studentInfo: [],
            academicYear: '',
            studentId: '',
            AllNotesData: [],
            note_tag_comments: [],
            student_enrolled_classes: [],
            linked_contact: [],
            noteLevel: '',
            noteScore: '',
            isEnabledArtifact: 'no',
            work_assignment: '',
            pictureVideoList: [],
            deletedMultimedia: [],
            docList: [],
            audioList: [],
            addDate: stateDate,
            addTime: "",
            fileURL: "",
            isLinkedStatus: false,
            isLinkedStatusForAll: false,
            isLinkedText: 'Share with linked contact',
            quality_points: 0,
            mediaUploadPercentage: 0,
            quality_note: quality_note,
            text_quality_note: 0,
            isSaved: true,
            isAudioRecord: false,
            timeLineShow: false,
            timeLineHeight: '200px',

            // upload Document....
            uploadAudioStatus: false,
            uploadMediaStatus: false,


            //Modal Para...
            confirmationForBack: false,
            showDocPopUp: false,
            showImageModalState: false,
            showVideoModalState: false,

            selectedVideo: {},
            selectedImage: {},
            selectedDoc: "",

            // audio recorder...
            timer: '00:00:00',
            record: false,
            show: false,
            isEnabled: false,
            isEnabledReminder: false,
            // isEnabledArtifact: false,
            auditTitle: "",
            recorder: new MicRecorder({ bitRate: 128 }),
            isRecording: false,
            recordAudio: false,


            isClassDisabled: false,

            // confirmation para...
            confirmationType: '',
            ChangeNoteData: [],
            formatDate: formatDate,
            signDate: signDate,
            formatClock: JSON.parse(formatClock),
            showPopUp: false,
            classList: newClassDataList,
            selectedClassName2: '',
            getNoteSpiralTagData: null,

            //generateAudioAIModal
            showGenerateAudioAIModal: false,
            transcribeAudioModal: false,
            showSummarizeModal: false,
            showSelectAudioModal: false,
            addStandardValue: '',
            selectAudioObj: null,
            selectAudioTotalDuration: '0:00',
            audioTranscribe: null,
            audioSummarization: null,
            editAudioTranscribeAndSummarize: false,
            selectedText: null,
            showPopover: false,
            showTranscribePopover: false,
            popoverPosition: { top: 0, left: 0 },
            popoverTranscribePosition: { top: 0, left: 0 },
            combinedSummaryText: null,
            countdown: 5,
            disableSmartBtn:false
        };

        this.intervalRef = React.createRef();
        this.handleSelection = this.handleSelection.bind(this);
        this.handleTranscribeSelection = this.handleTranscribeSelection.bind(this)
        this.handleHighlight = this.handleHighlight.bind(this);
        this.handleTranscribeHighlight = this.handleTranscribeHighlight.bind(this)

    }

    // ---------componentDidMount -------------
    componentDidMount() {
        const h5Text = document.getElementById('div1')?.querySelector('h5')?.innerText || '';
        // document.addEventListener('selectionchange', this.handleSelection);
        // if (h5Text === 'Audio Transcription') {
        //     document.addEventListener("selectionchange", this.handleSelection);
        // }

        // if (h5Text === 'Audio Summarization') {
        //     document.addEventListener("selectionchange", this.handleTranscribeSelection);
        // } else {
        //     document.addEventListener('selectionchange', this.handleSelection);
        // }
        // document.addEventListener("selectionchange", this.handleSelection);
        // document.addEventListener('selectionchange', this.handleTranscribeSelection)
    }
    // ---------UNSAFE_componentWillMount-------
    UNSAFE_componentWillMount() {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { pageType,
            data,
            studentInfo,
            academicYear,
            NotesData,
            StudentAllNoteDetails,
            class_id,
            selectedClassName2 = ''
        } = state;
        if (pageType === 'New') {
            // ------set New data for show in note----------

            this.setState({
                isClassDisabled: false,
                class_id: class_id,
                AllNotesData: NotesData,
                pageType: pageType,
                noteData: [],
                studentInfo: studentInfo,
                studentId: studentInfo?.cc_student_id,
                academicYear: academicYear,
                note_tag_comments: [],
                linked_contact: StudentAllNoteDetails.data.student_details.linked_contact,
                student_enrolled_classes: StudentAllNoteDetails.data.student_enrolled_classes,
                noteLevel: '',
                noteScore: '',
                isEnabledArtifact: 'no',
                work_assignment: '',
                isLinkedStatus: StudentAllNoteDetails.data.student_details.is_toggle_active,
                isLinkedStatusForAll: StudentAllNoteDetails.data.student_details.is_toggle_active,
                isLinkedText: StudentAllNoteDetails.data.student_details.toggle_text,
                pictureVideoList: [],
                docList: [],
                audioList: [],
                // audioList: [
                //     {
                //         "cc_attachment_id": 13926,
                //         "cc_attachment_url": "rgnl/holmesstguser/notes/notes-4045/33b31054-16cc-4cdf-aabb-d275ca309408.mp3",
                //         "cc_attachment_note_id": 16316,
                //         "cc_attachment_size": 0.46,
                //         "cc_attachment_type": "audio",
                //         "cc_media_mark_artified": "no",
                //         "cc_attachment_name": "",
                //         "cc_lp_id": null,
                //         "cc_attachment_operation": "Unchanged",
                //         "cc_attachment_fullurl": "https://firebasestorage.googleapis.com/v0/b/us-central-01-bucket-stg/o/rgnl%2Fholmesstguser%2Fnotes%2Fnotes-4045%2F33b31054-16cc-4cdf-aabb-d275ca309408.mp3?alt=media&token=2717e91a-50c4-4f27-b087-16e7a6c816df"
                //     }
                // ],
                quality_points: 0,
                selectedClassName2: selectedClassName2

            }, () => {
                this.getTagData();
                this.getLevelandScoreByClass();
            })

            // ------set New data for show in note----------

        } else {
            // ------set Edit data for show in note----------
            let attachments = data?.note_attachment;
            let DummyAudioNote = [];
            let DummyVideoNote = [];
            let DummyDocNote = [];
            for (let item of attachments) {
                if (item.cc_attachment_type === "audio") {
                    DummyAudioNote.push(item)
                } else if (item.cc_attachment_type === "other") {
                    DummyDocNote.push(item)
                } else {
                    DummyVideoNote.push(item)
                }

            }
            let assignment = '';
            if (data?.cc_work_assignment === null || data?.cc_work_assignment === "null") {
                assignment = '';
            } else {
                assignment = data?.cc_work_assignment
            }
            let dateData = getFormattedDate(data?.cc_note_reminder_date, this.state.formatDate, this.state.signDate);
            this.setState({
                isClassDisabled: true,
                class_id: class_id,
                AllNotesData: NotesData,
                pageType: pageType,
                noteData: data,
                addTime: data?.cc_note_reminder,
                addDate: dateData,
                studentInfo: studentInfo,
                studentId: studentInfo.cc_student_id,
                academicYear: academicYear,
                linked_contact: StudentAllNoteDetails.data?.student_details.linked_contact,
                note_tag_comments: data?.note_tag_comments,
                student_enrolled_classes: StudentAllNoteDetails.student_enrolled_classes,
                noteLevel: data?.cc_note_level,
                noteScore: data?.cc_note_score,
                isEnabledArtifact: data?.cc_note_mark_artified,
                work_assignment: assignment,
                isLinkedStatus: data?.cc_linked_flag === 1 ? true : false,
                isLinkedStatusForAll: StudentAllNoteDetails.data?.student_details.is_toggle_active,
                isLinkedText: StudentAllNoteDetails.data?.student_details.toggle_text,
                pictureVideoList: DummyVideoNote,
                docList: DummyDocNote,
                audioList: DummyAudioNote,
                quality_points: data?.cc_note_quality_points > 10 ? 10 : data?.cc_note_quality_points,
                text_quality_note: 0,
                selectedClassName2: selectedClassName2

            }, () => {
                this.getTextPowerPoint(data?.note_tag_comments)

            })
            // ------set Edit data for show in note----------

        }

        this.getUserProfile(studentInfo)


    }

    // ---------- Timer ---------
    startTime = () => {
        this.intervalRef.current = setInterval(() => {
            if (this.state.countdown > 0) {
                this.setState((prevState) => ({
                    countdown: prevState.countdown - 1
                }));
            } else {
                this.stopTimer();
            }
        }, 1000);
    }

    componentWillUnmount() {
        this.stopTimer();
        document.removeEventListener("selectionchange", this.handleSelection);
        document.removeEventListener('selectionchange', this.handleTranscribeSelection)
    }

    stopTimer = () => {
        clearInterval(this.intervalRef?.current);
        // this.setState({
        //     showGenerateAudioAIModal: false,
        //     transcribeAudioModal: true,
        //     // showSummarizeModal: true,
        //     // showSelectAudioModal: true
        // })
    };
    // ---------UNSAFE_componentWillMount-------

    // ---------componentWillReceiveProps-------
    async UNSAFE_componentWillReceiveProps(nextProps) {
        // ---------get Class Tag WillReceiveProps-------
        if (nextProps.classTag.action === 'GET_CLASS_TAG_SUCCESS') {
            if (nextProps.classTag.Tag_Data) {
                if (this?.props?.history?.location?.state?.pageType == 'Edit') {
                    this.setState({
                        note_tag_comments: this?.props?.history?.location?.state?.data?.note_tag_comments
                    })
                } else {
                    if(JSON.parse(sessionStorage.getItem('noteCommentData'))?.length > 0) {
                        this.setState({
                            note_tag_comments: JSON.parse(sessionStorage.getItem('noteCommentData'))
                        })
                    } else {
                    if (nextProps.classTag.Tag_Data.length > 0) {
                        let tagData = nextProps.classTag.Tag_Data
                        for (let item of tagData) {
                            item.tag_comment_text = ''
                        }
                        this.setState({
                            note_tag_comments: tagData
                        })

                    } else {
                        this.setState({
                            note_tag_comments: []
                        })
                    }
                }
                }
            }

        }
        // ---------get Class Tag WillReceiveProps-------


        // ---------Edit Note  WillReceiveProps-------
        if (nextProps.notesData.action === 'EDIT_STUDENT_NOTE_SUCCESS') {
            if (nextProps.notesData.Edit_Student_Note) {
                if (nextProps.notesData.Edit_Student_Note.status === "Success") {
                    // if (!this.state.isSaved) {
                    this.setState({
                        isSaved: true
                    }, () => {
                        this.goBack()
                    })
                    // }
                }

            }

        }
        // ---------Edit Note  WillReceiveProps-------

        // ---------Add Note  WillReceiveProps-------
        if (nextProps.notesData.action === 'ADD_STUDENT_NOTE_SUCCESS') {
            if (nextProps.notesData.Add_Student_Note) {
                if (nextProps.notesData.Add_Student_Note.status === "Success") {
                    // if (!this.state.isSaved) {
                    this.setState({
                        isSaved: true
                    }, () => {
                        this.goBack()
                    })
                    // }

                }

            }

        }
        // ---------Add Note  WillReceiveProps-------

        if (nextProps.notesData.action === 'ADD_STUDENT_NOTE_FAILURE' || 'EDIT_STUDENT_NOTE_FAILURE') {
            if (nextProps.notesData.error && nextProps.notesData.error.data &&
                (nextProps.notesData.error.data.insufficient_space == 1)) {
                this.setState({
                    showPopUp: true
                })
            }
        }



        if (nextProps?.getNoteSpiralTag?.status == 200) {
            this.setState({
                getNoteSpiralTagData: nextProps?.getNoteSpiralTag?.data
            })
        }

        //--------vertext props-------------
     

        if (nextProps?.notesData?.getTranscribeAudioError?.status == 501 || nextProps?.notesData?.getAudioSummaryVertextError?.status == 501) {
            this.setState({
                showGenerateAudioAIModal: false
            })
        }
        if (nextProps?.notesData?.getTranscribeAudioSuccess?.status == 200 && nextProps?.notesData?.getTranscribeAudioSuccess?.data?.note_description?.length > 0) {
            
            let dataArray = [...this.state.note_tag_comments]
        // Find the index of the object with cc_tag_title equal to text
        const positiveCommentsIndex = dataArray.findIndex(
            (obj) => obj.cc_tag_title === 'Audio Transcription'
        );

        if(positiveCommentsIndex !== -1 && this.state.audioTranscribe?.length > 0) {
            console.log('if')
            dataArray[positiveCommentsIndex].tag_comment_text = sessionStorage.getItem('finalTranscribedAudio') ? sessionStorage.getItem('finalTranscribedAudio') : this.state.audioTranscribe
        } else {
            console.log('else')
        // Check if the object exists and tag_comment_text is empt y
        if (positiveCommentsIndex !== -1 && dataArray[positiveCommentsIndex].tag_comment_text === '') {
            // Update tag_comment_text
            dataArray[positiveCommentsIndex].tag_comment_text = nextProps?.notesData?.getTranscribeAudioSuccess?.data?.note_description;
        }
    }
      
        

         // ------Add Power points------
         let oldText_quality_note = this.state.text_quality_note;
         let newText_quality_note = 0
         for (let item of dataArray) {
             if (item.tag_comment_text) {
                 if (newText_quality_note < this.state.quality_note.max_allotted_text) {
                     newText_quality_note = Number(newText_quality_note) + Number(this.state.quality_note.text_allotted_points)
                 }
             }
 
         }
         let quality_points = this.state.quality_points
         if (oldText_quality_note > newText_quality_note) {
             let value = Number(oldText_quality_note) - Number(newText_quality_note);
             quality_points = Number(quality_points) - Number(value);
 
         } else {
             let value = Number(newText_quality_note) - Number(oldText_quality_note)
             quality_points = Number(quality_points) + Number(value);
 
         }
         // ------Add Power points------
         
            this.setState({
                showGenerateAudioAIModal: false,
                transcribeAudioModal: true,
                audioTranscribe: nextProps?.notesData?.getTranscribeAudioSuccess?.data?.note_description,
                note_tag_comments: dataArray,
                text_quality_note: newText_quality_note,
                quality_points: quality_points,
            })
        }

        if (nextProps?.notesData?.getAudioSummaryVertextSuccess?.status == 200) {
            
             // Combine headings and passages into a single string
              const combinedText = nextProps?.notesData?.getAudioSummaryVertextSuccess?.data?.text_summerized?.map(item => `${item.heading}\n\n${item.passage}`).join('\n\n');

              let dataArray = [...this.state.note_tag_comments]
        // Find the index of the object with cc_tag_title equal to text
        const positiveCommentsIndex = dataArray.findIndex(
            (obj) => obj.cc_tag_title === 'Audio Summarization'
        );

        // const transcribeCommentsIndex = dataArray.findIndex(
        //     (obj) => obj.cc_tag_title === 'Audio Transcription'
        // );

        // Check if the object exists and tag_comment_text is empty
        if (positiveCommentsIndex !== -1 && dataArray[positiveCommentsIndex].tag_comment_text === '') {
            // Update tag_comment_text
            dataArray[positiveCommentsIndex].tag_comment_text = combinedText;
        }

         // Check if the object exists and tag_comment_text is empty
        //  if (transcribeCommentsIndex !== -1 && this.state.audioTranscribe.length > 0) {
        //     // Update tag_comment_text
        //     dataArray[positiveCommentsIndex].tag_comment_text = this.state.audioTranscribe;
        // }
      
        

         // ------Add Power points------
         let oldText_quality_note = this.state.text_quality_note;
         let newText_quality_note = 0
         for (let item of dataArray) {
             if (item.tag_comment_text) {
                 if (newText_quality_note < this.state.quality_note.max_allotted_text) {
                     newText_quality_note = Number(newText_quality_note) + Number(this.state.quality_note.text_allotted_points)
                 }
             }
 
         }
         let quality_points = this.state.quality_points
         if (oldText_quality_note > newText_quality_note) {
             let value = Number(oldText_quality_note) - Number(newText_quality_note);
             quality_points = Number(quality_points) - Number(value);
 
         } else {
             let value = Number(newText_quality_note) - Number(oldText_quality_note)
             quality_points = Number(quality_points) + Number(value);
 
         }
         // ------Add Power points------
         
            this.setState({
                showGenerateAudioAIModal: false,
                transcribeAudioModal: false,
                showSummarizeModal: true,
                audioSummarization: combinedText,
                combinedSummaryText: combinedText,
                note_tag_comments: dataArray,
                text_quality_note: newText_quality_note,
                quality_points: quality_points,
            })
        }

        if(nextProps?.notesData?.isLoadingGetsummaryAudio || nextProps?.notesData?.isLoadingGetTranscribeAudio) {
            this.setState({
                showGenerateAudioAIModal: true,
                transcribeAudioModal: false,
                showSummarizeModal: false,
            })
        }
        
        

    }
    // ---------componentWillReceiveProps-------

    // ----------get Text Points at edit time-----
    getTextPowerPoint = (data) => {
        let newText_quality_note = 0
        for (let item of data) {
            if (item.tag_comment_text) {
                if (newText_quality_note < this.state.quality_note.max_allotted_text) {
                    newText_quality_note = Number(newText_quality_note) + Number(this.state.quality_note.text_allotted_points)
                }
            }
        }
        this.setState({
            text_quality_note: newText_quality_note,
        })
    }
    // ----------get Text Points at edit time-----


    // ---------set Comment Tag Data-------
    setCommentData = (data) => {
        // ------Add Power points------
        let oldText_quality_note = this.state.text_quality_note;
        let newText_quality_note = 0
        for (let item of data) {
            if (item.tag_comment_text) {
                if (newText_quality_note < this.state.quality_note.max_allotted_text) {
                    newText_quality_note = Number(newText_quality_note) + Number(this.state.quality_note.text_allotted_points)
                }
            }

        }
        let quality_points = this.state.quality_points
        if (oldText_quality_note > newText_quality_note) {
            let value = Number(oldText_quality_note) - Number(newText_quality_note);
            quality_points = Number(quality_points) - Number(value);

        } else {
            let value = Number(newText_quality_note) - Number(oldText_quality_note)
            quality_points = Number(quality_points) + Number(value);

        }
        // ------Add Power points------

        this.setState({
            text_quality_note: newText_quality_note,
            quality_points: quality_points,
            note_tag_comments: data,
            isSaved: false
        })
    }
    // ---------set Comment Tag Data-------

    // ------set lavel/score value according to class----
    getLevelandScoreByClass = () => {
        const classes = this.state.student_enrolled_classes;
        for (let item of classes) {
            if (item.cc_class_id === parseInt(this.state.class_id)) {
                if (item.cc_class_student_level === null || item.cc_class_student_score === null) {
                    this.setState({
                        noteLevel: '',
                        noteScore: '',
                    })
                } else {
                    this.setState({
                        noteLevel: item.cc_class_student_level,
                        noteScore: item.cc_class_student_score,
                    })
                }

            }
        }
    }
    // ------set lavel/score value according to class----


    // ---------Get Class Tag Data-------
    getTagData() {
        const data = {
            "class_id": this.state.class_id
        }
        this.props.getClassTag(data)
    }
    // ---------Get Class Tag Data-------


    // ---------Get Image From Firebase-------
    async getUserProfile(value) {
        let data = ''
        if (value?.cc_student_pic_url.includes("https") === true) {
            this.setState({
                Userprofile: value?.cc_student_pic_url,
                userProfileName: value?.cc_student_pic_url,

            })
        } else {
            if (value?.cc_student_pic_url === '') {
                this.setState({
                    Userprofile: '',
                    userProfileName: '',
                })
            }
            else {
                data = await getImage(value?.cc_student_pic_url);
                this.setState({
                    Userprofile: data,
                    userProfileName: value?.cc_student_pic_url,

                })
            }
        }



    }
    // ---------Get Image From Firebase--------


    // ---------Select Class On WorkAssignment--------
    onChangeWorkAssignment = event => {
        this.setState(
            {
                work_assignment: event.target.value,
                isSaved: false
            }, () => {

            });
    }
    // ---------Select Class On WorkAssignment--------


    // ---------Select Class On Change--------
    selectOnChange(event) {
        this.setState({
            class_id: event.target.value,
        }, () => {
            if (this.state.pageType === 'New') {
                this.setState({
                    pictureVideoList: [],
                    docList: [],
                    audioList: [],
                    quality_points: 0,
                    text_quality_note: 0,
                    isSaved: true
                })
                this.getTagData();
                this.getLevelandScoreByClass();
            }
        })
    }
    // ---------Select Class On Change--------


    // ---------Input Handle change For Level/Score-------
    onChangeText = event => {
        if (event.target.name === "ModalLevel") {
            if (event.target.value.length < 3) {
                this.setState(
                    {
                        [event.target.name]: event.target.value,
                        isSaved: false
                    }
                );
            }
        } else {
            // this.setState(
            //     {
            //         [event.target.name]: event.target.value,
            //         isSaved: false
            //     }
            // );
            if (event.target.name === "noteLevel") {
                const scoreValue = /^[a-zA-Z0-9]+$/;
                if (event.target.value.length < 3 && (!event.target.value || event.target.value.match(scoreValue))) {
                    this.setState(
                        {
                            [event.target.name]: event.target.value,
                            isSaved: false
                        }
                    );
                }
            } else if (event.target.name === "noteScore") {
                const scoreValue = /^[0-9]+$/;
                if (event.target.value.length < 3 && (!event.target.value || event.target.value.match(scoreValue))) {
                    this.setState(
                        {
                            [event.target.name]: event.target.value,
                            isSaved: false
                        }
                    );
                }
            } else {
                this.setState(
                    {
                        [event.target.name]: event.target.value,
                        isSaved: false
                    }
                );
            }
        }
    };
    // ---------Input Handle change For Level/Score-------


    // ---------Calender Date Picker-------
    setDate = (date) => {
        let value = getFormattedDate(date, this.state.formatDate, this.state.signDate);

        this.setState({
            addDate: value,
            isSaved: false
        })
    }
    // ---------Calender Date Picker-------

    // ---------Calender Date Picker-------
    setTime = (date, format) => {

        //         let time = moment(date, "ddd MMM DD YYYY HH:mm:ss ZZ");
        //         console.log(sessionStorage.getItem('clockFormat'))
        let clockFormatHere = sessionStorage.getItem('clockFormat')
        //         console.log('clockFormatHere',typeof clockFormatHere, clockFormatHere, JSON.stringify(clockFormatHere), clockFormatHere == "24 Hours", clockFormatHere === "24 Hours", JSON.stringify(clockFormatHere) == "\"24 Hours\"")
        //         console.log(sessionStorage.getItem('clockFormat') == "24 Hours" ? time.format("HH:mm A") : time.format("hh:mm A"))
        //         console.log(sessionStorage.getItem('clockFormat') == "24 Hours" ? time.format("HH:mm") : time.format("hh:mm A"))
        // let formattedTime = sessionStorage.getItem('clockFormat') == "24 Hours" ? time.format("HH:mm A") : time.format("hh:mm A");
        // console.log('formattedTime',formattedTime);
        // console.log(moment(date).format('HH:mm A'), JSON.parse(clockFormatHere))
        let customDate = JSON.parse(clockFormatHere) == "24 Hours" ? moment(date).format('HH:mm') : moment(date).format('hh:mm A')

        this.setState({
            // addTime: moment(date).format('LT'),
            addTime: customDate,
            isSaved: false
        })
    }
    // ---------Calender Date Picker-------



    // -------------------- Audio Modal ------------------

    // ---------Audio Modal open-------
    handleShowAudioModal = () => {
        this.setState({
            show: true,
            record: false,
            isEnabled: false,
            isSaved: false
        })
    }
    // ----------Audio Modal open-------

    // ----------Audio Recording Start-------
    startRecorder() {
        this.state.recorder
            .start()
            .then(() => {
                this.setState({ record: true });
            })
            .catch(e => {
                console.error(e);
            });
    }
    // ----------Audio Recording Start-------

    // ----------Save Audio Recording-------
    stopRecording = () => {
        this.setState({
            isAudioRecord: false,
            show: false,
            record: false,
            isEnabled: false,
            isSaved: false
        });
        this.state.recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                this.setState({ record: false });
                const file = new File(buffer, 'Recorded_audio.mp3', {
                    type: blob.type,
                    lastModified: Date.now(),
                });
                
                const data = JSON.parse(sessionStorage.getItem('UserData'))
                this.setState({
                    uploadAudioStatus: true,
                })
                const media_Size = (blob.size / 1000000);

                // ---------DummyName-------

                const GetRendomName = getRandomName();
                const DummyName = GetRendomName + '.mp3';
                // ---------DummyName-------

                // ---------FolderName-------
                const FindFolderNameArray = data.cc_user_email_id.split("@")
                const FolderName = FindFolderNameArray[0]
                // ---------FolderName-------

                const uploadTask = storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/${DummyName}`).put(file)
                uploadTask.on('state_changed',
                    (snapShot) => {
                        const progress = Math.round(
                            (snapShot.bytesTransferred / snapShot.totalBytes) * 100
                        );
                        this.setState({
                            mediaUploadPercentage: progress
                        })
                    }, (err) => {
                        this.setState({
                            uploadAudioStatus: false
                        })
                        createNotification('error', 'Audio Not Uploaded. Please give it another shot!');
                    }, () => {
                        storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/`).child(DummyName).getDownloadURL()

                            .then(fireBaseUrl => {
                                let value = {
                                    cc_attachment_fullurl: fireBaseUrl,
                                    cc_attachment_url: `${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/${DummyName}`,
                                    cc_attachment_name: this.state.auditTitle,
                                    cc_attachment_type: 'audio',
                                    cc_attachment_size: media_Size,
                                    cc_attachment_operation: "add"
                                }
                                let DummyList = this.state.audioList;
                                DummyList.push(value)
                                let quality_points = this.state.quality_points;
                                const isAudioInArrayLength = this.state.audioList.filter((item) => {
                                    return item.cc_attachment_type === 'audio'
                                })
                                if (isAudioInArrayLength.length > 1) {
                                    quality_points = this.state.quality_points
                                } else {
                                    quality_points = Number(quality_points) + Number(this.state.quality_note.audio_allotted_points)
                                }
                                this.setState({
                                    uploadAudioStatus: false,
                                    audioList: DummyList,
                                    disableSmartBtn: false,
                                    quality_points: quality_points,
                                    auditTitle: "",
                                    isSaved: false

                                })
                                // createNotification('success', 'Great Job! Audio Upload Successful!');
                            })
                    })
            });

    }
    // ----------Save Audio Recording-------

    // ----------Set Audio Title-------
    setAudioTitle = (e) => {
        if (e.target.value !== undefined) {
            this.setState({
                auditTitle: e.target.value,
                isSaved: false
            })
        }
    }
    // ----------Set Audio Title-------

    // ----------Delete Audio -------
    onAudioDelete = (index) => {
        let tempAudioList = this.state.audioList
        tempAudioList.splice(index, 1)
        this.setState({ audioList: tempAudioList });
    }
    // ----------Delete Audio-------

    // ----------Edit Audio Title-------
    enableAudioNameEditing = () => {
        this.setState({ isEnabled: true });
    }
    // ----------Edit Audio Title-------


    // ---------Audio Modal Close-------
    handleSaveState = () => {
        this.setState({
            isAudioRecord: true,
            show: false,
            record: false,
            isEnabled: false,
            isSaved: false
        })
    }

    handleClose = () => {
        this.state.recorder.stop()
        this.setState({
            isAudioRecord: false,
            show: false,
            record: false,
            isEnabled: false,
            isSaved: false
        })

    }

    handlePause = () => {
        this.state.recorder.stop()
        this.setState({
            record: false,
            isEnabled: false,
        })

    }
    // ---------Audio Modal Close-------

    // -------------------- Audio Modal ------------------

    // ----------------------- Video upload ----------------------

    // ---------Get Image and Video-------
    getMediaHandle = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        e.target.value = '';
        if (file !== undefined) {
            if (file.type.indexOf("image") !== -1 || file.type.indexOf("video") !== -1) {
                reader.onloadend = () => {
                    this.uploadMediaFile(file)
                }
                reader.readAsDataURL(file);
            }
            else if ((file.type.indexOf("msword") !== -1 || file.type.indexOf("vnd.ms-excel") !== -1 || file.type.indexOf("vnd.ms-powerpoint") !== -1 || file.type.indexOf("mspowerpoint") !== -1 || file.type.indexOf("powerpoint") !== -1 || file.type.indexOf("x-mspowerpoint") !== -1 || file.type.indexOf("pdf") !== -1 || file.type.indexOf("csv") !== -1 || file.type.indexOf("doc") !== -1 || file.type.indexOf("docx") !== -1)) {
                reader.onloadend = () => {
                    this.uploadDocFile(file)
                }
                reader.readAsDataURL(file);
            } else {
                alert("Please select Image, Video, Pdf, Doc, Docx, Xls, Xlsx, Ppt, pptx, Csv file only.")
            }
        }
    }
    // ---------Get Image and Video-------

    // ---------doc Upload on Firebase-------
    uploadDocFile = (e) => {
        const data = JSON.parse(sessionStorage.getItem('UserData'))
        const docAsFile = e;
        this.setState({
            uploadMediaStatus: true,
        })

        const media_Size = (docAsFile.size / 1000000);
        // ---------Media Type-------
        const mediaTypeArray = docAsFile.type.split("/")
        const mediaType = mediaTypeArray[1]
        // ---------Media Type-------

        // ---------Image Type-------
        const ImageTypeArray = docAsFile.name.split(".")
        const ImageType = ImageTypeArray[ImageTypeArray.length - 1]

        // ---------Image Type-------

        // ---------DummyName-------
        const GetRendomName = getRandomName()
        const DummyName = GetRendomName + '.' + ImageType;
        // ---------DummyName-------

        // ---------FolderName-------
        const FinfFolderNameArray = data.cc_user_email_id.split("@")
        const FolderName = FinfFolderNameArray[0]
        // ---------FolderName-------


        const uploadTask = storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/${DummyName}`).put(docAsFile)
        uploadTask.on('state_changed',
            (snapShot) => {
                const progress = Math.round(
                    (snapShot.bytesTransferred / snapShot.totalBytes) * 100
                );
                this.setState({
                    mediaUploadPercentage: progress
                })
            }, (err) => {
                this.setState({
                    uploadMediaStatus: false
                })

                createNotification('error', 'Multimedia not uploaded. Please give it another shot!');
            }, () => {
                storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/`).child(DummyName).getDownloadURL()
                    .then(fireBaseUrl => {
                        let value = {
                            cc_attachment_fullurl: fireBaseUrl,
                            cc_attachment_url: `${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/${DummyName}`,
                            cc_attachment_type: "other",
                            cc_attachment_size: media_Size,
                            cc_attachment_operation: "add"
                        }
                        let DummyList = this.state.docList;
                        DummyList.push(value)
                        let quality_points = this.state.quality_points;
                        
                        if (this.state.docList.length > 1) {
                            quality_points = this.state.quality_points
                        } else {
                            if (mediaType === "image") {
                                quality_points = Number(quality_points) + Number(this.state.quality_note.image_allotted_points)
                            } else if (mediaType === "video") {
                                quality_points = Number(quality_points) + Number(this.state.quality_note.video_allotted_points)
                            } else {
                                quality_points = Number(quality_points) + Number(this.state.quality_note.text_allotted_points)
                            }
                        }
                        this.setState({
                            uploadMediaStatus: false,
                            docList: DummyList,
                            quality_points: quality_points,
                            isSaved: false

                        })
                        // createNotification('success', 'Multimedia uploaded successfully');

                    })
            })
    }
    // ---------Image Upload on Firebase-------


    // ---------Image Upload on Firebase-------
    uploadMediaFile = (e) => {
        const data = JSON.parse(sessionStorage.getItem('UserData'))
        const imageAsFile = e;
        this.setState({
            uploadMediaStatus: true,
        })

        const media_Size = (imageAsFile.size / 1000000);
        // ---------Media Type-------
        const mediaTypeArray = imageAsFile.type.split("/")
        const mediaType = mediaTypeArray[0]
        // ---------Media Type-------

        // ---------Image Type-------
        const ImageTypeArray = imageAsFile.name.split(".")
        const ImageType = ImageTypeArray[ImageTypeArray.length - 1]

        // ---------Image Type-------

        // ---------DummyName-------
        const GetRendomName = getRandomName()
        const DummyName = GetRendomName + '.' + ImageType;
        // ---------DummyName-------

        // ---------FolderName-------
        const FinfFolderNameArray = data.cc_user_email_id.split("@")
        const FolderName = FinfFolderNameArray[0]
        // ---------FolderName-------


        const uploadTask = storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/${DummyName}`).put(imageAsFile)
        uploadTask.on('state_changed',
            (snapShot) => {
                const progress = Math.round(
                    (snapShot.bytesTransferred / snapShot.totalBytes) * 100
                );
                this.setState({
                    mediaUploadPercentage: progress
                })
            }, (err) => {
                this.setState({
                    uploadMediaStatus: false
                })

                createNotification('error', 'Multimedia not uploaded. Please give it another shot!');
            }, () => {
                storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/`).child(DummyName).getDownloadURL()
                    .then(fireBaseUrl => {
                        let value = {
                            cc_attachment_fullurl: fireBaseUrl,
                            cc_attachment_url: `${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/${DummyName}`,
                            cc_attachment_type: mediaType,
                            cc_attachment_size: media_Size,
                            cc_attachment_operation: "add"
                        }
                        let DummyList = this.state.pictureVideoList;
                        DummyList.push(value)
                        let quality_points = this.state.quality_points;

                        if (mediaType === "image") {
                            let imageArray = []
                            const isImageInArray = this.state.pictureVideoList.some((item) => {
                                return item.cc_attachment_type === 'image'
                            })
                            
                            const isImageInArrayLength = this.state.pictureVideoList.filter((item) => {
                                return item.cc_attachment_type === 'image'
                            })
                            // imageArray.push(isImageInArrayLength)
                            // console.log(943, imageArray, imageArray?.length)
                            
                            if (isImageInArrayLength.length > 1) {
                                quality_points = this.state.quality_points
                            } else {
                                quality_points = Number(quality_points) + Number(this.state.quality_note.image_allotted_points)
                            }
                        } else {
                            const isVideoInArrayLength = this.state.pictureVideoList.filter((item) => {
                                return item.cc_attachment_type === 'video'
                            })
                            if (isVideoInArrayLength.length > 1) {
                                quality_points = this.state.quality_points
                            } else {
                                quality_points = Number(quality_points) + Number(this.state.quality_note.video_allotted_points)
                            }
                        }
                        this.setState({
                            uploadMediaStatus: false,
                            pictureVideoList: DummyList,
                            quality_points: quality_points,
                            isSaved: false

                        })
                        // createNotification('success', 'Multimedia uploaded successfully');

                    })
            })
    }
    // ---------Image Upload on Firebase-------

    // ----------------------- Video upload ----------------------




    // ---------Toggle For LIn Contact-------
    toggleLink = (e) => {
        this.setState({
            isLinkedStatus: !this.state.isLinkedStatus,
            isSaved: false
        })
    }
    // ---------Toggle For LIn Contact-------

    // ----------------------- Modal Fuction ----------------------

    // ---------Video Show on Modal -------
    handleVideoModalShow = (item) => {
        let tmp = item
        this.setState({ showVideoModalState: true, selectedVideo: tmp })
    }
    // ---------Video Show on Modal -------

    // ---------Video Hide on Modal -------
    handleVideoModalClose = () => {
        this.setState({ showVideoModalState: false, selectedVideo: [] })
    }
    // ---------Video Hide on Modal -------

    // ---------Doc Show on Modal -------
    handleDocModalShow = (item) => {

        this.setState({
            modalDocSelect: item,
        })
        const encodeUrlStr = encodeURIComponent(item.cc_attachment_fullurl)
        this.setState({ selectedDoc: encodeUrlStr },
            () => {
                this.setState({ showDocPopUp: true })
            })
    }
    // ---------Doc Show on Modal -------

    blobToFile = (theBlob, fileName) => {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }

    reportDocDownload = (URl, projectName) => {
        const options = {
            method: 'GET',
            baseURL: URl,
        };
        axios(options).then(async responses => {
            if (responses.data.message === "No record found") {
                createNotification('error', responses.data.message);
            } else {
                const options = {
                    method: 'GET',
                    baseURL: URl,
                    responseType: 'blob'
                };
                axios(options).then(async responses => {
                    const file = new Blob(
                        [responses.data],
                        { type: 'application/pdf' });
                    const filesURL = URL.createObjectURL(file);
                    this.setState({ fileURL: filesURL })
                })
            }
        })
            .catch(err => {
                if (err && err.message) {
                    createNotification('error', err.message);
                }
            });
    }

    // ---------Doc Hide on Modal -------
    handleDocModalClose = () => {
        this.setState({ showDocPopUp: false, selectedDoc: [] })
    }
    // ---------Doc Hide on Modal -------


    // ---------Image Show on Modal -------
    handleImageModalShow = (item) => {
        let tmp = item
        this.setState({ showImageModalState: true, selectedImage: tmp })
    }
    // ---------Image Show on Modal -------


    // ---------Image Hide on Modal -------
    handleImageModalClose = () => {
        this.setState({ showImageModalState: false, selectedImage: [] })
    }

    handleGenerateAudioAiModalClose = () => {
        this.setState({
            showGenerateAudioAIModal: false,
            transcribeAudioModal: false,
            showSummarizeModal: false,
            showSelectAudioModal: false,
            editAudioTranscribeAndSummarize: false,
            showPopover: false,
            showTranscribePopover: false,
            countdown: 0
        })
    }
    // ---------Image Hide on Modal -------

    // ----------------------- Modal Fuction ----------------------

    onDeleteUpdateMedia = (i, type) => {

        if (this.state.pageType === 'Edit') {
            if (type === 'audio') {
                console.log('audio')
                let DummyArrays = this.state.audioList
                let crr = DummyArrays.map((val, j) => {
                    if (j === i) {
                        if (val.cc_attachment_operation === "Unchanged") {
                            return (
                                { ...val, "cc_attachment_operation": "unchangedRemoved" }
                            )
                        } else {
                            return (
                                { ...val, "cc_attachment_operation": "remove" }
                            )
                        }
                    } else {
                        return { ...val }
                    }
                })
                this.setState({ audioList: crr },
                    () => {
                        this.onDeleteMedia(i, type)
                    })
                    let disableSmartAiBtn = crr?.every((item) => item?.cc_attachment_operation == "unchangedRemoved" || item?.cc_attachment_operation == "remove")
                    this.setState({
                        disableSmartBtn: disableSmartAiBtn
                    })
            } else if (type === 'other') {
                console.log('other')
                let DummyArrays = this.state.docList
                let crr = DummyArrays.map((val, j) => {

                    if (j === i) {
                        if (val.cc_attachment_operation === "Unchanged") {
                            return (
                                { ...val, "cc_attachment_operation": "unchangedRemoved" }
                            )
                        } else {
                            return (
                                { ...val, "cc_attachment_operation": "remove" }
                            )
                        }
                    } else {
                        return { ...val }
                    }
                })

                this.setState({ docList: crr },
                    () => {
                        this.onDeleteMedia(i, type)
                    })
            } else {
                console.log('else')
                let DummyArrays = this.state.pictureVideoList
                let crr = DummyArrays.map((val, j) => {

                    if (j === i) {
                        if (val.cc_attachment_operation === "Unchanged") {
                            return (
                                { ...val, "cc_attachment_operation": "unchangedRemoved" }
                            )
                        } else {
                            return (
                                { ...val, "cc_attachment_operation": "remove" }
                            )
                        }
                    } else {
                        return { ...val }
                    }

                })

                this.setState({ pictureVideoList: crr },
                    () => {
                        this.onDeleteMedia(i, type)
                    })
            }
        } else {
            this.onDeleteMedia(i, type)
        }
    }

    // --------------------Delete  Audio/Video/Image from Firebase  ------------------
    onDeleteMedia = async (i, type) => {
        let DummyArray = []
        if (type === 'audio') {
            DummyArray = this.state.audioList
        } else if (type === 'other') {
            DummyArray = this.state.docList
        } else {
            DummyArray = this.state.pictureVideoList
        }

        const data = JSON.parse(sessionStorage.getItem('UserData'))

        // ---------FolderName-------
        const FinfFolderNameArray = data.cc_user_email_id.split("@")
        // ---------FolderName-------

        const fileName = DummyArray[i].cc_attachment_url;
        const mediaType = DummyArray[i].cc_attachment_type;
        if (this.state.pageType === 'Edit') {
            this.state.deletedMultimedia.push(DummyArray[i]);
            try {
                let quality_points = this.state.quality_points;

                if (mediaType === 'audio') {
                    
                    const audioDummyArray = DummyArray.filter((item) => {
                        return item.cc_attachment_type === 'audio'
                    })
                    const deleteAudioArray = this.state.deletedMultimedia.filter((item) => {
                        return item.cc_attachment_type === 'audio'
                    })
                    if (audioDummyArray.length == deleteAudioArray.length) {
                        if (quality_points > this.state.quality_note.audio_allotted_points) {
                            quality_points = Number(quality_points) - Number(this.state.quality_note.audio_allotted_points)
                        } else {
                            quality_points = 0;
                        }
                    } else {
                        quality_points = this.state.quality_points
                    }
                    this.setState({
                        audioList: DummyArray,
                        quality_points: quality_points,
                        isSaved: false
                    });

                } else {
                    if (mediaType === "image") {
                        const imageDummyArray = DummyArray.filter((item) => {
                            return item.cc_attachment_type === 'image'
                        })
                        const deleteImageArray = this.state.deletedMultimedia.filter((item) => {
                            return item.cc_attachment_type === 'image'
                        })
                        if (imageDummyArray.length == deleteImageArray.length) {
                            if (quality_points > this.state.quality_note.image_allotted_points) {
                                quality_points = Number(quality_points) - Number(this.state.quality_note.image_allotted_points)
                            } else {
                                quality_points = 0;
                            }
                        } else {
                            quality_points = this.state.quality_points
                        }
                    } else if (mediaType === "other") {
                        const docDummyArray = DummyArray.filter((item) => {
                            return item.cc_attachment_type === "other"
                        })
                        const deleteDocArray = this.state.deletedMultimedia.filter((item) => {
                            return item.cc_attachment_type === "other"
                        })
                        if (docDummyArray.length == deleteDocArray.length) {
                            if (quality_points > this.state.quality_note.text_allotted_points) {
                                quality_points = Number(quality_points) - Number(this.state.quality_note.text_allotted_points)
                            } else {
                                quality_points = 0;
                            }
                        } else {
                            quality_points = this.state.quality_points
                        }
                    } else {
                        const videoDummyArray = DummyArray.filter((item) => {
                            return item.cc_attachment_type === "video"
                        })
                        const deleteVideoArray = this.state.deletedMultimedia.filter((item) => {
                            return item.cc_attachment_type === "video"
                        })
                        if (videoDummyArray.length == deleteVideoArray.length) {
                            if (quality_points > this.state.quality_note.video_allotted_points) {
                                quality_points = Number(quality_points) - Number(this.state.quality_note.video_allotted_points)
                            } else {
                                quality_points = 0;
                            }
                        } else {
                            quality_points = this.state.quality_points
                        }
                    }

                    if (mediaType === "other") {
                        this.setState({
                            docList: DummyArray,
                            quality_points: quality_points,
                            isSaved: false
                        });
                    } else {
                        
                        this.setState({
                            pictureVideoList: DummyArray,
                            quality_points: quality_points,
                            isSaved: false
                        });
                    }
                }
            } catch (e) {
                createNotification('error', 'The Multimedia was not deleted. Please try again.');
            }
        } else {
            DummyArray.splice(i, 1)
            try {
                let quality_points = this.state.quality_points;
                if (type === 'audio') {
                    if (quality_points > this.state.quality_note.audio_allotted_points) {
                        quality_points = Number(quality_points) - Number(this.state.quality_note.audio_allotted_points)
                    } else {
                        quality_points = 0;
                    }
                    this.setState({
                        audioList: DummyArray,
                        quality_points: quality_points,
                        isSaved: false
                    });

                } else {
                    if (mediaType === "image") {
                        if (quality_points > this.state.quality_note.image_allotted_points) {
                            quality_points = Number(quality_points) - Number(this.state.quality_note.image_allotted_points)
                        } else {
                            quality_points = 0;
                        }
                    } else if (mediaType === "other") {
                        if (quality_points > this.state.quality_note.text_allotted_points) {
                            quality_points = Number(quality_points) - Number(this.state.quality_note.text_allotted_points)
                        } else {
                            quality_points = 0;
                        }
                    } else {
                        if (quality_points > this.state.quality_note.video_allotted_points) {
                            quality_points = Number(quality_points) - Number(this.state.quality_note.video_allotted_points)
                        } else {
                            quality_points = 0;
                        }
                    }
                    if (mediaType === "other") {
                        this.setState({
                            docList: DummyArray,
                            quality_points: quality_points,
                            isSaved: false
                        });
                    } else {
                        this.setState({
                            pictureVideoList: DummyArray,
                            quality_points: quality_points,
                            isSaved: false
                        });
                    }
                }
                await storage.ref(`${fileName}`).delete()
                // createNotification('success', 'Multimedia deleted successfully.');
            } catch (e) {
                createNotification('error', 'The Multimedia was not deleted. Please try again.');
            }
        }
    }
    // -----------------Delete  Audio/Video/Image from Firebase  -------------



    // --------------------Change  note Details  ------------------
    changeNote = (data) => {
        if (this.state.isSaved) {
            let attachments = data.note_attachment;
            let DummyAudioNote = [];
            let DummyVideoNote = [];
            let DummyDocNote = [];
            for (let item of attachments) {
                if (item.cc_attachment_type === "audio") {
                    DummyAudioNote.push(item)
                } else if (item.cc_attachment_type === "other") {
                    DummyDocNote.push(item)
                } else {
                    DummyVideoNote.push(item)
                }
            }
            let assignment = '';
            if (data.cc_work_assignment === null || data.cc_work_assignment === "null") {
                assignment = '';
            } else {
                assignment = data.cc_work_assignment
            }
            let dateValue = getFormattedDate(data.cc_note_reminder_date, this.state.formatDate, this.state.signDate);
            this.setState({
                noteData: data,
                addDate: dateValue,
                note_tag_comments: data.note_tag_comments,
                noteLevel: data.cc_note_level,
                noteScore: data.cc_note_score,
                isEnabledArtifact: data.cc_note_mark_artified,
                work_assignment: assignment,
                pictureVideoList: DummyVideoNote,
                docList: DummyDocNote,
                audioList: DummyAudioNote,
                quality_points: data.cc_note_quality_points > 10 ? 10 : data.cc_note_quality_points,
                isSaved: true

            })


        } else {

            this.setState({
                confirmationForBack: true,
                confirmationType: 'noteChange',
                ChangeNoteData: data
            })


        }
    }
    // --------------------Change  note Details  ------------------



    // --------------------add note ------------------
    addNotes = () => {
        this.setState({
            showSummarizeModal: false,
            transcribeAudioModal: false,
            showGenerateAudioAIModal: false,
            showSelectAudioModal: false
        })
        if (this.state.quality_points === 0) {
            // createNotification('error', 'Your note is empty, “Please enter comments "');
            return;
        }
        const comments = this.state.note_tag_comments;
        let tagStatus = true;
        for (let item of comments) {
            if (item?.tag_comment_text?.trim().length > 0) {
                tagStatus = false
            }
        }
        if (tagStatus) {
            createNotification('error', 'Your note is empty, “Please enter comments "');
            return;
        }

        // --------get attachment array----
        const { pictureVideoList, audioList, docList } = this.state

        let attachment_array = [];
        for (let item of docList) {
            let attachment = {
                "cc_attachment_url": item.cc_attachment_url,
                "cc_attachment_size": item.cc_attachment_size,
                "cc_attachment_type": item.cc_attachment_type,
                "cc_attachment_operation": item.cc_attachment_operation,
                "cc_media_mark_artified": item.cc_media_mark_artified == 'yes' ? 'yes' : 'no',
                "cc_attachment_name": ""
            }
            attachment_array.push(attachment)
        }
        for (let item of pictureVideoList) {
            let attachment = {
                "cc_attachment_url": item.cc_attachment_url,
                "cc_attachment_size": item.cc_attachment_size,
                "cc_attachment_type": item.cc_attachment_type,
                "cc_attachment_operation": item.cc_attachment_operation,
                "cc_media_mark_artified": item.cc_media_mark_artified == 'yes' ? 'yes' : 'no',
                "cc_attachment_name": ""
            }
            attachment_array.push(attachment)
        }
        for (let item of audioList) {
            let attachment = {
                "cc_attachment_url": item.cc_attachment_url,
                "cc_attachment_size": item.cc_attachment_size,
                "cc_attachment_type": item.cc_attachment_type,
                "cc_attachment_operation": item.cc_attachment_operation,
                "cc_media_mark_artified": item.cc_media_mark_artified == 'yes' ? 'yes' : 'no',
                "cc_attachment_name": item.cc_attachment_name
            }
            attachment_array.push(attachment)

        }
        // --------get attachment array----

        // --------get Tags array----
        let AllNoteTag = this.state.note_tag_comments;
        let customized_tag_list = '';
        let customized_tag_array = [];


        // ------Get customized_tag_comments JSON----
        if (this.state.pageType === 'Edit') {

            let index = 0;
            // ------Get customized_tag Comment by Edit----
            for (let item of AllNoteTag) {

                if (item.tag_id) {
                    let value = []
                    value.push(item.tag_comment_text)

                    customized_tag_array.push(value)
                    index++
                } else {
                    let value = customized_tag_array[index - 1]
                    value.push(item?.tag_comment_text)
                    customized_tag_array[index - 1] = value
                }
            }
            // ------Get customized_tag Comment by Edit----
        } else {
            let index = 0;
            // ------Get customized_tag Comment by Add----
            for (let item of AllNoteTag) {
                if (item.cc_tag_id) {
                    let value = []
                    value.push(item.tag_comment_text)
                    customized_tag_array.push(value)
                    index++
                } else {
                    let value = customized_tag_array[index - 1]
                    value.push(item.tag_comment_text)
                    customized_tag_array[index - 1] = value
                }
            }
            // ------Get customized_tag Comment by Add----
        }
        // ------Get customized_tag_comments JSON----

        // ------Get customized_tag_list in one string----
        if (this.state.pageType === 'Edit') {
            // ------Get customized_tag_list by Edit----
            for (let item of AllNoteTag) {

                if (customized_tag_list === '') {
                    if (item.tag_id === '') {
                        customized_tag_list = ''
                    } else {
                        customized_tag_list = item.tag_id
                    }
                }
                else {
                    if (!(item.tag_id === '')) {
                        customized_tag_list = customized_tag_list + "," + item.tag_id
                    }
                }
            }
            // ------Get customized_tag_list by Edit----
        } else {
            // ------Get customized_tag_list by New----
            for (let item of AllNoteTag) {
                if (customized_tag_list === '') {
                    if (item.cc_tag_id === '') {
                        customized_tag_list = ''
                    } else {
                        customized_tag_list = item.cc_tag_id
                    }
                }
                else {
                    if (!(item.cc_tag_id === '')) {
                        customized_tag_list = customized_tag_list + "," + item.cc_tag_id
                    }
                }
            }
            // ------Get customized_tag_list by New----


        }
        // ------Get customized_tag_list in one string----
        const { class_id, isLinkedStatus, addTime, addDate, noteLevel, isEnabledArtifact, noteScore, studentInfo, work_assignment, noteData } = this.state;

        let apiData = {
            "class_id": class_id,
            "attachment_details_array": attachment_array,
            "customized_tag_array": customized_tag_array,
            "customized_tag_list": customized_tag_list,
            "linked_flag": isLinkedStatus === true ? 'true' : 'false',
            "note_reminder": addTime,
            "note_reminder_date": getDateFormat(addDate),
            "note_student_level": noteLevel,
            "note_student_marked": isEnabledArtifact,
            "note_student_score": noteScore,
            "student_list": studentInfo.cc_student_id,
            "work_assignment": work_assignment
        }

        if (this.state.pageType === 'Edit') {
            apiData.note_id = noteData.cc_note_id
            let finalAttachments = [];
            for (let item of attachment_array) {
                if (item.cc_attachment_operation === "remove" || item.cc_attachment_operation === "unchangedRemoved") {
                    finalAttachments.push({ ...item, "cc_attachment_operation": "Remove" });
                } else if (item.cc_attachment_operation === "add") {
                    finalAttachments.push(item);
                } else if (item.cc_attachment_operation === "Unchanged" || !item.cc_attachment_operation) {
                    finalAttachments.push({ ...item, "cc_attachment_operation": "Unchanged" });
                }
            }
            
            apiData.attachment_details_array = finalAttachments;
            apiData.history = this.props.history
            if (this?.props?.location?.state?.noteBatchIds?.length > 0 && this?.props?.location?.state?.noteBatchIds?.includes(',')) {
                apiData.note_ids_array = this?.props?.location?.state?.noteBatchIds?.split(',')
                apiData.student_list = this?.props?.location?.state?.studentIdsList

            }
            this.setState({
                showSummarizeModal: false
            })
            this.props.editStudentNote(apiData)
            {
                this.state.deletedMultimedia && this.state.deletedMultimedia.length > 0 && this.state.deletedMultimedia.map(async (val) => {
                    if (val.cc_attachment_operation !== "Unchanged") {
                        const data = JSON.parse(sessionStorage.getItem('UserData'))
                        const FinfFolderNameArray = data.cc_user_email_id.split("@")
                        const FolderName = FinfFolderNameArray[0]
                        const fileName = val.cc_attachment_url;
                        await storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/${fileName}`).delete()
                    }
                })
            }
        } else {
            
            let finalAttachments = [];
            for (let item of attachment_array) {
                if (item.cc_attachment_operation !== "remove") {
                    finalAttachments.push(item);
                }
            }
            apiData.attachment_details_array = finalAttachments;
            apiData.history = this.props.history
            this.setState({
                showSummarizeModal: false
            })
            this.props.addStudentNote(apiData)

        }

    }
    // --------------------add note ------------------



    // ---------Confirmation Modal Response -----------
    confirmationToBack = () => {
        this.setState({
            confirmationForBack: false,
            isSaved: true

        }, () => {
            if (this.state.confirmationType === 'back') {
                this.goBack();
            }
            else {
                this.changeNote(this.state.ChangeNoteData)
            }

        })

    }
    // ---------Confirmation Modal Response -----------


    // --------------------Go Back ------------------
    goBack = () => {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { directAdd, deletedMultimedia, pageType
        } = state;
        const { pictureVideoList, audioList, docList } = this.state

        if (pageType !== 'Edit') {
            let attachment_array = [];
            for (let item of docList) {
                let attachment = {
                    "cc_attachment_url": item.cc_attachment_url,
                    "cc_attachment_size": item.cc_attachment_size,
                    "cc_attachment_type": item.cc_attachment_type,
                    "cc_attachment_operation": item.cc_attachment_operation,
                    "cc_attachment_name": ""
                }
                attachment_array.push(attachment)
            }
            for (let item of pictureVideoList) {
                let attachment = {
                    "cc_attachment_url": item.cc_attachment_url,
                    "cc_attachment_size": item.cc_attachment_size,
                    "cc_attachment_type": item.cc_attachment_type,
                    "cc_attachment_operation": item.cc_attachment_operation,
                    "cc_attachment_name": ""
                }
                attachment_array.push(attachment)
            }
            for (let item of audioList) {
                let attachment = {
                    "cc_attachment_url": item.cc_attachment_url,
                    "cc_attachment_size": item.cc_attachment_size,
                    "cc_attachment_type": item.cc_attachment_type,
                    "cc_attachment_operation": item.cc_attachment_operation,
                    "cc_attachment_name": item.cc_attachment_name
                }
                attachment_array.push(attachment)
            }
            attachment_array && attachment_array.length > 0 && attachment_array.map(async (val) => {
                if (val.cc_attachment_operation === "remove") {
                    const data = JSON.parse(sessionStorage.getItem('UserData'))
                    const FinfFolderNameArray = data.cc_user_email_id.split("@")
                    const FolderName = FinfFolderNameArray[0]
                    const fileName = val.cc_attachment_url;
                    await storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/${fileName}`).delete()
                }
            })
        } else {
            deletedMultimedia && deletedMultimedia.length > 0 && deletedMultimedia.map(async (val) => {
                if (val.cc_attachment_operation !== "Unchanged") {
                    const data = JSON.parse(sessionStorage.getItem('UserData'))
                    const FinfFolderNameArray = data.cc_user_email_id.split("@")
                    const FolderName = FinfFolderNameArray[0]
                    const fileName = val.cc_attachment_url;
                    await storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/${fileName}`).delete()
                }
            })
        }
        if (directAdd === "directAdd") {
            if (this.state.isSaved) {
                // this.props.history.push(routes.STUDENTNOTE, {
                //     studentInfo: JSON.parse(sessionStorage.getItem('selectedprofile')),
                //     academicYear: sessionStorage.getItem('selectedYear'),
                //     From: "Academic",
                //     BackPage: 'Academic Year',
                //     directAdd: 'directAddNo'
                // })
                this.props.history.goBack()
            } else {
                this.setState({
                    confirmationForBack: true,
                    confirmationType: 'back'
                })
            }
        } else {
            if (this.state.isSaved) {
                this.props.history.goBack()
            } else {
                this.setState({
                    confirmationForBack: true,
                    confirmationType: 'back'
                })
            }
        }
    }
    // --------------------Go Back ------------------

    onData = (e) => {
    }

    handleClosePopUp = (e) => {
        this.setState({
            showPopUp: false,
        })
        // window.location.reload();
    }

    // --------------------showHideTimLine ------------------
    showHideTimLine() {
        if (this.state.timeLineShow) {
            this.setState({
                timeLineHeight: "200px",
                timeLineShow: false
            })
        } else {
            this.setState({
                timeLineHeight: "100%",
                timeLineShow: true
            })
        }
    }
    // --------------------showHideTimLine ------------------

    // ---------BackTO-------
    BackToDash = () => {
        this.props.history.replace("/home/academic")
    }
    // ---------BackTO-------
    // ---------BackTO-------
    BackTOHome = () => {
        if (this.props.history.location.state.BackPage === "Academic Year") {
            this.props.history.replace("/home/academic")
        } else {
            this.props.history.goBack();
        }
    }
    // ---------BackTO-------
    // --------------------Select-Search-Option ------------------

    // newClassDataList = this?.state.classList?.map((obj, i) => (
    //     {
    //         ...obj,
    //         name: student_enrolled_classes[i].cc_class_name,
    //         value: student_enrolled_classes[i].cc_class_id
    //     }

    // ));

    options = [
        {
            type: "group",
            name: "",
            items: [{ name: "ABC", value: "" },]
        }
    ];

    //
    // --------------------Select-Search-Option ------------------

    // --------------------Custom Button ------------------
    PinkButton = styled(Button)({
        color: "#BD47E8",
        backgroundColor: "#BD47E81A",
        borderColor: "#BD47E8",
        borderRadius: "8px",
        marginTop: "3px",
        "&:hover": {
            backgroundColor: "#BD47E81A",
            borderColor: "#BD47E8",
        },
        "&:disabled": {
            backgroundColor: "#FFFFFF",
            borderColor: "#00000066",
            color: "#00000066",
        },
        textTransform: "capitalize"
    })

    YellowButton = styled(Button)({
        color: "#FFA700",
        backgroundColor: "#FFA7001A",
        borderColor: "#FFA700",
        borderRadius: "8px",
        marginLeft: "10px",
        marginTop: "3px",
        "&:hover": {
            backgroundColor: "#FFA7001A",
            borderColor: "#FFA700",
        },
        "&:disabled": {
            backgroundColor: "#FFFFFF",
            borderColor: "#00000066",
            color: "#00000066",
            startIcon: <img src={starYellow} alt="" width="14" height="14" />
        },
        textTransform: "capitalize"
    })

    GreyButton = styled(Button)({
        color: "#00000066",
        backgroundColor: "#FFFFFF",
        borderColor: "#00000066",
        borderRadius: "8px",
        marginLeft: "10px",
        marginTop: "3px",
        cursor: 'default!important',
        "&:hover": {
            backgroundColor: "#FFFFFF",
            borderColor: "#00000066",
        },
        "&:disabled": {
            backgroundColor: "#FFFFFF",
            borderColor: "#00000066",
            color: "#00000066",
            startIcon: <img src={starYellow} alt="" width="14" height="14" />
        },
        "&.react-datepicker-wrapper": {
            cursor: 'pointer',
        },
        textTransform: "capitalize"
    })
    // --------------------Custom Button ------------------

    setMediaArtiAddEdit = (item, type) => {

        if (type === "image" || type === "video") {
            if (!item.cc_media_mark_artified || item?.cc_media_mark_artified === "no") {
                let mediaValue = this.state.pictureVideoList.map((val, i) => {
                    if (val?.cc_attachment_url === item?.cc_attachment_url) {
                        return { ...val, cc_media_mark_artified: "yes" }
                    } else {
                        return { ...val }
                    }

                })

                this.setState({
                    pictureVideoList: mediaValue,
                }, () => {
                    this.forceUpdate()
                })
            } else {
                console.log('else')
                let mediaValue = this.state.pictureVideoList.map((val, i) => {
                    if (val?.cc_attachment_url === item?.cc_attachment_url) {
                        return { ...val, cc_media_mark_artified: "no" }
                    } else {
                        return { ...val }
                    }
                })
                this.setState({
                    pictureVideoList: mediaValue,
                })
            }
        } else if (type === "doc") {
            if (!item.cc_media_mark_artified || item?.cc_media_mark_artified === "no") {
                let mediaValue = this.state.docList.map((val, i) => {
                    if (val?.cc_attachment_url === item?.cc_attachment_url) {
                        return { ...val, cc_media_mark_artified: "yes" }
                    } else {
                        return { ...val }
                    }
                })
                this.setState({
                    docList: mediaValue,
                    // modalDocSelect: mediaValue  
                }, () => {
                    this.forceUpdate()
                })
            } else {
                let mediaValue = this.state.docList.map((val, i) => {
                    if (val?.cc_attachment_url === item?.cc_attachment_url) {
                        return { ...val, cc_media_mark_artified: "no" }
                    } else {
                        return { ...val }
                    }
                })
                this.setState({
                    docList: mediaValue,
                    // modalDocSelect: mediaValue
                },
                    () => {
                        this.forceUpdate()
                    }
                )

            }
        }

    }

    //set radio value
    standardValueHandler = (event) => {
        this.setState({
            addStandardValue: event?.target?.value
        })
    };

    proceedToAudioHandler = () => {
        let tempSelectedAudioObj = this.state.audioList.filter((item) => item?.cc_attachment_url == this.state.addStandardValue)
        
        const audio = new Audio(tempSelectedAudioObj[0].cc_attachment_fullurl)
        

        audio.addEventListener('loadedmetadata', () => {
            
            this.setState({
                selectAudioTotalDuration: formatAudioDuration(audio.duration)
            })
        });
        this.setState({
            selectAudioObj: tempSelectedAudioObj[0]
        })
        let obj = {
            audio_url: `${firebaseBucket}/${tempSelectedAudioObj?.[0]?.cc_attachment_url}`,
            class_id: this.state.class_id
        }
        this.props.getAudioFromVertex(obj)
    }

    getAudioTimeHandler = (value) => {
        
    }

    handleSelection() {
        const selection = window.getSelection();
        // if (document.getElementById('finalSummarize')?.querySelector('h5')?.innerText == 'Audio Summarization') {
        if (selection && !selection.isCollapsed) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const relativeParent = document.body.parentNode.getBoundingClientRect()

            this.setState({
                selectedText: selection.toString(),
                showPopover: true,
                showTranscribePopover: false,
                popoverPosition: {
                    //   top: (rect.bottom / 3) + 'px',
                    //   left: (rect.width / 2) + 'px',
                    // top: (rect.bottom - rect.top) + 'px',
                    top: (rect.bottom + window.scrollY - 400),
                    left: ((relativeParent.right - rect.right) / 3) + 'px'
                    // left: rect.left + window.scrollX + (rect.width / 3),
                },
            });
        } else {
            this.setState({
                selectedText: null,
                showPopover: false,
            });
        }
        // }

        // if (document.getElementById('finalSummarize')?.querySelector('h5')?.innerText == 'Audio Transcription') {
        //     if (selection && !selection.isCollapsed) {
        //         this.handleTranscribeSelection()
        //     }

        // }
    }

    handleTranscribeSelection() {
        const selection = window.getSelection();
        if (selection && !selection.isCollapsed) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const relativeParent = document.body.parentNode.getBoundingClientRect()
            this.setState({
                selectedText: selection.toString(),
                showTranscribePopover: true,
                showPopover: false,
                popoverTranscribePosition: {
                    //   top: (rect.bottom / 3) + 'px',
                    //   left: (rect.width / 2) + 'px',
                    // top: (rect.bottom - rect.top) + 'px',
                    top: (rect.bottom + window.scrollY - 435),
                    left: ((relativeParent.right - rect.right) / 3) + 'px'
                    // left: rect.left + window.scrollX + (rect.width / 3),
                },
            });
        } else {
            this.setState({
                selectedText: null,
                showTranscribePopover: false,
                showPopover: false
            });
        }
    }

    handleHighlight(text) {
        let dataArray = [...this.state.note_tag_comments]
        const { location = {} } = this.props
        const { state = {} } = location;
        const { pageType} = state;
        if (pageType === "Edit") {
            // Find the index of the object with cc_tag_title equal to text
            const positiveCommentsIndex = dataArray.findIndex(
              (obj) => obj.cc_tag_title === text
            );
      
            // Check if the object exists and tag_comment_text is empty
            if (
              positiveCommentsIndex !== -1 &&
              dataArray[positiveCommentsIndex].tag_comment_text === ""
            ) {
              // Update tag_comment_text
              dataArray[positiveCommentsIndex].tag_comment_text = this.state.selectedText;
            } else {
              // Create a new object with empty tag_comment_text right after the updated object
              const newObject = {
                cc_tag_abbre: dataArray[positiveCommentsIndex]?.cc_tag_abbre,
                cc_tag_title: text,
                tag_comment_add_on: new Date(),
                tag_comment_id: "",
                tag_comment_note_id: dataArray[positiveCommentsIndex]?.tag_comment_note_id,
                tag_comment_student_id: dataArray[positiveCommentsIndex]?.tag_comment_student_id,
                tag_comment_text: this.state?.selectedText,
                tag_id: "",
              };
              // Insert the new object right after the updated object
              dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
            }
            sessionStorage.setItem(
              "finalNoteCommentCardsAfterRemove",
              JSON.stringify(dataArray)
            );
          } else {
            // Find the index of the object with cc_tag_title equal to text
            const positiveCommentsIndex = dataArray.findIndex(
              (obj) => obj.cc_tag_title === text
            );
      
            // Check if the object exists and tag_comment_text is empty
            if (
              positiveCommentsIndex !== -1 &&
              dataArray[positiveCommentsIndex].tag_comment_text === ""
            ) {
              // Update tag_comment_text
              dataArray[positiveCommentsIndex].tag_comment_text = this.state?.selectedText;
            } else {
              // Create a new object with empty tag_comment_text right after the updated object
              const newObject = {
                cc_tag_abbre: dataArray[positiveCommentsIndex]?.cc_tag_abbre,
                cc_tag_add_on: new Date(),
                cc_tag_checked: "no",
                cc_tag_class_id: this.state?.class_id,
                cc_tag_id: "",
                cc_tag_modifyed_on: new Date(),
                tag_comment_text: this.state?.selectedText,
                cc_tag_title: text,
              };
              // Insert the new object right after the updated object
              dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
            }
            sessionStorage.setItem(
              "finalNoteCommentCardsAfterRemove",
              JSON.stringify(dataArray)
            );
          }

        // // Find the index of the object with cc_tag_title equal to text
        // const positiveCommentsIndex = dataArray.findIndex(
        //     (obj) => obj.cc_tag_title === text
        // );

        // // Check if the object exists and tag_comment_text is empty
        // if (positiveCommentsIndex !== -1 && dataArray[positiveCommentsIndex].tag_comment_text === '') {
        //     // Update tag_comment_text
        //     dataArray[positiveCommentsIndex].tag_comment_text = this.state.selectedText;
        // } else {
        //     // Create a new object with empty tag_comment_text right after the updated object
        // const newObject = {
        //     cc_tag_id: '', // Provide a new ID for the new object
        //     cc_tag_title: text,
        //     cc_tag_class_id: 2202,
        //     cc_tag_checked: "no",
        //     cc_tag_add_on: "2023-10-27T07:54:17.000Z",
        //     cc_tag_modifyed_on: null,
        //     cc_tag_abbre: "P C",
        //     tag_comment_text: this.state.selectedText
        // };
        //   // Insert the new object right after the updated object
        //   dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
        // }
      
        

         // ------Add Power points------
         let oldText_quality_note = this.state.text_quality_note;
         let newText_quality_note = 0
         for (let item of dataArray) {
             if (item.tag_comment_text) {
                 if (newText_quality_note < this.state.quality_note.max_allotted_text) {
                     newText_quality_note = Number(newText_quality_note) + Number(this.state.quality_note.text_allotted_points)
                 }
             }
 
         }
         let quality_points = this.state.quality_points
         if (oldText_quality_note > newText_quality_note) {
             let value = Number(oldText_quality_note) - Number(newText_quality_note);
             quality_points = Number(quality_points) - Number(value);
 
         } else {
             let value = Number(newText_quality_note) - Number(oldText_quality_note)
             quality_points = Number(quality_points) + Number(value);
 
         }
         // ------Add Power points------

        this.setState({
            showPopover: false,
            showTranscribePopover: false,
            note_tag_comments: dataArray,
            text_quality_note: newText_quality_note,
            quality_points: quality_points,
            showSummarizeModal: false
        }, () => {
            sessionStorage.setItem('noteCommentData', JSON.stringify(dataArray))
        });
    }

    handleTranscribeHighlight(text) {

        let dataArray = [...this.state.note_tag_comments]
        
        const { location = {} } = this.props
        const { state = {} } = location;
        const { pageType} = state;
        if (pageType === "Edit") {
            // Find the index of the object with cc_tag_title equal to text
            const positiveCommentsIndex = dataArray.findIndex(
              (obj) => obj.cc_tag_title === text
            );
      
            // Check if the object exists and tag_comment_text is empty
            if (
              positiveCommentsIndex !== -1 &&
              dataArray[positiveCommentsIndex].tag_comment_text === ""
            ) {
              // Update tag_comment_text
              dataArray[positiveCommentsIndex].tag_comment_text = this.state.selectedText;
            } else {
              // Create a new object with empty tag_comment_text right after the updated object
              const newObject = {
                cc_tag_abbre: dataArray[positiveCommentsIndex]?.cc_tag_abbre,
                cc_tag_title: text,
                tag_comment_add_on: new Date(),
                tag_comment_id: "",
                tag_comment_note_id: dataArray[positiveCommentsIndex]?.tag_comment_note_id,
                tag_comment_student_id: dataArray[positiveCommentsIndex]?.tag_comment_student_id,
                tag_comment_text: this.state?.selectedText,
                tag_id: "",
              };
              // Insert the new object right after the updated object
              dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
            }
            sessionStorage.setItem(
              "finalNoteCommentCardsAfterRemove",
              JSON.stringify(dataArray)
            );
          } else {
            // Find the index of the object with cc_tag_title equal to text
            const positiveCommentsIndex = dataArray.findIndex(
              (obj) => obj.cc_tag_title === text
            );
      
            // Check if the object exists and tag_comment_text is empty
            if (
              positiveCommentsIndex !== -1 &&
              dataArray[positiveCommentsIndex].tag_comment_text === ""
            ) {
              // Update tag_comment_text
              dataArray[positiveCommentsIndex].tag_comment_text = this.state?.selectedText;
            } else {
              // Create a new object with empty tag_comment_text right after the updated object
              const newObject = {
                cc_tag_abbre: dataArray[positiveCommentsIndex]?.cc_tag_abbre,
                cc_tag_add_on: new Date(),
                cc_tag_checked: "no",
                cc_tag_class_id: this.state?.class_id,
                cc_tag_id: "",
                cc_tag_modifyed_on: new Date(),
                tag_comment_text: this.state?.selectedText,
                cc_tag_title: text,
              };
              // Insert the new object right after the updated object
              dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
            }
            sessionStorage.setItem(
              "finalNoteCommentCardsAfterRemove",
              JSON.stringify(dataArray)
            );
          }

        // Find the index of the object with cc_tag_title equal to text
        // const positiveCommentsIndex = dataArray.findIndex(
        //     (obj) => obj.cc_tag_title === text
        // );

        // // Check if the object exists and tag_comment_text is empty
        // if (positiveCommentsIndex !== -1 && dataArray[positiveCommentsIndex].tag_comment_text === '') {
        //     // Update tag_comment_text
        //     dataArray[positiveCommentsIndex].tag_comment_text = this.state.selectedText;
        // } else {
        //     // Create a new object with empty tag_comment_text right after the updated object
        // const newObject = {
        //     cc_tag_id: '', // Provide a new ID for the new object
        //     cc_tag_title: text,
        //     cc_tag_class_id: 2202,
        //     cc_tag_checked: "no",
        //     cc_tag_add_on: "2023-10-27T07:54:17.000Z",
        //     cc_tag_modifyed_on: null,
        //     cc_tag_abbre: "P C",
        //     tag_comment_text: this.state.selectedText
        // };
        //   // Insert the new object right after the updated object
        //   dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
        // }
      
        

          // ------Add Power points------
          let oldText_quality_note = this.state.text_quality_note;
          let newText_quality_note = 0
          for (let item of dataArray) {
              if (item.tag_comment_text) {
                  if (newText_quality_note < this.state.quality_note.max_allotted_text) {
                      newText_quality_note = Number(newText_quality_note) + Number(this.state.quality_note.text_allotted_points)
                  }
              }
  
          }
          let quality_points = this.state.quality_points
          if (oldText_quality_note > newText_quality_note) {
              let value = Number(oldText_quality_note) - Number(newText_quality_note);
              quality_points = Number(quality_points) - Number(value);
  
          } else {
              let value = Number(newText_quality_note) - Number(oldText_quality_note)
              quality_points = Number(quality_points) + Number(value);
  
          }
          // ------Add Power points------
        this.setState({
            showTranscribePopover: false,
            showPopover: false,
            note_tag_comments: dataArray,
            text_quality_note: newText_quality_note,
            quality_points: quality_points,
            showSummarizeModal: false
        },() => {
            sessionStorage.setItem('notCommentData', JSON.stringify(dataArray))
        });
    }

    logH5Content = (event) => {
        const selectedText = window.getSelection().toString();
        const divId = event.currentTarget.id;
        const h5Content = document.getElementById(divId)?.querySelector('h5')?.textContent;
        
        if (selectedText) {
            
            if (divId == 'div3' || divId == 'div1') {
                this.handleSelection()
            } else {
                if (h5Content == 'Audio Summarization') {
                    this.handleTranscribeSelection()
                }
            }
        }
        if (selectedText?.length == 0) {
            this.setState({
                showTranscribePopover: false,
                showPopover: false
            })
        }
    };

    transcribeSummarizeHandler = () => {
        sessionStorage.setItem('finalTranscribedAudio',this.state.audioTranscribe)
        let obj = {
            text_to_be_summerized: this.state.audioTranscribe
        }
        this.props.getAudioSummaryFromVertex(obj)
        this.setState({
            transcribeAudioModal: false,
            showGenerateAudioAIModal: true
        })
    }

    findAndChangeTagContent = (value) => {
        
        let dataArray = [...this.state.note_tag_comments]
         // Find the index of the object with cc_tag_title equal to text
         const positiveCommentsIndex = dataArray.findIndex(
            (obj) => obj.cc_tag_title === value
        );
            
        // Check if the object exists and tag_comment_text is empty
        if (positiveCommentsIndex !== -1) {
            // Update tag_comment_text
            let textValue = ''
            if(value == "Audio Transcription") {
                textValue = this.state.audioTranscribe
            } else {
                textValue = this.state.audioSummarization
            }
            

            dataArray[positiveCommentsIndex].tag_comment_text = textValue
        }
        
        this.setState({
            note_tag_comments: dataArray
        }, () => {
            
        }) 
    }

    render() {
        const PinkButton = this.PinkButton
        const YellowButton = this.YellowButton
        const GreyButton = this.GreyButton
        let { note_tag_comments, studentInfo, AllNotesData, quality_points, mediaUploadPercentage, pageType, linked_contact, class_id, timeLineHeight, timeLineShow } = this.state;
        const { getTagLoader } = this.props;
        const { showPopover, popoverPosition, showTranscribePopover, popoverTranscribePosition } = this.state;
        const _ = require('lodash');

        return (
            <div className='addNoteMainContainer'>

                <Row className='px-3'>
                    {/* ---------------Header Bar------------ */}
                    <Col xs="12" sm="12" md="12" className='headerBar '>
                        <Col xs="5" className=" note-header-title ">
                            <div className='stdNoteBackDiv'>
                                <img className='calIcon' src={back} alt='' width='60' height='40' onClick={() => { this.BackToDash(); this.props.resetStudentNotes() }} />
                            </div>
                            <div className='stdNoteDetailTextDiv'>
                                <div className='stdNoteDisplayFlex'>
                                    <p className='currentYear' onClick={() => { this.goBack(); this.props.resetStudentNotes() }}><b>{this.props.location.state.BackPage == "FrmCls" ? "Class" : this.state.academicYear}</b></p>
                                    <i className="classForIcon material-icons">chevron_right</i>
                                    <p className='stdNoteGrade1' onClick={() => { this.goBack(); this.props.resetStudentNotes() }}>Notes </p>
                                    <i className="classForIcon material-icons">chevron_right</i>
                                    <p className='stdNoteGrade2'>Create Note </p>
                                </div>
                            </div>
                        </Col>
                        {/* ---------------Header Bar------------ */}
                        <Col className='powerCenter' xs="2">
                            {/* ---------------Progress Bar------------ */}
                            {quality_points === 0 ? <div className="blankPowerPointaDic">
                                <p className='powerText'>Power</p>
                                <p className='powerPointText'>Points</p>
                            </div> :
                                <StudentNoteProgressbarComponent max_score={this.state.quality_note.max_score} value={quality_points} type={'points'} />}
                            {/* ---------------Progress Bar------------ */}

                        </Col>
                        <Col xs="5" className="text-right action-div">
                            <Button variant="default" className="action-btn" onClick={() => { this.props.history.goBack() }}
                                style={{ background: "#ea3b3b", color: '#fff', width: 100, height: 40, borderRadius: 8, textTransform: 'capitalize' }}
                            // disabled={quality_points == 0 ? true : false}
                            >
                                <img src={closeIcon} alt='' /> Cancel
                            </Button>
                            <Button 
                            style={quality_points == 0 ? { cursor: "not-allowed",opacity: "0.5", background: "#2ec428", color: '#fff', width: 100, height: 40, borderRadius: 8, textTransform: 'capitalize' } : { cursor: "pointer", background: "#2ec428", color: '#fff', width: 100, height: 40, borderRadius: 8, textTransform: 'capitalize' }} variant="default" 
                            className="action-btn" 
                            onClick={() => { if(quality_points == 0) {return;} else {this.addNotes(); this.props.resetStudentNotes()} }} >
                                <img src={tick} alt='' />&nbsp;&nbsp; Save
                                {/* <img src={saveBtnIcon} alt='' /> */}
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    {/* ---------------left panel------------ */}
                    <Col md="4" lg="3" className="left-section">
                        <Card className="br-8">
                            <Card.Body>

                                {/* ---------------Profile Section------------ */}
                                {/* ---------------Profile Details------------ */}
                                <Card.Text as="div">
                                    <Row className="profile-card">
                                        <Col xs="12" className="profile-col">
                                            <div className="profile-card-img">
                                                {this.state.Userprofile === '' ?
                                                    <h5 className='noteImageDummyText'>{studentInfo?.cc_student_first_name.charAt(0)}{studentInfo?.cc_student_last_name.charAt(0)}</h5>

                                                    :
                                                    <Card.Img className="profile-img" variant="top" alt='' src={this.state.Userprofile} />}
                                            </div>
                                            <div className={`profile-details-col ${studentInfo?.cc_student_email_id.length > 0 ? 'd-flex flex-wrap flex-column pr b-6' : ''}`}>
                                                <span className={`profile-name ${studentInfo?.cc_student_email_id.length > 0 ? 'mb-13' : ''}`}>{studentInfo?.cc_student_last_name?.replace(/(.{11})..+/, "$1…")} {studentInfo?.cc_student_first_name?.replace(/(.{11})..+/, "$1…")}</span>

                                                <span className="profile-mail">
                                                    {_.truncate(studentInfo?.cc_student_email_id, {
                                                        'length': 30,
                                                        'omission': '...'
                                                    })}
                                                </span>
                                            </div>
                                        </Col>

                                    </Row>
                                </Card.Text>
                                {/* ---------------Profile Details------------ */}

                                {/* ---------------Profile Previous TimeLine------------ */}
                                {AllNotesData && AllNotesData.length > 0 &&
                                    <Row className="profile-card">
                                        <Col xs="12">
                                            <span className="timeline-header">
                                                Note Timeline
                                                {AllNotesData && AllNotesData.length > 4 && <Button className="pull-right" onClick={() => { this.showHideTimLine() }}>
                                                    {timeLineShow ? "Hide All" : "View All"}

                                                </Button>}
                                            </span>
                                        </Col>
                                        <Col style={{ height: timeLineHeight, overflow: 'hidden' }}>
                                            <div className="addEditNoteTimeLine">
                                                <VerticalTimeline
                                                    layout="1-column"
                                                    className="VerticalTimelineSection"

                                                >
                                                    {AllNotesData && AllNotesData.length > 0 && AllNotesData.map((item, index) => (
                                                        <div key={index}>
                                                            {item.cc_note_show_type === 'note' &&
                                                                <VerticalTimelineElement
                                                                    className="vertical-timeline-element--work point"

                                                                    date={item.cc_note_reminder_date === '' ? '' : <img src={remiderIconPink} alt='' className='historyIconPink' />}
                                                                >
                                                                    <Row className="timeline-section">
                                                                        <Col xs="4" className="text-left pl-0">
                                                                            <span onClick={() => {
                                                                                if (pageType === 'Edit') { this.changeNote(item) }
                                                                                else { createNotification("error", "Can't edit existing notes, while creating new notes") }
                                                                            }} className={item.cc_note_id === this.state.noteData.cc_note_id ? "timeline-time-Unsaved" : "timeline-time-cursor"}>{getFormattedDate(item.cc_note_add_on, this.state.formatDate, this.state.signDate)}</span>
                                                                        </Col>
                                                                        <Col xs="4" className="text-right pr-0 ">
                                                                            <i className={item.cc_note_mark_artified === 'no' ? "" : "fa fa-star starSelected"} aria-hidden="true"></i>
                                                                            {item.note_attachment && item.note_attachment.length > 0 && <span className="attached-tag">
                                                                                <img src={attachedIcon} alt='' />
                                                                            </span>}
                                                                        </Col>
                                                                        <Col xs="4" className="text-right pr-2">
                                                                            <span className="timeline-time-Unsaved">
                                                                                {/* {this.state.isSaved ? "Saved" : "Unsaved"} */}
                                                                                {
                                                                                    this.props.history.location.state.pageType !== 'Edit' ? 'Saved' : this.props.history.location.state.data.cc_note_id == item.cc_note_id && !this.state.isSaved ? 'Unsaved' : 'Saved'
                                                                                }
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                </VerticalTimelineElement>}
                                                        </div>))}
                                                </VerticalTimeline>
                                            </div>
                                        </Col>
                                    </Row>}
                                {/* ---------------Profile Previous TimeLine------------ */}

                                {/* ---------------Profile Section------------ */}
                            </Card.Body>
                        </Card>

                        {/* ---------------Linked Contact Section------------ */}
                        {this.state.isLinkedStatus && <Card className="br-8 mt-15">
                            <Card.Body>
                                {linked_contact.length > 0 ?
                                    <Card.Title className="classRoom-card-title">Linked Contact</Card.Title>
                                    :
                                    <Card.Title className="classRoom-card-title">No Linked Contact</Card.Title>}
                                <Card.Text as="div">
                                    <Row className="linked-contact-card">
                                        <Col xs="12">
                                            <ol>
                                                {linked_contact && linked_contact.length > 0 && linked_contact.map((item, index) => (
                                                    <div key={index}>
                                                        {item.cc_parent_action === "linked" && <li><strong>{item.cc_contact_name}</strong><span style={{ marginTop: 7 }}>{item.cc_contact_email_id}</span></li>}
                                                    </div>
                                                ))}
                                            </ol>
                                        </Col>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>}
                        {/* ---------------Linked Contact Section------------ */}

                    </Col>
                    {/* ---------------left panel------------ */}


                    {/* ---------------Right panel------------ */}
                    <Col md="8" lg="9" className="right-section">

                        {/* ---------------Second Top header panel------------ */}
                        <Card className="NoteFilterSection br-8">
                            <Card.Body >
                                <Row>
                                    <Col xs="6" md="6" lg="3" className="note-header-title noteDate student-note-add-date-picker align-items-center">
                                        <DatePicker
                                            // selected={new date()}
                                            onChange={date => { this.setDate(date) }}
                                            customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                                        />
                                        {this.state.addDate}
                                    </Col>
                                    <Col style={{ margin: "auto" }} xs="6" md="6" lg="4" className="note-header-select-add-std text-center">
                                        <div className="NoteDropdownContainer">
                                            {/* <div className="iconDiv"><i className="fa fa-search searchFiltersIcon"></i></div> */}
                                            {/* <SelectSearch
                                                // ref={searchInput}
                                                options={options}
                                                onChange={this.selectOnChange.bind(this)}
                                                // filterOptions={handleFilter}
                                                value=""
                                                name="Workshop"
                                                placeholder="Search class*"
                                                search
                                            /> */}

                                            <div>Class : {this.props.notesData.Get_Student_Classes?.filter(character => character?.cc_class_id == this?.state.class_id)?.[0]?.cc_class_name}</div>
                                           

                                        </div>
                                    </Col>

                                    <Col xs="12" md="12" lg="5" className="pl-2 note-header-right-section">
                                        {this.state.addTime ?
                                            <PinkButton variant="outlined" size="small">
                                                <DatePicker
                                                    selected={new Date()}
                                                    onChange={date => { this.setTime(date) }}
                                                    showTimeSelect
                                                    timeFormat={this.state.formatClock === "24 Hours" ? "HH:mm" : "haa"}
                                                    timeIntervals={60}
                                                    minDate={new Date()}
                                                    minTime={setMinutes(new Date(), 60)}
                                                    maxTime={setHours(setMinutes(new Date(), 45), 23)}
                                                    maxDate={new Date()}
                                                    customInput={<img src={remiderIconPink} alt="" width="14" height="14" />}
                                                    dateFormat="Pp"
                                                    timeCaption="Hour"
                                                />
                                                {this.state.addTime}
                                            </PinkButton> :
                                            <GreyButton variant="outlined" size="small">
                                                <DatePicker
                                                    className='greyReactPicker'
                                                    selected={new Date()}
                                                    onChange={date => { this.setTime(date) }}
                                                    showTimeSelect
                                                    timeFormat={this.state.formatClock === "24 Hours" ? "HH:mm" : "haa"}
                                                    timeIntervals={60}
                                                    minDate={new Date()}
                                                    minTime={setMinutes(new Date(), 60)}
                                                    maxTime={setHours(setMinutes(new Date(), 45), 23)}
                                                    maxDate={new Date()}
                                                    customInput={<img src={remiderIconPink} alt="" width="14" height="14" />}
                                                    dateFormat="Pp"
                                                    timeCaption="Hour"
                                                />
                                                Reminder
                                            </GreyButton>}

                                        {this.state.isEnabledArtifact === 'yes' ?
                                            <YellowButton
                                                variant="outlined"
                                                size="small"
                                                onClick={() => { this.setState({ isEnabledArtifact: 'no', isSaved: false }) }}
                                            >
                                                <img src={starFilled} className="reminderIcon" alt="" width="16" height="18" onClick={() => { this.setState({ isEnabledArtifact: 'no', isSaved: false }) }} />
                                                Remove Artifact
                                            </YellowButton> :
                                            <GreyButton
                                                variant="outlined"
                                                size="small"

                                                onClick={() => { this.setState({ isEnabledArtifact: 'yes', isSaved: false }) }}
                                            >
                                                <img src={starYellowBorder} className="reminderIcon" alt="" width="16" height="18" onClick={() => { this.setState({ isEnabledArtifact: 'yes', isSaved: false }) }} />
                                                Mark as Artifact
                                            </GreyButton>}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        {/* ---------------Second Top header panel------------ */}

                        <Row>

                            {/* ---------------Picture/Video and Audio panel------------ */}
                            <Col xs="12" md="12" lg="8" className="pr-7-5">
                                <Card className="add-media-sec mt-15 br-8">
                                    <Card.Body>
                                        <Row>
                                            {/* ---------------Picture/Video/Level/Socre------------ */}
                                            <Col xs="12">
                                                <Row>
                                                    {/* ---------------Picture/Video panel------------ */}
                                                    <Col xs="12" className="title">
                                                        <div className="multimediaTitles">
                                                            <p className="attachTitles">Multimedia Notes</p>
                                                            <p className="suggestion">— Supported Files: JPEG, MP4, PDF, DOCX, CSV, XLSX, PPTX</p>
                                                        </div>
                                                        <div className="audio-sec">
                                                            <Button className="btn-add-media">
                                                                {this.state.uploadMediaStatus ?
                                                                    /* ---------------Progress Bar------------ */
                                                                    <StudentNoteProgressbarComponent value={mediaUploadPercentage} type={'media'} />
                                                                    /* ---------------Progress Bar------------ */

                                                                    :
                                                                    <img className="add-media" src={addTransperent} alt='' />}


                                                                <Form.Control type="file" accept="video/*|image/*|pdf/*|doc/*|docx/*|gif/*|jpg/*|jpeg/*|png/*|xls/*|xlsx/*|ppt/*|pptx/*|csv/*" onChange={e => { this.getMediaHandle(e) }} />
                                                            </Button>
                                                            <div className="picture-video-sec">
                                                                {this.state.pictureVideoList.length > 0 && this.state.pictureVideoList.map((item, i) => {

                                                                    return (
                                                                        item.cc_attachment_operation !== "remove" && item.cc_attachment_operation !== "unchangedRemoved" && <div key={i}>
                                                                            {item.cc_attachment_type !== 'image' && item.cc_attachment_type !== 'audio' ? (
                                                                                <div className="note" style={{ position: 'relative' }}>
                                                                                    <div className="img-div">
                                                                                        <img src={playIcon} className="playIcon" alt='' width="18" height="18" onClick={() => { this.handleVideoModalShow(item) }} />
                                                                                    </div>
                                                                                    <img className="deleteBtn" src={closeMediaIcon} alt='' width="14" height="14" onClick={() => { this.onDeleteUpdateMedia(i, 'video') }} />
                                                                                    {item.cc_media_mark_artified == 'yes' &&
                                                                                        <div className="badgeDoNotet"></div>
                                                                                    }
                                                                                </div>
                                                                            ) : (
                                                                                <>
                                                                                    {item.cc_attachment_type !== "audio" ?
                                                                                        <div className="note" style={{ backgroundImage: `url(${item.cc_attachment_fullurl})`, position: 'relative' }}>
                                                                                            <div className='img-div-only' onClick={() => { this.handleImageModalShow(item) }} >

                                                                                            </div>
                                                                                            <img className="deleteImgBtn" src={closeMediaIcon} alt='' width="14" height="14" onClick={() => { this.onDeleteUpdateMedia(i, 'video') }} />
                                                                                            {item.cc_media_mark_artified == 'yes' &&
                                                                                                <div className="badgeDoNotet"></div>
                                                                                            }
                                                                                        </div> : ""}
                                                                                </>)}
                                                                        </div>
                                                                    )
                                                                })}
                                                                {this.state.docList.length > 0 && (
                                                                    this.state.docList.map((item, i) => {
                                                                        return (
                                                                            item.cc_attachment_operation !== "remove" && item.cc_attachment_operation !== "unchangedRemoved" && <div key={i}>
                                                                                <div className="note">
                                                                                    <div className="img-divDoc" style={{ position: 'relative' }}>
                                                                                        {item.cc_attachment_url.split(".").pop() === "pdf" && <img src={note_pdf} alt='' className="csvIcon" onClick={() => { this.handleDocModalShow(item) }} />}
                                                                                        {item.cc_attachment_url.split(".").pop() === "doc" || item.cc_attachment_url.split(".").pop() === "docx" ? <img src={note_doc} alt='' className="csvIcon" onClick={() => { this.handleDocModalShow(item) }} /> : ""}
                                                                                        {/* {item.cc_attachment_url.split(".").pop() === "docx" || item.cc_attachment_url.split(".").pop() === "docx" ? <img src={note_doc} alt='' className="csvIcon" onClick={() => { this.handleDocModalShow(item) }} /> : ""} */}
                                                                                        {item.cc_attachment_url.split(".").pop() === "ppt" || item.cc_attachment_url.split(".").pop() === "pptx" ? <img src={note_ppt} alt='' className="csvIcon" onClick={() => { this.handleDocModalShow(item) }} /> : ""}
                                                                                        {item.cc_attachment_url.split(".").pop() === "xls" && <img src={note_xls} alt='' className="csvIcon" onClick={() => { this.handleDocModalShow(item) }} />}
                                                                                        {item.cc_attachment_url.split(".").pop() === "xlsx" && <img src={note_xls} alt='' className="csvIcon" onClick={() => { this.handleDocModalShow(item) }} />}
                                                                                        {item.cc_attachment_url.split(".").pop() === "csv" && <img src={note_csv} alt='' className="csvIcon" onClick={() => { this.handleDocModalShow(item) }} />}
                                                                                        {item.cc_media_mark_artified == 'yes' &&
                                                                                            <div className="badgeDoNotet"></div>
                                                                                        }
                                                                                    </div>
                                                                                    <img className="deleteBtn" src={closeMediaIcon} alt='' width="14" height="14" onClick={() => { this.onDeleteUpdateMedia(i, 'other') }} style={{ position: 'relative' }} />

                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    }))}
                                                                {this.state.docList.length <= 0 && this.state.pictureVideoList.length <= 0 ?
                                                                    <p className="multimedia-suggestion">As a best practice remember to add media<br></br> notes to support your text notes</p> :
                                                                    <p className="multimedia-suggestion"></p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {/* ---------------Picture/Video panel------------ */}


                                                </Row>
                                            </Col>
                                            {/* ---------------Picture/Video/Level/Socre------------ */}

                                            <Col xs="12" className="hr-divider">
                                                <hr></hr>
                                            </Col>

                                            {/* ---------------Audio panel------------ */}
                                            <Col xs="12" className="title">

                                                <p>Audio Notes </p>
                                                <div className="audio-sec">
                                                    <Button className="btn-add-media" style={{ marginTop: 3 }} onClick={() => this.handleShowAudioModal()}>
                                                        {this.state.uploadAudioStatus ?
                                                            <StudentNoteProgressbarComponent className='upload_progress' value={mediaUploadPercentage} type={'media'} />


                                                            :
                                                            <img className="add-media" src={addTransperent} alt='' />}

                                                    </Button>
                                                    <div className="audit-sec">
                                                        {this.state.audioList.map((item, index) => {
                                                            return (
                                                                item.cc_attachment_operation !== "remove" && item.cc_attachment_operation !== "unchangedRemoved" && <div key={index} className="audio-div">
                                                                    <div className="audio-sub-div">
                                                                        <p className="audio-filename">{item.cc_attachment_name === '' ? "Audio Note" : item.cc_attachment_name}</p>
                                                                        <audio controls className="player" preload="false" controlsList="nodownload" src={item.cc_attachment_fullurl} ></audio>
                                                                    </div>
                                                                    <img className="deleteBtn" src={closeMediaIcon} alt='' width="14" height="14" onClick={() => { this.onDeleteUpdateMedia(index, 'audio') }} />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                {
                                                this?.props?.history?.location?.state?.pageType == 'Edit' ?
                                                <Button variant='outlined' className='smartAI_btn text-capitalize' onClick={() => {
                                                    this.setState({
                                                        // showGenerateAudioAIModal: true,
                                                        // transcribeAudioModal: true,
                                                        // showSummarizeModal: true
                                                        showSelectAudioModal: true
                                                    })
                                                }}
                                                disabled={this.state.uploadAudioStatus || this.state.disableSmartBtn || this.state.audioList?.length == 0}
                                                >Smart Ai</Button>
                                                :
                                                <Button variant='outlined' className='smartAI_btn text-capitalize' onClick={() => {
                                                    this.setState({
                                                        // showGenerateAudioAIModal: true,
                                                        // transcribeAudioModal: true,
                                                        // showSummarizeModal: true
                                                        showSelectAudioModal: true
                                                    })
                                                }}
                                                disabled={this.state.uploadAudioStatus || this.state.audioList?.length == 0}
                                                >Smart Ai</Button>
                                                }

                                                
                                            </Col>
                                            {/* ---------------Audio panel------------ */}

                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            {/* ---------------Picture/Video and Audio panel------------ */}

                            {/* ---------------Level & Score panel------------ */}
                            <Col xs="12" md="12" lg="4" className="pl-7-5">
                                <Card className="add-Level-Score-sec mt-15 br-8">
                                    <Card.Body>
                                        <Row className='level-row'>
                                            <Col xs="6" className="level-title">
                                                <div className="level-sub-div">
                                                    <p className='level-sec'>
                                                        Level:
                                                    </p>
                                                    <FormControl
                                                        placeholder="Aa"
                                                        aria-label="Level"
                                                        name='noteLevel'
                                                        value={this.state.noteLevel}
                                                        onChange={this.onChangeText}
                                                    />
                                                </div>
                                            </Col>
                                            <hr></hr>
                                            <Col xs="6" className="score-title">
                                                <div className="score-sub-div">
                                                    <p className='score-sec'>
                                                        Score:
                                                    </p>
                                                    <FormControl
                                                        placeholder="00"
                                                        aria-label="Score"
                                                        name='noteScore'
                                                        value={this.state.noteScore}
                                                        onChange={this.onChangeText}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                {/* ---------------Level & Score panel------------ */}
                                {/* ---------------Miscellaneous panel------------ */}
                                <Card className="add-assignment-sec mt-15 br-8">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12" className="title">
                                                <span style={{ float: "left" }}>{this.state.isLinkedText}</span>
                                                {pageType === 'New' ? <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    className="text-right"
                                                    label=""
                                                    checked={this.state.isLinkedStatus}
                                                    onChange={this.toggleLink}
                                                    disabled={linked_contact.length > 0 ? false : true}
                                                /> :
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        className="text-right"
                                                        label=""
                                                        checked={this.state.isLinkedStatus}
                                                        onChange={this.toggleLink}
                                                        disabled={linked_contact.length > 0 ? false : true}
                                                    />}
                                            </Col>
                                            {this.state.isLinkedStatusForAll ?
                                                <Col xs="12" className='contactTextAreaTopDist'>
                                                    <Form.Control onChange={this.onChangeWorkAssignment} as="textarea" rows="6" value={this.state.work_assignment}
                                                        placeholder="Write assignment note here…"
                                                    />
                                                </Col> :
                                                <Col xs="12" className=''>

                                                </Col>}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            {/* ---------------Miscellaneous panel------------ */}


                            {/* ---------------Comment Section------------ */}

                            <Col xs="12" className="mt-15">
                                {getTagLoader && <img src={loaderImag} alt='' className='loaderUploadIcon' />}
                                {note_tag_comments && note_tag_comments.length > 0 && <StudentNoteCommentCardComponent
                                    type={'Edit'}
                                    pageType={pageType}
                                    width="4"
                                    class_id={class_id}
                                    // CommentData={this?.props?.history?.location?.state?.pageType == 'Edit' ? 
                                    // (JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) ? JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) : this?.props?.history?.location?.state?.data?.note_tag_comments) 
                                    // :
                                    //  this.state.note_tag_comments}
                                    CommentData={this?.props?.history?.location?.state?.pageType == 'Edit' ? 
                                    (JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) ? JSON.parse(sessionStorage.getItem('finalNoteCommentCardsAfterRemove')) : this.state?.note_tag_comments) 
                                    :
                                     this.state.note_tag_comments}
                                    // CommentData={this.state.note_tag_comments}
                                    // CommentData={this?.props?.history?.location?.state?.data?.note_tag_comments}
                                    setCommentData={this.setCommentData.bind(this)}
                                    coreStandardNotes={this.state.getNoteSpiralTagData || this?.props?.history?.location?.state?.class_core_standard}
                                    quality_points={this.state.quality_points}
                                    pageTypeFromProps={this?.props?.history?.location?.state?.pageType}
                                    logH5Content={this.logH5Content.bind(this)}
                                    finalAudioList={this.state.audioList}
                                // setSpeedNote = {this.setSpeedNote.binf(this)}
                                />}
                            </Col>
                            {/* ---------------Comment Section------------ */}

                        </Row>
                    </Col >
                    {/* ---------------Right panel------------ */}

                </Row >

                {/* ---------------Record Audio Modal------------ */}
                <Modal Modal className="add-media-modal br-8" show={this.state.show} onHide={this.handleClose} >
                    <Modal.Header>
                        <Modal.Title>
                            <Button variant="default" onClick={() => { this.enableAudioNameEditing() }}>
                                <i className="fa fa-pencil"></i>
                            </Button>
                            {this.state.isEnabled ? (
                                <Form.Control type="text" placeholder="Enter Audio Title Here" onChange={(e) => { this.setAudioTitle(e) }} />
                            ) : (
                                "Audio Note - " + moment().format('LT')
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="timer" >
                            <Timer
                                lastUnit="m"
                                initialTime={0}
                                checkpoints={[
                                    {
                                        time: 60000 * 3,
                                        callback: () => this.stopRecording(),
                                    },
                                ]}
                                startImmediately={!this.state.record}
                            >
                                {({ start, resume, pause, stop, reset, timerState }) => (<div>
                                    <div className={this.state.record ? "display-none record-btn text-center" : "record-btn text-center"}>
                                        <Button onClick={this.startRecorder.bind(this)}>
                                            <img src={startRecordingIcon} onClick={reset} alt='' />
                                        </Button>
                                        <p className="record-info">Recording Duration Limit: 3 Mins</p>
                                    </div>
                                    <div className={!this.state.record ? "display-none record-btn text-center" : "record-btn text-center"} >
                                        {/* <div> */}
                                        <Button className="stopIcon" onClick={pause}>
                                            <img src={stopIcon} alt='' />
                                        </Button>
                                        <Button className="playAfterPause" onClick={resume}>
                                            <img src={playIcon} className="playIconPause" alt='' width="35" height="35" />
                                        </Button>
                                        <div className='timer_Div' style={{ width: '75px', marginTop: '45px', marginRight: '34px' }}>
                                            0<Timer.Hours />:0<Timer.Minutes />:<Timer.Seconds />
                                        </div>
                                    </div>
                                </div>)}
                            </Timer>




                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        {!this.state.record ? (
                            <Button variant="danger" onClick={this.handleClose} style={{ backgroundColor: '#EB4041', borderRadius: '8px' }}>
                                <img src={closeIcon} alt='' />
                            </Button>
                        ) : (
                            <>
                                <Button className="deleteIcon" onClick={this.handleClose} style={{ backgroundColor: '#EB4041', borderRadius: '8px' }}>
                                    {/* <img src={deleteIcon} alt='' style={{backgroundColor:'#EB4041 !important'}} /> */}

                                    <i className="fa fa-trash-o" style={{ fontSize: "25px", color: "white", backgroundColor: "rgb(235, 64, 65)" }} ></i>
                                </Button>
                                <div style={{ width: 20 }}>

                                </div>
                                <Button variant="success" onClick={this.stopRecording} style={{ backgroundColor: '#4AD245', borderRadius: '8px' }}>
                                    <img src={tickIcon} alt='' />
                                </Button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal >
                {/* ---------------Record Audio Modal------------ */}


                {/* ---------------Play Video Modal------------ */}
                <Modal className="show-video-modal br-8" show={this.state.showVideoModalState} onHide={this.handleVideoModalClose}>
                    <Modal.Header>
                        <span className="mediaArti">Mark As Artifact
                            {/* <i className={this.state.selectedImage.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star-o starUnSelected"} 
                                //  key={i} 
                                 aria-hidden="true" 
                                 onClick={() => { this.setMediaArtiAddEdit(this.state.selectedImage, "image") }}
                                >
                                    </i> : ""
                                */}

                            {this.state.pictureVideoList && this.state.pictureVideoList.length > 0 && this.state.pictureVideoList.map((val, i) => (
                                val.cc_attachment_url === this.state.selectedVideo.cc_attachment_url ? <i className={val.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star starUnSelected"} key={i} aria-hidden="true" onClick={() => { this.setMediaArtiAddEdit(this.state.selectedVideo, "video") }}></i> : ""
                            ))}
                        </span>
                        <Button variant="default" className="delete-btn" onClick={this.handleVideoModalClose} style={{ minWidth: 'auto', width: 40, height: 40, borderRadius: 8 }}>
                            <img src={closeIcon} alt='' />
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.selectedVideo.cc_attachment_url !== undefined && (
                            <video width="750" height="450" style={{ border: "12px solid #e9e9e9" }} controls src={this.state.selectedVideo.cc_attachment_fullurl}></video>
                        )}
                    </Modal.Body>
                </Modal>
                {/* ---------------Play Video Modal------------ */}
                {/* ---------------Image View Modal------------ */}
                <Modal className="show-video-modal br-8" show={this.state.showImageModalState} onHide={this.handleImageModalClose}>
                    <Modal.Header>
                        <span className="mediaArti">Mark As Artifact
                            {/* <i className={this.state.selectedImage.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star-o starUnSelected"} 
                                //  key={i} 
                                 aria-hidden="true" 
                                 onClick={() => { this.setMediaArtiAddEdit(this.state.selectedImage, "image") }}
                                >
                                    </i> : ""
                                */}

                            {this.state.pictureVideoList && this.state.pictureVideoList.length > 0 && this.state.pictureVideoList.map((val, i) => (
                                val.cc_attachment_url === this.state.selectedImage.cc_attachment_url ? <i className={val.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star starUnSelected"} key={i} aria-hidden="true" onClick={() => { this.setMediaArtiAddEdit(this.state.selectedImage, "image") }}></i> : ""
                            ))}
                        </span>
                        <Button variant="default" className="delete-btn" onClick={this.handleImageModalClose} style={{ minWidth: 'auto', width: 40, height: 40, borderRadius: 8 }}>
                            <img src={closeIcon} alt='' />
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.selectedImage.cc_attachment_url !== undefined && (
                            <img src={this.state.selectedImage.cc_attachment_fullurl} alt="" style={{ width: "100%", height: "100%", border: "12px solid #e9e9e9" }} />

                        )}
                    </Modal.Body>
                </Modal>
                {/* ---------------Image View Modal------------ */}


                {/* --------------Confirmation Modal------------ */}
                <Modal centered className="delete-modal-warning br-8" show={this.state.confirmationForBack} >
                    <Modal.Header>
                        <Modal.Title>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <img src={warningIcon} alt="" style={{ width: "60px", height: "60px" }} />
                                <span style={{ fontSize: '24px', color: '#ff1f1f', marginTop: '10px' }}>Wait!</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Do you want to {this.state.confirmationType === 'back,' ? " go back, " : " change note, "}your data will be lost. </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className="cancel-btn-div cancel-btn-div-note" onClick={() => { this.setState({ confirmationForBack: false }) }}
                            style={{ marginRight: 18 }}
                        >
                            Cancel
                        </Button>
                        <Button variant="default" className="goback-btn-div goback-btn-div-note" onClick={() => { this.confirmationToBack() }}>
                            Go back
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal centered className="doc-modal-warning br-8" show={this.state.showDocPopUp} >
                    <Modal.Header style={{ justifyContent: "space-between" }}>
                        <span className="mediaArti">Mark As Artifact
                            {/* <i className={this.state.selectedImage.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star-o starUnSelected"} 
                                //  key={i} 
                                 aria-hidden="true" 
                                 onClick={() => { this.setMediaArtiAddEdit(this.state.selectedImage, "image") }}
                                >
                                    </i> : ""
                                */}

                            {this.state.docList && this.state.docList.length > 0 && this.state.docList.map((val, i) => (
                                (val.cc_attachment_url === this.state.modalDocSelect.cc_attachment_url) ? <i className={val.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star starUnSelected"} key={i} aria-hidden="true" onClick={() => { this.setMediaArtiAddEdit(this.state.modalDocSelect, "doc") }}></i> : ""
                            ))}
                        </span>
                        <Button variant="default" className="delete-btn" onClick={() => {
                            this.setState({
                                showDocPopUp: false,
                                selectedDoc: "",
                                modalDocSelect: {}
                            })
                        }}
                            style={{ minWidth: 'auto', width: 40, height: 40, borderRadius: 8 }}
                        >
                            <img src={closeIcon} alt='' />
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <IframeGoogleDoc url={this.state.selectedDoc} />
                    </Modal.Body>
                </Modal>

                {/*-----------generate AI Audio  ---------------*/}
                <Modal centered className="delete-modal-warning generateAudioModal br-8"  backdrop="static" show={this.state.showGenerateAudioAIModal} onHide={this.handleGenerateAudioAiModalClose}>
                    <Modal.Header className="rubricsModalHeader generateAudioModalHeader pb-0" closeButton>
                    <div className='w-100 d-flex insertDriveButton'>
                            <IconButton aria-label="notes">
                                <InsertDriveFileIcon />
                            </IconButton>
                        <div className='d-flex flex-column align-items-center'>
                            <p className='mb-0'>{this.state.selectAudioObj?.cc_attachment_name?.length > 0 ? this.state.selectAudioObj?.cc_attachment_name : 'Audio Note'}.mp3</p>
                            <span className='time'>{this.state.selectAudioTotalDuration} {this?.state?.selectAudioTotalDuration?.split(':')[0] == 0 ? 'Sec' : 'Min'}</span>
                        </div>
                        <div className='d-flex flex-column w-100'>
                           <img src={audioWave} alt='audioWave' className='img-fluid' />
                        </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div class="sk-fading-circle">
                            <div class="sk-circle1 sk-circle"></div>
                            <div class="sk-circle2 sk-circle"></div>
                            <div class="sk-circle3 sk-circle"></div>
                            <div class="sk-circle4 sk-circle"></div>
                            <div class="sk-circle5 sk-circle"></div>
                            <div class="sk-circle6 sk-circle"></div>
                            <div class="sk-circle7 sk-circle"></div>
                            <div class="sk-circle8 sk-circle"></div>
                            <div class="sk-circle9 sk-circle"></div>
                            <div class="sk-circle10 sk-circle"></div>
                            <div class="sk-circle11 sk-circle"></div>
                            <div class="sk-circle12 sk-circle"></div>
                        </div>
                        <p className="prepareText">Generating Your Notes</p>
                        <p className={`pleaseWaitText`}>
                            please wait…
                        </p>
                    </Modal.Body>
                </Modal>

                {/*--------------transcribe audio modal ----------------*/}
                <Modal centered className="delete-modal-warning generateAudioModal transcribeAudioModalClass br-8"  backdrop="static" show={this.state.transcribeAudioModal} onHide={this.handleGenerateAudioAiModalClose}>

                    <Modal.Header className="rubricsModalHeader generateAudioModalHeader pb-0 align-items-center" closeButton>
                        <div className='w-100 d-flex insertDriveButton'>
                            <IconButton aria-label="notes">
                                <InsertDriveFileIcon />
                            </IconButton>
                            <div className='d-flex flex-column align-items-center'>
                                <p className='mb-0'> {this.state.selectAudioObj?.cc_attachment_name?.length > 0 ? this.state.selectAudioObj?.cc_attachment_name : 'Audio Note'}.mp3</p>
                                <span className='time'>{this.state.selectAudioTotalDuration} {this?.state?.selectAudioTotalDuration?.split(':')[0] == 0 ? 'Sec' : 'Min'}</span>
                            </div>
                            <div className='d-flex flex-column w-100'>
                           <img src={audioWave} alt='audioWave' className='img-fluid' />
                        </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="pb-0" style={{ position: 'relative' }}>

                        {
                            this.state.editAudioTranscribeAndSummarize ?
                                <>
                                    <Form.Control as="textarea" rows={5} onChange={(e) => {
                                        this.setState({
                                            audioTranscribe: e.target.value
                                        })
                                    }}>
                                        {this.state.audioTranscribe}
                                        {/* From the dawn of times, people were writing. We have written using symbols,
                                        like in Ancient Egypt. And we have written using letters, like in Renaissance times.
                                        And all of us got at least one writing assignment in school, without the “Why?”
                                        And yet, today writing is so underrated that most people want to avoid it.
                                        But the truth is–you will have to write. Comments, documentation, design documents,
                                        presentations. Whether you like it or not. So why not become better at it? */}
                                    </Form.Control>
                                </>
                                :
                                <div style={{ position: 'relative' }} id='div3' 
                                // onMouseUp={this.logH5Content}
                                >
                                    {/* <TranscribeNote audioTranscribe={this.state.audioTranscribe}/> */}
                                    <p className='transcribe_para'>
                                        {/* {this.state.audioTranscribe || ''} */}
                                        <div>
                                            {/* {showPopover && (
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: popoverPosition.top,
                                                        //   left: popoverPosition.left,
                                                        padding: "10px",
                                                        background: "#fff",
                                                        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                                        zIndex: 999,
                                                        // display: 'flex',
                                                        // justifyContent: 'space-between',
                                                        minWidth: 526
                                                    }}
                                                >
                                                    <button onClick={() => {this.handleHighlight('Positive Comments')}} className='mr-2 ml-2 positive_comment_btn'>Positive Comments</button>
                                                    <button onClick={() => {this.handleHighlight('Teaching Point')}} className='mr-2 ml-2 teaching_point_btn'>Teaching Point</button>
                                                    <button onClick={() => {this.handleHighlight('Instructional Need')}} className='mr-2 ml-2 instructional_need_btn'>Instructional Need</button>
                                                    <button onClick={() => {this.handleHighlight('Comments 1')}} className='mr-2 ml-2 mt-2 comment_one_btn'>Comments 1</button>
                                                    <button onClick={() => {this.handleHighlight('Comments 2')}} className='mr-2 ml-2 mt-2 comment_one_btn'>Comments 2</button>
                                                </div>
                                            )} */}
                                            <div>
                                                {/* <p>
                                                    All engineers are good writers… of code. But I believe that in
                                                    order to become a better engineer, you should improve your writing skills.
                                                </p>
                                                <p>
                                                    From the dawn of times, people were writing. We have written using symbols,
                                                    like in Ancient Egypt. And we have written using letters, like in Renaissance times.
                                                    And all of us got at least one writing assignment in school, without the “Why?”
                                                    And yet, today writing is so underrated that most people want to avoid it.
                                                    But the truth is–you will have to write. Comments, documentation, design documents,
                                                    presentations. Whether you like it or not. So why not become better at it?
                                                </p> */}
                                                {this.state.audioTranscribe || ''}
                                            </div>
                                        </div>
                                    </p>
                                </div>
                        }


                    </Modal.Body>
                    <Modal.Footer className='justify-content-between transscribe-audio-modal-footer'>
                        <Button variant="secondary" className='summarize_btn' startIcon={<InsertDriveFileIcon />} size='small' onClick={() => {
                            this.transcribeSummarizeHandler()
                        }}
                        disabled={this.state.audioTranscribe?.trim()?.length == 0}
                        >
                            Summarize
                        </Button>

                        {
                            this.state.editAudioTranscribeAndSummarize ?
                                <>
                                    <div className='d-flex'>
                                        <Button className="deleteIcon" onClick={() => {
                                            this.setState({
                                                editAudioTranscribeAndSummarize: false,
                                                audioTranscribe: this.props?.getTranscribeAudioSuccess?.data?.note_description
                                            })
                                        }} style={{ backgroundColor: '#fff', color: '#EB4041', textTransform: 'uppercase',  borderRadius: '8px', minWidth: 40 }}>
                                            Cancel
                                        </Button>
                                        <div style={{ width: 20 }}>

                                        </div>
                                        <Button variant="success" onClick={() => {
                                           this.findAndChangeTagContent('Audio Transcription')
                                           this.setState({
                                            editAudioTranscribeAndSummarize: false,
                                           })
                                        }} style={{ backgroundColor: '#fff', color : '#4AD245', textTransform: 'uppercase', borderRadius: '8px', minWidth: 40 }}>
                                           Save
                                        </Button>
                                    </div>
                                </>
                                :
                                <>
                                    <Button variant="primary" className='summarize_btn' startIcon={<EditIcon />} size='small' onClick={() => {
                                        this.setState({
                                            editAudioTranscribeAndSummarize: true
                                        })
                                    }}>
                                        Edit
                                    </Button>
                                </>
                        }
                    </Modal.Footer>
                </Modal>

                {/* ----------------summarize modal -----------------------*/}
                <Modal centered className="delete-modal-warning generateAudioModal summarizeModal br-8" show={this.state.showSummarizeModal} onHide={this.handleGenerateAudioAiModalClose}  backdrop="static">
                    <Modal.Header className="rubricsModalHeader generateAudioModalHeader pb-0 align-items-center" closeButton>
                        <div className='w-100 d-flex insertDriveButton'>
                            <IconButton aria-label="notes">
                                <InsertDriveFileIcon />
                            </IconButton>
                            <div className='d-flex flex-column align-items-center'>
                                <p className='mb-0'> {this.state.selectAudioObj?.cc_attachment_name?.length > 0 ? this.state.selectAudioObj?.cc_attachment_name : 'Audio Note'}.mp3</p>
                                <span className='time'>{this.state.selectAudioTotalDuration} {this?.state?.selectAudioTotalDuration?.split(':')[0] == 0 ? 'Sec' : 'Min'}</span>
                            </div>
                            <div className='d-flex flex-column w-100'>
                           <img src={audioWave} alt='audioWave' className='img-fluid' />
                        </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="pb-0">

                        <div className='d-flex justify-content-between'>
                            <div className='summarize_para' id="div1" onMouseUp={this.logH5Content}>
                                <h5>Audio Transcription</h5>
                                <div>
                                    {showPopover && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: popoverPosition.top,
                                                // left: popoverPosition.left,
                                                left: 0,
                                                padding: "10px",
                                                background: "#fff",
                                                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                                zIndex: 999,
                                                display: 'flex',
                                                // justifyContent: 'space-between',
                                                width: 375,
                                                flexWrap : 'wrap'
                                            }}
                                        >
                                             <button onClick={() => {this.handleHighlight('Positive Comments')}} className='mr-2 ml-2 mt-2 positive_comment_btn'>Positive Comments</button>
                                             <button onClick={() => {this.handleHighlight('Teaching Point')}} className='mr-2 ml-2 mt-2 teaching_point_btn'>Teaching Point</button>
                                             <button onClick={() => {this.handleHighlight('Instructional Need')}} className='mr-2 ml-2 mt-2 instructional_need_btn'>Instructional Need</button>
                                             <button onClick={() => {this.handleHighlight('Comments 1')}} className='mr-2 ml-2 mt-2 comment_one_btn'>Comments 1</button>
                                             <button onClick={() => {this.handleHighlight('Comments 2')}} className='mr-2 ml-2 mt-2 comment_one_btn'>Comments 2</button>
                                        </div>
                                    )}
                                    <div>
                                        {/* <p>
                                            All engineers are good writers… of code. But I believe that in
                                            order to become a better engineer, you should improve your writing skills.
                                        </p>
                                        <p>
                                            From the dawn of times, people were writing. We have written using symbols,
                                            like in Ancient Egypt. And we have written using letters, like in Renaissance times.
                                            And all of us got at least one writing assignment in school, without the “Why?”
                                            And yet, today writing is so underrated that most people want to avoid it.
                                            But the truth is–you will have to write. Comments, documentation, design documents,
                                            presentations. Whether you like it or not. So why not become better at it?
                                        </p> */}
                                        <p style={{font  :'normal normal normal 14px/20px Open Sans'}}>{sessionStorage.getItem('finalTranscribedAudio') || this.state.audioTranscribe || ''}</p>
                                    </div>
                                </div>
                                {/* <p style={{ position: 'relative' }}>
                                    {showPopover && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: popoverPosition.top,
                                                left: popoverPosition.left,
                                                padding: "10px",
                                                background: "#fff",
                                                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                                zIndex: 999,
                                            }}
                                        >
                                            <button onClick={this.handleHighlight}>Highlight</button>
                                        </div>
                                    )}
                                    {this.state.audioTranscribe || ''}
                                </p> */}
                            </div>

                            <div className='summarize_para' id="div2" onMouseUp={this.logH5Content}>
                                <h5 style={{ zIndex: 2 }}>Audio Summarization</h5>
                               
                                    {
                                        this.state.editAudioTranscribeAndSummarize ? 
                                        <>
                                         <Form.Control as="textarea" rows={6} onChange={(e) => {
                                        this.setState({
                                            audioSummarization: e.target.value
                                        })
                                    }}
                                    className='audioNoteTextAreaEdit'
                                    >
                                        {this.state.audioSummarization || ''}
                                        {/* Objectives of lesson: Explain the process of photosynthesis, List the three components necessary for photosynthesis to take place. List the products of photosynthesis, Compare the leaves of a plant with all the components. Larger Goals: Photosynthesis, Carbon Dioxide, Oxygen, Glucose Specific Objectives: Explain the process of photosynthesis, List the three components necessary for photosynthesis to take place. List the products of photosynthesis, Compare the leaves of a plant that has all the components needed for photosynthesis to one that has a component missing. */}
                                    </Form.Control>
                                        </>
                                        :
                                        <>
                                         <div style={{ position: 'relative' }}>
                                    {showTranscribePopover && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: popoverTranscribePosition.top,
                                                // left: popoverTranscribePosition.left,
                                                left: '-8px',
                                                padding: "10px",
                                                background: "#fff",
                                                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                                zIndex: 999,
                                                display: 'flex',
                                                // justifyContent: 'space-between',
                                                width: 375,
                                                flexWrap : 'wrap'
                                            }}
                                        >
                                            <button onClick={() => {this.handleTranscribeHighlight('Positive Comments')}} className='mr-2 ml-2 positive_comment_btn'>Positive Comments</button>
                                            <button onClick={() => {this.handleTranscribeHighlight('Teaching Point')}} className='mr-2 ml-2 teaching_point_btn'>Teaching Point</button>
                                            <button onClick={() => {this.handleTranscribeHighlight('Instructional Need')}} className='mr-2 ml-2 mt-2 instructional_need_btn'>Instructional Need</button>
                                            <button onClick={() => {this.handleTranscribeHighlight('Comments 1')}} className='mr-2 ml-2 mt-2 comment_one_btn'>Comments 1</button>
                                            <button onClick={() => {this.handleTranscribeHighlight('Comments 2')}} className='mr-2 ml-2 mt-2 comment_one_btn'>Comments 2</button>
                                        </div>
                                    )}
                                    <pre style={{font  :'normal normal normal 14px/20px Open Sans'}}>
                                    
                                    {this.state.audioSummarization || ''}
                                    </pre>
                                         {/* Objectives of lesson: Explain the process of photosynthesis, List the three components necessary for photosynthesis to take place. List the products of photosynthesis, Compare the leaves of a plant with all the components. Larger Goals: Photosynthesis, Carbon Dioxide, Oxygen, Glucose Specific Objectives: Explain the process of photosynthesis, List the three components necessary for photosynthesis to take place. List the products of photosynthesis, Compare the leaves of a plant that has all the components needed for photosynthesis to one that has a component missing. */}
                                         </div>
                                        </>
                                    }
                            </div>
                        </div>
                        <p className='hint_para mt-2'>Hint: Select data to tag as type of comment i.e. positive comment</p>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-between transscribe-audio-modal-footer'>
                        {
                            this.state.editAudioTranscribeAndSummarize ?
                                <>
                                    <div className='d-flex'>
                                        <Button className="deleteIcon" onClick={() => {
                                            this.setState({
                                                editAudioTranscribeAndSummarize: false,
                                                audioTranscribe: this.props?.getTranscribeAudioSuccess?.data?.note_description,
                                                audioSummarization: this.state.audioSummarization?.length > 0 ? this.state.audioSummarization : this.state.combinedSummaryText
                                            })
                                        }} style={{ backgroundColor: '#fff', color: '#EB4041', textTransform: 'uppercase',  borderRadius: '8px', minWidth: 40 }}>
                                            Cancel
                                        </Button>
                                        <div style={{ width: 20 }}>

                                        </div>
                                        <Button variant="success" onClick={() => {
                                            this.setState({
                                                editAudioTranscribeAndSummarize: false,
                                            })
                                            this.findAndChangeTagContent('Audio Summarization')
                                        }} style={{ backgroundColor: '#fff', color : '#4AD245', textTransform: 'uppercase', borderRadius: '8px', minWidth: 40, opacity: this.state.audioSummarization?.trim()?.length == 0 ? '0.5' : '1' }}
                                        disabled={this.state.audioSummarization?.trim()?.length == 0}
                                        >
                                           Save
                                        </Button>
                                    </div>
                                </>
                                :
                                <>
                                    <Button variant="primary" className='summarize_btn' startIcon={<EditIcon />} size='small' onClick={() => {
                                        this.setState({
                                            editAudioTranscribeAndSummarize: true
                                        })
                                    }}>
                                        Edit
                                    </Button>
                                </>
                        }
                    </Modal.Footer>
                </Modal>

                {/*---------showselectaudiomodal --------------------*/}
                <Modal centered className="delete-modal-warning selectAudioModal  br-8" show={this.state.showSelectAudioModal} onHide={this.handleGenerateAudioAiModalClose}  backdrop="static">
                    <Modal.Header className="rubricsModalHeader generateAudioModalHeader pb-0 align-items-center">
                        <p>Select an Audio Note to Generate Smart AI Notes</p>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={this.state.addStandardValue}
                                onChange={this.standardValueHandler}
                                style={{ display: 'block' }}
                            >
                                <Row>
                                    {
                                        this.state.audioList?.filter((item) => item?.cc_attachment_operation  != "unchangedRemoved")?.map((audioItem, audioIdx) => {
                                            
                                            return (
                                                <>
                                                    <Col xs={this.state.audioList?.filter((item) => item?.cc_attachment_operation  != "unchangedRemoved")?.length == 1 ? 12 : 6} key={audioItem?.cc_attachment_url} style={{ paddingRight: 0 }}>
                                                        <FormControlLabel className='audioRadioBtn' key={audioItem} value={audioItem?.cc_attachment_url} control={<Radio />} label={<RadioCardLabel audioObj={audioItem} audioIndex={audioIdx} getAudioTimeHandler={this.getAudioTimeHandler} />} />
                                                    </Col>
                                                </>
                                            )
                                        })
                                    }
                                </Row>
                            </RadioGroup>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className='mt-4'>
                        <Button
                            variant="default"
                            className="delete-modal-warning cancel-btn mr-3"
                            onClick={this.handleGenerateAudioAiModalClose}
                            style={{ background: "transparent", color: "#000", textTransform: 'capitalize' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="default"
                            className="delete-modal-warning save-standard-btn"
                            style={{ borderRadius: "8px", color: "#fff", textTransform: 'capitalize', opacity: (this.state.addStandardValue.length == 0 || this.props.isLoadingGetTranscribeAudio) ? '0.5' : '1' }}
                            onClick={() => {
                                this.startTime();
                                this.proceedToAudioHandler()
                                this.setState({
                                    showGenerateAudioAIModal: true,
                                    showSelectAudioModal: false
                                })
                            }}
                            disabled={this.state.addStandardValue.length == 0 || this.props.isLoadingGetTranscribeAudio}
                        >
                            Proceed
                        </Button>
                    </Modal.Footer>

                </Modal>
                {/* --------------Confirmation Modal------------ */}
                {this.state.showPopUp && <UpgradePopUp handleClosePopUp={this.handleClosePopUp} />}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    state: state,
    classTag: state.classTag,
    getTagLoader: state.classTag.loader,
    notesData: state.studentNote,
    getNoteSpiralTag: state?.classStandard?.get_note_spiral_tag,
    isLoadingGetTranscribeAudio: state?.studentNote?.isLoadingGetTranscribeAudio,
    getTranscribeAudioSuccess: state?.studentNote?.getTranscribeAudioSuccess,
    getTranscribeAudioSuccessDescription: state?.notesData?.getTranscribeAudioSuccess?.data?.note_description
});
const mapDispatchToProps = dispatch => {
    return {
        addStudentNote: (data) => dispatch(addStudentNote(data)),
        editStudentNote: (data) => dispatch(editStudentNote(data)),
        getClassTag: (data) => dispatch(getClassTag(data)),
        getAudioFromVertex: (data) => dispatch(getAudioFromVertex(data)),
        getAudioSummaryFromVertex: (data) => dispatch(getAudioSummaryFromVertex(data)),
        resetStudentNotes: (data) =>dispatch(resetStudentNotes(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    StudentNoteAdd
);


import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import DatePicker from "react-datepicker";
import SelectSearch from 'react-select-search';
import arrow from 'Assets/AttendanceModule/arrow.png';
import arrowLeft from 'Assets/AcademicModule/arrowLeft.png';
import FormativeAssessmentTag from '../FormativeAssessmentTagComponent/FormativeAssessmentTag';
import AssessmentStudentProfile from '../AddAssessmentStudentProfileComponent/AddStudentProfiles';
import CoreStandardPoover from '../AssessmentCoreStandardPopoverComponent/CoreStandardPopover';
import StudentProfiles from '../AssessmentStudentProfileComponent/StudentProfiles';
import addIcon from 'Assets/FormativeAssessmentModule/plus.png';
import back from 'Assets/CommonComponent/back.png'
import calendarIcon from 'Assets/FormativeAssessmentModule/calendar.svg';
import closeIcon from 'Assets/FormativeAssessmentModule/close.svg';
import tickIcon from 'Assets/FormativeAssessmentModule/tick.svg';
import warningIcon from 'Assets/FormativeAssessmentModule/warningIcon.png';
import DivLoader from 'Components/LoadingComponent/DivLoader';
import { getFormattedDate } from "Utils/Helper";
import UpgradePopUp from '../../upgradePopUp/upgradePopUp'
import { getDateFormat } from 'Utils/Helper';
import { createNotification } from 'Config/notificationtoast';
import './FormativeAssessmentDashboard.scss';
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';

const FormativeAssessmentDashboard = (props) => {
    const { getFormativeAssessmentRequest, getAssessmentStudentsListRequest, addAssessmentTagRequest, editAssessmentTagRequest, deleteAssessmentTagRequest, FormativeAssessmentTags, AllStudent, location, loader, getTagStudentLoader, updateColorAssessmentTagRequired, updateSelectedAssessmentTagRequired, getSelectdIdForTag, getClassList } = props;
    const { history, classeslist } = props;
    const { FormativeAssessment } = props.state;
    const { state } = location;
    const { AcademicData } = state;
    const [date, setDateCheck] = useState(new Date())
    const [updatedDate, setUpdatedDate] = useState(date)
    const [addAssissmentPage, setAssissmentPageCheck] = useState(false)
    const [typeAssissment, setAssissmentTypeCheck] = useState('add')
    const [permissionType, setPermissionType] = useState('')
    const [selectedTagName, setAssissmentTagNameCheck] = useState('')
    const [studentList, setStudentList] = useState([]);
    const [arrayOfColor, setArrayOfColorCheck] = useState([])
    const [trafficColorsModal, setTrafficColorsModalCheck] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false)
    const [selectedClass, setSelectedClass] = useState("");
    const [classData, setClassdata] = useState({});
    const [classList, setClassList] = useState();
    const [selectedClassName, setSelectedClassName] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [chooseClass, setChooseClass] = useState("");
    const [chooseClassName, setChooseClassName] = useState("");
    const [showClassConfirm, setShowClassConfirm] = useState(false);
    const [classdetailData, setClassdetailData] = useState({});
    const [classesList, setClassesList] = useState([])
    const [editAssesmentTagId, setEditAssesmentTagId] = useState(0)
    const [userEditTag, setUserEditTag] = useState(false)
    const [userAddAssesment, setUserAddAssesment] = useState(false)
    const [classSharePermission, setClassSharePermission] = useState('')


    const allClassesData = useSelector((state) => state?.academic?.academic_Roster?.data?.class_data)

    const fullClassListData = useSelector((state) => state?.class?.class_Data?.data?.[0])

    useEffect(() => {
        setClassesList(allClassesData)
    },[allClassesData])


    

    // classdetailData
    /* ---------------onLoad------------ */
    
    const BackPage = props?.location?.state?.BackPage;

    useEffect(() => {
        if (BackPage == "Academic Year") {
        const value = {
            filter_by: 'both',
            // page_no: "full",
            class_academic_year: AcademicData.year_ids
        }
        getClassList(value)
    }
    if(BackPage == 'ClassDB') {
        setSelectedClassName(props?.location?.state?.classDetailsdata?.cc_class_name)
        setClassdetailData(props?.location?.state?.classDetailsdata)
        // setClassdata(props?.location?.state?.classDetailsdata)
        setClassSharePermission(props?.history?.location?.state?.classDetailsdata?.cc_class_share_permission)
        const apiValue = {
            "classId": props?.history?.location?.state?.classDetailsdata?.cc_class_id,
            "academicYearId": AcademicData.academic_year_id,
            }
            getFormativeAssessmentRequest(apiValue);
    }
    }, [])

    useEffect(() => {
        if (AllStudent?.data && AllStudent?.data?.length > 0) {
            setStudentList(AllStudent?.data);
        }
    }, [AllStudent])

    useEffect(() => {
        if (classeslist.action === "GET_CLASS_LIST_SUCCESS" && classeslist && classeslist.class_Data && classeslist.class_Data.data[0].length > 0) {
            setClassList(classeslist.class_Data.data[0])
        }
    }, [classeslist, classeslist.action])

    /* ---------------onLoad------------ */

    /* ---------------Date Select------------ */
    const selectDateCheck = (value) => {
        setDateCheck(value)
    }

    useEffect(() => {
        
        if (classData !== "") {
            setPermissionType(classData.cc_class_share_permission)
        }
    }, [classData]);

    useEffect(() => {
        if (FormativeAssessment && FormativeAssessment.action === "ADD_ASSESSMENT_TAG_FAILURE"
            || "EDIT_ASSESSMENT_TAG_FAILURE") {
            if (FormativeAssessment.error && (FormativeAssessment.error.message === "Note limit has been exceeded. Please upgrade the plan"
                || FormativeAssessment.error.message === "Media limit has been exceeded. Please upgrade the plan"
                || FormativeAssessment.error.message === "Please update your subscription plan")) {
                setShowPopUp(true);
            }
        }
    }, [FormativeAssessment]);

    useEffect(() => {
        const signDate = sessionStorage.getItem('separatorFormat')
        const formatDate = sessionStorage.getItem('dateFormat')
        const formattedDate = getFormattedDate(date, formatDate, signDate);
        setUpdatedDate(formattedDate)
    }, [date]);

    /* ---------------Date Select------------ */

    const searchInput = useRef();
    const classDataList = classList;
    const newClassDataList = classList?.map((obj, i) => (
        {
            ...obj,
            name: classDataList[i].cc_class_name,
            value: classDataList[i].cc_class_id
        }
    ));

    const newArr = newClassDataList?.map((name, i) => ({ ['name']: newClassDataList[i].cc_class_name, ['value']: newClassDataList[i].cc_class_id, ["data"]: newClassDataList[i] }));

    const options = [
        {
            type: "group",
            name: "",
            items: newArr !== undefined ? newArr : [{ name: "", value: "", data: ""},]
        }
    ];

    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    const handleChange = (value, ...args) => {
   
        let classData = {}

        if(JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher") {
            classData = fullClassListData?.find((item) => item?.cc_class_id == value)
        } else {
            classData = classesList?.find((item) => item?.cc_class_id == value)
        }
            
        setClassSharePermission(classData?.cc_class_share_permission)
        sessionStorage.setItem('FAClassPermission',classData?.cc_class_share_permission)
        if (addAssissmentPage && AllStudent?.data?.length > 0) {
            let defaultVal = JSON.parse(sessionStorage.getItem("formAssessmentStd"));
            let assessmentTagDeflt = JSON.parse(sessionStorage.getItem("assesTag"));
            if ("cc_ref_student_color_code" in defaultVal[0] === false) {
                let dfltVal = defaultVal.map(val => {
                    return { ...val, "cc_ref_student_color_code": "" }
                })
                const stddiffList = (getDifference(AllStudent?.data, dfltVal));
                if (stddiffList && stddiffList.length > 0) {
                    setChooseClass(args[0].value);
                    setChooseClassName(args[0].name);
                    setClassdetailData(args[0].data);
                    
                    setShowClassConfirm(true);
                } else if (assessmentTagDeflt !== selectedTagName) {
                    setShowClassConfirm(true);
                } else {
                    setAssissmentPageCheck(false);
                    setSelectedClass(args[0].value);
                    setSelectedClassName(args[0].name);
                    setClassdetailData(args[0].data);
                    
                    const apiValue = {
                        "classId": args[0].value || sessionStorage.getItem("cLsIdForSingleClass"),
                        "academicYearId": AcademicData.academic_year_id,
                    }
                    getFormativeAssessmentRequest(apiValue);
                }
            } else {
                const stddiffList = (getDifference(AllStudent?.data, defaultVal));
                if (stddiffList && stddiffList.length > 0) {
                    setChooseClass(args[0].value);
                    setChooseClassName(args[0].name);
                    setClassdetailData(args[0].data);
                    setShowClassConfirm(true);
                } else if (assessmentTagDeflt !== selectedTagName) {
                    setShowClassConfirm(true);
                } else {
                    setAssissmentPageCheck(false);
                    setSelectedClass(args[0].value);
                    setSelectedClassName(args[0].name);
                    setClassdetailData(args[0].data);
                    if(args[0].data.student_count > 0){
                        const apiValue = {
                        "classId": args[0].value || sessionStorage.getItem("cLsIdForSingleClass"),
                        "academicYearId": AcademicData.academic_year_id,
                    }
                    getFormativeAssessmentRequest(apiValue);}
                }
            }
        } else {

            setAssissmentPageCheck(false);
            setSelectedClass(args[0]?.value);
            setSelectedClassName(args[0]?.name || props?.location?.state?.classDetailsdata?.cc_class_name);
            setClassdetailData(args[0]?.data || props?.location?.state?.classDetailsdata);
       

            

            if(args[0]?.data.student_count > 0 )
        {
            const apiValue = {
                "classId": args[0]?.value || sessionStorage.getItem("cLsIdForSingleClass"),
                "academicYearId": AcademicData.academic_year_id,
            }
            getFormativeAssessmentRequest(apiValue);
        }
      
        }
    };
    // useEffect(() => {
    //     if (BackPage == "class" || BackPage == "ClassDB") {
    //         const apiValue = {
    //             "classId": sessionStorage.getItem("cLsIdForSingleClass"),
    //             "academicYearId": AcademicData.academic_year_id,
    //         }
    //         getFormativeAssessmentRequest(apiValue);
    //     }
        
    
    // }, [])
    /* ---------------change Assissment Check------------ */
    const [CLR, setCLR] = useState();
    const [FT, setFT] = useState();
    
    const changeAssissmentCheck = () => {
        console.log("clicked")
        const apiValue = {
            "classId": selectedClass ||  sessionStorage.getItem("cLsIdForSingleClass"),
            "academicYearId": AcademicData.academic_year_id,
        }
        console.log('apiValue :>> ');
        getFormativeAssessmentRequest(apiValue);
        const value = {
            itemIndex: 0,
            index: 0,
            deSeletColor: true
        }
        sessionStorage.setItem("formAssessmentStd", JSON.stringify(AllStudent.data));
        sessionStorage.setItem("assesTag", JSON.stringify(selectedTagName));
        setDateCheck(new Date())
        setAssissmentTagNameCheck('')
        updateColorAssessmentTagRequired(value)
        setAssissmentPageCheck(!addAssissmentPage)
        setAssissmentTypeCheck('add')
        setUserEditTag(false)
        setUserAddAssesment(true)
        setCLR(userEditTag ? "abcc" : "bccc");
        setFT(true)
        
        
    }
    /* ---------------change Assissment Check------------ */

    /* ---------------ON change of tag Name Check------------ */
    const onChangeName = (e) => {
        setAssissmentTagNameCheck(e.target.value)
    }
    /* ---------------ON change of tag Name Check------------ */

    /* ---------------ON change of tag Id Check------------ */
    const onChangeTagId = (item) => {
        updateSelectedAssessmentTagRequired(item.assessment_tag_id)
        setAssissmentPageCheck(true)
        setAssissmentTypeCheck('edit')
        setUserEditTag(true)
        setAssissmentTagNameCheck(item.assessment_tag_name)
        setDateCheck(new Date(item.assessment_tag_date))
        const APIData = {
            "assessment_tag_id": item.assessment_tag_id,
        }
        getAssessmentStudentsListRequest(APIData)
    }
    /* ---------------ON change of tag Id Check------------ */

    const onSelectTagId = (item) => {
        const APIData = {
            "assessment_tag_id": item.assessment_tag_id,
        }
        updateSelectedAssessmentTagRequired(item.assessment_tag_id)
        setAssissmentTagNameCheck(item.assessment_tag_name)
        setDateCheck(new Date(item.assessment_tag_date))
        getAssessmentStudentsListRequest(APIData)
    }

    /* ---------------ON change of tag Id Check------------ */
    const onDeleteTagId = (id) => {
        updateSelectedAssessmentTagRequired('')
        deleteAssissment(id)
        setUserEditTag(false)
        // if (FormativeAssessmentTags && FormativeAssessmentTags.data && FormativeAssessmentTags.data.assessment_details.length > 0) {
        //     // const APIData = {
        //     //     "assessment_tag_id": FormativeAssessmentTags.data.assessment_details[0].assessment_tag_id,
        //     // }
        //     updateSelectedAssessmentTagRequired(FormativeAssessmentTags.data.assessment_details[0].assessment_tag_id)
        //     // getAssessmentStudentsListRequest(APIData)
        // } else {
        // updateSelectedAssessmentTagRequired('')
        // }
    }
    /* ---------------ON change of tag Id Check------------ */

    const handleClosePopUp = event => {
        setShowPopUp(false);
    }

    /* ---------------Cancel Assissment Check------------ */
    const onCancelTagId = () => {
        setAssissmentPageCheck(false)
        setAssissmentTypeCheck('add')
        setEditAssesmentTagId(0)
        setUserEditTag(false)
        setUserAddAssesment(false)
        if (getSelectdIdForTag) {
            const APIData = {
                "assessment_tag_id": getSelectdIdForTag,
            }
            getAssessmentStudentsListRequest(APIData)
            return;
        }
        if (FormativeAssessmentTags && FormativeAssessmentTags.data && FormativeAssessmentTags.data.assessment_details.length > 0) {
            const APIData = {
                "assessment_tag_id": FormativeAssessmentTags.data.assessment_details[0].assessment_tag_id,
            }
            updateSelectedAssessmentTagRequired(FormativeAssessmentTags.data.assessment_details[0].assessment_tag_id)
            getAssessmentStudentsListRequest(APIData)
        }
    }
    /* ---------------Cancel Assissment Check------------ */


    /* ---------------add Assissment Check------------ */
    const addAssissment = () => {
        setUserAddAssesment(false)
        if (!selectedTagName) {
            createNotification('error', 'Please enter the Tag name.');
            return false;
        }
        let isColorBlank = true;
        let ArrayOfColor = []
        for (let item of AllStudent?.data) {
            let data = { student_id: item.cc_student_id }
            if (item.cc_ref_student_color_code) {
                isColorBlank = false;
                data.student_color_code = item.cc_ref_student_color_code
                ArrayOfColor.push(data)
            } else {
                data.student_color_code = ''
                ArrayOfColor.push(data)
            }
        }
        setArrayOfColorCheck(ArrayOfColor)
        if (isColorBlank) {
            setTrafficColorsModalCheck(true)
        } else {
            approvalToAddAssissment(ArrayOfColor)
        }
        setEditAssesmentTagId(0)
    }
    /* ---------------add Assissment Check------------ */

    /* ---------------Not Approved for Add/Edit Tag------------ */
    const notApproveNew = () => {
        setTrafficColorsModalCheck(false)
        setArrayOfColorCheck([])
    }
    /* ---------------Not Approved for Add/Edit Tag ------------ */

    /* ---------------Approved for Add/Edit Tag------------ */
    const approvalToAddAssissment = (arrayColor) => {
        setTrafficColorsModalCheck(false)
        let APIData = {
            "assessmentTagName": selectedTagName,
            "date": getDateFormat(date),
            "classId": selectedClass || sessionStorage.getItem("cLsIdForSingleClass"),
            "studentDetailsArray": arrayColor,
            "academicYearId": AcademicData.academic_year_id,
        }
        if (typeAssissment === 'add') {
            addAssessmentTagRequest(APIData)

        } else {
            APIData.assessmentTagId = getSelectdIdForTag
            editAssessmentTagRequest(APIData)
        }
        updateSelectedAssessmentTagRequired('')
        setAssissmentPageCheck(!addAssissmentPage)
        setAssissmentTypeCheck('add')
        setUserEditTag(false)
    }
    /* ---------------Approved for Add/Edit Tag------------ */


    /* ---------------delete Assissment Check------------ */
    const deleteAssissment = (id) => {
        const APIData = {
            "assessmentTagId": id,
            "classId": selectedClass || sessionStorage.getItem("cLsIdForSingleClass"),
            "academicYearId": AcademicData.academic_year_id,
        }
        deleteAssessmentTagRequest(APIData)
    }
    /* ---------------delete Assissment Check------------ */

    const getDifference = (stdList, dfltVal) => {
        return stdList.filter(object1 => {
            return !dfltVal.some(object2 => {
                return object1.cc_ref_student_color_code === object2.cc_ref_student_color_code;
            });
        });
    }

    const goBack = () => {
        if (addAssissmentPage && selectedClass && AllStudent?.data && AllStudent?.data?.length > 0) {
            let defaultVal = JSON.parse(sessionStorage.getItem("formAssessmentStd"));
            let assessmentTagDeflt = JSON.parse(sessionStorage.getItem("assesTag"));
            if ("cc_ref_student_color_code" in defaultVal && defaultVal[0] === false) {
                let dfltVal = defaultVal.map(val => {
                    return { ...val, "cc_ref_student_color_code": "" }
                })
                const stddiffList = (getDifference(AllStudent.data, dfltVal));
                if (stddiffList && stddiffList.length > 0) {
                    setShowConfirm(true);
                } else if (assessmentTagDeflt !== selectedTagName) {
                    setShowConfirm(true);
                } else {
                    history.goBack();
                }
            } else {
                const stddiffList = (getDifference(AllStudent.data, defaultVal));
                if (stddiffList && stddiffList.length > 0) {
                    setShowConfirm(true);
                } else if (assessmentTagDeflt !== selectedTagName) {
                    setShowConfirm(true);
                } else {
                    history.goBack();
                }
            }
        } else {
            history.goBack();
        }
    }

    const closeWarnModal = () => {
        let defaultVal = JSON.parse(sessionStorage.getItem("formAssessmentStd"));
        history.goBack();
        setShowConfirm(false);
        setStudentList(defaultVal);
        setAssissmentPageCheck(false);
    }
    const cancelWarnModal = () => {
        setShowConfirm(false);
    }
    const closeClassWarnModal = () => {
        
        setSelectedClass(chooseClass);
        setSelectedClassName(chooseClassName);
        const apiValue = {
            "classId": chooseClass || sessionStorage.getItem("cLsIdForSingleClass"),
            "academicYearId": AcademicData.academic_year_id,
        }
        
        getFormativeAssessmentRequest(apiValue);
        setShowClassConfirm(false);
        setAssissmentPageCheck(false);
    }
    const cancelClassWarnModal = () => {
        setShowClassConfirm(false);
    }
// console.log("424 studentList", studentList)
// console.log("425 Allstudent", AllStudent)
// useEffect(() => {
//     console.log('backprops',props)
//     console.log(BackPage)
//     if (BackPage == "class" || BackPage == "classDB") {
      
//       setSelectedClassName(props?.location?.state?.classDetailsdata?.cc_class_name)
//       setClassdetailData(props?.location?.state?.classDetailsdata)
//       console.log('selectedClassName :>> ', selectedClassName);
 
//     }
//   }, [props])


  useEffect(() => {

  },[selectedClassName, classdetailData])

    return (
        <div className='assessmentMainContainer'>
            <Row>
                <Col md="12" className="right-sections">
                    {/* ---------------Add Assessment ------------ */}
                    <Card className="header">
                        <Card.Body className="headerCard">
                            <div className='classAssessmentDetailsMain'>
                                <div className='assessmentBackDiv'>
                                    <img className='calIcon' src={back} alt='' width='60' height='40' onClick={() => {
                                        history.replace('/home/academic')
                                    }} />
                                </div>
                                <hr className="vrLine"></hr>
                                <div className='assessmentDetailsDiv'>
                                    <div className='displayFlexClassAssessment'>
                                        {
                                            BackPage == "ClassDB" ? 
                                            <>
                                            <p className='formAssessGrade' 
                                            onClick={() => {
                                                history.replace('/home/academic')
                                            }}
                                            >{AcademicData.academic_year}</p>
                                        <i className="assessmentClassForIcon material-icons">chevron_right</i>
                                        <p className='formAssessGrade' onClick={goBack}>{"Class"}</p>
                                        <i className="assessmentClassForIcon material-icons">chevron_right</i>
                                        <p className='formAssessGrade2'>Formative Assessment </p>
                                            </> 
                                            :
                                            <>
                                         <p className='formAssessGrade' onClick={goBack}>{BackPage == "Academic Year" ? AcademicData.academic_year : "Class"}</p>
                                        <i className="assessmentClassForIcon material-icons">chevron_right</i>
                                        <p className='formAssessGrade2'>Formative Assessment </p>
                                            </>
                                        }
                                        {/* <p className='formAssessGrade' onClick={goBack}>{BackPage == "Academic Year" ? AcademicData.academic_year : "Class"}</p>
                                        <i className="assessmentClassForIcon material-icons">chevron_right</i>
                                        <p className='formAssessGrade2'>Formative Assessment </p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="dropdowns-assessment-main-container">
                                <div className="iconDiv"><i className="fa fa-search searchFiltersIcon"></i></div>
                                    <SelectSearch
                                         ref={searchInput}
                                         options={options}
                                         filterOptions={handleFilter}
                                         value={BackPage == "Academic Year" ?  { name: selectedClassName, value: 0 } : {name: props.location.state.classDetailsdata.cc_class_name, value: 0}}
                                         name="Workshop"
                                         placeholder="Search class*"
                                         search
                                         onChange={handleChange}
                                         disabled={BackPage == "class" || BackPage == "ClassDB" || addAssissmentPage && AllStudent.data && AllStudent.data.length > 0  ? true : false}
                                    />
                                </div>
                            {addAssissmentPage && AllStudent.data && AllStudent.data.length > 0 ? <div className="assessBtns">
                                <div className="text-right">
                                    <Button onClick={onCancelTagId} variant="danger" className="actionBtn cancel">
                                        <img src={closeIcon} alt='' />
                                        <p className='saveCancTitle'>Cancel</p>
                                    </Button>
                                    <Button onClick={addAssissment} variant="success" className="actionBtn save">
                                        <img src={tickIcon} alt='' />
                                        <p className='saveTitle'>Save</p>

                                    </Button>
                                </div>
                            </div> :
                                <div className="assessBtns">
                                   {selectedClassName ? <div>
                                        {(!(loader || getTagStudentLoader) && AllStudent.data && AllStudent.data.length > 0 &&
                                            permissionType !== null && permissionType === 'read') || (classdetailData?.student_count < 1) || (classSharePermission == 'read') ?
                                            <Button variant="primary" className="disabledAdd">
                                                <img src={addIcon} alt='' className="addIcon" />
                                                Assessment
                                            </Button> :
                                            <Button onClick={changeAssissmentCheck} variant="primary" className="add">
                                                <img src={addIcon} alt='' className="addIcon" />
                                                Assessment
                                            </Button> }
                                    </div> : ""}
                                </div>
                            }
                        </Card.Body>
                    </Card>
                    {!selectedClassName && BackPage == "Academic Year" && <div className='containerDiv'>
                        <img src={arrow} alt='' className='assessArrowImage' />
                        <div className='containerTextDiv'>
                            <span className='containerText'>Please select class to view or create assessment</span>
                        </div>
                    </div>}
                    {selectedClassName && classdetailData.student_count < 1 && <div className='formContainersDiv'>
                            <p className='formContainerTextNoStd'>Please add students to the class to use assessment module.</p>
                    </div>}
                    {(loader || getTagStudentLoader) && <div>
                        <DivLoader />
                    </div>}
                </Col>
            </Row>
            {selectedClassName && classdetailData.student_count > 0&&<Row className="blockSection">
                <Col md="4" lg="3" className="left-section">
                    {/* ---------------Classroom Section------------ */}
                    {!loader && <Card className="br-8 leftCol mt-15 mb-15">
                        <FormativeAssessmentTag
                            {...props}
                            FormativeAssessmentTags={props.FormativeAssessmentTags}
                            onSelectTagId={onSelectTagId}
                            onChangeTagId={onChangeTagId}
                            onDeleteTagId={onDeleteTagId}
                            selectedTagId={getSelectdIdForTag}
                            typeAssissment={typeAssissment}
                            permissionType={permissionType}
                            classSharePermission={classSharePermission}
                            editAssesmentTagId={editAssesmentTagId}
                            setEditAssesmentTagId={setEditAssesmentTagId}
                            setUserEditTag={setUserEditTag}
                            userEditTag={userEditTag}
                            userAddAssesment={userAddAssesment}
                            setUserAddAssesment={setUserAddAssesment}
                            setAssissmentTypeCheck={setAssissmentTypeCheck}
                            setAssissmentPageCheck={setAssissmentPageCheck}
                            history={history}
                            FT = {FT}
                            setFT = {setFT}
                        />
                    </Card>}
                    {/* ---------------Classroom Section------------ */}
                </Col>
                {<Col md="8" lg="9" className="right-section">
                    {!loader && getSelectdIdForTag === "" && !addAssissmentPage && classdetailData.student_count > 0&&<div className='nullTextContainerDiv'>
                        <img src={arrowLeft} alt='' className='assessArrowImageNull' />
                        <div className='containerTextDiv'>
                            <span className='containerText'>Please select tag to view Student assessment</span>
                        </div>
                    </div>}
                    {/* ------ Loader Div----- */}
                    {selectedClassName && addAssissmentPage && AllStudent?.data?.length > 0 && classdetailData.student_count > 0&&<Card className="sub-header mt-15 br-8">
                        <Card.Body>
                            <Row>
                                <Col xs="12" className="title">
                                    <DatePicker
                                        onChange={e => selectDateCheck(e)}
                                        customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                                    />
                                    <div className="date">{updatedDate}</div>
                                    <FormControl
                                        placeholder="Add Assessment Tag"
                                        aria-label="Add-Assessment-Tag"
                                        className="input"
                                        value={selectedTagName}
                                        onChange={e => onChangeName(e)}
                                    />
                                    <div
                                     style={FormativeAssessmentTags.data.class_core_standard && FormativeAssessmentTags.data.class_core_standard.length > 0 ? {pointerEvents : "visible"} : {pointerEvents : "none" , opacity : "0.5", cursor : 'default'}} >
                                    {/* {FormativeAssessmentTags.data.class_core_standard && FormativeAssessmentTags.data.class_core_standard.length > 0 &&  */}
                                    <CoreStandardPoover CoreStandardList={FormativeAssessmentTags.data.class_core_standard} setAssissmentTagNameCheck={setAssissmentTagNameCheck} />
                                    </div>
                                    {/* } */}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>}
                    {/* ---------------Student Profiles  ------------ */}
                    {!(loader || getTagStudentLoader) && AllStudent?.data && AllStudent?.data?.length > 0 && <div  className = {addAssissmentPage ? "studentsDivAssessment addpg" : "studentsDivAssessment nrmlpg"} style={addAssissmentPage ? {height : "30vw"} : {backgroundColor : "none"}} ><Row>
                        {addAssissmentPage ?
                            <AssessmentStudentProfile updateColorAssessmentTagRequired={updateColorAssessmentTagRequired} AllStudent={AllStudent?.data} typeAssissment={typeAssissment} CLR= {CLR}  FT = {FT} />
                            :
                            !addAssissmentPage && getSelectdIdForTag !== "" ? <StudentProfiles updateColorAssessmentTagRequired={updateColorAssessmentTagRequired} AllStudent={AllStudent?.data} getAllStudentsStatus={props.getAllStudentsStatus} /> : ""
                        }
                    </Row></div>}
                    {/* ---------------Student Profiles  ------------ */}
                    {!(loader || getTagStudentLoader) && addAssissmentPage && AllStudent?.data && AllStudent?.data?.length === 0 && <div>
                        <p className='noStudentinAssessment'>No student in class</p>
                    </div>}
                </Col>}
            </Row>}
            { /* ---------------Modal For Add Assessment While No Color------------ */}
            <Modal size="sm" show={trafficColorsModal} centered={true}>
                <Modal.Body style={{ textAlign: 'center', background: '#ffffff' }}>
                    <div className='TagSaveModalMain'>
                        <p className='TagSaveModalNoteText'>{typeAssissment === 'add' ? 'Add' : 'Update'} Assessment Tag</p>
                    </div>
                    <p className='TagSaveModalWorningText'>Would you like to create the assessment tag without assigning students any traffic light colors?</p>
                    <div className='permissionButtonsDiv'>
                        <div className='backButtonTagText' onClick={notApproveNew}>
                            No
                        </div>
                        <div>
                            <Button onClick={() => { approvalToAddAssissment(arrayOfColor) }} className='addAssessmentGreenBgColor' color="primary" style={{borderRadius : 8}}>
                                <span className='modalSaveTagButtonText' > {typeAssissment === 'add' ? 'Add' : 'Update'}</span>
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            { /* ---------------Modal For Delete Assessment------------ */}
            {showPopUp && <UpgradePopUp handleClosePopUp={handleClosePopUp} />}
            {/* class Confirmation Modal */}
            <Modal centered className="formativeClass-modal-warning br-8" show={showClassConfirm} onHide={() => { setShowClassConfirm(false) }}>
                <Modal.Body>
                    <div>
                        <img src={warningIcon} alt='' className='warningIcon' />
                        <p className="waitTitle">Wait!</p>
                        <div className="modalText"><p>Do you want to change the class, your modified data would be lost.</p></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="cancelAtt-btn"
                        onClick={cancelClassWarnModal}
                    >
                        Cancel
                    </Button>
                    <Button variant="default" className="getReport-btn"
                        onClick={closeClassWarnModal}
                    >
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* class Confirmation Modal */}
            {/* Confirmation Modal */}
            <Modal centered className="formative-modal-warning br-8" show={showConfirm} onHide={() => { setShowConfirm(false) }}>
                <Modal.Body>
                    <div>
                        <img src={warningIcon} alt='' className='warningIcon' />
                        <p className="waitTitle">Wait!</p>
                        <div className="modalText"><p>Do you want to go back, your data will be lost.</p></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="cancelAtt-btn"
                        onClick={cancelWarnModal}
                    >
                        Cancel
                    </Button>
                    <Button variant="default" className="getReport-btn"
                        onClick={closeWarnModal}
                    >
                        Go Back
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Confirmation Modal */}
        </div>
    )

}
FormativeAssessmentDashboard.propTypes = {
    getFormativeAssessmentRequest: PropTypes.func,
    getAssessmentStudentsListRequest: PropTypes.func,
    addAssessmentTagRequest: PropTypes.func,
    editAssessmentTagRequest: PropTypes.func,
    deleteAssessmentTagRequest: PropTypes.func,
    updateColorAssessmentTagRequired: PropTypes.func,
    updateSelectedAssessmentTagRequired: PropTypes.func,
    updateClassGradebookNullRequest: PropTypes.func,
    getTagStudentLoader: PropTypes.bool,
    loader: PropTypes.bool,
    location: PropTypes.object,
};
export default FormativeAssessmentDashboard

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import SelectSearch from 'react-select-search';
import back from 'Assets/CommonComponent/back.png'
import refreshIcon from 'Assets/ClassGradeBookModule/refresh.svg';
import reorderIcon from 'Assets/ClassGradeBookModule/reorder.svg';
import arrow from 'Assets/AttendanceModule/arrow.png';
import addIcon from 'Assets/ClassGradeBookModule/plus.svg';
import tickIcon from 'Assets/ClassGradeBookModule/tick.svg';
import cancelIcon from 'Assets/ClassGradeBookModule/Cancel.png';
import deleteIcon from 'Assets/ClassGradeBookModule/delete.png';
import closeWhiteIcon from 'Assets/ClassGradeBookModule/closeWhite.svg';
import { getDateFormat ,getFormattedDate } from 'Utils/Helper';
import warningIcon from 'Assets/FormativeAssessmentModule/warningIcon.png';
import { createNotification } from 'Config/notificationtoast';
import UpgradePopUp from '../../../upgradePopUp/upgradePopUp'
import ClassGradeBookTable from '../ClassGradeBookTableComponent/ClassGradeBookTable';
import ClassGradeBookDatePicker from '../ClassGradeBookDatePickerComponent/ClassGradeBookDatePicker';
import ClassGradeBookAddStudent from '../ClassGradeBookAddStudentComponent/ClassGradeBookAddStudent';
import { ScrollSync } from 'react-scroll-sync';
import DivLoader from 'Components/LoadingComponent/DivLoader';
import './ClassGradeBookDashboard.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setGradebookDataState } from 'Actions/GradebookAction/classGradebookAction';

const ClassGradeBookDashboard = (props) => {
    const { getClassGradebookRequest,
        getClassGradebookList,
        history,
        classeslist,
        loader,
        updateClassGradebookColorRequest,
        updateClassGradebookAllColorRequest,
        addNewClassGradebookEventRequest,
        updateClassGradebookInputRequest,
        addNewClassGradebookCategoryRequest,
        updateClassGradebookIdRequest,
        deleteClassGradebookEventRequest,
        updateClassGradebookEventRequest,
        updateClassGradebookStudentsRequest,
        addClassGradebookPopoverDataRequest,
        updateClassGradebookPopoverColorRequest,
        updateClassGradebookPopoverInputRequest,
        updateClassGradebookScoreCommentRequest,
        allStudentList,
        selectedStudentForPopoverDetails,
        updateClassGradebookDragDataRequest,
        updateClassGradebookDragEventDataRequest,
        openClassGradebookPopoverRequest,
        NewClassGradebookRequest,
        getClassNoteNullRequest,
        getStudentGradebookNullRequest,
        upgradePopUp,
        getClassList

    } = props
    const dispatch = useDispatch()
    const { location } = history
    const { state } = location;
    const { AcademicData } = state;
    const { UserClassGradebook } = props.state;
    const [isDragDisabled, setDragDisabledCheck] = useState(false)
    const [categoryName, setCategoryNameCheck] = useState('')
    const [categoryId, setCategoryIdCheck] = useState('')
    const [eventId, setEventIdCheck] = useState('')
    const [eventName, setEventNameCheck] = useState('')
    // const [date, setdateCheck] = useState(new Date())
    const [addGradebookPage, setaddGradebookPageCheck] = useState(false)
    const [showPopUp, setShowPopUp] = useState(false)
    const [typeOfGradebook, setTypeOfGradebookCheck] = useState('show')
    const [selectedClass, setSelectedClass] = useState("");
    const [ClassData, setClassdata] = useState({});
    const [classList, setClassList] = useState();
    const [selectedClassName, setSelectedClassName] = useState("");
    const [mainGradebooklist, setMainGradebooklist] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showClassConfirm, setShowClassConfirm] = useState(false);
    const [chooseClass, setChooseClass] = useState("");
    const [chooseClassName, setChooseClassName] = useState("");
    const [selectedClassId, setSelectedClassId] = useState();
    const signDate = sessionStorage.getItem('separatorFormat')
    const formatDate = sessionStorage.getItem('dateFormat')
    const [showConfirmBreadCrumb, setShowConfirmBreadCrumb] = useState(false);
    const [showDeleteConfirmation, setshowDeleteConfirmation] = useState(false);
    const [isGradebookAddError, setIsGradebookAddError] = useState(false)
    let message = `${typeOfGradebook === 'show' ? "Add Grading Event" : "Edit Grading Event"}`
    const titleMainsDiv = ["titleMainsDiv", selectedClassName !== "" ? "titleMainsDivFlex" : ""]
    const dropdownsGradebookMainContainer = ["dropdownsGradebook-main-container", selectedClassName !== "" ? "dropdownsGradebook-flex" : ""]
    const gradebooksGradeTitle = ["gradebooksGradeTitle", addGradebookPage ? "enableBreadCrumb" : ""]
    /* ---------------onLoad------------ */
    
    const grading_event_date = props?.state?.UserClassGradebook?.selectedCategoryList?.category_events?.[0]?.grading_event_date
    
    const [date, setdateCheck] = useState(grading_event_date ? grading_event_date :  new Date())

    const BackPage = props?.location?.state?.BackPage;
    
    useEffect(() => {
        if (BackPage == "Academic Year") {
        const value = {
            filter_by: 'both',
            // page_no: "full",
            class_academic_year: AcademicData.year_ids
        }
        getClassList(value)
        window.sessionStorage.removeItem('studentGradebookScore')
    }
    }, [])

    

    useEffect(() => {
        if (getClassGradebookList && getClassGradebookList?.students && getClassGradebookList?.students?.length > 0) {
            
            setMainGradebooklist(getClassGradebookList);
        }
    }, [getClassGradebookList])

    useEffect(() => {
        if (classeslist.action === "GET_CLASS_LIST_SUCCESS" && classeslist && classeslist?.class_Data && classeslist?.class_Data?.data[0].length > 0) {
            setClassList(classeslist?.class_Data?.data[0])
        }
    }, [classeslist, classeslist.action])

    useEffect(() => {
        if (upgradePopUp && (upgradePopUp.message === 'Note limit has been exceeded. Please upgrade the plan'
            || upgradePopUp.message === "Media limit has been exceeded. Please upgrade the plan"
            || upgradePopUp.message === "Please update your subscription plan")) {
            setShowPopUp(true)
        }
    }, [UserClassGradebook])

    /* ---------------onLoad------------ */
    const searchInput = useRef();
    const classDataList = classList;
    const newClassDataList = classList?.map((obj, i) => (
        {
            ...obj,
            name: classDataList[i].cc_class_name,
            value: classDataList[i].cc_class_id,
        }
    ));

    const newArr = newClassDataList?.map((name, i) => ({ ['name']: newClassDataList[i].cc_class_name, ['value']: newClassDataList[i].cc_class_id, ["data"]: newClassDataList[i] }));

    const options = [
        {
            type: "group",
            name: "",
            items: newArr !== undefined ? newArr : [{ name: "", value: "", data: "" },]
        }
    ];

    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    const handleChange = (value, ...args) => {
        setSelectedClassId(value)
        if (addGradebookPage && selectedClass && mainGradebooklist && mainGradebooklist?.students?.length > 0) {
            let defaultVal = JSON.parse(sessionStorage.getItem("mainGradebooklist"));
            if (message === "Add Grading Event") {
                let changedValues = [];
                getClassGradebookList?.students?.map(val => {
                    if (val.grading_event_student_color_code && val.grading_event_student_color_code !== "") {
                        changedValues.push(val)
                    } else if (val.grading_event_student_comments && val.grading_event_student_comments !== "") {
                        changedValues.push(val)
                    } else if (val.grading_event_student_score && val.grading_event_student_score !== "") {
                        changedValues.push(val)
                    }
                })
                if (changedValues && changedValues.length > 0) {
                    setChooseClass(args[0].value);
                    setChooseClassName(args[0].name);
                    setClassdata(args[0].data);
                    setShowClassConfirm(true);
                } else if (eventName !== "") {
                    setShowClassConfirm(true);
                } else if (categoryName !== "") {
                    setShowClassConfirm(true);
                } else {
                    setaddGradebookPageCheck(false);
                    setSelectedClass(args[0].value);
                    setSelectedClassName(args[0].name);
                    setClassdata(args[0].data);
                    if (args[0].data.student_count > 0) {
                        const apiValue = {
                            "classId": args[0].value || sessionStorage.getItem("cLsIdForGBSC"),
                            "page_no": 1
                        }
                        getClassGradebookRequest(apiValue);
                    }
                }
            } else if (message === "Edit Grading Event") {
                if (categoryId !== "" && eventId === "") {
                    let defaultVal = JSON.parse(sessionStorage.getItem("mainGradebooklist"));
                    let defaultCategories = defaultVal.category;
                    let currentCategories = mainGradebooklist.category;
                    let defaultcat = defaultCategories && defaultCategories.filter(val => val.cc_category_id === categoryId);
                    let changedValues = [];
                    getClassGradebookList?.students?.map(val => {
                        if (val.grading_event_student_color_code && val.grading_event_student_color_code !== "") {
                            changedValues.push(val)
                        } else if (val.grading_event_student_comments && val.grading_event_student_comments !== "") {
                            changedValues.push(val)
                        } else if (val.grading_event_student_score && val.grading_event_student_score !== "") {
                            changedValues.push(val)
                        }
                    })
                    if (changedValues && changedValues.length > 0) {
                        setChooseClass(args[0].value);
                        setChooseClassName(args[0].name);
                        setClassdata(args[0].data);
                        setShowClassConfirm(true);
                    } else if (eventName !== "") {
                        setShowClassConfirm(true);
                    } else if (categoryName !== "") {
                        setShowClassConfirm(true);
                    } else {
                        setaddGradebookPageCheck(false);
                        setSelectedClass(args[0].value);
                        setSelectedClassName(args[0].name);
                        setClassdata(args[0].data);
                        if (args[0].data.student_count > 0) {
                            const apiValue = {
                                "classId": args[0].value || sessionStorage.getItem("cLsIdForGBSC"),
                                "page_no": 1
                            }
                            getClassGradebookRequest(apiValue);
                        }
                    }
                } else {
                    let defaultVal = JSON.parse(sessionStorage.getItem("mainGradebooklist"));
                    let defaultEventStd = [];
                    let currentEventStd = [];
                    let defaultcat = [];
                    let currnetCat = [];
                    defaultVal.category.map(val => {
                        if (val.cc_category_id === categoryId) {
                            defaultcat.push(val)
                            val.category_events.map(value => {
                                defaultEventStd.push(value.event_student)
                            })
                        }
                    })
                    mainGradebooklist.category.map(val => {
                        if (val.cc_category_id === categoryId) {
                            currnetCat.push(val)
                            val.category_events.map(value => {
                                currentEventStd.push(value.event_student)
                            })
                        }
                    })

                    let defaultEvent = defaultcat && defaultcat[0].category_events && defaultcat[0].category_events.filter(val => val.grading_event_id === eventId);
                    let clrDiff = currentEventStd[0].filter(object1 => !defaultEventStd[0].some(object2 => object2.grading_event_student_color_code === object1.grading_event_student_color_code))
                    let cmtDiff = currentEventStd[0].filter(object1 => !defaultEventStd[0].some(object2 => object2.grading_event_student_comments === object1.grading_event_student_comments))
                    let scoreDiff = currentEventStd[0].filter(object1 => !defaultEventStd[0].some(object2 => object2.grading_event_student_score === object1.grading_event_student_score))

                    if (clrDiff && clrDiff.length > 0 || cmtDiff.length > 0 || scoreDiff.length > 0) {
                        setChooseClass(args[0].value);
                        setChooseClassName(args[0].name);
                        setClassdata(args[0].data);
                        setShowClassConfirm(true);
                    } else if (eventName !== "") {
                        setShowClassConfirm(true);
                    } else if (categoryName !== "") {
                        setShowClassConfirm(true);
                    } else {
                        setaddGradebookPageCheck(false);
                        setSelectedClass(args[0].value);
                        setSelectedClassName(args[0].name);
                        setClassdata(args[0].data);
                        if (args[0].data.student_count > 0) {
                            const apiValue = {
                                "classId":  args[0].value || sessionStorage.getItem("cLsIdForGBSC"),
                                "page_no": 1
                            }
                            getClassGradebookRequest(apiValue);
                        }
                    }
                }
            }
        }
        else {
            setaddGradebookPageCheck(false);
            setSelectedClass(args[0]?.value);
            setSelectedClassName(args[0]?.name || props?.location?.state?.classDetailsdata?.cc_class_name);
            setClassdata(args[0]?.data || props?.location?.state?.classDetailsdata);
            if (args[0]?.data?.student_count > 0) {
                const apiValue = {
                    "classId": args[0].value || sessionStorage.getItem("cLsIdForGBSC"),
                    "page_no": 1
                }
                getClassGradebookRequest(apiValue);
            }
        }
    };
    useEffect(() => {
        if (BackPage == "class") {
            const apiValue = {
                "classId": sessionStorage.getItem("cLsIdForGBSC"),
                "page_no": 1
            }
            getClassGradebookRequest(apiValue);
        }
        
    
    }, [])
    /* ---------------refresh Call------------ */
    const refreshCall = () => {
        const apiValue = {
            "classId": selectedClass || sessionStorage.getItem("cLsIdForGBSC"),
            "page_no": 1
        }
        getClassGradebookRequest(apiValue);
    }
    /* ---------------refresh Call------------ */

    /* ---------------Reorder State------------ */
    const reorderState = () => {
        setDragDisabledCheck(!isDragDisabled)
    }
    /* ---------------Reorder State------------ */
    const handleClosePopUp = event => {
        setShowPopUp(false);
    }

    /* ---------------Change Category/Event State------------ */
    const changeValueOfHeader = (data) => {
        if (message === "Add Grading Event") {
            if (data.type) {
                setCategoryNameCheck(data.value)
            } else {
                setEventNameCheck(data.value)
            }
        } else {
            // setEventNameCheck(data.value)
            if (data.type) {
                setCategoryNameCheck(data.value)
            } else {
                setEventNameCheck(data.value)
            }
        }
    }
    /* ---------------Change Category/Event State------------ */

    /* ---------------Change Date State------------ */
    const selectDateCheck = (value) => {
        setdateCheck(value)
    }
    /* ---------------Change Date State------------ */
    useEffect(() => {
        setdateCheck(grading_event_date)
       }, [grading_event_date])
    /* ---------------Change Gradebook Type------------ */
    const changeGradebookType = (value) => {
        setTypeOfGradebookCheck(value)
        changePageStatus()
    }
    /* ---------------Change Gradebook Type------------ */

    const addNewPageOpen = () => {
        if (!isDragDisabled) {
            sessionStorage.setItem("mainGradebooklist", JSON.stringify(mainGradebooklist));
            NewClassGradebookRequest()
            setTypeOfGradebookCheck('show');
            setCategoryNameCheck('')
            setCategoryIdCheck('')
            setEventIdCheck('')
            setEventNameCheck('')
            setdateCheck(new Date())
            changePageStatus()
        }
    }

    /* ---------------Change Page State status------------ */
    const changePageStatus = () => {
        setaddGradebookPageCheck(!addGradebookPage);
        
    }
    /* ---------------Change Page State status------------ */

    /* ---------------Validation Check for add------------ */
    const checkValidation = () => {
        let studentErrorData = allStudentList.filter((item) => {
            return item?.grading_event_student_score_error?.length > 0
        })
        if(studentErrorData && studentErrorData.length > 0) {
            createNotification('error', "Please enter valid score")
            return
        } else {
        if (!categoryName) {
            createNotification('error', 'Please enter a category name.');
            return false;
        }
        if (!eventName) {
            createNotification('error', 'Please enter an event name.');
            return false;
        }
        if (typeOfGradebook === "editEvent") {
            updateEventCheck()
        }
        if (typeOfGradebook === "editCat") {
            addEventInCategory()
        }
        if (typeOfGradebook === "show") {
            addNewCategory()
        }
    }

    }
    /* ---------------Validation Check for add------------ */


    /* ---------------Bind color array------------ */
    const bindStudentArray = () => {
        let studentArray = [];
        for (let item of allStudentList) {
            const arrayData = {
                grading_event_student_color_code: item.grading_event_student_color_code ? item.grading_event_student_color_code : '',
                grading_event_comments: item.grading_event_student_comments ? item.grading_event_student_comments : '',
                grading_event_score: item.grading_event_student_score ? item.grading_event_student_score : '',
                grading_event_student_id: item?.cc_student_id || item?.grading_event_student_id
            }
            studentArray.push(arrayData)
        }
        
        return studentArray;
    }
    /* ---------------Bind color array------------ */


    /* ---------------Add New Category------------ */
    const addNewCategory = async () => {
        sessionStorage.setItem("mainGradebooklist", JSON.stringify(mainGradebooklist));

        const studentArray = await bindStudentArray();
        checkValidationNew()
        if (!(eventName === "" && categoryName === "")) {
            const apiValue = {
                "classId": selectedClass || sessionStorage.getItem("cLsIdForGBSC"),
                "categoryName": categoryName,
                "date": getDateFormat(date),
                "eventName": eventName,
                "studentDetailsArray": studentArray
            }
            addNewClassGradebookCategoryRequest(apiValue)
            changePageStatus()
        }
    }
    /* ---------------Add New Category------------ */
    const checkValidationNew = () => {
        if (!categoryName) {
            createNotification('error', 'Please enter a category name.');
            return false;
        }
        if (!eventName) {
            createNotification('error', 'Please enter an event name.');
            return false;
        }
    }

    /* ---------------Update Event------------ */
    const updateEventCheck = async () => {
        sessionStorage.setItem("mainGradebooklist", JSON.stringify(mainGradebooklist));

        const studentArray = await bindStudentArray();

        const APIData = {
            "classId": selectedClass || sessionStorage.getItem("cLsIdForGBSC"),
            "categoryId": categoryId,
            "date": date,
            "eventName": eventName,
            "eventId": eventId,
            "categoryName": categoryName,
            "studentDetailsArray": studentArray
        }
        updateClassGradebookEventRequest(APIData)
        changePageStatus()
    }
    /* ---------------Update Event------------ */

    /* ---------------add new event in category------------ */
    const addEventInCategory = async () => {
        sessionStorage.setItem("mainGradebooklist", JSON.stringify(mainGradebooklist));

        const studentArray = await bindStudentArray();

        const APIData = {
            "categoryId": categoryId,
            "classId": selectedClass || sessionStorage.getItem("cLsIdForGBSC"),
            "eventName": eventName,
            "date": getDateFormat(date),
            "studentDetailsArray": studentArray
        }
        addNewClassGradebookEventRequest(APIData);
        changePageStatus()
    }
    /* ---------------add new event in category------------ */

    /* ---------------Add New Category------------ */
    const deleteCategoryEvent = async () => {
        const apiValue = {
            "eventId": eventId,
            "classId": selectedClass || sessionStorage.getItem("cLsIdForGBSC"),
        }
        deleteClassGradebookEventRequest(apiValue)
        setshowDeleteConfirmation(false)
        changePageStatus()
    }
    /* ---------------Add New Category------------ */

    const onReorderState = () => {
        reorderState()
    }

    const onRefreshCall = () => {
        if (!isDragDisabled) {
            refreshCall()
        }
    }

    const cancelClassWarnModal = () => {
        setShowClassConfirm(false);
    }

    const closeClassWarnModal = () => {
        setShowClassConfirm(false);
        setaddGradebookPageCheck(false);
        setSelectedClass(chooseClass);
        setSelectedClassName(chooseClassName);
        const apiValue = {
            "classId": selectedClassId || sessionStorage.getItem("cLsIdForGBSC"),
            "page_no": 1
        }

        
        getClassGradebookRequest(apiValue);
    }

    const cancelWarnModal = () => {
        setShowConfirm(false);
    }

    const closeWarnModal = () => {
        let defaultVal = JSON.parse(sessionStorage.getItem("mainGradebooklist"));
        history.goBack();
        setShowConfirm(false);
        setMainGradebooklist(defaultVal);
        setaddGradebookPageCheck(false);
    }

    const getDifference = (stdList, dfltVal) => {
        return stdList.filter(object1 => {
            return !dfltVal.some(object2 => {
                if (object1.grading_event_student_color_code === object2.grading_event_student_color_code) {
                    return
                } else if (object1.grading_event_student_comments === object2.grading_event_student_comments) {
                    return
                } else if (object1.grading_event_student_score === object2.grading_event_student_score) {
                    return
                } else {
                    return
                }
            });
        });
    }
    const goBack = () => {
        dispatch(setGradebookDataState())
        if (addGradebookPage && selectedClass && mainGradebooklist && mainGradebooklist.students.length > 0) {
            if (message === "Add Grading Event") {
                let changedValues = [];
                getClassGradebookList?.students?.map(val => {
                    if (val.grading_event_student_color_code && val.grading_event_student_color_code !== "") {
                        changedValues.push(val)
                    } else if (val.grading_event_student_comments && val.grading_event_student_comments !== "") {
                        changedValues.push(val)
                    } else if (val.grading_event_student_score && val.grading_event_student_score !== "") {
                        changedValues.push(val)
                    }
                })
                if (changedValues && changedValues.length > 0) {
                    setShowConfirm(true);
                } else if (eventName !== "") {
                    setShowConfirm(true);
                } else if (categoryName !== "") {
                    setShowConfirm(true);
                } else {
                    history.goBack();
                }


            } else if (message === "Edit Grading Event") {
                console.log('else if')
                if (categoryId !== "" && eventId === "") {
                    console.log('else if ka if')
                    let defaultVal = JSON.parse(sessionStorage.getItem("mainGradebooklist"));
                    let defaultCategories = defaultVal.category;
                    let currentCategories = mainGradebooklist.category;
                    let defaultcat = defaultCategories && defaultCategories.filter(val => val.cc_category_id === categoryId);
                    let changedValues = [];
                    getClassGradebookList?.students?.map(val => {
                        if (val.grading_event_student_color_code && val.grading_event_student_color_code !== "") {
                            changedValues.push(val)
                        } else if (val.grading_event_student_comments && val.grading_event_student_comments !== "") {
                            changedValues.push(val)
                        } else if (val.grading_event_student_score && val.grading_event_student_score !== "") {
                            changedValues.push(val)
                        }
                    })
                    if (changedValues && changedValues.length > 0) {
                        setShowConfirm(true);
                    } else if (eventName !== "") {
                        setShowConfirm(true);
                    } else if (categoryName !== "") {
                        setShowConfirm(true);
                    } else {
                        history.goBack();
                    }
                } else {
                    console.log('else if ka else')
                    let defaultVal = JSON.parse(sessionStorage.getItem("mainGradebooklist"));
                    let defaultEventStd = [];
                    let currentEventStd = [];
                    let defaultcat = [];
                    let currnetCat = [];
                    defaultVal.category.map(val => {
                        if (val.cc_category_id === categoryId) {
                            defaultcat.push(val)
                            val.category_events.map(value => {
                                defaultEventStd.push(value.event_student)
                            })
                        }
                    })
                    mainGradebooklist.category.map(val => {
                        if (val.cc_category_id === categoryId) {
                            currnetCat.push(val)
                            val.category_events.map(value => {
                                currentEventStd.push(value.event_student)
                            })
                        }
                    })

                    let defaultEvent = defaultcat && defaultcat[0].category_events && defaultcat[0].category_events.filter(val => val.grading_event_id === eventId);
                    
                    let clrDiff = currentEventStd[0].filter(object1 => !defaultEventStd[0].some(object2 => object2.grading_event_student_color_code === object1.grading_event_student_color_code))
                    let cmtDiff = currentEventStd[0].filter(object1 => !defaultEventStd[0].some(object2 => object2.grading_event_student_comments === object1.grading_event_student_comments))
                    let scoreDiff = currentEventStd[0].filter(object1 => !defaultEventStd[0].some(object2 => object2.grading_event_student_score === object1.grading_event_student_score))
                 
                    if (clrDiff && clrDiff.length > 0 || cmtDiff.length > 0 || scoreDiff.length > 0) {
                        setShowConfirm(true);
                    } else if (eventName !== getClassGradebookList?.category?.[0]?.category_events?.[0]?.grading_event_name) {
                        setShowConfirm(true);
                    } else if (categoryName !== getClassGradebookList?.category[0]?.cc_category_name) {
                        setShowConfirm(true);
                    } else {
                        history.goBack();
                    }
                }
            }
        } else {
            history.goBack();
        }
    }

    const cancelBreadcumbModal = () => {
        setShowConfirmBreadCrumb(false);
    }

    const closeBreadcumbModal = () => {
        setShowConfirmBreadCrumb(false);
        setaddGradebookPageCheck(false);
        const apiValue = {
            "classId": selectedClass || sessionStorage.getItem("cLsIdForGBSC"),
            "page_no": 1
        }
        getClassGradebookRequest(apiValue);
    }

    const breadCumbNavigation = () => {
        if (message === "Add Grading Event") {
            let changedValues = [];
            getClassGradebookList?.students?.map(val => {
                if (val.grading_event_student_color_code && val.grading_event_student_color_code !== "") {
                    changedValues.push(val)
                } else if (val.grading_event_student_comments && val.grading_event_student_comments !== "") {
                    changedValues.push(val)
                } else if (val.grading_event_student_score && val.grading_event_student_score !== "") {
                    changedValues.push(val)
                }
            })
            if (changedValues && changedValues.length > 0) {
                setShowConfirmBreadCrumb(true);
            } else if (eventName !== "") {
                setShowConfirmBreadCrumb(true);
            } else if (categoryName !== "") {
                setShowConfirmBreadCrumb(true);
            } else {
                setaddGradebookPageCheck(false);
            }
        } else if (message === "Edit Grading Event") {
            
            if (categoryId !== "" && eventId === "") {
                let defaultVal = JSON.parse(sessionStorage.getItem("mainGradebooklist"));
                let defaultCategories = defaultVal?.category;
                let currentCategories = mainGradebooklist.category;
                
                let defaultcat = defaultCategories && defaultCategories.filter(val => val.cc_category_id === categoryId);
                let changedValues = [];
                getClassGradebookList?.students?.map(val => {
                    if (val.grading_event_student_color_code && val.grading_event_student_color_code !== "") {
                        changedValues.push(val)
                    } else if (val.grading_event_student_comments && val.grading_event_student_comments !== "") {
                        changedValues.push(val)
                    } else if (val.grading_event_student_score && val.grading_event_student_score !== "") {
                        changedValues.push(val)
                    }
                })
                if (changedValues && changedValues.length > 0) {
                    setShowConfirmBreadCrumb(true);
                } else if (defaultcat.cc_category_name !== categoryName) {
                    setShowConfirmBreadCrumb(true);
                } else if (eventName !== "") {
                    setShowConfirmBreadCrumb(true);
                } else {
                    setaddGradebookPageCheck(false);
                }
            } else {

                let defaultVal = JSON.parse(sessionStorage.getItem("mainGradebooklist"));
                let defaultEventStd = [];
                let currentEventStd = [];
                let defaultcat = [];
                let currnetCat = [];
                
                defaultVal.category.map(val => {
                    if (val.cc_category_id === categoryId) {
                        defaultcat.push(val)
                        val.category_events.map(value => {
                            defaultEventStd.push(value.event_student)
                        })
                    }
                })
                mainGradebooklist.category.map(val => {
                    if (val.cc_category_id === categoryId) {
                        currnetCat.push(val)
                        val.category_events.map(value => {
                            currentEventStd.push(value.event_student)
                        })
                    }
                })

                let defaultEvent = defaultcat && defaultcat[0].category_events && defaultcat[0].category_events.filter(val => val.grading_event_id === eventId);
                let clrDiff = currentEventStd[0].filter(object1 => !defaultEventStd[0].some(object2 => object2.grading_event_student_color_code === object1.grading_event_student_color_code))
                let cmtDiff = currentEventStd[0].filter(object1 => !defaultEventStd[0].some(object2 => object2.grading_event_student_comments === object1.grading_event_student_comments))
                let scoreDiff = currentEventStd[0].filter(object1 => !defaultEventStd[0].some(object2 => object2.grading_event_student_score === object1.grading_event_student_score))

                if (clrDiff && clrDiff.length > 0 || cmtDiff.length > 0 || scoreDiff.length > 0) {
                    setShowConfirmBreadCrumb(true);
                } else if (defaultcat[0].cc_category_name !== categoryName) {
                    setShowConfirmBreadCrumb(true);
                } else if (defaultEvent[0].grading_event_name !== eventName) {
                    setShowConfirmBreadCrumb(true);
                } else {
                    setaddGradebookPageCheck(false);
                }
            }
        }
    }
    
    useEffect(() => {
        if (BackPage == "class") {
          
          setSelectedClassName(props?.location?.state?.classDetailsdata?.cc_class_name)
          setClassdata(props?.location?.state?.classDetailsdata)
          
     
        }
      }, [props])

      const GradebookAddError = (value) => {
        setIsGradebookAddError(value)
      }
    
    return (
        <div className='classGradeBookMainContainer container'>
            <Row>
                <Col md="12" className="right-section">
                    <Card className="header">
                        <Card.Body className="headerChild">
                            <div className={titleMainsDiv.join(' ')}>
                                <div className='gradebooksBackDiv'>
                                    <div className="homeIconDiv">
                                        <img className='calIcon' src={back} alt='' width='60' height='40' 
                                        onClick={() => {history.replace("/home/academic");}} 
                                        />
                                    </div>
                                    <hr className="vrLine"></hr>

                                    <div className='displayFlexGradebooks'>
                                        {
                                            BackPage == 'class' ? 
                                            <>
                                            <p className='gradebookGrades' onClick={() => { history.replace("/home/academic");}}>{AcademicData.academic_year || history.location.state.academicYear}</p>
                                        <i className="gradebooksForIcon1 material-icons">chevron_right</i>
                                        <p className='gradebookGrades' onClick={goBack}>{"Class"}</p>
                                        <i className="gradebooksForIcon1 material-icons">chevron_right</i>
                                        <p className={gradebooksGradeTitle.join(' ')} onClick={breadCumbNavigation}>Gradebook </p>
                                        {addGradebookPage && <i className="gradebooksForIcon2 material-icons">chevron_right</i>}
                                        {addGradebookPage && <p className='gradebooksBreadCrumbTitle'>{message} : {selectedClassName}</p>}
                                            </>
                                            :
                                            <>
                                            <p className='gradebookGrades' onClick={goBack}>{BackPage == "Academic Year" ? AcademicData.academic_year || history.location.state.academicYear : "Class" }</p>
                                        <i className="gradebooksForIcon1 material-icons">chevron_right</i>
                                        <p className={gradebooksGradeTitle.join(' ')} onClick={breadCumbNavigation}>Gradebook </p>
                                        {addGradebookPage && <i className="gradebooksForIcon2 material-icons">chevron_right</i>}
                                        {addGradebookPage && <p className='gradebooksBreadCrumbTitle'>{message} : {selectedClassName}</p>}
                                            </>
                                        }
                                    {/* <p className='gradebookGrades' onClick={goBack}>{BackPage == "Academic Year" ? AcademicData.academic_year || history.location.state.academicYear : "Class" }</p>
                                        <i className="gradebooksForIcon1 material-icons">chevron_right</i>
                                        <p className={gradebooksGradeTitle.join(' ')} onClick={breadCumbNavigation}>Gradebook </p>
                                        {addGradebookPage && <i className="gradebooksForIcon2 material-icons">chevron_right</i>}
                                        {addGradebookPage && <p className='gradebooksBreadCrumbTitle'>{message} : {selectedClassName}</p>} */}
                                    </div>
                                </div>
                            </div>
                            {! addGradebookPage && <div className={dropdownsGradebookMainContainer.join(' ')}>
                                <div class="iconDiv"><i className="fa fa-search searchFiltersIcon"></i></div>
                                    <SelectSearch
                                        ref={searchInput}
                                        options={options}
                                        filterOptions={handleFilter}
                                        value={BackPage == "Academic Year" ? { name: selectedClassName, value: 0 } : {name: props.location.state.classDetailsdata.cc_class_name, value: 0}}
                                        name="Workshop"
                                        placeholder="Search class*"
                                        search
                                        onChange={handleChange}
                                        disabled={BackPage == "class"   ? true : false}
                                    />
                                </div>
                            }
                            {!selectedClassName && <div className="NoShowDiv"></div>}
                            <div className="gradebook-side-buttons">
                                {selectedClassName && !addGradebookPage && mainGradebooklist && mainGradebooklist.category && mainGradebooklist.category.length > 0 && <div className="pl-2 refresh-tags text-right"><Button onClick={onRefreshCall} variant="default" className="refresh-btn pull-right">
                                    <img src={refreshIcon} alt='' className='refresh-icon' />
                                </Button>
                                    {ClassData.cc_class_share_permission !== null && ClassData.cc_class_share_permission === 'read' ?
                                        <Button variant="default" className="disabledReorder-btn pull-right">
                                            <img src={reorderIcon} alt='' className='reorder-icon' />
                                        </Button> :
                                        <Button variant="default" className={`${isDragDisabled ? "closeWhite-btn" : " reorder-btn"} pull-right`} onClick={onReorderState}>
                                            {isDragDisabled ? (
                                                <img src={closeWhiteIcon} alt='' className='reorder-icon' />
                                            ) : (
                                                <img src={reorderIcon} alt='' className='reorder-icon' />
                                            )}
                                        </Button>}</div>}
                                {selectedClassName && mainGradebooklist && mainGradebooklist?.students && mainGradebooklist?.students?.length > 0 && <div>{addGradebookPage ? <div className="pl-2 flexDisply text-right">
                                    {typeOfGradebook === 'editEvent' && <Button 
                                    onClick={() => {
                                        setshowDeleteConfirmation(true)
                                    }
                                        
                                    
                                    } 
                                    variant="default" className="deleteCatButton" disabled={ClassData?.cc_class_share_permission === 'read'}>
                                        <img src={deleteIcon} alt='' width="19" height="18" />
                                        <p className="iconTexts">Delete</p>
                                    </Button>}

                                   {/* <Button onClick={() => {changePageStatus(); dispatch(setGradebookDataState())
        const apiValue = {
            "classId": selectedClass,
            "page_no": 1
        }
        getClassGradebookRequest(apiValue);}} variant="default" className="cancelButton">
                                        <img src={closeWhiteIcon} alt='' width="22" height="33" />
                                        <p className="iconTexts">Cancel</p>
                                    </Button> */}
                                        <img onClick={() => {
                                            // if(ClassData.cc_class_share_permission === 'read') {
                                            //     return;
                                            // } else {
                                            changePageStatus(); dispatch(setGradebookDataState())
        const apiValue = {
            "classId": selectedClass || sessionStorage.getItem("cLsIdForGBSC"),
            "page_no": 1
        }
        getClassGradebookRequest(apiValue);
                                // }
                                }} style = {{width : "100px", height : "40px", marginRight : "5px", cursor :"pointer"}} src={cancelIcon} alt=''  />
                                    
                                    {/* <Button onClick={checkValidation} variant="default" className="addNewButton">
                                        <img src={tickIcon} alt='' width="18" height="12" />
                                        <p className="iconTexts">Save</p>
                                    </Button> */}
                                        {/* <img onClick={checkValidation} variant="default" className="anb" src={saveIcon} style = {{width : "100px", height : "40px", marginRight : "5px", cursor : "pointer"}} alt='' /> */}
                                        <Button onClick={checkValidation}
                                             variant="default" className="CNAG-saveCloseBtn d-flex flex-wrap justify-content-center align-items-center"
                                                style={{ background : "#2ec428", color: '#fff', width: 100, height: 40, borderRadius : 8}}
                                                disabled={!categoryName || !eventName || ClassData?.cc_class_share_permission === 'read'}
                                                >
                                                    {/* <img src={saveIcon} alt='' /> */}
                                                    <img src={tickIcon} alt='' />&nbsp; Save
                                                </Button>
                                </div>
                                    :
                                    <div className="pl-2">
                                       {(ClassData.cc_class_share_permission !== null && ClassData.cc_class_share_permission === 'read') || ClassData.student_count < 1 ? <Button variant="primary" className="disabledAddButton">
                                            <img src={addIcon} alt='' className='plus-img' /> Grading Event
                                        </Button> :
                                            <Button onClick={addNewPageOpen} variant="primary" className="addButton">
                                                <img src={addIcon} alt='' className='plus-img' /> Grading Event
                                            </Button>}
                                    </div>}</div>}
                            </div>
                        </Card.Body>
                    </Card>
                    {selectedClassName && addGradebookPage && <Card className="refresh-section mt-15 br-8">
                        <ClassGradeBookDatePicker
                            addGradebookPage={addGradebookPage}
                            ClassData={ClassData}
                            refreshCall={refreshCall}
                            reorderState={reorderState}
                            isDragDisabled={isDragDisabled}
                            categoryName={categoryName}
                            eventName={eventName}
                            date={date}
                            changeValueOfHeader={changeValueOfHeader}
                            selectDateCheck={selectDateCheck}
                            typeOfGradebook={typeOfGradebook}
                        />
                    </Card>}

                </Col>
            </Row>
            {/* ------ Loader Div----- */}
            {loader && <div>
                <DivLoader />

            </div>}
            {/* ------ Loader Div----- */}


            {!selectedClassName && BackPage == "Academic Year"  && <div className='containersDiv'>
                <img src={arrow} alt='' className='gradebookArrowImage' />
                <div className='containerTextDiv'>
                    <span className='containerText'>Please select class to view or create gradebook</span>
                </div>
            </div>}

            {selectedClassName && ClassData.student_count < 1 && <div className='gradeContainersDiv'>
                <p className='gradeContainerTextNoStd'>Please add students to the class to use gradebook module.</p>
            </div>}

            <Row><Col className="MainColTableGradebook" md="12">
                {selectedClassName && ClassData.student_count > 0 && <div>

                    {addGradebookPage ?

                        <ClassGradeBookAddStudent
                            studentsList={allStudentList}
                            updateClassGradebookColorRequest={updateClassGradebookColorRequest}
                            updateClassGradebookInputRequest={updateClassGradebookInputRequest}
                            updateClassGradebookAllColorRequest={updateClassGradebookAllColorRequest}
                            ClassData={ClassData}
                            GradebookAddError={GradebookAddError}

                        />
                        :
                        <ScrollSync>
                            <ClassGradeBookTable
                                ClassData={ClassData}
                                AcademicData={AcademicData}
                                history={history}
                                isDragDisabled={isDragDisabled}
                                getClassGradebookList={mainGradebooklist}
                                updateClassGradebookStudentsRequest={updateClassGradebookStudentsRequest}
                                updateClassGradebookIdRequest={updateClassGradebookIdRequest}
                                loader={loader}
                                changeGradebookType={changeGradebookType}
                                setCategoryIdCheck={setCategoryIdCheck}
                                setCategoryNameCheck={setCategoryNameCheck}
                                setEventNameCheck={setEventNameCheck}
                                setEventIdCheck={setEventIdCheck}
                                setdateCheck={setdateCheck}
                                updateClassGradebookPopoverColorRequest={updateClassGradebookPopoverColorRequest}
                                updateClassGradebookPopoverInputRequest={updateClassGradebookPopoverInputRequest}
                                addClassGradebookPopoverDataRequest={addClassGradebookPopoverDataRequest}
                                selectedStudentForPopoverDetails={selectedStudentForPopoverDetails}
                                updateClassGradebookScoreCommentRequest={updateClassGradebookScoreCommentRequest}
                                updateClassGradebookDragDataRequest={updateClassGradebookDragDataRequest}
                                updateClassGradebookDragEventDataRequest={updateClassGradebookDragEventDataRequest}
                                openClassGradebookPopoverRequest={openClassGradebookPopoverRequest}
                                getStudentGradebookNullRequest={getStudentGradebookNullRequest}
                            />

                        </ScrollSync>
                    }

                </div>}
            </Col></Row>


            {showPopUp && <UpgradePopUp handleClosePopUp={handleClosePopUp} />}
            {/* class Confirmation Modal */}
            <Modal centered className="gradebookClass-modal-warning br-8" show={showClassConfirm} onHide={() => { setShowClassConfirm(false) }}>
                <Modal.Body>
                    <div>
                        <img src={warningIcon} alt='' className='warningIcon' />
                        <p className="waitTitle">Wait!</p>
                        <div className="modalText"><p>Do you want to change the class, your modified data would be lost.</p></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="cancelAtt-btn"
                        onClick={cancelClassWarnModal}
                    >
                        Cancel
                    </Button>
                    <Button variant="default" className="getReport-btn"
                        onClick={closeClassWarnModal}
                    >
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* class Confirmation Modal */}
            {/* Confirmation Modal */}
            <Modal centered className="gradebook-modal-warning br-8" show={showConfirm} onHide={() => { setShowConfirm(false) }}>
                <Modal.Body>
                    <div>
                        <img src={warningIcon} alt='' className='warningIcon' />
                        <p className="waitTitle">Wait!</p>
                        <div className="modalText"><p>Do you want to go back, your data will be lost.</p></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="cancelAtt-btn"
                        onClick={cancelWarnModal}
                    >
                        Cancel
                    </Button>
                    <Button variant="default" className="getReport-btn"
                        onClick={closeWarnModal}
                    >
                        Go Back
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Confirmation Modal */}
            {/* breadCrumb Modal */}
            <Modal centered className="gradebook-modal-warning br-8" show={showConfirmBreadCrumb} onHide={() => { setShowConfirmBreadCrumb(false) }}>
                <Modal.Body>
                    <div>
                        <img src={warningIcon} alt='' className='warningIcon' />
                        <p className="waitTitle">Wait!</p>
                        <div className="modalText"><p>Do you want to go back, your data will be lost.</p></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="cancelAtt-btn"
                        onClick={cancelBreadcumbModal}
                    >
                        Cancel
                    </Button>
                    <Button variant="default" className="getReport-btn"
                        onClick={closeBreadcumbModal}
                    >
                        Go Back
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* breadCrumb Modal */}

            {/* Delete Modal */}
            <Modal centered className="gradebook-event-modal  br-8" show={showDeleteConfirmation} onHide={() => { setshowDeleteConfirmation(false) }}>
                <Modal.Body>
                    <div>
                        <img src={warningIcon} alt='' className='warningIcon' />
                        <p className="waitTitle">Wait!</p>
                        <div className="modalText"><p>Are you sure, you want to delete all the Grading Event?</p></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className="cancelAtt-btn"
                        onClick={() => {
                            setshowDeleteConfirmation(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="warning" className="deleteEventButton"
                        onClick={() => {deleteCategoryEvent()}}
                    >
                       Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}
ClassGradeBookDashboard.propTypes = {
    getClassGradebookRequest: PropTypes.func,
    openClassGradebookPopoverRequest: PropTypes.func,
    NewClassGradebookRequest: PropTypes.func,
    updateClassGradebookDragDataRequest: PropTypes.func,
    updateClassGradebookScoreCommentRequest: PropTypes.func,
    addClassGradebookPopoverDataRequest: PropTypes.func,
    updateClassGradebookPopoverInputRequest: PropTypes.func,
    updateClassGradebookPopoverColorRequest: PropTypes.func,
    updateClassGradebookColorRequest: PropTypes.func,
    updateClassGradebookAllColorRequest: PropTypes.func,
    addNewClassGradebookEventRequest: PropTypes.func,
    updateClassGradebookInputRequest: PropTypes.func,
    addNewClassGradebookCategoryRequest: PropTypes.func,
    updateClassGradebookIdRequest: PropTypes.func,
    deleteClassGradebookEventRequest: PropTypes.func,
    updateClassGradebookEventRequest: PropTypes.func,
    updateClassGradebookStudentsRequest: PropTypes.func,
    updateClassGradebookDragEventDataRequest: PropTypes.func,
    getClassGradebookList: PropTypes.object,
    history: PropTypes.object,
    loader: PropTypes.bool,
    getClassNoteNullRequest: PropTypes.func,


};

export default ClassGradeBookDashboard


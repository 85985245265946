import { call, takeLatest, put } from 'redux-saga/effects';
import UserLessonPlanReducer from 'Redux/Reducers/ClassReducer/classLessonPlanReducer';
import { createNotification } from 'Config/notificationtoast';
import {
    GET_VIEW_LESSON_PLAN_LIST,
    ADD_LESSON_PLAN,
    GET_VIEW_QUESTION_LIST,
    COPY_LESSON_PLAN,
    DELETE_LESSON_PLAN,
    VIEW_LESSON_PLAN_DETAILS,
    SAVE_EXECUTION_LESSON_PLAN,
    SAVE_EVALUATION_LESSON_PLAN,
    DELETE_DYNAMIC_QUESTION_LESSON_PLAN,
    GET_VIEW_IMPORT_LESSON_PLAN_LIST,
    GET_IMPORT_TOPIC_LIST,
    SAVE_IMPORT_LESSON_PLAN_RATING,
    GET_LESSON_PLAN_SCHOOL_TEACHER,
    SHARE_LESSON_PLAN,
    IMPORT_LESSON_PLAN,
    GET_LP_TEXT
} from 'Actions/constants';

import {
    GetViewLessonPlan_API,
    AddLessonPlan_API,
    GetViewQuestionsList_API,
    CopyLesssonPlan_API,
    DeleteLesssonPlan_API,
    ViewLesssonPlanDetails_API,
    SaveExecution_API,
    SaveEvaluation_API,
    DeleteDynamicQuestion_API,
    ImportLessonPlan_API,
    GetViewImportLessonPlan_API,
    GetImportTopicList_API,
    SaveImportLessonPlanRating_API,
    GetSchoolTeacher_API,
    ShareLessonPlan_API,
    getLpText_API
} from 'Config/Api';
import { getImage, getLessonPlanImage } from "Config/commonFirebaseImage";
import { chroniclecloud_code } from 'Config/Constant';
import { decryptData, encryptData, logOut } from 'Utils/Helper';
import request from 'Config/request';
import { setClassLessonPlanState } from 'Actions/ClassAction/classAction';


export function* userClassLessonPlanModuleSaga() {
    yield takeLatest(GET_VIEW_LESSON_PLAN_LIST, getViewLessonPlanList);
    yield takeLatest(ADD_LESSON_PLAN, addLessonPlan);
    yield takeLatest(GET_VIEW_QUESTION_LIST, getViewQuestionList);
    yield takeLatest(COPY_LESSON_PLAN, copyLessonPlan);
    yield takeLatest(DELETE_LESSON_PLAN, deleteLessonPlan);
    yield takeLatest(VIEW_LESSON_PLAN_DETAILS, viewLessonPlanDetails);
    yield takeLatest(SAVE_EXECUTION_LESSON_PLAN, saveExecutionLessonPlan);
    yield takeLatest(SAVE_EVALUATION_LESSON_PLAN, saveEvaluationLessonPlan);
    yield takeLatest(DELETE_DYNAMIC_QUESTION_LESSON_PLAN, deleteDynamicQuestionLessonPlan);
    yield takeLatest(GET_VIEW_IMPORT_LESSON_PLAN_LIST, getViewImportLessonPlanList);
    yield takeLatest(GET_IMPORT_TOPIC_LIST, getImportTopicList);
    yield takeLatest(SAVE_IMPORT_LESSON_PLAN_RATING, saveImportLessonPlanRating);
    yield takeLatest(GET_LESSON_PLAN_SCHOOL_TEACHER, getLessonPlanSchoolTeacher);
    yield takeLatest(SHARE_LESSON_PLAN, shareLessonPlan);
    yield takeLatest(IMPORT_LESSON_PLAN, importLessonPlan);
    yield takeLatest(GET_LP_TEXT, getLpText);
}

function* getViewLessonPlanList(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetViewLessonPlan_API}?user_id=${data.cc_user_id}&class_id=${action.data.class_id}&plan_status=${action.data.plan_status}&order_by=${action.data.order_by}&search_data=${action.data.search_data}&platform=web`
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "plan_status": action.data.plan_status,
        "order_by": action.data.order_by
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            yield put(UserLessonPlanReducer.getViewLessonPlanListSuccess(MainDecode));
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.getViewLessonPlanListFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.getViewLessonPlanListFailure(false));
    }
}

function* addLessonPlan(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = AddLessonPlan_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "role" : data.user_type,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        'topic': action.data.topic,
        'title': action.data.title,
        'planned_at': action.data.planned_at,
        'notification_minutes': action.data.notification_minutes,
        'notification_fixed_time': action.data.notification_fixed_time,
        'other_urls': action.data.other_urls,
        'question_answer_array': action.data.question_answer_array,
        'attachment_details_array': action.data.attachment_details_array,
        'lesson_plan_id': action.data.selectionType != "addNew" ? action.data.lesson_plan_id : "",
        'message' : action.data.message,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            sessionStorage.setItem("LID",MainDecode?.data.lesson_plan_id)
            sessionStorage.removeItem('LPPlaiingQuestion')
            sessionStorage.removeItem('LPAudioList')
            sessionStorage.removeItem('LPTopic')
            sessionStorage.removeItem('LPTitle')
            sessionStorage.removeItem('LPUrllist')
            sessionStorage.removeItem('LPReminderTime')
            sessionStorage.removeItem('LPNotification')
            sessionStorage.removeItem('LPFormatDate')
            sessionStorage.removeItem('LPDocList')
            sessionStorage.removeItem('LPMinutes')
            sessionStorage.removeItem('LPPictureVideoList')     
            yield put(UserLessonPlanReducer.addLessonPlanSuccess(MainDecode));
            // action.data.history.goBack()
            let data = {
                class_id : action.data.class_id,
                plan_status : 'all',
                order_by : 'recent_modified',
                search_data : ""
            }
            yield put(UserLessonPlanReducer.viewLessonPlanDetails(data));
            yield put(UserLessonPlanReducer.getViewLessonPlanList(data));
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.addLessonPlanFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.addLessonPlanFailure(false));
    }
}
function* getViewQuestionList(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetViewQuestionsList_API}?user_id=${data.cc_user_id}&platform=web`;
    const BODY = JSON.stringify({
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserLessonPlanReducer.getViewQuestionListSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.getViewQuestionListFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.getViewQuestionListFailure(false));
    }
}

function* copyLessonPlan(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${CopyLesssonPlan_API}?user_id=${data.cc_user_id}&class_id=${action.data.class_id}&lesson_plan_id=${action.data.lesson_plan_id}&platform=web`;
    const BODY = JSON.stringify({
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "lesson_plan_id": action.data.lesson_plan_id,
        "class_id": action.data.class_id,
        "platform" : "web"
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserLessonPlanReducer.copyLessonPlanSuccess(MainDecode));
            createNotification('success', MainDecode.message);
            // window.location.reload()
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.copyLessonPlanFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.copyLessonPlanFailure(false));
    }
}

function* deleteLessonPlan(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = DeleteLesssonPlan_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "lesson_plan_id": action.data.lesson_plan_id,
        "class_id": action.data.class_id,
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            // yield put(UserLessonPlanReducer.setClassLessonPlanState())
            // dispatch(setClassLessonPlanState())
            yield put(UserLessonPlanReducer.deleteLessonPlanSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.deleteLessonPlanFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.deleteLessonPlanFailure(false));
    }
}
function* viewLessonPlanDetails(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${ViewLesssonPlanDetails_API}?user_id=${data.cc_user_id}&class_id=${action.data.class_id || sessionStorage.getItem("CID")}&lesson_plan_id=${action.data.lesson_plan_id || sessionStorage.getItem("LID")}&platform=web`;
    const BODY = JSON.stringify({
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "lesson_plan_id": action.data.lesson_plan_id,
        "class_id": action.data.class_id
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);

        if (MainDecode.status == 200) {
            
                for ( let attachDetails of MainDecode.data.attachment_details_array) {
                if (attachDetails.cc_attachment_url) {
                    if (!(attachDetails.cc_attachment_url.includes("https") === true)) {
                        attachDetails.cc_attachment_fullurl = yield getLessonPlanImage(attachDetails.cc_attachment_url, action.data.class_id);
                    }
                }
            }
            
            
            yield put(UserLessonPlanReducer.viewLessonPlanDetailsSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.viewLessonPlanDetailsFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.viewLessonPlanDetailsFailure(false));
    }
}

function* saveExecutionLessonPlan(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    let newQuestionArr = action.data.question_answer_array.map(item => ({
        ...item,
        answer : !item.answer ? "" : item.answer 
    }))
    const URL = SaveExecution_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        'question_answer_array': newQuestionArr,
        'lesson_plan_id': action.data.lesson_plan_id || sessionStorage.getItem("LID"),
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserLessonPlanReducer.saveExecutionLessonPlanSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.saveExecutionLessonPlanFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.saveExecutionLessonPlanFailure(false));
    }
}

function* saveEvaluationLessonPlan(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    let newQuestionArr = action.data.question_answer_array.map(item => ({
        ...item,
        answer : !item.answer ? "" : item.answer 
    }))
    const URL = SaveEvaluation_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        'question_answer_array': newQuestionArr,
        'lesson_plan_id': action.data.lesson_plan_id || sessionStorage.getItem("LID"),
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserLessonPlanReducer.saveEvaluationLessonPlanSuccess(MainDecode));
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.saveEvaluationLessonPlanFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.saveEvaluationLessonPlanFailure(false));
    }
}

function* deleteDynamicQuestionLessonPlan(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = DeleteDynamicQuestion_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        'view_tab': action.data.view_tab,
        'lesson_plan_id': action.data.lesson_plan_id,
        'id': action.data.id,
        "platform"  : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserLessonPlanReducer.deleteDynamicQuestionLessonPlanSuccess(MainDecode));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.deleteDynamicQuestionLessonPlanFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.deleteDynamicQuestionLessonPlanFailure(false));
    }
}

function* getViewImportLessonPlanList(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetViewImportLessonPlan_API}?user_id=${data.cc_user_id}&class_id=${''}&sharing_type=${action.data.sharing_type}&topic=${action.data.topic}&keyword=${action.data.keyword}&platform=web`;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "sharing_type": action.data.sharing_type,
        // "shared_plan": action.data.shared_plan,
        "topic": action.data.topic,
        "keyword": action.data.keyword,
        "platform" : "web"
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserLessonPlanReducer.getViewImportLessonPlanListSuccess(MainDecode));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.getViewImportLessonPlanListFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.getViewImportLessonPlanListFailure(false));
    }
}

function* getImportTopicList(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetImportTopicList_API}?user_id=${data.cc_user_id}&class_id=${action.data.class_id}&platform=web`;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserLessonPlanReducer.getImportTopicListSuccess(MainDecode));
            // action.data.history.goBack()
            createNotification('success', MainDecode.message);
            // action.data.setShowTeacherListComp(true)
            // action.data.setImportViewPage(false)
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.getImportTopicListFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.getImportTopicListFailure(false));
    }
}

function* saveImportLessonPlanRating(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = SaveImportLessonPlanRating_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "lesson_plan_id": action.data.lesson_plan_id,
        "rating": action.data.rating,
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserLessonPlanReducer.saveImportLessonPlanRatingSuccess(MainDecode));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.saveImportLessonPlanRatingFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.saveImportLessonPlanRatingFailure(false));
    }
}

function* importLessonPlan(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = ImportLessonPlan_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        'lesson_plan_id': action.data.lesson_plan_id,
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserLessonPlanReducer.importLessonPlanSuccess(MainDecode));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.importLessonPlanFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.importLessonPlanFailure(false));
    }
}

function* getLessonPlanSchoolTeacher(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${GetSchoolTeacher_API}?user_id=${data.cc_user_id}&school_name=${data.cc_user_school_name}&page=${action.data.page_no}&platform=web`;
    const BODY = JSON.stringify({
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "school_name": data.cc_user_school_name,
        "page_no": action.data.page_no,
    })
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {

            for (let shareData of MainDecode.data.teacher_data) {
                if (shareData.cc_user_pic) {
                    if (!(shareData.cc_user_pic.includes("https") === true)) {
                        shareData.cc_user_pic = yield getImage(shareData.cc_user_pic);
                    }
                }
            }
            yield put(UserLessonPlanReducer.getLessonPlanSchoolTeacherSuccess(MainDecode));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.getLessonPlanSchoolTeacherFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.getLessonPlanSchoolTeacherFailure(false));
    }
}

function* shareLessonPlan(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = ShareLessonPlan_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        'lesson_plan_id': action.data.lesson_plan_id,
        'sharing_type': action.data.sharing_type,
        'shared_user_ids': action.data.shared_user_ids,
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            yield put(UserLessonPlanReducer.shareLessonPlanSuccess(MainDecode));
            createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.shareLessonPlanFailure(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.shareLessonPlanFailure(false));
    }
}

function* getLpText(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = getLpText_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        // "role" : data.user_type,
        "user_id": `${data.cc_user_id}`,
        // "device_udid": sessionStorage.getItem('UDID'),
        "plan_title": action?.data?.plan_title,
        "plan_topic": action?.data?.plan_topic,
        "platform": "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status === 200) {
            sessionStorage.setItem("LID",MainDecode?.data.lesson_plan_id)
            // sessionStorage.removeItem('LPPlaiingQuestion')
            // sessionStorage.removeItem('LPAudioList')
            // sessionStorage.removeItem('LPTopic')
            // sessionStorage.removeItem('LPTitle')
            // sessionStorage.removeItem('LPUrllist')
            // sessionStorage.removeItem('LPReminderTime')
            // sessionStorage.removeItem('LPNotification')
            // sessionStorage.removeItem('LPFormatDate')
            // sessionStorage.removeItem('LPDocList')
            // sessionStorage.removeItem('LPMinutes')
            // sessionStorage.removeItem('LPPictureVideoList')     
            yield put(UserLessonPlanReducer.getLpTextSuccess(MainDecode));
            // action.data.history.goBack()
            // let data = {
            //     class_id : action.data.class_id,
            //     plan_status : 'all',
            //     order_by : 'recent_modified',
            //     search_data : ""
            // }
            // yield put(UserLessonPlanReducer.viewLessonPlanDetails(data));
            // yield put(UserLessonPlanReducer.getViewLessonPlanList(data));
            // createNotification('success', MainDecode.message);
        } else {
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
            createNotification('error', MainDecode.message);
            yield put(UserLessonPlanReducer.getLpTextError(MainDecode));
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserLessonPlanReducer.getLpTextError(false));
    }
}

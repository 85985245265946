import React, { useRef } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as moment from 'moment';
import * as routes from "Router/RoutesURL";
import { getDaysdiff } from "Utils/Helper";
import './ClassNoteStudentProfile.scss';
import forkIcon from 'Assets/NoteModule/forkIcon.svg';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentClasses, getStudentNote } from 'Actions/NoteAction/studentNoteAction';

const ClassNoteStudentProfile = (props) => {
    const { classNotes, classNotesDetails, enabledCheck, pageSet, totalCount, selectedStudentsForGroupNote, ClassData, AcademicDetails, getData, history, selectedClassName, selectedClassName2 } = props;
    const dispatch = useDispatch()
    const { classListData } = history.location.state;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isHovered, setIsHovered] = useState({});
    const [classNotesChildData, setClassNotesChildData] = useState([])
    const [studentInfoData, setStudentInfoData] = useState(null)
    const [isRedirectToNotes, setIsRedirectToNotes] = useState(false)
    const DataRef = useRef(null);
    

    const handleScroll = () => {
       
        const { scrollTop, clientHeight, scrollHeight } = DataRef?.current;
    //     console.log(scrollTop + clientHeight == scrollHeight)
    //     setStudentDivHeight(clientHeight)
    //     console.log(176, ClassList.length)
    //     console.log(177, ClassOverAllCount?.[0]?.count)
        if(classNotesChildData && classNotesChildData.length != totalCount) {
        if (parseInt(scrollTop) + clientHeight >= scrollHeight) {
          console.log('ifscroll')
          pageSet()
        //   console.log(numBoxes)
        //   console.log(223,loadMoreCount)
        //   const { data = "" } = history.location.state;
        //      let pageNoData = loadMoreCount;
        //   pageNoData++;
        //   console.log(227,pageNoData)
        //   setLoadMoreCount(pageNoData)
        //   setLoadMoreCountStatus(true);
        //   setAcademicYear(data.academic_year);
        //   setAcademicYearId(data.academic_year_id);
        //   // this.setState({
        //   //     academicYear: data.academic_year,
        //   //     academicYearId: data.academic_year_id
        //   // })
        //   // const { filter, loadMoreCount } = this.state
          
        //   const value = {
        //     filter_by: filter,
        //     page_no: pageNoData,
        //     class_academic_year: data.year_ids,
        //     limit : numBoxes
        //   };
        //   console.log(201, value)
        //     dispatch(getClassList(value));
         
        }
      }
        //  else {
        //   setIsLoading(false)
        // }
      }

    useEffect(() => {
        if(classNotes.length > 0) {
            setClassNotesChildData(classNotes)
        }
    },[classNotes])

    useEffect(() => {
        console.log('classnote')
    },[classNotesChildData])

    const toggle = () => {
        setTooltipOpen(!tooltipOpen);
    }


    const text = () => {
        alert("This is a alert Function")
    }
    const viewTimeline = (item, index) => {
        history.push(routes.STUDENTNOTE, {
            studentInfo: item,
            academicYear: AcademicDetails,
            academicYearIDFromClassNote : props?.AcademicYearId,
            classPermission: ClassData.cc_class_share_permission,
            classId: ClassData.cc_class_id  || props?.history?.location?.state?.Class_id,
            rosterClassId: ClassData.cc_class_id,
            From: "Academic",
            pageBYClass: true,
            BackPage: props.history.location.state.BackPage == "Note Select Class" ? 'Class Notes' : "Notecls",
            ClassData: ClassData,
            classListData: classListData,
            selectedClassName: selectedClassName,
            selectedClassName2: selectedClassName2
        })
    }

    const handleMouseEnter = index => {
        setIsHovered(prevState => {
            return { isHovered: { ...prevState.isHovered, [index]: 'block' } };
        });
    };

    const handleMouseLeave = index => {
        setIsHovered(prevState => {
            return { isHovered: { ...prevState.isHovered, [index]: 'none' } };
        });
    };

    const editStudent = (value) => {
        // let classNotes2 = classNotes;
        // classNotes2[index].editOpen = !classNotes2[index].editOpen;

        setClassNotesChildData(prevState => {
            return prevState.map(item => {
              //gets everything that was already in item, and updates "done"
              //else returns unmodified item
              return item.cc_student_id === value ? {...item, editOpen: !item.editOpen} : item
          })})
    }

    const createNoteHandler = (item) => {
        setStudentInfoData(item)
        const data = {
            "class_id":  ClassData.cc_class_id || sessionStorage.getItem("classIDForNotes"),
            "student_id": item?.cc_student_id,
            "filter_by": "both",
            "page_no": 1,
            "academic_id": props?.AcademicYearId
        }
        dispatch(getStudentNote(data))

        const data2 = {
            "student_id": item?.cc_student_id,
            "page_no": 1,
            "class_id": ClassData.cc_class_id || sessionStorage.getItem("classIDForNotes"),
            "filter_by": "both",
            "pageBYClass": true,
            "rosterClassId": ClassData.cc_class_id,
            "academic_id": props?.AcademicYearId
        }
        dispatch(getStudentClasses(data2))
        // this.props.getStudentClasses(data)
        setIsRedirectToNotes(true)

        
    }

    

    const getStudentNoteData = useSelector((state) => state?.studentNote?.Get_Student_Note)

    useEffect(() => {
        
        if(getStudentNoteData?.status == 200 && isRedirectToNotes) {
            history.push(routes.STUDENTADDNOTE,
            {
                pageType: 'New',
                data: '',
                studentInfo: studentInfoData,
                academicYear: history?.location?.state?.academicYear,
                academicYearIDFromClassNote : props?.AcademicYearId,
                student_enrolled_classes: getStudentNoteData?.data?.student_enrolled_classes,
                NotesData: getStudentNoteData?.data?.response_data,
                StudentAllNoteDetails: getStudentNoteData,
                class_id: ClassData?.cc_class_id,
                directAdd: "directAdd",
                classList: []
            })
        }
    },[getStudentNoteData])

    return (
        <div
        ref={DataRef} 
        // // style={{height : "calc(100vh - 110px)"}}
        style={{
          // height : StudentList.length > 0 ? `70vh` : "82vh", 
        //   height : ClassList.length > 0 ? `100vh` : "82vh",
        height : "calc(100vh - 200px)",
        // height : "100vh",
          overflowY : 'scroll',
          overflowX : 'hidden'
          }}  
         onScroll={handleScroll}
        // style={{
        //     height : "100vh",
        //     overflow : "hidden scroll"
        // }}
        >
        <Row className="StudentProfiles-CNSP">
            {classNotesChildData && classNotesChildData.length > 0 && classNotesChildData.map((item, index) => (
                // console.log(moment(new Date()) > moment(item.cc_note_reminder_date_string)),
                <Col xs="12" md="4" lg="3" className="colClass-CNSP"
                    key={index}>
                    <Card className="mt-15 br-8">
                        <Card.Body className="mainDiv-CNSP">
                            <Row className="profile-CNSP">
                                {enabledCheck ? 
                                
                                <Col xs="12" className="mb-12">
                                    <div className="img-div-CNSP cursor-pointer" onClick={() => {
                                         if (props?.ClassData?.cc_class_share_permission == 'read') {
                                            return;
                                            } else {
                                                createNoteHandler(item)
                                                }
                                    }}>
                                        {item.cc_student_pic_url ?
                                            <Card.Img className="img-CNSP" variant="top" alt='' src={item.cc_student_pic_url} />
                                            :
                                            <div className='studentProfileImageDiv-CNSP'>
                                                <div className='imageDummyRosterProfile-CNSP'>
                                                    <h5 className='rosterProfileImageText-CNSP' style={{marginBottom : 0}} >{item.cc_student_first_name.charAt(0)}{item.cc_student_last_name.charAt(0)}</h5>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="details" onClick={() => {
                                        
                                    }}>
                                        <div className="name">
                                            {item.cc_student_last_name.length >= 11 || item.cc_student_first_name.length >= 11 ?
                                                <span className='std-name-small-CNSP cursor-pointer' onClick={() => {
                                                    if (props?.ClassData?.cc_class_share_permission == 'read') {
                                            return;
                                            } else {
                                                createNoteHandler(item)
                                                }
                                                }} style={{position : 'relative', top : 5}}>
                                                    {item?.cc_student_last_name?.replace(/(.{11})..+/, "$1…")} {item.cc_student_first_name?.replace(/(.{11})..+/, "$1…")}
                                                </span> :
                                                <span className='std-name-CNSP cursor-pointer' onClick={() => {
                                                    if (props?.ClassData?.cc_class_share_permission == 'read') {
                                            return;
                                            } else {
                                                createNoteHandler(item)
                                                }
                                                }} style={{position : 'relative', top : 5}}>
                                                    {item?.cc_student_last_name?.replace(/(.{11})..+/, "$1…")} {item.cc_student_first_name?.replace(/(.{11})..+/, "$1…")}
                                                </span>
                                            }
                                            {enabledCheck && (
                                                <span className='checBoxDiv' onClick={() => { getData(item.cc_student_id) }}>
                                                    {selectedStudentsForGroupNote.includes(item.cc_student_id) === true ?
                                                        <i className="checkBoxfilter material-icons">{"radio_button_checked"}</i>
                                                        :
                                                        <i className="checkBoxfilter material-icons">{"radio_button_unchecked"}</i>
                                                    }
                                                </span>
                                            )}
                                        </div>
                                        {(ClassData.cc_class_color_wheel_display === 'yes' && item.cc_note_reminder_date !== '') ?
                                            <div className="note">
                                                <b id='Total'>Total:</b> <span className="left" ><b>{item.total_note_count}</b> <b>Notes </b></span> -
                                                {getDaysdiff(item.cc_note_reminder_date_string) === 0 && <span style={{ color: "#006400" }} className="right"> Updated Today </span>}
                                                {(getDaysdiff(item.cc_note_reminder_date_string) !== 0 && getDaysdiff(item.cc_note_reminder_date_string) < classNotesDetails.cc_user_green_note_days + 1) && <span style={{ color: "#006400" }} className="right">{getDaysdiff(item.cc_note_reminder_date_string) + ' days'}{moment(new Date()) > moment(item.cc_note_reminder_date_string) ? ' ago' : ' later'}</span>}
                                                {(getDaysdiff(item.cc_note_reminder_date_string) !== 0 && getDaysdiff(item.cc_note_reminder_date_string) > classNotesDetails.cc_user_green_note_days && getDaysdiff(item.cc_note_reminder_date_string) < classNotesDetails.cc_user_yellow_note_days + 1) && <span style={{ color: "#fdd60a" }} className="right">{getDaysdiff(item.cc_note_reminder_date_string) + ' days'}{moment(new Date()) > moment(item.cc_note_reminder_date_string) ? ' ago' : ' later'}</span>}
                                                {(getDaysdiff(item.cc_note_reminder_date_string) !== 0 && getDaysdiff(item.cc_note_reminder_date_string) > classNotesDetails.cc_user_yellow_note_days) && <span style={{ color: "#F44336" }} className="right">{getDaysdiff(item.cc_note_reminder_date_string) + ' days'}{moment(new Date()) > moment(item.cc_note_reminder_date_string) ? ' ago' : ' later'}</span>}
                                            </div>
                                            :
                                            <div className="note">
                                                <b id='Total'>Total: </b><span className="left" ><b>{item.total_note_count}</b> <b>Notes</b> </span> -
                                                {item.cc_note_reminder_date === '' && <span className="right"> No update</span>}
                                                {(item.cc_note_reminder_date !== '' && getDaysdiff(item.cc_note_reminder_date_string) === 0) && <span className="right"> Updated Today</span>}
                                                {item.cc_note_reminder_date !== '' && <span className="right">{getDaysdiff(item.cc_note_reminder_date_string) + ' days'}{moment(new Date()) > moment(item.cc_note_reminder_date_string) ? ' ago' : ' later'}</span>}
                                            </div>}
                                        <div className=''>

                                        </div>
                                    </div>
                                </Col> :
                                    <Col xs="12" className="mb-12" key={index} >
                                        <div className="img-div-CNSP cursor-pointer" onClick={() => {
                                             if (props?.ClassData?.cc_class_share_permission == 'read') {
                                            return;
                                            } else {
                                                createNoteHandler(item)
                                                }
                                        }}>
                                            {item.cc_student_pic_url ?
                                                <Card.Img className="img-CNSP" variant="top" alt='' src={item.cc_student_pic_url} />
                                                :
                                                <div className='studentProfileImageDiv-CNSP'>
                                                    <div className='imageDummyRosterProfile-CNSP'>
                                                        <h5 className='rosterProfileImageText-CNSP' style={{marginBottom : 0}}
                                                      
                                                        >{item.cc_student_first_name.charAt(0)}{item.cc_student_last_name.charAt(0)}</h5>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="details">
                                            <div className="name">
                                                {item.cc_student_last_name.length >= 11 || item.cc_student_first_name.length >= 11 ?
                                                    <span className='std-name-small-CNSP cursor-pointer' onClick={() => {
                                                         if (props?.ClassData?.cc_class_share_permission == 'read') {
                                            return;
                                            } else {
                                                createNoteHandler(item)
                                                }
                                                    }} style={{position : 'relative', top : 5}}>
                                                        {item?.cc_student_last_name?.replace(/(.{11})..+/, "$1…")} {item.cc_student_first_name?.replace(/(.{11})..+/, "$1…")}
                                                    </span> :
                                                    <span className='std-name-CNSP cursor-pointer'  onClick={() => {
                                                         if (props?.ClassData?.cc_class_share_permission == 'read') {
                                            return;
                                            } else {
                                                createNoteHandler(item)
                                                }
                                                    }} style={{position : 'relative', top : 5}}>
                                                        {item?.cc_student_last_name?.replace(/(.{11})..+/, "$1…")} {item.cc_student_first_name?.replace(/(.{11})..+/, "$1…")}
                                                    </span>
                                                }
                                                {enabledCheck && (

                                                    <span className='checBoxDiv' onClick={() => { getData(item.cc_student_id) }}>
                                                        {selectedStudentsForGroupNote.includes(item.cc_student_id) ?
                                                            <i className="checkBoxfilter material-icons">{"radio_button_checked"}</i>
                                                            :
                                                            <i className="checkBoxfilter material-icons">{"radio_button_unchecked"}</i>
                                                        }
                                                    </span>
                                                )}
                                                <div  id={`PopoverLegacy${index}`} type="button"
                                                 onClick={() => editStudent(item.cc_student_id)} 
                                                className='showMore'
                                                // className="studentMenuIcon-RD"
                                                 >
                                                    <i className="material-icons" >more_vert</i>
                                                    <UncontrolledPopover
                                                        trigger="legacy"
                                                        // trigger="click"
                                                        placement="right"
                                                        isOpen={item.editOpen}
                                                        target={`PopoverLegacy${index}`}
                                                        toggle={() => editStudent(item.cc_student_id)}
                                                        style={{ borderRadius: "5px" }}
                                                        className='viewTimeline viewStudentNoteCardPopover'
                                                        // className="'popover academicPopOver-RD"
                                                    >
                                                        <PopoverBody>
                                                            <div className='outerTooltipdiv' onClick={() => { viewTimeline(item, index) }}>
                                                                <span className='tooltipIconDiv'>
                                                                    {/* <i className="material-icons forkIcon">more_vert</i> */}
                                                                    <img src={forkIcon} alt='' className=' ' width='18' height='18' />
                                                                </span>
                                                                <span className='tooltipTextDiv'>
                                                                    <p>View Timeline</p>
                                                                </span>
                                                            </div>
                                                        </PopoverBody>
                                                    </UncontrolledPopover>
                                                </div>
                                            </div>
                                            {(ClassData.cc_class_color_wheel_display === 'yes' && item.cc_note_reminder_date !== '') ?
                                                <div className="note">
                                                    <b id='Total'>Total: </b><span className="left" ><b>{item.total_note_count}</b> <b>Notes</b> </span> -
                                                    {getDaysdiff(item.cc_note_reminder_date_string) === 0 && <span style={{ color: "#006400" }} className="right"> Updated Today </span>}
                                                    {(getDaysdiff(item.cc_note_reminder_date_string) !== 0 && getDaysdiff(item.cc_note_reminder_date_string) < classNotesDetails.cc_user_green_note_days + 1) && <span style={{ color: "#006400" }} className="right">{getDaysdiff(item.cc_note_reminder_date_string) + ' days'}{moment(new Date()) > moment(item.cc_note_reminder_date_string) ? ' ago' : ' later'}</span>}
                                                    {(getDaysdiff(item.cc_note_reminder_date_string) !== 0 && getDaysdiff(item.cc_note_reminder_date_string) > classNotesDetails.cc_user_green_note_days && getDaysdiff(item.cc_note_reminder_date_string) < classNotesDetails.cc_user_yellow_note_days + 1) && <span style={{ color: "#fdd60a" }} className="right">{getDaysdiff(item.cc_note_reminder_date_string) + ' days'}{moment(new Date()) > moment(item.cc_note_reminder_date_string) ? ' ago' : ' later'}</span>}
                                                    {(getDaysdiff(item.cc_note_reminder_date_string) !== 0 && getDaysdiff(item.cc_note_reminder_date_string) > classNotesDetails.cc_user_yellow_note_days) && <span style={{ color: "#F44336" }} className="right">{getDaysdiff(item.cc_note_reminder_date_string) + ' days'}{moment(new Date()) > moment(item.cc_note_reminder_date_string) ? ' ago' : ' later'}</span>}
                                                </div>
                                                :
                                                <div className="note">
                                                    <b id='Total'>Total:</b> <span className="left" ><b>{item.total_note_count}</b><b> Notes </b></span> -
                                                    {item.cc_note_reminder_date === '' && <span className="right"> No update</span>}
                                                    {(item.cc_note_reminder_date !== '' && getDaysdiff(item.cc_note_reminder_date_string) === 0) && <span className="right"> Updated Today</span>}
                                                    {/* {item.cc_note_reminder_date !== '' && 
                                                    // getDaysdiff(item.cc_note_reminder_date_string) === 0 && 
                                                    <span className="right">
                                                        {getDaysdiff(item.cc_note_reminder_date_string) + ' days'}{moment(new Date()) > moment(item.cc_note_reminder_date_string) ? ' ago' : ' later'}
                                                        </span>} */}
                                                        {item.cc_note_reminder_date !== '' && 
                                                    getDaysdiff(item.cc_note_reminder_date_string) > 0 && 
                                                    <span className="right">
                                                        {getDaysdiff(item.cc_note_reminder_date_string) + ' days'}{moment(new Date()) > moment(item.cc_note_reminder_date_string) ? ' ago' : ' later'}
                                                        </span>}
                                                </div>}
                                        </div>

                                    </Col>}

                                <Col xs="12">
                                    <div className="score">
                                        <span className="left"><b>Level</b>: <b>{item.cc_note_level === '' ? '-' : item.cc_note_level}</b> | <b>Score</b>: <b>{item.cc_note_score === '' ? '-' : item.cc_note_score} </b></span>
                                        <span className="right"><b>{item.reminder_note_count} Reminder Notes</b></span>
                                    </div>
                                </Col>
                                <Col xs="12" className="text" >
                                    <hr></hr>
                                    {item.checked_tag.tag_comment_text === '' ? 'No comment' : item.checked_tag.tag_comment_text}
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col >
            ))
            }
        </Row>
        </div>
    )

}
ClassNoteStudentProfile.propTypes = {
    classNotes: PropTypes.array,
    enabledCheck: PropTypes.bool,

};


export default ClassNoteStudentProfile


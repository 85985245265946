// -------------Auth Module------------
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const GET_USER_PAYMENT_DETAILS = 'GET_USER_PAYMENT_DETAILS';
export const GET_USER_PAYMENT_DETAILS_SUCCESS = 'GET_USER_PAYMENT_DETAILS_SUCCESS';
export const GET_USER_PAYMENT_DETAILS_FAILURE = 'GET_USER_PAYMENT_DETAILS_FAILURE';

export const FORGOT = 'FORGOT';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAILURE = 'FORGOT_FAILURE';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_RESET = 'REGISTER_RESET';

export const PLACE_SEARCH = 'PLACE_SEARCH';
export const PLACE_SEARCH_SUCCESS = 'PLACE_SEARCH_SUCCESS';
export const PLACE_SEARCH_FAILURE = 'PLACE_SEARCH_FAILURE';

export const SELECT_PLACE = 'SELECT_PLACE';
export const SELECT_PLACE_SUCCESS = 'SELECT_PLACE_SUCCESS';
export const SELECT_PLACE_FAILURE = 'SELECT_PLACE_FAILURE';

export const VERIFY = 'VERIFY';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'VERIFY_FAILURE';

export const RESEND_VERIFY = 'RESEND_VERIFY';
export const RESEND_VERIFY_SUCCESS = 'RESEND_VERIFY_SUCCESS';
export const RESEND_VERIFY_FAILURE = 'RESEND_VERIFY_FAILURE';

// -------------Auth Module------------

// -------------Academic Module------------
export const GET_ACADEMIC_LIST = 'GET_ACADEMIC_LIST';
export const GET_ACADEMIC_LIST_SUCCESS = 'GET_ACADEMIC_LIST_SUCCESS';
export const GET_ACADEMIC_LIST_FAILURE = 'GET_ACADEMIC_LIST_FAILURE';

export const ADD_ACADEMIC_YEARS = 'ADD_ACADEMIC_YEARS';
export const ADD_ACADEMIC_YEARS_SUCCESS = 'ADD_ACADEMIC_YEARS_SUCCESS';
export const ADD_ACADEMIC_YEARS_FAILURE = 'ADD_ACADEMIC_YEARS_FAILURE';

export const DELETE_ACADEMIC_YEARS = 'DELETE_ACADEMIC_YEARS';
export const DELETE_ACADEMIC_YEARS_SUCCESS = 'DELETE_ACADEMIC_YEARS_SUCCESS';
export const DELETE_ACADEMIC_YEARS_FAILURE = 'DELETE_ACADEMIC_YEARS_FAILURE';

export const EDIT_ACADEMIC_YEARS = 'EDIT_ACADEMIC_YEARS';
export const EDIT_ACADEMIC_YEARS_SUCCESS = 'EDIT_ACADEMIC_YEARS_SUCCESS';
export const EDIT_ACADEMIC_YEARS_FAILURE = 'EDIT_ACADEMIC_YEARS_FAILURE';

export const GET_ACADEMIC_YEARS = 'GET_ACADEMIC_YEARS';
export const GET_ACADEMIC_YEARS_SUCCESS = 'GET_ACADEMIC_YEARS_SUCCESS';
export const GET_ACADEMIC_YEARS_FAILURE = 'GET_ACADEMIC_YEARS_FAILURE';

export const GET_ACADEMIC_SCORE = 'GET_ACADEMIC_SCORE';
export const GET_ACADEMIC_SCORE_SUCCESS = 'GET_ACADEMIC_SCORE_SUCCESS';
export const GET_ACADEMIC_SCORE_FAILURE = 'GET_ACADEMIC_SCORE_FAILURE';

export const GET_ROSTER_CLASS_BY_ACADEMIC_YEAR = 'GET_ROSTER_CLASS_BY_ACADEMIC_YEAR'
export const GET_ROSTER_CLASS_BY_ACADEMIC_YEAR_SUCCESS = 'GET_ROSTER_CLASS_BY_ACADEMIC_YEAR_SUCCESS'
export const GET_ROSTER_CLASS_BY_ACADEMIC_YEAR_FAILURE = 'GET_ROSTER_CLASS_BY_ACADEMIC_YEAR_FAILURE'

export const GET_ACADEMIC_RESET = 'GET_ACADEMIC_RESET'
// -------------Academic Module------------


// -------------Profile Module------------
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
// -------------Profile Module------------


// -------------Roster Module------------
export const ADD_ROSTER = 'ADD_ROSTER';
export const ADD_ROSTER_SUCCESS = 'ADD_ROSTER_SUCCESS';
export const ADD_ROSTER_FAILURE = 'ADD_ROSTER_FAILURE';

export const GET_ROSTER_LIST = 'GET_ROSTER_LIST';
export const GET_ROSTER_LIST_SUCCESS = 'GET_ROSTER_LIST_SUCCESS';
export const GET_ROSTER_LIST_FAILURE = 'GET_ROSTER_LIST_FAILURE';

export const DELETE_ROSTER = 'DELETE_ROSTER';
export const DELETE_ROSTER_SUCCESS = 'DELETE_ROSTER_SUCCESS';
export const DELETE_ROSTER_FAILURE = 'DELETE_ROSTER_FAILURE';

export const GET_ROSTER = 'GET_ROSTER';
export const GET_ROSTER_SUCCESS = 'GET_ROSTER_SUCCESS';
export const GET_ROSTER_FAILURE = 'GET_ROSTER_FAILURE';

export const EDIT_ROSTER = 'EDIT_ROSTER';
export const EDIT_ROSTER_SUCCESS = 'EDIT_ROSTER_SUCCESS';
export const EDIT_ROSTER_FAILURE = 'EDIT_ROSTER_FAILURE';



export const ADD_ROSTER_CONTACT = 'ADD_ROSTER_CONTACT';
export const ADD_ROSTER_CONTACT_SUCCESS = 'ADD_ROSTER_CONTACT_SUCCESS';
export const ADD_ROSTER_CONTACT_FAILURE = 'ADD_ROSTER_CONTACT_FAILURE';

export const EDIT_ROSTER_CONTACT = 'EDIT_ROSTER_CONTACT';
export const EDIT_ROSTER_CONTACT_SUCCESS = 'EDIT_ROSTER_CONTACT_SUCCESS';
export const EDIT_ROSTER_CONTACT_FAILURE = 'EDIT_ROSTER_CONTACT_FAILURE';

export const DELETE_ROSTER_CONTACT = 'DELETE_ROSTER_CONTACT';
export const DELETE_ROSTER_CONTACT_SUCCESS = 'DELETE_ROSTER_CONTACT_SUCCESS';
export const DELETE_ROSTER_CONTACT_FAILURE = 'DELETE_ROSTER_CONTACT_FAILURE';



export const ADD_ROSTER_INFO = 'ADD_ROSTER_INFO';
export const ADD_ROSTER_INFO_SUCCESS = 'ADD_ROSTER_INFO_SUCCESS';
export const ADD_ROSTER_INFO_FAILURE = 'ADD_ROSTER_INFO_FAILURE';

export const EDIT_ROSTER_INFO = 'EDIT_ROSTER_INFO';
export const EDIT_ROSTER_INFO_SUCCESS = 'EDIT_ROSTER_INFO_SUCCESS';
export const EDIT_ROSTER_INFO_FAILURE = 'EDIT_ROSTER_INFO_FAILURE';

export const DELETE_ROSTER_INFO = 'DELETE_ROSTER_INFO';
export const DELETE_ROSTER_INFO_SUCCESS = 'DELETE_ROSTER_INFO_SUCCESS';
export const DELETE_ROSTER_INFO_FAILURE = 'DELETE_ROSTER_INFO_FAILURE';



export const ADD_LINK_ROSTER = 'ADD_LINK_ROSTER';
export const ADD_LINK_ROSTER_SUCCESS = 'ADD_LINK_ROSTER_SUCCESS';
export const ADD_LINK_ROSTER_FAILURE = 'ADD_LINK_ROSTER_FAILURE';


export const GET_ROSTER_ENROLLED = 'GET_ROSTER_ENROLLED';
export const GET_ROSTER_ENROLLED_SUCCESS = 'GET_ROSTER_ENROLLED_SUCCESS';
export const GET_ROSTER_ENROLLED_FAILURE = 'GET_ROSTER_ENROLLED_FAILURE';

export const EDIT_ROSTER_ENROLLED = 'EDIT_ROSTER_ENROLLED';
export const EDIT_ROSTER_ENROLLED_SUCCESS = 'EDIT_ROSTER_ENROLLED_SUCCESS';
export const EDIT_ROSTER_ENROLLED_FAILURE = 'EDIT_ROSTER_ENROLLED_FAILURE';

export const SEARCH_ROSTER = 'SEARCH_ROSTER';
export const SEARCH_ROSTER_SUCCESS = 'SEARCH_ROSTER_SUCCESS';
export const SEARCH_ROSTER_FAILURE = 'SEARCH_ROSTER_FAILURE';

export const IMPORT_ROSTER = 'IMPORT_ROSTER';
export const IMPORT_ROSTER_SUCCESS = 'IMPORT_ROSTER_SUCCESS';
export const IMPORT_ROSTER_FAILURE = 'IMPORT_ROSTER_FAILURE';


// -------------Roster Module------------

// -------------Class Module------------
export const GET_CLASS_LIST = 'GET_CLASS_LIST';
export const GET_CLASS_LIST_SUCCESS = 'GET_CLASS_LIST_SUCCESS';
export const GET_CLASS_LIST_FAILURE = 'GET_CLASS_LIST_FAILURE';

export const ADD_CLASS = 'ADD_CLASS';
export const ADD_CLASS_SUCCESS = 'ADD_CLASS_SUCCESS';
export const ADD_CLASS_FAILURE = 'ADD_CLASS_FAILURE';

export const EDIT_CLASS = 'EDIT_CLASS';
export const EDIT_CLASS_SUCCESS = 'EDIT_CLASS_SUCCESS';
export const EDIT_CLASS_FAILURE = 'EDIT_CLASS_FAILURE';

export const EDIT_CLASS_COLOR_WHEEL = 'EDIT_CLASS_COLOR_WHEEL';
export const EDIT_CLASS_COLOR_WHEEL_SUCCESS = 'EDIT_CLASS_COLOR_WHEEL_SUCCESS';
export const EDIT_CLASS_COLOR_WHEEL_FAILURE = 'EDIT_CLASS_COLOR_WHEEL_FAILURE';

export const DELETE_CLASS = 'DELETE_CLASS';
export const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS';
export const DELETE_CLASS_FAILURE = 'DELETE_CLASS_FAILURE';

export const SEARCH_CLASS = 'SEARCH_CLASS';
export const SEARCH_CLASS_SUCCESS = 'SEARCH_CLASS_SUCCESS';
export const SEARCH_CLASS_FAILURE = 'SEARCH_CLASS_FAILURE';

export const ADD_CLASS_ROSTER = 'ADD_CLASS_ROSTER';
export const ADD_CLASS_ROSTER_SUCCESS = 'ADD_CLASS_ROSTER_SUCCESS';
export const ADD_CLASS_ROSTER_FAILURE = 'ADD_CLASS_ROSTER_FAILURE';

export const GET_CLASS_ROSTER_LIST = 'GET_CLASS_ROSTER_LIST';
export const GET_CLASS_ROSTER_LIST_SUCCESS = 'GET_CLASS_ROSTER_LIST_SUCCESS';
export const GET_CLASS_ROSTER_LIST_FAILURE = 'GET_CLASS_ROSTER_LIST_FAILURE';

export const DELETE_CLASS_ROSTER = 'DELETE_CLASS_ROSTER';
export const DELETE_CLASS_ROSTER_SUCCESS = 'DELETE_CLASS_ROSTER_SUCCESS';
export const DELETE_CLASS_ROSTER_FAILURE = 'DELETE_CLASS_ROSTER_FAILURE';

export const UPDATE_ROSTER_LEVEL = 'UPDATE_ROSTER_LEVEL';
export const UPDATE_ROSTER_LEVEL_SUCCESS = 'UPDATE_ROSTER_LEVEL_SUCCESS';
export const UPDATE_ROSTER_LEVEL_FAILURE = 'UPDATE_ROSTER_LEVEL_FAILURE';

export const SEARCH_CLASS_ROSTER = 'SEARCH_CLASS_ROSTER';
export const SEARCH_CLASS_ROSTER_SUCCESS = 'SEARCH_CLASS_ROSTER_SUCCESS';
export const SEARCH_CLASS_ROSTER_FAILURE = 'SEARCH_CLASS_ROSTER_FAILURE';



export const GET_NOT_SELECTED_ROSTERS = 'GET_NOT_SELECTED_ROSTERS';
export const GET_NOT_SELECTED_ROSTERS_SUCCESS = 'GET_NOT_SELECTED_ROSTERS_SUCCESS';
export const GET_NOT_SELECTED_ROSTERS_FAILURE = 'GET_NOT_SELECTED_ROSTERS_FAILURE';

export const ADD_SELECTED_ROSTERS = 'ADD_SELECTED_ROSTERS';
export const ADD_SELECTED_ROSTERS_SUCCESS = 'ADD_SELECTED_ROSTERS_SUCCESS';
export const ADD_SELECTED_ROSTERS_FAILURE = 'ADD_SELECTED_ROSTERS_FAILURE';


export const IMPORT_CLASS_ROSTER = 'IMPORT_CLASS_ROSTER';
export const IMPORT_CLASS_ROSTER_SUCCESS = 'IMPORT_CLASS_ROSTER_SUCCESS';
export const IMPORT_CLASS_ROSTER_FAILURE = 'IMPORT_CLASS_ROSTER_FAILURE';


// -------------Class Module------------

// -------------Tag Module------------

export const GET_CLASS_TAG = 'GET_CLASS_TAG';
export const GET_CLASS_TAG_SUCCESS = 'GET_CLASS_TAG_SUCCESS';
export const GET_CLASS_TAG_FAILURE = 'GET_CLASS_TAG_FAILURE';

export const ADD_CLASS_TAG = 'ADD_CLASS_TAG';
export const ADD_CLASS_TAG_SUCCESS = 'ADD_CLASS_TAG_SUCCESS';
export const ADD_CLASS_TAG_FAILURE = 'ADD_CLASS_TAG_FAILURE';

export const EDIT_CLASS_TAG = 'EDIT_CLASS_TAG';
export const EDIT_CLASS_TAG_SUCCESS = 'EDIT_CLASS_TAG_SUCCESS';
export const EDIT_CLASS_TAG_FAILURE = 'EDIT_CLASS_TAG_FAILURE';

export const DELETE_CLASS_TAG = 'DELETE_CLASS_TAG';
export const DELETE_CLASS_TAG_SUCCESS = 'DELETE_CLASS_TAG_SUCCESS';
export const DELETE_CLASS_TAG_FAILURE = 'DELETE_CLASS_TAG_FAILURE';

export const GET_SPEED_NOTE = 'GET_SPEED_NOTE';
export const GET_SPEED_NOTE_SUCCESS = 'GET_SPEED_NOTE_SUCCESS';
export const GET_SPEED_NOTE_FAILURE = 'GET_SPEED_NOTE_FAILURE';

export const GET_NOTE_CORE_STANDARD = 'GET_NOTE_CORE_STANDARD';
export const GET_NOTE_CORE_STANDARD_SUCCESS = 'GET_NOTE_CORE_STANDARD_SUCCESS';
export const GET_NOTE_CORE_STANDARD_FAILURE = 'GET_NOTE_CORE_STANDARD_FAILURE';

export const GET_NOTE_SPIRAL_TAG = 'GET_NOTE_SPIRAL_TAG';
export const GET_NOTE_SPIRAL_TAG_SUCCESS = 'GET_NOTE_SPIRAL_TAG_SUCCESS';
export const GET_NOTE_SPIRAL_TAG_FAILURE = 'GET_NOTE_SPIRAL_TAG_FAILURE';

export const IMPORT_SPEED_NOTE = 'IMPORT_SPEED_NOTE';
export const IMPORT_SPEED_NOTE_SUCCESS = 'IMPORT_SPEED_NOTE_SUCCESS';
export const IMPORT_SPEED_NOTE_FAILURE = 'IMPORT_SPEED_NOTE_FAILURE';
// -------------Tag Module------------

// -------------class Core Standard Module------------

export const GET_CLASS_CORE_STANDARD = 'GET_CLASS_CORE_STANDARD';
export const GET_CLASS_CORE_STANDARD_SUCCESS = 'GET_CLASS_CORE_STANDARD_SUCCESS';
export const GET_CLASS_CORE_STANDARD_FAILURE = 'GET_CLASS_CORE_STANDARD_FAILURE';

export const GET_CORE_STANDARD = 'GET_CORE_STANDARD';
export const GET_CORE_STANDARD_SUCCESS = 'GET_CORE_STANDARD_SUCCESS';
export const GET_CORE_STANDARD_FAILURE = 'GET_CORE_STANDARD_FAILURE';

export const ADD_CLASS_CORE_STANDARD = 'ADD_CLASS_CORE_STANDARD';
export const ADD_CLASS_CORE_STANDARD_SUCCESS = 'ADD_CLASS_CORE_STANDARD_SUCCESS';
export const ADD_CLASS_CORE_STANDARD_FAILURE = 'ADD_CLASS_CORE_STANDARD_FAILURE';

export const IMPORT_CUSTOM_STANDARDS = 'IMPORT_CUSTOM_STANDARDS'
export const IMPORT_CUSTOM_STANDARDS_ERROR = 'IMPORT_CUSTOM_STANDARDS_ERROR'
export const IMPORT_CUSTOM_STANDARDS_SUCCESS = 'IMPORT_CUSTOM_STANDARDS_SUCCESS'

export const DELETE_CUSTOM_STANDARDS = 'DELETE_CUSTOM_STANDARDS'
export const DELETE_CUSTOM_STANDARDS_ERROR = 'DELETE_CUSTOM_STANDARDS_ERROR'
export const DELETE_CUSTOM_STANDARDS_SUCCESS = 'DELETE_CUSTOM_STANDARDS_SUCCESS'

export const EDIT_CUSTOM_STANDARDS = 'EDIT_CUSTOM_STANDARDS'
export const EDIT_CUSTOM_STANDARDS_SUCCESS = 'EDIT_CUSTOM_STANDARDS_SUCCESS'
export const EDIT_CUSTOM_STANDARDS_ERROR = 'EDIT_CUSTOM_STANDARDS_ERROR'

export const GET_LP_TEXT = 'GET_LP_TEXT'
export const GET_LP_TEXT_ERROR = 'GET_LP_TEXT_ERROR'
export const GET_LP_TEXT_SUCCESS = 'GET_LP_TEXT_SUCCESS'



// -------------Studnet Note Module------------
export const ADD_STUDENT_NOTE = 'ADD_STUDENT_NOTE';
export const ADD_STUDENT_NOTE_SUCCESS = 'ADD_STUDENT_NOTE_SUCCESS';
export const ADD_STUDENT_NOTE_FAILURE = 'ADD_STUDENT_NOTE_FAILURE';

export const EDIT_STUDENT_NOTE = 'EDIT_STUDENT_NOTE';
export const EDIT_STUDENT_NOTE_SUCCESS = 'EDIT_STUDENT_NOTE_SUCCESS';
export const EDIT_STUDENT_NOTE_FAILURE = 'EDIT_STUDENT_NOTE_FAILURE';


export const GET_STUDENT_NOTE = 'GET_STUDENT_NOTE';
export const GET_STUDENT_NOTE_SUCCESS = 'GET_STUDENT_NOTE_SUCCESS';
export const GET_STUDENT_NOTE_FAILURE = 'GET_STUDENT_NOTE_FAILURE';

export const DELETE_STUDENT_NOTE = 'DELETE_STUDENT_NOTE';
export const DELETE_STUDENT_NOTE_SUCCESS = 'DELETE_STUDENT_NOTE_SUCCESS';
export const DELETE_STUDENT_NOTE_FAILURE = 'DELETE_STUDENT_NOTE_FAILURE';

// -------------Class Group Note Module------------

export const ADD_GROUP_NOTE = 'ADD_GROUP_NOTE';
export const ADD_GROUP_NOTE_SUCCESS = 'ADD_GROUP_NOTE_SUCCESS';
export const ADD_GROUP_NOTE_FAILURE = 'ADD_GROUP_NOTE_FAILURE';

export const EDIT_GROUP_NOTE = 'EDIT_GROUP_NOTE';
export const EDIT_GROUP_NOTE_SUCCESS = 'EDIT_GROUP_NOTE_SUCCESS';
export const EDIT_GROUP_NOTE_FAILURE = 'EDIT_GROUP_NOTE_FAILURE';


export const GET_GROUP_NOTE = 'GET_GROUP_NOTE';
export const GET_GROUP_NOTE_SUCCESS = 'GET_GROUP_NOTE_SUCCESS';
export const GET_GROUP_NOTE_FAILURE = 'GET_GROUP_NOTE_FAILURE';

export const DELETE_GROUP_NOTE = 'DELETE_GROUP_NOTE';
export const DELETE_GROUP_NOTE_SUCCESS = 'DELETE_GROUP_NOTE_SUCCESS';
export const DELETE_GROUP_NOTE_FAILURE = 'DELETE_GROUP_NOTE_FAILURE';
export const UPGRADE_POP_UP_OPEN = 'UPGRADE_POP_UP_OPEN';
export const UPGRADE_POP_UP_OPEN_GRADEBOOK = 'UPGRADE_POP_UP_OPEN_GRADEBOOK';

// -------------Class Group Note Module------------

export const GET_STUDENT_CLASSES = 'GET_STUDENT_CLASSES';
export const GET_STUDENT_CLASSES_SUCCESS = 'GET_STUDENT_CLASSES_SUCCESS';
export const GET_STUDENT_CLASSES_FAILURE = 'GET_STUDENT_CLASSES_FAILURE';

export const SET_STUDENT_ARTIFACT = 'SET_STUDENT_ARTIFACT';
export const SET_STUDENT_ARTIFACT_SUCCESS = 'SET_STUDENT_ARTIFACT_SUCCESS';
export const SET_STUDENT_ARTIFACT_FAILURE = 'SET_STUDENT_ARTIFACT_FAILURE';

export const SET_MEDIA_ATTACHMENT_ARTIFACT = 'SET_MEDIA_ARTIFACT';
export const SET_MEDIA_ATTACHMENT_ARTIFACT_SUCCESS = 'SET_MEDIA_ATTACHMENT_ARTIFACT_SUCCESS';
export const SET_MEDIA_ATTACHMENT_ARTIFACT_FAILURE = 'SET_MEDIA_ATTACHMENT_ARTIFACT_FAILURE';

export const ADD_STUDENT_MISCELLANEOUS = 'ADD_STUDENT_MISCELLANEOUS';
export const ADD_STUDENT_MISCELLANEOUS_SUCCESS = 'ADD_STUDENT_MISCELLANEOUS_SUCCESS';
export const ADD_STUDENT_MISCELLANEOUS_FAILURE = 'ADD_STUDENT_MISCELLANEOUS_FAILURE';

export const EDIT_STUDENT_MISCELLANEOUS = 'EDIT_STUDENT_MISCELLANEOUS';
export const EDIT_STUDENT_MISCELLANEOUS_SUCCESS = 'EDIT_STUDENT_MISCELLANEOUS_SUCCESS';
export const EDIT_STUDENT_MISCELLANEOUS_FAILURE = 'EDIT_STUDENT_MISCELLANEOUS_FAILURE';

export const DELETE_STUDENT_MISCELLANEOUS = 'DELETE_STUDENT_MISCELLANEOUS';
export const DELETE_STUDENT_MISCELLANEOUS_SUCCESS = 'DELETE_STUDENT_MISCELLANEOUS_SUCCESS';
export const DELETE_STUDENT_MISCELLANEOUS_FAILURE = 'DELETE_STUDENT_MISCELLANEOUS_FAILURE';


export const GET_GROUP_NOTE_STUDENTS = 'GET_GROUP_NOTE_STUDENTS';
export const GET_GROUP_NOTE_STUDENTS_SUCCESS = 'GET_GROUP_NOTE_STUDENTS_SUCCESS';
export const GET_GROUP_NOTE_STUDENTS_FAILURE = 'GET_GROUP_NOTE_STUDENTS_FAILURE';


export const GET_GROUP_DETAILS_REQUEST = 'GET_GROUP_DETAILS_REQUEST';
export const GET_GROUP_DETAILS_SUCCESS = 'GET_GROUP_DETAILS_SUCCESS';
export const GET_GROUP_DETAILS_FAILURE = 'GET_GROUP_DETAILS_FAILURE';

export const ADD_MULTIPLE_STUDENTS_REQUEST = 'ADD_MULTIPLE_STUDENTS_REQUEST';
export const ADD_MULTIPLE_STUDENTS_SUCCESS = 'ADD_MULTIPLE_STUDENTS_SUCCESS';
export const ADD_MULTIPLE_STUDENTS_FAILURE = 'ADD_MULTIPLE_STUDENTS_FAILURE';

export const ADD_NEW_GROUP_REQUEST = 'ADD_NEW_GROUP_REQUEST';
export const ADD_NEW_GROUP_SUCCESS = 'ADD_NEW_GROUP_SUCCESS';
export const ADD_NEW_GROUP_FAILURE = 'ADD_NEW_GROUP_FAILURE';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';

export const ADD_NEW_STUDENT_IN_GROUP_REQUEST = 'ADD_NEW_STUDENT_IN_GROUP_REQUEST';
export const ADD_NEW_STUDENT_IN_GROUP_SUCCESS = 'ADD_NEW_STUDENT_IN_GROUP_SUCCESS';
export const ADD_NEW_STUDENT_IN_GROUP_FAILURE = 'ADD_NEW_STUDENT_IN_GROUP_FAILURE';

export const REMOVE_STUDENT_FROM_GROUP_REQUEST = 'REMOVE_STUDENT_FROM_GROUP_REQUEST';
export const REMOVE_STUDENT_FROM_GROUP_SUCCESS = 'REMOVE_STUDENT_FROM_GROUP_SUCCESS';
export const REMOVE_STUDENT_FROM_GROUP_FAILURE = 'REMOVE_STUDENT_FROM_GROUP_FAILURE';

export const RENAME_GROUP_REQUEST = 'RENAME_GROUP_REQUEST';
export const RENAME_GROUP_SUCCESS = 'RENAME_GROUP_SUCCESS';
export const RENAME_GROUP_FAILURE = 'RENAME_GROUP_FAILURE';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';

// export const STUDENTS_LIST_OF_NOTE = 'STUDENTS_LIST_OF_NOTE';
// export const STUDENTS_LIST_OF_NOTE_SUCCESS = 'STUDENTS_LIST_OF_NOTE_SUCCESS';
// export const STUDENTS_LIST_OF_NOTE_FAILURE = 'STUDENTS_LIST_OF_NOTE_FAILURE';
// export const NOTE_STUDENTS_LIST = 'NOTE_STUDENTS_LIST';
// export const NOTE_STUDENTS_LIST_SUCCESS = 'NOTE_STUDENTS_LIST_SUCCESS';
// export const NOTE_STUDENTS_LIST_FAILURE = 'NOTE_STUDENTS_LIST_FAILURE';

// -------------Note Module------------

// -------------Class Note Module------------
export const GET_CLASS_NOTE_REQUEST = 'GET_CLASS_NOTE_REQUEST';
export const GET_CLASS_NOTE_SUCCESS = 'GET_CLASS_NOTE_SUCCESS';
export const GET_CLASS_NOTE_FAILURE = 'GET_CLASS_NOTE_FAILURE';

export const GET_CLASS_NOTE_NULL_REQUEST = 'GET_CLASS_NOTE_NULL_REQUEST';
export const GET_CLASS_GROUP_NOTES_DATA = 'GET_CLASS_GROUP_NOTES_DATA';
export const GET_CLASS_GROUP_NOTES_DATA_ERROR = 'GET_CLASS_GROUP_NOTES_DATA_ERROR';
export const GET_CLASS_GROUP_NOTES_DATA_SUCCESS = 'GET_CLASS_GROUP_NOTES_DATA_SUCCESS';

export const CLASS_GROUP_NOTE_DELETE = 'CLASS_GROUP_NOTE_DELETE'
export const CLASS_GROUP_NOTE_DELETE_ERROR = 'CLASS_GROUP_NOTE_DELETE_ERROR'
export const CLASS_GROUP_NOTE_DELETE_SUCCESS = 'CLASS_GROUP_NOTE_DELETE_SUCCESS'

export const CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT = 'CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT'
export const CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT_ERROR = 'CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT_ERROR'
export const CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT_SUCCESS = 'CLASS_GROUP_NOTE_ATTACHEMENT_ARTIFACT_SUCCESS' 

export const DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT = 'DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT'
export const DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT_ERROR = 'DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT_ERROR'
export const DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT_SUCCESS = 'DELETE_GROUP_NOTE_FROM_SINGLE_STUDENT_SUCCESS'

export const CLASS_GROUP_NOTE_ARTIFACT = 'CLASS_GROUP_NOTE_ARTIFACT'
export const CLASS_GROUP_NOTE_ARTIFACT_ERROR = 'CLASS_GROUP_NOTE_ARTIFACT_ERROR'
export const CLASS_GROUP_NOTE_ARTIFACT_SUCCESS = 'CLASS_GROUP_NOTE_ARTIFACT_SUCCESS'

export const RESET_CLASS_GROUP_NOTE = 'RESET_CLASS_GROUP_NOTE'

export const GET_AUDIO_FROM_VERTEX = 'GET_AUDIO_FROM_VERTEX'
export const GET_AUDIO_FROM_VERTEX_ERROR = 'GET_AUDIO_FROM_VERTEX_ERROR'
export const GET_AUDIO_FROM_VERTEX_SUCCESS = 'GET_AUDIO_FROM_VERTEX_SUCCESS'

export const GET_AUDIO_SUMMARY_FROM_VERTEX = 'GET_AUDIO_SUMMARY_FROM_VERTEX'
export const GET_AUDIO_SUMMARY_FROM_VERTEX_ERROR = 'GET_AUDIO_SUMMARY_FROM_VERTEX_ERROR'
export const GET_AUDIO_SUMMARY_FROM_VERTEX_SUCCESS = 'GET_AUDIO_SUMMARY_FROM_VERTEX_SUCCESS'

export const RESET_STUDENT_NOTES = 'RESET_STUDENT_NOTES'

// -------------Class Note Module------------


// -------------Formative Assissment Module------------
export const GET_FORMATIVE_ASSESSMENT_REQUEST = 'GET_FORMATIVE_ASSESSMENT_REQUEST';
export const GET_FORMATIVE_ASSESSMENT_SUCCESS = 'GET_FORMATIVE_ASSESSMENT_SUCCESS';
export const GET_FORMATIVE_ASSESSMENT_FAILURE = 'GET_FORMATIVE_ASSESSMENT_FAILURE';

export const GET_ASSESSMENT_STUDENTS_LIST_REQUEST = 'GET_ASSESSMENT_STUDENTS_LIST_REQUEST';
export const GET_ASSESSMENT_STUDENTS_LIST_SUCCESS = 'GET_ASSESSMENT_STUDENTS_LIST_SUCCESS';
export const GET_ASSESSMENT_STUDENTS_LIST_FAILURE = 'GET_ASSESSMENT_STUDENTS_LIST_FAILURE';

export const ADD_ASSESSMENT_TAG_REQUEST = 'ADD_ASSESSMENT_TAG_REQUEST';
export const ADD_ASSESSMENT_TAG_SUCCESS = 'ADD_ASSESSMENT_TAG_SUCCESS';
export const ADD_ASSESSMENT_TAG_FAILURE = 'ADD_ASSESSMENT_TAG_FAILURE';

export const EDIT_ASSESSMENT_TAG_REQUEST = 'EDIT_ASSESSMENT_TAG_REQUEST';
export const EDIT_ASSESSMENT_TAG_SUCCESS = 'EDIT_ASSESSMENT_TAG_SUCCESS';
export const EDIT_ASSESSMENT_TAG_FAILURE = 'EDIT_ASSESSMENT_TAG_FAILURE';

export const DELETE_ASSESSMENT_TAG_REQUEST = 'DELETE_ASSESSMENT_TAG_REQUEST';
export const DELETE_ASSESSMENT_TAG_SUCCESS = 'DELETE_ASSESSMENT_TAG_SUCCESS';
export const DELETE_ASSESSMENT_TAG_FAILURE = 'DELETE_ASSESSMENT_TAG_FAILURE';

export const GET_ALL_STUDENTS_LIST_REQUEST = 'GET_ALL_STUDENTS_LIST_REQUEST';
export const GET_ALL_STUDENTS_LIST_SUCCESS = 'GET_ALL_STUDENTS_LIST_SUCCESS';
export const GET_ALL_STUDENTS_LIST_FAILURE = 'GET_ALL_STUDENTS_LIST_FAILURE';


export const UPDATE_COLOR_ASSESSMENT_TAG_REQUIRED = 'UPDATE_COLOR_ASSESSMENT_TAG_REQUIRED';

export const UPDATE_SELECTED_ASSESSMENT_TAG_REQUIRED = 'UPDATE_SELECTED_ASSESSMENT_TAG_REQUIRED';
// -------------Formative Assissment Module------------

// -------------Student GradeBook Module------------
export const GET_STUDENT_GRADEBOOK_REQUEST = 'GET_STUDENT_GRADEBOOK_REQUEST';
export const GET_STUDENT_GRADEBOOK_SUCCESS = 'GET_STUDENT_GRADEBOOK_SUCCESS';
export const GET_STUDENT_GRADEBOOK_FAILURE = 'GET_STUDENT_GRADEBOOK_FAILURE';

export const UPDATE_STUDENT_GRADEBOOK_REQUEST = 'UPDATE_STUDENT_GRADEBOOK_REQUEST';
export const UPDATE_STUDENT_GRADEBOOK_SUCCESS = 'UPDATE_STUDENT_GRADEBOOK_SUCCESS';
export const UPDATE_STUDENT_GRADEBOOK_FAILURE = 'UPDATE_STUDENT_GRADEBOOK_FAILURE';

export const UPDATE_STUDENT_GRADEBOOK_AVERAGE_REQUEST = 'UPDATE_STUDENT_GRADEBOOK_AVERAGE_REQUEST';

export const UPDATE_STUDENT_GRADEBOOK_CHECKBOX_REQUEST = 'UPDATE_STUDENT_GRADEBOOK_CHECKBOX_REQUEST';

export const UPDATE_STUDENT_GRADEBOOK_INPUT_REQUEST = 'UPDATE_STUDENT_GRADEBOOK_INPUT_REQUEST';

export const UPDATE_STUDENT_GRADEBOOK_COLOR_REQUEST = 'UPDATE_STUDENT_GRADEBOOK_COLOR_REQUEST';

export const GET_STUDENT_GRADEBOOK_NULL_REQUEST = 'GET_STUDENT_GRADEBOOK_NULL_REQUEST'

// -------------Student GradeBook Module------------


// -------------Class GradeBook Module------------
export const GET_CLASS_GRADEBOOK_REQUEST = 'GET_CLASS_GRADEBOOK_REQUEST';
export const GET_CLASS_GRADEBOOK_SUCCESS = 'GET_CLASS_GRADEBOOK_SUCCESS';
export const GET_CLASS_GRADEBOOK_FAILURE = 'GET_CLASS_GRADEBOOK_FAILURE';

export const ADD_NEW_CLASS_GRADEBOOK_CATEGORY_REQUEST = 'ADD_NEW_CLASS_GRADEBOOK_CATEGORY_REQUEST';
export const ADD_NEW_CLASS_GRADEBOOK_CATEGORY_SUCCESS = 'ADD_NEW_CLASS_GRADEBOOK_CATEGORY_SUCCESS';
export const ADD_NEW_CLASS_GRADEBOOK_CATEGORY_FAILURE = 'ADD_NEW_CLASS_GRADEBOOK_CATEGORY_FAILURE';

export const ADD_NEW_CLASS_GRADEBOOK_EVENT_REQUEST = 'ADD_NEW_CLASS_GRADEBOOK_EVENT_REQUEST';
export const ADD_NEW_CLASS_GRADEBOOK_EVENT_SUCCESS = 'ADD_NEW_CLASS_GRADEBOOK_EVENT_SUCCESS';
export const ADD_NEW_CLASS_GRADEBOOK_EVENT_FAILURE = 'ADD_NEW_CLASS_GRADEBOOK_EVENT_FAILURE';

export const UPDATE_CLASS_GRADEBOOK_EVENT_REQUEST = 'UPDATE_CLASS_GRADEBOOK_EVENT_REQUEST';
export const UPDATE_CLASS_GRADEBOOK_EVENT_SUCCESS = 'UPDATE_CLASS_GRADEBOOK_EVENT_SUCCESS';
export const UPDATE_CLASS_GRADEBOOK_EVENT_FAILURE = 'UPDATE_CLASS_GRADEBOOK_EVENT_FAILURE';

export const UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_REQUEST = 'UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_REQUEST';
export const UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_SUCCESS = 'UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_SUCCESS';
export const UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_FAILURE = 'UPDATE_CLASS_GRADEBOOK_SCORE_COMMENT_FAILURE';


export const DELETE_CLASS_GRADEBOOK_EVENT_REQUEST = 'DELETE_CLASS_GRADEBOOK_EVENT_REQUEST';
export const DELETE_CLASS_GRADEBOOK_EVENT_SUCCESS = 'DELETE_CLASS_GRADEBOOK_EVENT_SUCCESS';
export const DELETE_CLASS_GRADEBOOK_EVENT_FAILURE = 'DELETE_CLASS_GRADEBOOK_EVENT_FAILURE';

export const UPDATE_CLASS_GRADEBOOK_DRAG_DATA_REQUEST = 'UPDATE_CLASS_GRADEBOOK_DRAG_DATA_REQUEST';
export const UPDATE_CLASS_GRADEBOOK_DRAG_DATA_SUCCESS = 'UPDATE_CLASS_GRADEBOOK_DRAG_DATA_SUCCESS';
export const UPDATE_CLASS_GRADEBOOK_DRAG_DATA_FAILURE = 'UPDATE_CLASS_GRADEBOOK_DRAG_DATA_FAILURE';


export const UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_REQUEST = 'UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_REQUEST';
export const UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_SUCCESS = 'UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_SUCCESS';
export const UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_FAILURE = 'UPDATE_CLASS_GRADEBOOK_DRAG_EVENT_DATA_FAILURE';

export const UPDATE_CLASS_GRADEBOOK_ID_REQUEST = 'UPDATE_CLASS_GRADEBOOK_ID_REQUEST';

export const UPDATE_CLASS_GRADEBOOK_STUDENTS_REQUEST = 'UPDATE_CLASS_GRADEBOOK_STUDENTS_REQUEST';

export const UPDATE_CLASS_GRADEBOOK_CATEGORY_INPUT_REQUEST = 'UPDATE_CLASS_GRADEBOOK_CATEGORY_INPUT_REQUEST';


export const UPDATE_CLASS_GRADEBOOK_COLOR_REQUEST = 'UPDATE_CLASS_GRADEBOOK_COLOR_REQUEST';

export const UPDATE_CLASS_GRADEBOOK_ALL_COLOR_REQUEST = 'UPDATE_CLASS_GRADEBOOK_ALL_COLOR_REQUEST';

export const UPDATE_CLASS_GRADEBOOK_INPUT_REQUEST = 'UPDATE_CLASS_GRADEBOOK_INPUT_REQUEST';

export const ADD_CLASS_GRADEBOOK_POPOVER_DATA_REQUEST = 'ADD_CLASS_GRADEBOOK_POPOVER_DATA_REQUEST';


export const UPDATE_CLASS_GRADEBOOK_POPOVER_COLOR_REQUEST = 'UPDATE_CLASS_GRADEBOOK_POPOVER_COLOR_REQUEST';

export const UPDATE_CLASS_GRADEBOOK_POPOVER_INPUT_REQUEST = 'UPDATE_CLASS_GRADEBOOK_POPOVER_INPUT_REQUEST';

export const UPDATE_CLASS_GRADEBOOK_NULL_REQUEST = 'UPDATE_CLASS_GRADEBOOK_NULL_REQUEST';

export const OPEN_CLASS_GRADEBOOK_POPOVER_REQUEST = 'OPEN_CLASS_GRADEBOOK_POPOVER_REQUEST';

export const NEW_CLASS_GRADEBOOK_REQUEST = 'NEW_CLASS_GRADEBOOK_REQUEST';
// -------------Class GradeBook Module------------

// -------------Blog Module------------
export const GET_BLOG_LIST_REQUEST = 'GET_BLOG_LIST_REQUEST';
export const GET_BLOG_LIST_SUCCESS = 'GET_BLOG_LIST_SUCCESS';
export const GET_BLOG_LIST_FAILURE = 'GET_BLOG_LIST_FAILURE';

export const LOAD_MORE_BLOG_LIST_REQUEST = 'LOAD_MORE_BLOG_LIST_REQUEST';

// -------------Blog Module------------


// -------------Data Usage Module------------
export const GET_DATA_USAGE_REQUEST = 'GET_DATA_USAGE_REQUEST';
export const GET_DATA_USAGE_SUCCESS = 'GET_DATA_USAGE_SUCCESS';
export const GET_DATA_USAGE_FAILURE = 'GET_DATA_USAGE_FAILURE';
// -------------Data Usage Module------------

// -------------Set Payment Module------------
export const SET_PAYMENT_DATA_REQUEST = 'SET_PAYMENT_DATA_REQUEST';
export const SET_PAYMENT_DATA_SUCCESS = 'SET_PAYMENT_DATA_SUCCESS';
export const SET_PAYMENT_DATA_FAILURE = 'SET_PAYMENT_DATA_FAILURE';


export const ADD_USER_SUBSCRIPTION_REQUEST = 'ADD_USER_SUBSCRIPTION_REQUEST';
export const ADD_USER_SUBSCRIPTION_SUCCESS = 'ADD_USER_SUBSCRIPTION_SUCCESS';
export const ADD_USER_SUBSCRIPTION_FAILURE = 'ADD_USER_SUBSCRIPTION_FAILURE';

export const ADD_PAYMENT_STATUS_SUCCESS = 'ADD_PAYMENT_STATUS_SUCCESS';


// -------------Set Payment Module------------


// -------------ContactUs Module------------
export const SEND_CONTACT_US_REQUEST = 'SEND_CONTACT_US_REQUEST';
export const SEND_CONTACT_US_SUCCESS = 'SEND_CONTACT_US_SUCCESS';
export const SEND_CONTACT_US_FAILURE = 'SEND_CONTACT_US_FAILURE';
// -------------ContactUs Module------------

// -------------SUBSCRIBE Module------------
export const SEND_SUBSCRIBE_REQUEST = 'SEND_SUBSCRIBE_REQUEST';
export const SEND_SUBSCRIBE_SUCCESS = 'SEND_SUBSCRIBE_SUCCESS';
export const SEND_SUBSCRIBE_FAILURE = 'SEND_SUBSCRIBE_FAILURE';

export const CHANGE_USER_SUBSCRIPTION_REQUEST = 'CHANGE_USER_SUBSCRIPTION_REQUEST';
export const CHANGE_USER_SUBSCRIPTION_SUCCESS = 'CHANGE_USER_SUBSCRIPTION_SUCCESS';
export const CHANGE_USER_SUBSCRIPTION_FAILURE = 'CHANGE_USER_SUBSCRIPTION_FAILURE';
// -------------SUBSCRIBE Module------------

//---------------STRIPE MODULE--------------
export const GET_STRIPE_PRODUCTS = 'GET_STRIPE_PRODUCTS'
export const GET_STRIPE_PRODUCTS_ERROR = 'GET_STRIPE_PRODUCTS_ERROR';
export const GET_STRIPE_PRODUCTS_SUCCESS = 'GET STRIPE_PRODUCTS_SUCCESS';

// -------------Footer Module------------
export const GET_SUBSCRIPTION_PLAN_REQUEST = 'GET_SUBSCRIPTION_PLAN_REQUEST';
export const GET_SUBSCRIPTION_PLAN_SUCCESS = 'GET_SUBSCRIPTION_PLAN_SUCCESS';
export const GET_SUBSCRIPTION_PLAN_FAILURE = 'GET_SUBSCRIPTION_PLAN_FAILURE';
// -------------Footer Module------------

// -------------setting Module------------
export const GET_USER_SETTING_REQUEST = 'GET_USER_SETTING_REQUEST';
export const GET_USER_SETTING_SUCCESS = 'GET_USER_SETTING_SUCCESS';
export const GET_USER_SETTING_FAILURE = 'GET_USER_SETTING_FAILURE';

export const UPDATE_USER_SETTING_REQUEST = 'UPDATE_USER_SETTING_REQUEST';
export const UPDATE_USER_SETTING_SUCCESS = 'UPDATE_USER_SETTING_SUCCESS';
export const UPDATE_USER_SETTING_FAILURE = 'UPDATE_USER_SETTING_FAILURE';
// -------------setting Module------------


// -------------Notification Module------------

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';

export const REJECT_SHARED_CLASS_REQUEST = 'REJECT_SHARED_CLASS_REQUEST';
export const REJECT_SHARED_CLASS_SUCCESS = 'REJECT_SHARED_CLASS_SUCCESS';
export const REJECT_SHARED_CLASS_FAILURE = 'REJECT_SHARED_CLASS_FAILURE';

export const REJECT_SHARED_STUDENT_REQUEST = 'REJECT_SHARED_STUDENT_REQUEST';
export const REJECT_SHARED_STUDENT_SUCCESS = 'REJECT_SHARED_STUDENT_SUCCESS';
export const REJECT_SHARED_STUDENT_FAILURE = 'REJECT_SHARED_STUDENT_FAILURE';

export const GET_USER_PLAN_SPACE_DETAILS = 'GET_USER_PLAN_SPACE_DETAILS';
export const GET_USER_PLAN_SPACE_DETAILS_FAILURE = 'GET_USER_PLAN_SPACE_DETAILS_FAILURE';
export const GET_USER_PLAN_SPACE_DETAILS_SUCCESS = 'GET_USER_PLAN_SPACE_DETAILS_SUCCESS';

export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

export const NOTIFICATION_MARK_AS_READ_REQUEST = 'NOTIFICATION_MARK_AS_READ_REQUEST';
export const NOTIFICATION_MARK_AS_READ_SUCCESS = 'NOTIFICATION_MARK_AS_READ_SUCCESS';
export const NOTIFICATION_MARK_AS_READ_FAILURE = 'NOTIFICATION_MARK_AS_READ_FAILURE';

export const GET_NOTIFICATION_COUNT_REQUEST = 'GET_NOTIFICATION_COUNT_REQUEST';
export const GET_NOTIFICATION_COUNT_SUCCESS = 'GET_NOTIFICATION_COUNT_SUCCESS';
export const GET_NOTIFICATION_COUNT_FAILURE = 'GET_NOTIFICATION_COUNT_FAILURE';
// -------------Notification Module------------

// -------------Core Standard Module------------
export const GET_CORE_STANDARD_LIST_REQUEST = 'GET_CORE_STANDARD_LIST_REQUEST';
export const GET_CORE_STANDARD_LIST_SUCCESS = 'GET_CORE_STANDARD_LIST_SUCCESS';
export const GET_CORE_STANDARD_LIST_FAILURE = 'GET_CORE_STANDARD_LIST_FAILURE';
// -------------Core Standard Module------------




export const LOADING = 'LOADING';
export const LOADING_SUCCESS = 'LOADING_SUCCESS';
export const LOADING_FAILURE = 'LOADING_FAILURE';

export const SET_ROSTER_SIDEBAR_OPEN = 'SET_ROSTER_SIDEBAR_OPEN';
export const SET_CLASS_SIDEBAR_OPEN = 'SET_CLASS_SIDEBAR_OPEN';

export const SET_ROSTER_STATE = 'SET_ROSTER_STATE';
export const SET_CLASS_STATE = 'SET_CLASS_STATE';
export const SET_CLASS_LESSON_PLAN_STATE = 'SET_CLASS_LESSON_PLAN_STATE';
export const SET_GRADEBOOK_DATA_STATE = 'SET_GRADEBOOK_DATA_STATE'
export const SET_GROUP_NOTES_STATE = 'SET_GROUP_NOTES_STATE'
export const SET_CLASS_ROSTER_STATE = 'SET_CLASS_ROSTER_STATE'

// GET_SCHOOL_TEACHER
export const GET_SCHOOL_TEACHER = 'GET_SCHOOL_TEACHER';
export const GET_SCHOOL_TEACHER_SUCCESS = 'GET_SCHOOL_TEACHER_SUCCESS';
export const GET_SCHOOL_TEACHER_FAILURE = 'GET_SCHOOL_TEACHER_FAILURE';

export const SHARE_CLASS = 'SHARE_CLASS';
export const SHARE_CLASS_SUCCESS = 'SHARE_CLASS_SUCCESS';
export const SHARE_CLASS_FAILURE = 'SHARE_CLASS_FAILURE';

export const SHARE_STUDENT = 'SHARE_STUDENT';
export const SHARE_STUDENT_SUCCESS = 'SHARE_STUDENT_SUCCESS';
export const SHARE_STUDENT_FAILURE = 'SHARE_STUDENT_FAILURE';

// export const GET_SIDEBAR_OPEN_SUCCESS = 'GET_SIDEBAR_OPEN_SUCCESS';
// export const GET_SIDEBAR_OPEN_FAILURE = 'GET_SIDEBAR_OPEN_FAILURE';

export const GET_CLASS_ATT_LIST = 'GET_CLASS_ATT_LIST';
export const GET_CLASS_ATT_LIST_SUCCESS = 'GET_CLASS_ATT_LIST_SUCCESS';
export const GET_CLASS_ATT_LIST_FAILURE = 'GET_CLASS_ATT_LIST_FAILURE';

export const ADD_CLASS_ATT = 'ADD_CLASS_ATT_';
export const ADD_CLASS_ATT_SUCCESS = 'ADD_CLASS_ATT_SUCCESS';
export const ADD_CLASS_ATT_FAILURE = 'ADD_CLASS_ATT_FAILURE';

export const GET_CLASS_ATT_BY_DATE_RANGE = 'GET_CLASS_ATT_BY_DATE_RANGE';
export const GET_CLASS_ATT_BY_DATE_RANGE_SUCCESS = 'GET_CLASS_ATT_BY_DATE_RANGE_SUCCESS';
export const GET_CLASS_ATT_BY_DATE_RANGE_FAILURE = 'GET_CLASS_ATT_BY_DATE_RANGE_FAILURE';

export const SEND_EMAIL_REPORT = 'SEND_EMAIL_REPORT';
export const SEND_EMAIL_REPORT_SUCCESS = 'SEND_EMAIL_REPORT_SUCCESS';
export const SEND_EMAIL_REPORT_FAILURE = 'SEND_EMAIL_REPORT_FAILURE';


// -------------Lesson Plan Module------------
export const GET_VIEW_LESSON_PLAN_LIST = 'GET_VIEW_LESSON_PLAN_LIST';
export const GET_VIEW_LESSON_PLAN_LIST_SUCCESS = 'GET_VIEW_LESSON_PLAN_LIST_SUCCESS';
export const GET_VIEW_LESSON_PLAN_LIST_FAILURE = 'GET_VIEW_LESSON_PLAN_LIST_FAILURE';

export const ADD_LESSON_PLAN = 'ADD_LESSON_PLAN';
export const ADD_LESSON_PLAN_SUCCESS = 'ADD_LESSON_PLAN_SUCCESS';
export const ADD_LESSON_PLAN_FAILURE = 'ADD_LESSON_PLAN_FAILURE';

export const GET_VIEW_QUESTION_LIST = 'GET_VIEW_QUESTION_LIST';
export const GET_VIEW_QUESTION_LIST_SUCCESS = 'GET_VIEW_QUESTION_LIST_SUCCESS';
export const GET_VIEW_QUESTION_LIST_FAILURE = 'GET_VIEW_QUESTION_LIST_FAILURE';

export const COPY_LESSON_PLAN = 'COPY_LESSON_PLAN';
export const COPY_LESSON_PLAN_SUCCESS = 'COPY_LESSON_PLAN_SUCCESS';
export const COPY_LESSON_PLAN_FAILURE = 'COPY_LESSON_PLAN_FAILURE';

export const DELETE_LESSON_PLAN = 'DELETE_LESSON_PLAN';
export const DELETE_LESSON_PLAN_SUCCESS = 'DELETE_LESSON_PLAN_SUCCESS';
export const DELETE_LESSON_PLAN_FAILURE = 'DELETE_LESSON_PLAN_FAILURE';

export const VIEW_EDIT_LESSON_PLAN = 'VIEW_EDIT_LESSON_PLAN';
export const VIEW_EDIT_LESSON_PLAN_SUCCESS = 'VIEW_EDIT_LESSON_PLAN_SUCCESS';
export const VIEW_EDIT_LESSON_PLAN_FAILURE = 'VIEW_EDIT_LESSON_PLAN_FAILURE';

export const VIEW_LESSON_PLAN_DETAILS = 'VIEW_LESSON_PLAN_DETAILS';
export const VIEW_LESSON_PLAN_DETAILS_SUCCESS = 'VIEW_LESSON_PLAN_DETAILS_SUCCESS';
export const VIEW_LESSON_PLAN_DETAILS_FAILURE = 'VIEW_LESSON_PLAN_DETAILS_FAILURE';

export const SAVE_EXECUTION_LESSON_PLAN = 'SAVE_EXECUTION_LESSON_PLAN';
export const SAVE_EXECUTION_LESSON_PLAN_SUCCESS = 'SAVE_EXECUTION_LESSON_PLAN_SUCCESS';
export const SAVE_EXECUTION_LESSON_PLAN_FAILURE = 'SAVE_EXECUTION_LESSON_PLAN_FAILURE';

export const SAVE_EVALUATION_LESSON_PLAN = 'SAVE_EVALUATION_LESSON_PLAN';
export const SAVE_EVALUATION_LESSON_PLAN_SUCCESS = 'SAVE_EVALUATION_LESSON_PLAN_SUCCESS';
export const SAVE_EVALUATION_LESSON_PLAN_FAILURE = 'SAVE_EVALUATION_LESSON_PLAN_FAILURE';

export const DELETE_DYNAMIC_QUESTION_LESSON_PLAN = 'DELETE_DYNAMIC_QUESTION_LESSON_PLAN';
export const DELETE_DYNAMIC_QUESTION_LESSON_PLAN_SUCCESS = 'DELETE_DYNAMIC_QUESTION_LESSON_PLAN_SUCCESS';
export const DELETE_DYNAMIC_QUESTION_LESSON_PLAN_FAILURE = 'DELETE_DYNAMIC_QUESTION_LESSON_PLAN_FAILURE';

export const GET_VIEW_IMPORT_LESSON_PLAN_LIST = 'GET_VIEW_IMPORT_LESSON_PLAN_LIST';
export const GET_VIEW_IMPORT_LESSON_PLAN_LIST_SUCCESS = 'GET_VIEW_IMPORT_LESSON_PLAN_LIST_SUCCESS';
export const GET_VIEW_IMPORT_LESSON_PLAN_LIST_FAILURE = 'GET_VIEW_IMPORT_LESSON_PLAN_LIST_FAILURE';

export const GET_IMPORT_TOPIC_LIST = 'GET_IMPORT_TOPIC_LIST';
export const GET_IMPORT_TOPIC_LIST_SUCCESS = 'GET_IMPORT_TOPIC_LIST_SUCCESS';
export const GET_IMPORT_TOPIC_LIST_FAILURE = 'GET_IMPORT_TOPIC_LIST_FAILURE';

export const SAVE_IMPORT_LESSON_PLAN_RATING = 'SAVE_IMPORT_LESSON_PLAN_RATING';
export const SAVE_IMPORT_LESSON_PLAN_RATING_SUCCESS = 'SAVE_IMPORT_LESSON_PLAN_RATING_SUCCESS';
export const SAVE_IMPORT_LESSON_PLAN_RATING_FAILURE = 'SAVE_IMPORT_LESSON_PLAN_RATING_FAILURE';

export const IMPORT_LESSON_PLAN = 'IMPORT_LESSON_PLAN';
export const IMPORT_LESSON_PLAN_SUCCESS = 'IMPORT_LESSON_PLAN_SUCCESS';
export const IMPORT_LESSON_PLAN_FAILURE = 'IMPORT_LESSON_PLAN_FAILURE';

export const GET_LESSON_PLAN_SCHOOL_TEACHER = 'GET_LESSON_PLAN_SCHOOL_TEACHER';
export const GET_LESSON_PLAN_SCHOOL_TEACHER_SUCCESS = 'GET_LESSON_PLAN_SCHOOL_TEACHER_SUCCESS';
export const GET_LESSON_PLAN_SCHOOL_TEACHER_FAILURE = 'GET_LESSON_PLAN_SCHOOL_TEACHER_FAILURE';

export const SHARE_LESSON_PLAN = 'SHARE_LESSON_PLAN';
export const SHARE_LESSON_PLAN_SUCCESS = 'SHARE_LESSON_PLAN_SUCCESS';
export const SHARE_LESSON_PLAN_FAILURE = 'SHARE_LESSON_PLAN_FAILURE';
// -------------Lesson Plan Module------------

// -------------Rubrics Module------------
export const GET_RUBRIC_LIST = 'GET_RUBRIC_LIST';
export const GET_RUBRIC_LIST_SUCCESS = 'GET_RUBRIC_LIST_SUCCESS';
export const GET_RUBRIC_LIST_FAILURE = 'GET_RUBRIC_LIST_FAILURE';

export const ADD_RUBRIC = 'ADD_RUBRIC';
export const ADD_RUBRIC_SUCCESS = 'ADD_RUBRIC_SUCCESS';
export const ADD_RUBRIC_FAILURE = 'ADD_RUBRIC_FAILURE';

export const VIEW_RUBRIC_DETAILS = 'VIEW_RUBRIC_DETAILS';
export const VIEW_RUBRIC_DETAILS_SUCCESS = 'VIEW_RUBRIC_DETAILS_SUCCESS';
export const VIEW_RUBRIC_DETAILS_FAILURE = 'VIEW_RUBRIC_DETAILS_FAILURE';

export const DELETE_CRITERIA = 'DELETE_CRITERIA';
export const DELETE_CRITERIA_SUCCESS = 'DELETE_CRITERIA_SUCCESS';
export const DELETE_CRITERIA_FAILURE = 'DELETE_CRITERIA_FAILURE';

export const SAVE_ASSESSMENT = 'SAVE_ASSESSMENT';
export const SAVE_ASSESSMENT_SUCCESS = 'SAVE_ASSESSMENT_SUCCESS';
export const SAVE_ASSESSMENT_FAILURE = 'SAVE_ASSESSMENT_FAILURE';

export const VIEW_STUDENTS_ASSESSMENT_DETAILS = 'VIEW_STUDENTS_ASSESSMENT_DETAILS';
export const VIEW_STUDENTS_ASSESSMENT_DETAILS_SUCCESS = 'VIEW_STUDENTS_ASSESSMENT_DETAILS_SUCCESS';
export const VIEW_STUDENTS_ASSESSMENT_DETAILS_FAILURE = 'VIEW_STUDENTS_ASSESSMENT_DETAILS_FAILURE';

export const DELETE_RUBRIC = 'DELETE_RUBRIC';
export const DELETE_RUBRIC_SUCCESS = 'DELETE_RUBRIC_SUCCESS';
export const DELETE_RUBRIC_FAILURE = 'DELETE_RUBRIC_FAILURE';

export const FORWARD_RUBRICS_REPORT = 'FORWARD_RUBRICS_REPORT';
export const FORWARD_RUBRICS_REPORT_SUCCESS = 'FORWARD_RUBRICS_REPORT_SUCCESS';
export const FORWARD_RUBRICS_REPORT_FAILURE = 'FORWARD_RUBRICS_REPORT_FAILURE';

export const GET_DYNAMIC_RUBRICS_CRITERIA = 'GET_DYNAMIC_RUBRICS_CRITERIA'
export const GET_DYNAMIC_RUBRICS_CRITERIA_ERROR = 'GET_DYNAMIC_RUBRICS_CRITERIA_ERROR'
export const GET_DYNAMIC_RUBRICS_CRITERIA_SUCCESS = 'GET_DYNAMIC_RUBRICS_CRITERIA_SUCCESS'

export const DELETE_PREVIOUS_CRITERIA_DATA = 'DELETE_PREVIOUS_CRITERIA_DATA'
export const DELETE_PREVIOUS_CRITERIA_DATA_ERROR = 'DELETE_PREVIOUS_CRITERIA_DATA_ERROR'
export const DELETE_PREVIOUS_CRITERIA_DATA_SUCCESS = 'DELETE_PREVIOUS_CRITERIA_DATA_SUCCESS'
  // -------------Rubrics Module------------

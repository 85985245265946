import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './ClassNoteDashboard.scss';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClassNoteStudentProfile from '../ClassNoteStudentProfileComponent/ClassNoteStudentProfile';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import DivLoader from 'Components/LoadingComponent/DivLoader';
import * as routes from "../../../../Router/RoutesURL";
import back from 'Assets/CommonComponent/back.png'
import SelectSearch from 'react-select-search';
import search from 'Assets/AttendanceModule/search.png'

const ClassNoteDashboard = (props) => {
    const { getClassNoteRequest, classNoteData, location, state, history, Class_id } = props;
    
    const { classNote } = state;
    const { loader } = classNote;
    const { ClassData, AcademicData } = location.state;
    const { classDetail, selectedClass, classListData, academicYearId, academicYear, } = history.location.state;
    const [classDetails, setClassDetailsCheck] = useState([])
    const [AcademicDetails, setAcademicDetailsCheck] = useState([])
    const [enabledCheck, setEnabledCheck] = useState(false);
    const [filterCheck] = useState('both');
    const [sortCheck, setSortCheck] = useState('sort_by_first_name');
    const [pageNo, setPageCheck] = useState(1);
    const [tagId] = useState('')
    const [selectedStudentsForGroupNote, setSelectedStudentsForGroupNote] = useState([]);
    const [filterClassNoteData, setFilterClassNoteData] = useState([])
    const [isSelected, setIsSelected] = useState(true);
    const [selectNewClass, setSelectNewClass] = useState(false)
    const [selectNewClassData, setSelectNewClassData] = useState(null)
    const [selectedClassName, setSelectedClassName] = useState(selectedClass ? selectedClass[0].name : classDetail)
    const selectedClassName2 = selectedClass ? selectedClass[0].name : classDetail
    const [sortByFilter, setSortByFilter] = useState('sort_by_first_name')
    const [searchText, setSearchText] = useState('')
    const [changeClassId, setChangeClassId] = useState(0)
    const [numBoxes, setNumBoxes] = useState(100);
    const [studentDivHeight, setStudentDivHeight] = useState(0)

    const noClassRef = useRef(null);
    useEffect(() => {
    
        const apiValue = {
            "classId": sessionStorage.getItem('classIDForNotes') ? sessionStorage.getItem('classIDForNotes') : props.location.state.BackPage == "ClassDB" ? props.location.state.Class_id : classDetail,
            "filterBy": filterCheck,
            "sortBy": sortCheck,
            "tagId": tagId,
            "pageNo": pageNo,
            "academicYearId": academicYearId,
            "search_text": searchText,
            "limit" : 15
        }
        getClassNoteRequest(apiValue);
        
    
}, [])
    useEffect(() => {
        // const boxHeight = 80; // height of each box in pixels
        // const windowHeight = window.innerHeight;
        // const numBoxes = Math.floor(windowHeight / boxHeight);
        // console.log(windowHeight, numBoxes)
        // setNumBoxes(numBoxes);
        const boxWidth = 410; // width of each box in pixels
        const boxHeight = 170; // height of each box in pixels
        const divWidth = noClassRef?.current?.offsetWidth;
        // const divHeight = window.innerHeight
        const divHeight = noClassRef?.current?.offsetHeight;
        const numBoxesWidth = Math.floor(divWidth / boxWidth);
        // const numBoxesWidth = 403
        const numBoxesHeight = Math.floor(divHeight / boxHeight);
        // const numBoxes = numBoxesWidth * numBoxesHeight;
        // console.log(numBoxesHeight, numBoxesWidth, numBoxes, divWidth, divHeight)
        // setNumBoxes(numBoxes);
      }, []);

      useEffect(() => {
        if(numBoxes != 'NaN' && numBoxes > 0) {
        // const { data = "" } = history.location.state;
        // let classId = "";
        // if (data && data.class_data && data.class_data.length > 0) {
        //   classId = data.class_data[0].cc_class_id;
        // }
        // setAcademicYear(data.academic_year);
        // setAcademicYearId(data.academic_year_id);
        // setClassIdForGradebook(classId);
        // // this.setState({
        // //     academicYear: data.academic_year,
        // //     academic_year_id: data.academic_year_id,
        // //     classIdForGradebook: classId
        // // })
        // const value = {
        //   class_id: "",
        //   filter_by: filter,
        //   name_filter_by: nameFilter,
        //   page_no: loadMoreCount > 1 ? "full" : loadMoreCount,
        //   limit : numBoxes,
        //   // academic_year: data.year_ids,
        //   academic_year: data.academic_year_id,
        // };
        // dispatch(getRosterList(value));
      }
      },[numBoxes])


    // --onLoad----
    useEffect(() => {
        
        
        if(numBoxes != 'NaN' && numBoxes > 0) {
            
        if(selectedClass?.[0]?.value > 0) {
            setClassDetailsCheck(selectedClass?.[0]?.details)   
        } else {
        setClassDetailsCheck(history.location.state.ClassData);
        }
        setAcademicDetailsCheck(AcademicData)
        if(sessionStorage.getItem('classIDForNotes') > 0) {
            const apiValue = {
                "classId": sessionStorage.getItem('classIDForNotes'),
                "filterBy": filterCheck,
                "sortBy": sortCheck,
                "tagId": tagId,
                "pageNo": pageNo,
                "academicYearId": academicYearId,
                "search_text": searchText,
                "limit" : numBoxes
            }
            if(pageNo > 0) {
            getClassNoteRequest(apiValue);
            }
        } else {
        const apiValue = {
            "classId": props.location.state.BackPage == "ClassDB" ? props.location.state.Class_id : classDetail,
            "filterBy": filterCheck,
            "sortBy": sortCheck,
            "tagId": tagId,
            "pageNo": pageNo,
            "academicYearId": academicYearId,
            "search_text": searchText,
            "limit" : numBoxes
        }
        if(pageNo > 0) {
        getClassNoteRequest(apiValue);
        }
    }
        }

    }, [pageNo, sortCheck, tagId, filterCheck, location, getClassNoteRequest, numBoxes]);

    useEffect(() => {
     
    },[classDetails])


    // const enabledChildCheckBox = () => {
    //     setEnabledCheck(!enabledCheck)
    // }

    const getData = (StudentId) => {
        if (selectedStudentsForGroupNote.includes(StudentId)) {
            setSelectedStudentsForGroupNote(selectedStudentsForGroupNote.filter((id) => id !== StudentId))
        } else {
            setSelectedStudentsForGroupNote([...selectedStudentsForGroupNote, StudentId])
        }
    }

    // -------set sorting------
    const sortSet = (value) => {
        setSortByFilter(value)
        setPageCheck(1)
        const apiValue = {
            "classId": props.location.state.BackPage == "ClassDB" ? props.location.state.Class_id : classDetail,
            "filterBy": filterCheck,
            "sortBy": value,
            "tagId": tagId,
            "pageNo": 1,
            "academicYearId": academicYearId,
            "search_text": searchText,
            "limit" : numBoxes
        }
        getClassNoteRequest(apiValue);
    }
    // -------set sorting------

    // -------set search------
    const handleSearchText = (event) => {
        setSearchText(event.target.value)
        if(event.target.value.length === 0) {
            setFilterClassNoteData([])
                const apiValue = {
            "classId": changeClassId > 0 ? changeClassId : (props.location.state.BackPage == "ClassDB" ? props.location.state.Class_id : classDetail),
            "filterBy": filterCheck,
            "sortBy": sortByFilter,
            "tagId": tagId,
            "pageNo": 1,
            "academicYearId": academicYearId,
            "search_text": event.target.value,
            "limit" : numBoxes
        }
        getClassNoteRequest(apiValue);
        }
    //     if(event.target.value.length > 2) {
          
    //         // let data = classNoteData.response_data.filter((item) => 
    //         //     item.cc_student_first_name.toLowerCase().includes(event.target.value.toLowerCase()) || item.cc_student_last_name.toLowerCase().includes(event.target.value.toLowerCase())
    //         // )
            
    //         // setFilterClassNoteData(data)
    //     const apiValue = {
    //         "classId": changeClassId,
    //         "filterBy": filterCheck,
    //         "sortBy": sortByFilter,
    //         "tagId": tagId,
    //         "pageNo": 1,
    //         "academicYearId": academicYearId,
    //         "search_text": event.target.value,
    //         "limit" : numBoxes
    //     }
    //     console.log(apiValue)
    //     getClassNoteRequest(apiValue);
    // }
    }

    useEffect(() => {
        if(searchText.length > 2) {
        const delayDebounceFn = setTimeout(() => {
            // Send Axios request here
            if (searchText.length > 2) {
                 const apiValue = {
                    "classId": changeClassId > 0 ? changeClassId : (props.location.state.BackPage == "ClassDB" ? props.location.state.Class_id : classDetail),
                    "filterBy": filterCheck,
                    "sortBy": sortByFilter,
                    "tagId": tagId,
                    "pageNo": 1,
                    "academicYearId": academicYearId,
                    "search_text": searchText,
                    "limit" : numBoxes
                 }
                getClassNoteRequest(apiValue);

        } else {
            setSearchText('')
            const apiValue = {
                "classId": changeClassId > 0 ? changeClassId : (props.location.state.BackPage == "ClassDB" ? props.location.state.Class_id : classDetail),
                "filterBy": filterCheck,
                "sortBy": sortByFilter,
                "tagId": tagId,
                "pageNo": 1,
                "academicYearId": academicYearId,
                "search_text": searchText,
                "limit" : numBoxes
            }
            getClassNoteRequest(apiValue);
            
            
        }
          }, 2000)

          return () => clearTimeout(delayDebounceFn)
        }
    },[searchText])


    // -------set Pagination------
    const pageSet = () => {
        let value = pageNo == 0 ? 1 : pageNo;
        value = value + 1
        setPageCheck(value)
        const apiValue = {
            "classId": props.location.state.BackPage == "ClassDB" ? props.location.state.Class_id : classDetail,
            "filterBy": filterCheck,
            "sortBy": sortCheck,
            "tagId": tagId,
            "pageNo": value,
            "academicYearId": academicYearId,
            "search_text": searchText,
            "limit" : numBoxes
        }
        getClassNoteRequest(apiValue);

    }
    // -------set Pagination------

    const searchInput = useRef();
    const options = [
        {
            type: "group",
            name: "",
            items: classListData
        }
    ];
    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    const handleChange = (...args) => {

        setPageCheck(0)
        setIsSelected(false)
        setClassDetailsCheck(args[1].details)
        setSelectedClassName(args[1].name)
        setSelectNewClass(true)
setSelectNewClassData([args[1].details])
        setChangeClassId(args[0])
        sessionStorage.setItem('classIDForNotes',args[0])
        sessionStorage.setItem('classNameForNotes',args[1].name)
        if (args.length > 0) {
            getNotes(args[0])
            sessionStorage.setItem('classIDForNotes',args[0])
        sessionStorage.setItem('classNameForNotes',args[1].name)
        }

    };

    const getNotes = (value) => {
        const apiValue = {
            "classId": props.location.state.BackPage == "ClassDB" ? props.location.state.Class_id : value > 0 ? value : classDetail,
            "filterBy": filterCheck,
            "sortBy": sortCheck,
            "tagId": tagId,
            "pageNo": 1,
            "academicYearId": AcademicData !== undefined ? AcademicData : academicYearId,
            "search_text": searchText,
            "limit" : numBoxes
        }
        getClassNoteRequest(apiValue);

    }
    const BackToDash = () => {
        history.replace("/home/academic")
    }

 

    return (
        <div className='classNotekMainContainer-CND container'>
 <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          background: "#f0f0f0",
          height: 176,
        }}
        className="sticky-header"
      >
            <Row>
           
                <Col md="12" lg="12" className="main-section-CND">
                    {/* ------ Class add group Note----- */}
                    <Card className="header-CND">
                        <Card.Body>
                            <Row className="header-title-row-CND">
                                <Col xs="12" md="5" className="header-title-CND">
                                    <div className="border-right-CND">
                                        <img className='back-image-CND' src={back} alt='' width='60' height='40' onClick={() => { BackToDash() }} />
                                    </div>
                                    <span className="year-CND" onClick={() => { BackToDash() }}>{academicYear}
                                        <i className="classForIcon-CND material-icons">chevron_right</i>
                                        {props.location.state.BackPage == "ClassDB" && <>
                                         <b id='Note-CND year-CND' onClick={() => {props.history.goBack()}}>Class</b> <i className="classForIcon-CND material-icons">chevron_right</i></>
                                         }
                                        <b id='Note-CND'>Notes</b>
                                    </span>
                                </Col>
                                <Col xs="12" md="7" className="m-auto dropdown-column-CND">
                                    <div className='header-dropdown-main-container-CND'>
                                        <div className="dropdown-main-container-CND">
                                            <img src={search} alt='' className='header-dropdown-main-container-CND dropdown-search-icon-CND' />
                                            <SelectSearch
                                                ref={searchInput}
                                                options={options}
                                                filterOptions={handleFilter}
                                                value={sessionStorage.getItem('classNameForNotes')?.length > 0 ?  { name: sessionStorage.getItem('classNameForNotes'), value: 0 } : isSelected ? { name: selectedClassName2, value: 0 } : { name: selectedClassName, value: 0 }}
                                                name="Workshop"
                                                placeholder="Search class*"
                                                search
                                                onChange={handleChange}
                                                disabled = {props.location.state.BackPage == "ClassDB"}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card >

                    {/* ------ Class add group Note----- */}

                    {/* ------ Class Filter Div----- */}
                    <Card className="refresh-section-CND mt-15 br-8">
                        <Card.Body>
                            <Row className="filterRow-CND">
                                <Col xs="12" md="5" lg="6" className="desc-search-col-CND">
                                    <span className="total-student-CND"> Total Students: {props?.classNoteDetails?.getClassNote?.total_count || 0} </span>
                                    <div className='totalNoteSearchDiv-CND'>
                                        <div className="searchSearchStylediv-CND">
                                            <div className="searchSearchdiv-CND searchStudentPlaceholder">
                                                <div className="input-group-prepend border-0">
                                                    <button id="button-addon4" type="button" className="btn btn-link text-info searchIconDiv-CND"><i className="fa fa-search searchIcon"></i></button>
                                                </div>
                                                <input
                                                    type='text'
                                                    autoComplete='off'
                                                    margin="normal"
                                                    value={searchText}
                                                    onChange={(e) => handleSearchText(e)}
                                                    className='searchStudent-CND'
                                                    placeholder="Enter Keywords…"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="12" md="7" lg="6" className="sort-by-col-CND">
                                    <span className='sort-by-CND'><p>Sort By :</p></span>
                                    <div className='Button-div-2-CND'>
                                        <button type="button" className={sortByFilter === "sort_by_first_name" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => { sortSet("sort_by_first_name") }}>
                                            First Name</button>
                                        <button type="button" className={sortByFilter === "sort_by_name" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => { sortSet("sort_by_name") }}>
                                            Last Name</button>
                                        <button type="button" className={sortByFilter === "sort_by_tag" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => { sortSet("sort_by_tag") }}>
                                            Note</button>
                                        <button type="button" className={sortByFilter === "sort_by_note_level" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => { sortSet("sort_by_note_level") }} >
                                            Level</button>
                                        <button type="button" className={sortByFilter === "sort_by_note_score" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => { sortSet("sort_by_note_score") }}>
                                            Score</button>
                                        <button type="button" className={sortByFilter === "sort_by_note_reminder_date" ? "btn btn-primary custom-button-CND" : "btn btn-outline-secondary custom-outline-btn-CND"} onClick={() => { sortSet("sort_by_note_reminder_date") }}>
                                            Note Status</button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    {/* ------ Class Filter Div----- */}
                </Col>
            </Row >
            </div>
            {/* ------ Loader Div----- */}
            {
                loader && <div >
                    <DivLoader />
                </div>
            }
            {/* ------ Loader Div----- */}

            {
                classNoteData && classNoteData.response_data && classNoteData.response_data.length > 0 ?
                    <ClassNoteStudentProfile
                        allProps={props}
                        ClassData={selectNewClass ? selectNewClassData[0] : classDetails?.length > 0 ? classDetails : props?.history?.location?.state?.selectedClass?.length > 0 ? props?.history?.location?.state?.selectedClass?.[0]?.details : props?.history?.location?.state?.ClassData}
                        enabledCheck={enabledCheck}
                        classNotes={filterClassNoteData.length > 0 ? filterClassNoteData : classNoteData.response_data}
                        filterClassNoteData={filterClassNoteData}
                        selectedStudentsForGroupNote={selectedStudentsForGroupNote}
                        classNotesDetails={classNoteData}
                        AcademicDetails={academicYear}
                        getData={getData}
                        history={history}
                        classListData={classListData}
                        selectedClassName={selectedClassName}
                        selectedClassName2={selectedClassName2}
                        pageSet={pageSet}
                        totalCount={classNoteData.total_count}
                        AcademicYearId={academicYearId}
                    />
                    :
                    <div
                    ref={noClassRef}
                    style={{height : '100vh'}}
                    >
                        {loader ? "" :
                              <p className='tapToAddText-CND' style={{position : 'absolute', top: '50%', left:'50%', transform  :'translate(-50%, -50%)', font: "normal normal 600 20px/14px Open Sans"}}> The students are not enrolled. Please enrol students to 
begin creating Notes.</p>}
                              
                    </div>
            }
            {/* {
                classNoteData && classNoteData.response_data && (classNoteData.total_count > classNoteData.response_data.length) && <div>
                    {classNoteData.response_data && classNoteData.response_data.length > 0 && <div style={{ padding: 15 }}>
                        {loader ?
                            <Button className='loadMoreRoster-CND' color="primary" >
                                <img src={loaderImag} alt='' className='loaderMoreIconCss-CND' />
                            </Button>
                            :
                            <Button onClick={pageSet} className='loadMoreRoster-CND' color="primary" >
                                <i style={{ fontSize: 20 }} className="material-icons">autorenew</i>
                                <span className='LoadButtonText-CND' > Load More</span>
                            </Button>}
                    </div>}
                </div>
            } */}
        </div >
    )


}
ClassNoteDashboard.propTypes = {
    getClassNoteRequest: PropTypes.func,
    classNoteData: PropTypes.object,
    classNoteDetails: PropTypes.object
};

export default ClassNoteDashboard


import React, { useState, useEffect } from 'react';
import crossMark from 'Assets/AttendanceModule/crossMark.svg';
import deleteIcon from 'Assets/ClassModule/delete.png'
import disBest from 'Assets/AttendanceModule/disBest.svg'
import disGood from 'Assets/AttendanceModule/disGood.svg'
import disFair from 'Assets/AttendanceModule/disFair.svg'
import bestAtt from 'Assets/LessonPlanModule/bestAtt.svg'
import goodAtt from 'Assets/LessonPlanModule/goodAtt.svg'
import fairAtt from 'Assets/LessonPlanModule/fairAtt.svg'
import './Evalution.scss';

const Evaluation = (props) => {
    const {
        viewLessonPlanDetailsState,
        selectionType,
        lessonPlanId,
        setSaveData,
        selectedTab,
        ClassDetails,
        apiCallPlanning,
        setApiDatasEvaluation,
        deleteDynamicQuestionLessonPlan,
        setEvalSubmitFade
    } = props;

    const [tempAnaQuestion, setTempAnaQuestion] = useState("");
    const [showAddMore, setShowAddMore] = useState("");
    const [questionAnswers, setQuestionAnswers] = useState([]);
    const [defaultQuestionAnswers, setDefaultQuestionAnswers] = useState([]);
    const [feedbackEval, setFeedbackEval] = useState([]);
    const [feedbackEvalDefault, setFeedbackEvalDefault] = useState([]);
    const [lpCreatedAt, setLpCreatedAt] = useState("");
    const [lpPlannedAt, setLpPlannedAt] = useState("");
    const [lpTitle, setLpTitle] = useState("");
    const [lpTopic, setLpTopic] = useState("");
    const [defaultLessonDetails, setDefaultLessonDetails] = useState([]);
    const apiData = {};

    useEffect(() => {
        if (selectedTab === "Evaluation") {
            setSaveData("evaluation")
        }
    })

    useEffect(() => {
        let emojiValues = [];
        let quesAnsValues = [];
        let defaultEmojiValues = [];
        let defaultQuesAnsValues = [];
        feedbackEval.map(val => {
            if (val.answer === null || val.answer === 0) {
                emojiValues.push(val.answer);
            }
        })
        feedbackEvalDefault.map(val => {
            if (val.answer === null || val.answer === 0) {
                defaultEmojiValues.push(val.answer);
            }
        })
        questionAnswers.map(val => {
            // if (val.answer === null || val.answer === "") {
            //     quesAnsValues.push(val.answer);
            // }
         
            if(val.answer == null) {
                
                quesAnsValues.push("some");
            } else {
                quesAnsValues.push(val.answer);  
            }   
        })
        defaultQuestionAnswers.map(val => {
            if (val.answer === null || val.answer === "") {
                defaultQuesAnsValues.push(val.answer);
            }
        })
        if (questionAnswers && feedbackEval) {
           
            if (emojiValues.length !== defaultEmojiValues.length || quesAnsValues.length !== defaultQuesAnsValues.length ||
                feedbackEval.length !== feedbackEvalDefault.length || questionAnswers.length !== defaultQuestionAnswers.length) {
                setEvalSubmitFade(true);
            } else {
                setEvalSubmitFade(false);
            }
        }
        if (selectionType == "edit") {

            setEvalSubmitFade(true)
        }
    })

    useEffect(() => {
        setDefaultLessonDetails(viewLessonPlanDetailsState);
        if (viewLessonPlanDetailsState && viewLessonPlanDetailsState.data) {
            const { cc_lp_created_at, cc_lp_planned_at, cc_lp_title, cc_lp_topic } = viewLessonPlanDetailsState.data;
            setLpCreatedAt(cc_lp_created_at);
            setLpPlannedAt(cc_lp_planned_at);
            setLpTitle(cc_lp_title);
            setLpTopic(cc_lp_topic);
        }
    }, [viewLessonPlanDetailsState])

    useEffect(() => {
        let questionAnsCombined = [];
        for (let item of questionAnswers) {
            questionAnsCombined.push(item)
        }
        for (let item of feedbackEval) {
            questionAnsCombined.push(item)
        }
        apiData['question_answer_array'] = questionAnsCombined;
        apiData["class_id"] = ClassDetails?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP");
        apiData['lesson_plan_id'] = lessonPlanId;

        setApiDatasEvaluation(apiData);
    }, [lpCreatedAt, questionAnswers, feedbackEval, viewLessonPlanDetailsState])

    useEffect(() => {
        if (selectionType === "edit" || selectionType === "addNew") {
            viewLessonPlanDetailsState && viewLessonPlanDetailsState.data.question_answer_array?.map(val => {
                const valObj = {};
                if (val.cc_lp_questionnaire_view_tab === "evaluation") {
                    valObj["id"] = val.cc_lp_questionnaire_id;
                    valObj["question_id"] = JSON.stringify(val.cc_question_id);
                    valObj["question_view_tab"] = val.cc_lp_questionnaire_view_tab;
                    valObj["type"] = val.cc_lp_questionnaire_type;
                    valObj["answer"] = val.cc_lp_questionnaire_response;
                    valObj["question_text"] = val.cc_lp_questionnaire_question_text;
                    questionAnswers.push(valObj)
                }
            })
            setDefaultQuestionAnswers(questionAnswers);
            setQuestionAnswers(questionAnswers);
        }

        viewLessonPlanDetailsState && viewLessonPlanDetailsState?.data?.evaluation_feedback_array?.map(val => {
            const feedaback = {};
            if (val.cc_lp_ef_view_tab === "analysis") {
                feedaback["id"] = val.cc_lp_ef_id;
                feedaback["question_id"] = JSON.stringify(val.cc_question_id);
                feedaback["question_view_tab"] = val.cc_lp_ef_view_tab;
                feedaback["type"] = val.cc_lp_ef_type;
                feedaback["answer"] = val.cc_lp_ef_response;
                feedaback["question_text"] = val.cc_lp_ef_question_text;
                feedbackEval.push(feedaback)
            }
        })
        setFeedbackEval(feedbackEval);
        setFeedbackEvalDefault(feedbackEval);
    }, [viewLessonPlanDetailsState])

    useEffect(() => {
        if (apiCallPlanning === false) {
            setQuestionAnswers(defaultQuestionAnswers);
            setFeedbackEval(feedbackEvalDefault);
            setTempAnaQuestion("");
            setShowAddMore(false);
        }
    }, [apiCallPlanning])

    const addMoreQuestions = () => {
        setShowAddMore(true);
    }

    const addQuestionsAns = (val, data) => {
        let answersdata = questionAnswers && questionAnswers.map(value => {
            let answerValue = { ...value }
            if (answerValue.question_id === data.question_id) {
                answerValue = { ...answerValue, answer: val }
            } else {
                answerValue = { ...answerValue }
            }
            return answerValue
        })
        setQuestionAnswers(answersdata);
    }

    const saveAnatempQue = () => {
        if (tempAnaQuestion !== "") {
            const feedaback = {};
            feedaback["id"] = null;
            feedaback["question_id"] = null;
            feedaback["question_view_tab"] = "analysis";
            feedaback["type"] = "dynamic";
            feedaback["answer"] = null;
            feedaback["question_text"] = tempAnaQuestion;
            feedbackEval.push(feedaback)
        }
        setFeedbackEval(feedbackEval);
        setTempAnaQuestion("");
        setShowAddMore(false);
    }

    const unSelectWhenClose = () => {
        setShowAddMore(false);
        setTempAnaQuestion("");
    }

    const addMoreEvalQuestions = () => {
        const Arr = [];
        const valObj = {};
        valObj["id"] = null;
        valObj["question_id"] = null;
        valObj["question_view_tab"] = "evaluation";
        valObj["type"] = "dynamic";
        valObj["answer"] = null;
        valObj["question_text"] = null;
        Arr.push(valObj)
        const questionAns = questionAnswers.concat(Arr);
        setQuestionAnswers(questionAns);
    }

    const removeMoreEvalQuestionsTemp = (val) => {
        let EvalQuestions = [...questionAnswers];
        EvalQuestions.splice(val, 1);
        setQuestionAnswers(EvalQuestions);
    }

    const removeMoreEvalQuestionsPer = (val) => {
        const apiData = {
            "lesson_plan_id": lessonPlanId,
            "view_tab": "evaluation",
            "class_id": ClassDetails?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
            "id": val
        }
        deleteDynamicQuestionLessonPlan(apiData);
    }

    const addDynamicEvalQues = (data, val) => {
        let answersdata = questionAnswers && questionAnswers.map((value, index) => {
            let answerValue = { ...value }
            if (index === val) {
                answerValue = { ...answerValue, question_text: data }
            } else {
                answerValue = { ...answerValue }
            }
            return answerValue
        })
        setQuestionAnswers(answersdata);
    }

    const addDynamicEvalAns = (data, val) => {
        let answersdata = questionAnswers && questionAnswers.map((value, index) => {
            let answerValue = { ...value }
            if (index === val) {
                answerValue = { ...answerValue, answer: data }
            } else {
                answerValue = { ...answerValue }
            }
            return answerValue
        })
        setQuestionAnswers(answersdata);
    }

    const setAttRemarks = (data, val) => {
        let answersdata = feedbackEval && feedbackEval.map((value, index) => {
            let answerValue = { ...value }
            if (index === val) {
                answerValue = { ...answerValue, answer: data }
            } else {
                answerValue = { ...answerValue }
            }
            return answerValue
        })
        setFeedbackEval(answersdata);
    }

    const disAttRemarks = (data, val) => {
        let answersdata = feedbackEval && feedbackEval.map((value, index) => {
            let answerValue = { ...value }
            if (index === val) {
                answerValue = { ...answerValue, answer: data }
            } else {
                answerValue = { ...answerValue }
            }
            return answerValue
        })
        setFeedbackEval(answersdata);
    }

    return (
        <div className="evalutionContainer" style={{height : "600px"}}>
            <div className="planTitleDiv">
                <div className='algnDivs'>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Plan Title: </p>
                        <p className="headTitles">{lpTitle}</p>
                    </div>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Planned on: </p>
                        <p className="headTitles">{lpCreatedAt}</p>
                    </div>
                </div>
                <div className='algnDivs'>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Topic: </p>
                        <p className="headTitles">{lpTopic}</p>
                    </div>
                    <div className="planTitle">
                        <p className="headTitlesPlan">Planned for: </p>
                        <p className="headTitles">{lpPlannedAt}</p>
                    </div>
                </div>
            </div>

            <div className='emojiDiv'>
                <div className='titleDiv'>
                    <p className='analyticsTitle'>Analytics</p>
                    <p className='emojiColHead'>Yes</p>
                    <p className='emojiColHead'>May be</p>
                    <p className='emojiColHead'>No</p>
                </div>
                {feedbackEval && feedbackEval.map((val, k) => {
                    return (<div className='emojiIconsDetails' key={k}>
                        <p className='questionTitle'>{val.question_text}</p>
                        {val.answer == 1 ?
                            <img className='emojiIcons-img' src={bestAtt}
                                onClick={() => { disAttRemarks(1, k) }}
                                alt='disEmoji' width='65' height='65' />
                            : <img className='emojiIcons-img' src={disBest}
                                onClick={() => { setAttRemarks(1, k) }}
                                alt='disEmoji' width='65' height='65' />}
                        {val.answer == 2 ?
                            <img className='emojiIcons-img' src={goodAtt}
                                onClick={() => { disAttRemarks(2, k) }}
                                alt='disEmoji' width='65' height='65' />
                            : <img className='emojiIcons-img' src={disGood}
                                onClick={() => { setAttRemarks(2, k) }}
                                alt='disEmoji' width='65' height='65' />}
                        {val.answer == 3 ?
                            <img className='emojiIcons-img' src={fairAtt}
                                onClick={() => { disAttRemarks(3, k) }}
                                alt='disEmoji' width='65' height='65' />
                            : <img className='emojiIcons-img' src={disFair}
                                onClick={() => { setAttRemarks(3, k) }}
                                alt='disEmoji' width='65' height='65' />}
                    </div>)
                })}
                {showAddMore && <div className='addMoreInputDiv'>
                    <input type="text" className='rationalInputText' name="email" placeholder="Enter here.." id="email" value={tempAnaQuestion} onChange={e => setTempAnaQuestion(e.target.value)} />
                    <div className='displayFlexInSeletAtt'>
                        <div className='undoSelect'
                            onClick={unSelectWhenClose}
                        >
                            <img
                                src={crossMark}
                                className='closeIcon'
                                alt=''
                                width='20'
                                height='20'
                            />
                        </div>
                        <div className='confirmSlectedDiv'
                            onClick={saveAnatempQue}
                        >
                            <i className='CrossAndConfirmIcon material-icons'>

                                check
                            </i>
                        </div>
                    </div>
                </div>}

                <button className='addMoreBtn' onClick={addMoreQuestions}> + Add more</button>
            </div>
            <div className="allSubDivs">
                {questionAnswers && questionAnswers.map((val, k) => {
                    return (
                        <div className="rationalDiv" key={k}>
                            {val.type === "static" ? <div className="rationalTitleBox">
                                <p className="rationalTitle backendQuestionCaps">{val.question_text}</p>
                            </div> :
                                <div className="rationalTitleBox">
                                    <textarea name="message" className="rationalInputsText" id="message" rows="4" value={val.question_text} onChange={e => addDynamicEvalQues(e.target.value, k)}
                                        placeholder="Enter Title here.."></textarea>
                                    {val.question_id === null && <button className='addMoreBtns' onClick={() => { removeMoreEvalQuestionsTemp(k) }}><img src={deleteIcon} alt='' className='lpOptionIcons' /> Remove</button>}
                                    {val.question_id !== null && <button className='addMoreBtns' onClick={() => { removeMoreEvalQuestionsPer(val.id) }}><img src={deleteIcon} alt='' className='lpOptionIcons' /> Remove</button>}
                                </div>
                            }
                            <div className="rationalInputBox">
                                {val.type === "static" ? <textarea name="message" className="rationalInputText" id="message" rows="4" value={val.answer} onChange={e => addQuestionsAns(e.target.value, val)}
                                    placeholder="Enter description here.."></textarea> :
                                    <textarea name="message" className="rationalInputText" id="message" rows="4" value={val.answer} onChange={e => addDynamicEvalAns(e.target.value, k)}
                                        placeholder="Enter description here.."></textarea>}
                            </div>
                        </div>
                    )
                })}
                <div className="rationalDiv">
                    <div className="rationalTitleBox">
                        <button className='addMoreBtns' onClick={addMoreEvalQuestions}> + Add More</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Evaluation

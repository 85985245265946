import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { Button } from 'reactstrap'
import 'react-datepicker/dist/react-datepicker.css'
import eye from 'Assets/AttendanceModule/eye.svg'
import './AttendanceDashboard.scss'
import arrow from 'Assets/AttendanceModule/arrow.png';
import back from 'Assets/CommonComponent/back.png'
import search from 'Assets/AttendanceModule/search.png'
import SelectSearch from 'react-select-search';
import { getClassList } from 'Actions/ClassAction/classAction';
import * as routes from "Router/RoutesURL";

const AttendanceDashboard = props => {
    const {
        history,
        classData,
    } = props;
    

    const classID = props.location.state.data
    const [showParticularAtt, setShowParticularAtt] = useState(false);
    const [showRangeAtt, setShowRangeAtt] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const academicYear = classID.academic_year

    useEffect(() => {
        const data = classID.year_ids
        
        const value = {
            filter_by: 'both',
            class_academic_year: data
        }
        props.getClassList(value)
    }, [classID])

    const searchInput = useRef();
    const classDataList = classData.class?.class_Data?.data?.[0]
    
    const newClassDataList = classDataList?.map((obj, i) => (
        {
            ...obj,
            name: classDataList[i].cc_class_name,
            value: classDataList[i].cc_class_id
        }

    ));
    const newArr = newClassDataList?.map((name, i) => ({ ['name']: newClassDataList[i].cc_class_name, ['value']: newClassDataList[i].cc_class_id }));
    const options = [
        {
            type: "group",
            name: "",
            items: newArr !== undefined ? newArr : [{ name: "", value: "" },]
        }
    ];

    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    const handleChange = (value, ...args) => {

        let data = classDataList?.find((item) => item.cc_class_id == value)
        history.push(routes.CLASSATTENDANCE,
            {
                classDetail: args[0].value,
                selectedClass: args,
                classListData: newArr,
                academicYear: academicYear,
                byAttendanceBtn: true,
                selectedClassDetail : data
            }
        );
    };

    const BackTOHome = () => {
        history.goBack()
        setShowParticularAtt(false);
        setShowRangeAtt(false);
    }

    return (
        <div className={'attendance-select-class-main-container'}>
            {!showPreview &&
                <div>
                    {!showParticularAtt && !showRangeAtt && (
                        <div className='header-main-container'>
                            <div className='header-breadcrumb-main-container'>
                                <div className='header-breadcrumb-back-btn-div'>
                                    <img className='header-breadcrumb-back-btn-img' src={back} alt='' onClick={BackTOHome} />
                                </div>
                                <div className='header-breadcrumb-inner-div'>
                                    <div className='breadcrumb-inner-flex-div'>
                                        <p className='breadcrumb-academic-year-text' onClick={BackTOHome}>{academicYear}</p>
                                        <i className="breadcrumb-arrow-icon material-icons">chevron_right</i>
                                        <p className='breadcrumb-attendance-text'>Attendance </p>
                                    </div>
                                </div>
                            </div>
                            <div className='header-dropdown-main-container'>
                                <div className="dropdown-main-container">
                                    <div className="iconDiv"><i className="fa fa-search searchFiltersIcon"></i></div>
                                    <SelectSearch
                                        ref={searchInput}
                                        options={options}
                                        filterOptions={handleFilter}
                                        value=""
                                        name="Workshop"
                                        placeholder="Search class*"
                                        search
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='header-view-report-main-container'>
                                <Button className='report-btn-container' disabled={true}>
                                    <img className='report-btn-icon' src={eye} alt='' />
                                    <span className='report-btn-text'> Report </span>
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            }
            <div className='body-main-container'>
                <img src={arrow} alt='' className='arrow-icon' />
                <div className='body-text-div'>
                    <span className='body-text'>Please select class to fill or view attendance</span>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    state: state,
    classData: state,
    loader: state.class.loader
});
const mapDispatchToProps = dispatch => {
    return {
        getClassList: (data) => dispatch(getClassList(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(
    AttendanceDashboard
);
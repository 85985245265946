import React, { useState, useEffect, useRef } from 'react';
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { saveAs } from 'file-saver';
import SelectSearch from 'react-select-search';
import calendarIcon from 'Assets/NoteModule/calendar.svg'
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import downloads from 'Assets/LessonPlanModule/downloads.svg';
import shareLesson from 'Assets/LessonPlanModule/shareLesson.svg';
import calclrIcon from 'Assets/RubricsModule/calclrIcon.png';
import importRub from 'Assets/RubricsModule/importRub.png';
import shareRubIcon from 'Assets/RubricsModule/shareRubIcon.png';
import back from 'Assets/CommonComponent/back.png';
import addIcon from 'Assets/ClassGradeBookModule/plus.svg';
import arrow from 'Assets/AttendanceModule/arrow.png';
import warningIcon from 'Assets/FormativeAssessmentModule/warningIcon.png';
import { getFormattedDate, addDaysGetSpan } from 'Utils/Helper';
import PreviewRubricsReport from './PreviewRubricsReport';
import ImportRubrics from './ImportRubrics.js';
import axios from 'axios';
import { createNotification } from 'Config/notificationtoast';
import { BASE_MICROSERVICE_URL } from "../../../Config/Constant";
import * as routes from "Router/RoutesURL";
import './ClassRubricsDashboard.scss';
import { useSelector } from 'react-redux';

const ClassRubricsDashboard = (props) => {
    const {
        getRubricList,
        deleteRubric,
        getRubricListState,
        deleteRubricState,
        forwardRubricsReport,
        getClassList,
        classeslist,
        history,
        state
    } = props;
    const { location } = history
    const { AcademicData, backpage } = location.state;
    const [rubricsListAll, setRubricsListAll] = useState([]);
    const [rubricsCount, setRubricsCount] = useState("");
    const [filterType, setFilterType] = useState("all");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [firstDatePre, setFirstDatePre] = useState("");
    const [rangeSecondDatePre, setRangeSecondDatePre] = useState("");
    const [showViewAtt, setShowViewAtt] = useState(false);
    const [firstDate, setFirstDate] = useState("");
    const [rangeSecondDate, setRangeSecondDate] = useState("");
    const [firstDateReportPre, setFirstDateReportPre] = useState("");
    const [rangeSecondDateReportPre, setRangeSecondDateReportPre] = useState("");
    const [recordFound, setRecordFound] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [upoadFile, setUpoadFile] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const [showImportPage, setShowImportPage] = useState(false);
    const [showPreviewType, setShowPreviewType] = useState("");
    const [showViewDateRange, setShowViewDateRange] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searchInputRub, setSearchInputRub] = useState('');
    const [mainResponse, setMainResponse] = useState([]);
    const [showAddNewRub, setShowAddNewRub] = useState(false);
    const [selectedClass, setSelectedClass] = useState("");
    const [ClassData, setClassdata] = useState({});
    const [classDetail, setClassdetail] = useState({});
    const [classList, setClassList] = useState();
    const [selectedClassName, setSelectedClassName] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [showClassConfirm, setShowClassConfirm] = useState(false);
    const [chooseClass, setChooseClass] = useState("");
    const [chooseClassName, setChooseClassName] = useState("");
    const [showConfirmBreadCrumb, setShowConfirmBreadCrumb] = useState(false);
    const [selectedRubReport, setSelectedRubReport] = useState("");
    const [allClassesData, setAllClassesData] = useState([])
    const [classPermissionShared, setClassPermissionShared] = useState('')
    const { academicYearId, academicYear, classId, classPermission } = history.location.state;
    const signDate = sessionStorage.getItem('separatorFormat')
    const formatDate = sessionStorage.getItem('dateFormat')
    const { loader, action } = state.classRubrics;
    const _ = require('lodash');
    const base_url_API = BASE_MICROSERVICE_URL;
    const device_udid = sessionStorage.getItem('UDID');
    const userLocalData = JSON.parse(sessionStorage.getItem('UserData'))
    const checkBoxDrft = ["checkBoxesfiltr material-icons", filterType === "drafted" ? "checkedColor" : ""]
    const allFiltDiv = ["allFiltDiv", filterType !== "all" ? "deSelectBtn" : ""]
    const drftDiv = ["drftDiv", filterType !== "drafted" ? "deSelectBtn" : ""]
    const cmpltDiv = ["cmpltDiv", filterType !== "completed" ? "deSelectBtn" : ""]

    const allClassData = useSelector((state) => state?.academic?.academic_Roster?.data?.class_data)

    const fullClassListData = useSelector((state) => state?.class?.class_Data?.data?.[0])
    


    useEffect(() => {
        setAllClassesData(allClassData)
    },[allClassData])

    useEffect(() => {
        if (backpage !== "classes") {
            const value = {
                filter_by: 'both',
                // page_no: "full",
                class_academic_year: AcademicData.year_ids
            }
            getClassList(value)
        }
    }, [])

    useEffect(() => {
        if (classeslist.action === "GET_CLASS_LIST_SUCCESS" && classeslist && classeslist?.class_Data && classeslist?.class_Data.data[0]?.length > 0 || AcademicData.class_data.length > 0) {
            setClassList(classeslist?.class_Data?.data?.[0] || AcademicData?.class_data)
        }
    }, [classeslist, classeslist.action])

    useEffect(() => {
        const rubricsSelectedClassData = JSON.parse(sessionStorage.getItem('rubricsSelectedClass'))
        if (rubricsSelectedClassData && rubricsSelectedClassData !== "" && rubricsSelectedClassData.cc_class_id && rubricsSelectedClassData.student_count > 0) {
            setShowAddNewRub(false);
            if (firstDatePre !== "") {
                const firstDate = addDaysGetSpan(firstDatePre, 1);
                const secondDate = addDaysGetSpan(rangeSecondDatePre, 1);
                const apiData = {
                    "class_id": rubricsSelectedClassData.cc_class_id,
                    "rubric_status": filterType,
                    "start_date": getDateFormatAsApi(firstDate),
                    "end_date": getDateFormatAsApi(secondDate)
                }
                getRubricList(apiData)
            } else {
                const apiData = {
                    "class_id": rubricsSelectedClassData.cc_class_id,
                    "rubric_status": filterType,
                    "start_date": "",
                    "end_date": ""
                }
                getRubricList(apiData)
            }
        }
    }, [filterType])

    useEffect(() => {
        const rubricsSelectedClassData = JSON.parse(sessionStorage.getItem('rubricsSelectedClass'))
        if (rubricsSelectedClassData && rubricsSelectedClassData !== "") {
            setShowAddNewRub(false);
            setSelectedClass(rubricsSelectedClassData?.cc_class_id);
            setClassdetail(rubricsSelectedClassData);
            setSelectedClassName(rubricsSelectedClassData?.cc_class_name);
            setClassdata(rubricsSelectedClassData);
            setSearchInputRub("");
            if (rubricsSelectedClassData?.student_count > 0) {
                const apiData = {
                    "class_id": rubricsSelectedClassData?.cc_class_id,
                    "rubric_status": filterType,
                    "start_date": "",
                    "end_date": ""
                }
                getRubricList(apiData)
            }
        }
    }, [])

    useEffect(() => {
        const spanDate = addDaysGetSpan(new Date(), 1);
        setFirstDateReportPre(new Date());
        setRangeSecondDateReportPre(spanDate);
        const formattedDate = getFormattedDate(new Date(), formatDate, signDate)
        setFirstDate(formattedDate);
        const secondFormattedDate = getFormattedDate(spanDate, formatDate, signDate)
        setRangeSecondDate(secondFormattedDate);
    }, [showViewAtt])

    useEffect(() => {
        if (action === "ADD_RUBRIC_SUCCESS") {
            const rubricsSelectedClassData = JSON.parse(sessionStorage.getItem('rubricsSelectedClass'))
            setSelectedClass(rubricsSelectedClassData.cc_class_id);
            setClassdetail(rubricsSelectedClassData);
            setSelectedClassName(rubricsSelectedClassData.cc_class_name);
            setClassdata(rubricsSelectedClassData);
            setSearchInputRub("");
            const apiData = {
                "class_id": rubricsSelectedClassData.cc_class_id,
                "rubric_status": filterType,
                "start_date": "",
                "end_date": ""
            }
            getRubricList(apiData)
        }
    }, [action])

    useEffect(() => {
        if (startDate === "" && showViewDateRange) {
            setFirstDatePre(new Date());
            const formattedDate = getFormattedDate(new Date(), formatDate, signDate)
            setStartDate(formattedDate);
            const spanDate = addDaysGetSpan(new Date(), 1);
            setRangeSecondDatePre(spanDate);
            const formattedSecondDate = getFormattedDate(spanDate, formatDate, signDate)
            setEndDate(formattedSecondDate);
        }
    }, [showViewDateRange])

    useEffect(() => {
        if (getRubricListState && getRubricListState?.data?.rubricArr) {
            let rubricsData = getRubricListState?.data?.rubricArr && getRubricListState?.data?.rubricArr.map(rubrics => {
                let rubricsValue = { ...rubrics }
                rubricsValue = {
                    ...rubricsValue,
                    editOpen: false
                }
                return rubricsValue
            })
            setRubricsCount(rubricsData.length);
            setRubricsListAll(rubricsData);
            setMainResponse(rubricsData);
        }
    }, [getRubricListState])

    const getDateFormatAsApi = date => {
        const FormatDate = date.toISOString().split('T')[0]
        return FormatDate
    }

    useEffect(() => {
        if (deleteRubricState && deleteRubricState?.status === 200) {
            const rubricsSelectedClassData = JSON.parse(sessionStorage.getItem('rubricsSelectedClass'))
            setSelectedClass(rubricsSelectedClassData?.cc_class_id);
            setClassdetail(rubricsSelectedClassData);
            setSelectedClassName(rubricsSelectedClassData?.cc_class_name);
            setClassdata(rubricsSelectedClassData);
            setSearchInputRub("");
            const apiData = {
                "class_id": rubricsSelectedClassData?.cc_class_id,
                "rubric_status": filterType,
                "start_date": "",
                "end_date": ""
            }
            getRubricList(apiData)
        }
    }, [deleteRubricState])

    const searchInput = useRef();
    const classDataList = classList;
    const newClassDataList = classList?.map((obj, i) => (
        {
            ...obj,
            name: classDataList[i].cc_class_name,
            value: classDataList[i].cc_class_id,
        }
    ));
    const newArr = newClassDataList?.map((name, i) => ({ ['name']: newClassDataList[i].cc_class_name, ['value']: newClassDataList[i].cc_class_id, ["data"]: newClassDataList[i] }));

    const options = [
        {
            type: "group",
            name: "",
            items: newArr !== undefined ? newArr : [{ name: "", value: "", data: "" },]
        }
    ];

    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return options;
            }
            const updatedItems = items.map((list) => {
                const newItems = list.items.filter((item) => {
                    return item.name.toLowerCase().includes(searchValue.toLowerCase());
                });
                return { ...list, items: newItems };
            });
            return updatedItems;
        };
    };

    const handleChange = (value, ...args) => {
        let classData = {}
        if(JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher") {
            classData = fullClassListData?.find((item) => item?.cc_class_id == value)
        } else {
            classData = allClassData?.find((item) => item?.cc_class_id == value)
        }
        
        
        setClassPermissionShared(classData?.cc_class_share_permission)
        if (firstDatePre !== "" || rangeSecondDatePre !== "") {
            setRubricsListAll([]);
            setChooseClass(args[0].value);
            setChooseClassName(args[0].name);
            setClassdata(args[0].data);
            setSearchInputRub("");
            setShowClassConfirm(true);
        } else if (searchInputRub !== "") {
            setRubricsListAll([]);
            setChooseClass(args[0].value);
            setChooseClassName(args[0].name);
            setClassdata(args[0].data);
            setSearchInputRub("");
            setShowClassConfirm(true);
        } else if (filterType !== "all") {
            setRubricsListAll([]);
            setChooseClass(args[0].value);
            setChooseClassName(args[0].name);
            setClassdata(args[0].data);
            setSearchInputRub("");
            setShowClassConfirm(true);
        } else {
            setRubricsListAll([]);
            sessionStorage.setItem("rubricsSelectedClass", JSON.stringify(args[0].data));
            setSelectedClass(args[0].value);
            setClassdetail(args[0].data);
            setSelectedClassName(args[0].name);
            setClassdata(args[0].data);
            setSearchInputRub("");
            if (args[0].data.student_count > 0) {
                const apiData = {
                    "class_id": args[0].value,
                    "rubric_status": filterType,
                    "start_date": "",
                    "end_date": ""
                }
                getRubricList(apiData)
            }
        }
    };

    const searchItems = (searchValue) => {
        setSearchInputRub(searchValue)
        if (searchValue !== '') {
            const filteredData = mainResponse.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInputRub.toLowerCase())
            })
            setRubricsListAll(filteredData)
        }
        else {
            setRubricsListAll(mainResponse)
        }
    }

    const BackTOHomeClass = () => {
        if (firstDatePre !== "" || rangeSecondDatePre !== "") {
            setShowConfirm(true);
        } else if (searchInputRub !== "") {
            setShowConfirm(true);
        } else if (filterType !== "all") {
            setShowConfirm(true);
        } else {
            setRubricsListAll([]);
            setRubricsCount("");
            setFilterType("drafted");
            setStartDate("");
            setEndDate("");
            setFirstDatePre("");
            setRangeSecondDatePre("");
            setSearchInputRub("");
            setShowViewDateRange(false);
            sessionStorage.setItem("rubricsSelectedClass", JSON.stringify(""));
            history.goBack();
        }
    }
    const BackTOHome = () => {
        if (firstDatePre !== "" || rangeSecondDatePre !== "") {
            setShowConfirm(true);
        } else if (searchInputRub !== "") {
            setShowConfirm(true);
        } else if (filterType !== "all") {
            setShowConfirm(true);
        } else {
            setRubricsListAll([]);
            setRubricsCount("");
            setFilterType("drafted");
            setStartDate("");
            setEndDate("");
            setFirstDatePre("");
            setRangeSecondDatePre("");
            setSearchInputRub("");
            setShowViewDateRange(false);
            sessionStorage.setItem("rubricsSelectedClass", JSON.stringify(""));
            history.push(routes.ACADEMICDASHBOARD)
        }
    }

    const deleteRubricValue = (value, index) => {
        const apiData = {
            "class_id": classDetail.cc_class_id,
            "rubric_id": value.cc_rubric_id,
        }
        deleteRubric(apiData)
    };

    const cancelViewAtt = () => {
        setShowViewDateRange(false);
    };

    const confirmationViewReport = () => {
        const rubricsSelectedClassData = JSON.parse(sessionStorage.getItem('rubricsSelectedClass'))
        setShowViewDateRange(false);
        const firstDate = addDaysGetSpan(firstDatePre, 1);
        const secondDate = addDaysGetSpan(rangeSecondDatePre, 1);
        const apiData = {
            "class_id": rubricsSelectedClassData.cc_class_id,
            "rubric_status": filterType,
            "start_date": getDateFormatAsApi(firstDate),
            "end_date": getDateFormatAsApi(secondDate)
        }
        getRubricList(apiData)
    };

    const confirmationViewRubReport = () => {
        setShowViewAtt(false);
        setShowPreview(true);
        setShowPreviewType("pdf");
        const rangeStartDate = getDateFormatAsApi(firstDateReportPre);
        const rangeEndDate = getDateFormatAsApi(rangeSecondDateReportPre);
        if (selectedRubReport !== "") {
            const pdfUrl = base_url_API + `rubric/apiDownloadRubricPdf?user_id=${userLocalData.cc_user_id}&device_udid=${device_udid}&class_id=${classDetail.cc_class_id}&platform=web`
            reportPDFDownload(pdfUrl, `${"rubricReport.pdf"}`);
        } else {
            const pdfUrl = base_url_API + `rubric/apiDownloadRubricPdf?user_id=${userLocalData.cc_user_id}&start_date=${rangeStartDate}&end_date=${rangeEndDate}&class_id=${classDetail.cc_class_id}&platform=web`
            reportPDFDownload(pdfUrl, `${"rubricReport.pdf"}`);
        }
    };
    // https://dev-us-central01-gateway-api.chroniclecloud.com/rubric/apiDownloadRubricPdf?user_id=154&start_date=2022-08-31&end_date=2022-12-12&class_id=413
    // {
    //     "user_id": "string",
    //     "start_date": "string",
    //     "end_date": "string",
    //     "class_id": "string",
    //     "rubric_id": "string",
    //     "platform": "string"
    //   }

    function blobToFile(theBlob, fileName) {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }

    const reportPDFDownload = (URl, projectName) => {
        const options = {
            method: 'GET',
            baseURL: URl,
        };
        axios(options).then(async responses => {
            if (responses.data.message === "Data not found") {

                setRecordFound(responses.data.message);
                createNotification('error', responses.data.message);
            } else {
                const options = {
                    method: 'GET',
                    baseURL: URl,
                    responseType: 'blob'
                };
                axios(options).then(async responses => {
                    const file = new Blob(
                        [responses.data],
                        { type: 'application/pdf' });
                    const myFile = blobToFile(file, "rubrics.pdf");
                    setUpoadFile(myFile)
                    const fileURL = URL.createObjectURL(file);
                    setFileUrl(fileURL)
                })
            }
        })
            .catch(err => {
                if (err && err.message) {
                    createNotification('error', err.message);
                }
            });
    }
    const singleReportPDFDownload = (URl, projectName) => {
        const options = {
            method: 'GET',
            baseURL: URl,
        };
        axios(options).then(async responses => {
            if (responses.data.message === "No record found") {
                setRecordFound(responses.data.message);
                createNotification('error', responses.data.message);
            } else {
                const options = {
                    method: 'GET',
                    baseURL: URl,
                    responseType: 'blob'
                };
                axios(options).then(async responses => {
                    const file = new Blob(
                        [responses.data],
                        { type: 'application/pdf' });
                    const myFile = blobToFile(file, "rubrics.pdf");
                    setUpoadFile(myFile)
                    const fileURL = URL.createObjectURL(file);
                    const fileName = `rubricsReport ${new Date()}.pdf`
                    saveAs(fileURL, fileName);
                })
            }
        })
            .catch(err => {
                if (err && err.message) {
                    createNotification('error', err.message);
                }
            });
    }

    const editLesson = (val) => {
        let lessonData =
            rubricsListAll && rubricsListAll.map((rubrics, index) => {
                let rubricsValue = { ...rubrics }
                if (val === index) {
                    if (rubricsValue.editOpen === false) {
                        rubricsValue = {
                            ...rubricsValue,
                            editOpen: true
                        }
                        return rubricsValue
                    } else {
                        rubricsValue = {
                            ...rubricsValue,
                            editOpen: false
                        }
                        return rubricsValue
                    }
                } else {
                    rubricsValue = {
                        ...rubricsValue,
                        editOpen: false
                    }
                    return rubricsValue
                }
            })
        setRubricsListAll(lessonData)
    }

    const setRangeFirstDate = (date) => {
        setFirstDatePre(date);
        const formattedDate = getFormattedDate(date, formatDate, signDate)
        setStartDate(formattedDate);
    }

    const addRangeSecondDate = (date) => {
        setRangeSecondDatePre(date);
        const formattedDate = getFormattedDate(date, formatDate, signDate)
        setEndDate(formattedDate);
    }

    const setRangeFirstDateReport = (date) => {
        setFirstDateReportPre(date);
        const formattedDate = getFormattedDate(date, formatDate, signDate)
        setFirstDate(formattedDate);
    }

    const addRangeSecondDateReport = (date) => {
        setRangeSecondDateReportPre(date);
        const formattedDate = getFormattedDate(date, formatDate, signDate)
        setRangeSecondDate(formattedDate);
    }

    const viewAttendanceClick = (value, item) => {
        if (item && value === "particular" && item.cc_rubric_id) {
            setSelectedRubReport(item.cc_rubric_id);
            const pdfUrl = base_url_API + `rubric/apiDownloadRubricPdf?user_id=${userLocalData.cc_user_id}&class_id=${classDetail.cc_class_id}&rubric_id=${item.cc_rubric_id}&platform=web`
            setShowPreview(true);
            setShowPreviewType("pdf");
            reportPDFDownload(pdfUrl, `${"rubricReport.pdf"}`);
        } else {
            setSelectedRubReport("");
            setShowViewAtt(true);
        }
    }

    // {
    //     "user_id": "string",
    //     "start_date": "string",
    //     "end_date": "string",
    //     "class_id": "string",
    //     "rubric_id": "string",
    //     "platform": "string"
    //   }
    const downnloadRubrics = (value, item) => {
        if (item && value === "particular" && item.cc_rubric_id) {
            setSelectedRubReport(item.cc_rubric_id);
            const pdfUrl = base_url_API + `rubric/apiDownloadRubricPdf?user_id=${userLocalData.cc_user_id}&class_id=${classDetail.cc_class_id}&platform=web`
            singleReportPDFDownload(pdfUrl, `${"rubricReport.pdf"}`);
        }
    }

    const cancelViewRub = date => {
        setShowViewAtt(false);
        setShowPreview(false);
        setShowPreviewType("pdf");
    }
    const changeFilterType = (value) => {
        setFilterType(value);
    }

    const openEditRubric = (item) => {
        if (item.cc_rubric_status === "drafted") {
            history.push(routes.ADDNEWRUBRICS, {
                ClassDetails: classDetail,
                selectionType: "edit",
                SelectedRubric: item,
                academicYear: AcademicData.academic_year,
                fillBtnEnables2: true,
            })
        } else if (item.cc_rubric_status === "completed") {
            history.push(routes.VIEWRUBRIC, {
                ClassDetails: classDetail,
                selectionType: "view",
                SelectedRubric: item,
                fillBtnEnables2: true,
            })
        }
    }

    const cancelClassWarnModal = () => {
        setShowClassConfirm(false);
    }

    const closeClassWarnModal = () => {
        setShowClassConfirm(false);
        setSelectedClass(chooseClass);
        setSelectedClassName(chooseClassName);
        const apiData = {
            "class_id": chooseClass,
            "rubric_status": filterType,
            "start_date": "",
            "end_date": ""
        }
        getRubricList(apiData)
    }

    const cancelWarnModal = () => {
        setShowConfirm(false);
    }

    const closeWarnModal = () => {
        setRubricsListAll([]);
        setRubricsCount("");
        setFilterType("drafted");
        setStartDate("");
        setEndDate("");
        setFirstDatePre("");
        setRangeSecondDatePre("");
        setShowViewDateRange(false);
        sessionStorage.setItem("rubricsSelectedClass", JSON.stringify(""));
        history.goBack();

    }

    return (
        <div className='classRubricsContainer'>
            {!showImportPage && !showPreview && <div>
                <div className='RubricsMainDiv'>
                    <div className="rubBackDivmain">
                        <div className='rubricsBackDiv'>
                            <img className='calIcon' src={back} alt='' width='60' height='40' onClick={BackTOHomeClass} />
                        </div>
                        <div className="vrLineRub"></div>
                        <div className='rubricsDetailsDiv'>
                            <div className='displayFlexClassRubrics'>
                                <p className='rubricsGrade' onClick={BackTOHome}>{AcademicData.academic_year}</p>
                                <i className="rubricsForIcon material-icons">chevron_right</i>
                                {backpage !== "classes" && backpage !== "viewSingle" && <p className='rubricsGrade2'>Rubrics</p>}
                                {backpage === "classes" && <p className='rubricsGrade' onClick={BackTOHomeClass}>Class</p>}
                                {backpage === "classes" && <i className="rubricsForIcon material-icons">chevron_right</i>}
                                {backpage === "classes" && <p className='rubricsGrade2'>Rubrics</p>}
                                {backpage === "classes" && <p className='rubricsGrade2'>{`: ${classDetail.cc_class_name}`}</p>}
                                {backpage === "viewSingle" && <p className='rubricsGrade2'>Rubrics</p>}
                                {backpage === "viewSingle" && <p className='rubricsGrade2'>{`: ${classDetail.cc_class_name}`}</p>}
                            </div>
                        </div>
                    </div>
                    {backpage !== "classes" && backpage !== "viewSingle" && <div className="dropdownsRubrics-main-container">
                        <div className="iconDiv"><i className="fa fa-search searchFiltersIcon"></i></div>
                        <SelectSearch
                            ref={searchInput}
                            options={options}
                            filterOptions={handleFilter}
                            value={{ name: selectedClassName, value: 0 }}
                            name="Workshop"
                            placeholder="Search class*"
                            search
                            onChange={handleChange}
                        />
                    </div>}
                    <div className='buttonMarginTop'>
                        {selectedClass !== "" && ClassData.student_count > 0 && <Button className='importRubricsBtn'
                            onClick={() => {
                                history.push(routes.ADDNEWRUBRICS, {
                                    ClassDetails: classDetail,
                                    selectionType: "addNew",
                                    academicYear: AcademicData.academic_year,
                                    backpage: "classes",


                                })
                            }}
                            disabled={(props.location.state.BackPage == "Academic Year" && classPermissionShared == 'read' ) || history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read'}
                        >
                            <img className='rubBtnIcon' src={addIcon} alt='' />
                            <span className='lessonBtnText'>Rubric</span>
                        </Button>}

                        {selectedClass !== "" && ClassData?.student_count > 0 && <Button className='importRubricsBtn'
                            onClick={() => { setShowImportPage(true) }}
                            disabled={(props.location.state.BackPage == "Academic Year" && classPermissionShared == 'read' ) || history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read'}
                        >
                            <img className='rubBtnIcon' src={importRub} alt='' />
                            <span className='lessonBtnText'>Import</span>
                        </Button>}
                        {selectedClass !== "" && ClassData?.student_count > 0 && <Button className='addNewRubricsBtn'
                            onClick={() => { viewAttendanceClick("all") }}
                            disabled={(props.location.state.BackPage == "Academic Year" && classPermissionShared == 'read' ) || history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read'}
                        >
                            <img className='rubBtnIcon' src={shareRubIcon} alt='' />
                            <span className='lessonBtnText'>Share</span>
                        </Button>}
                    </div>
                </div>
                <div>
                    {selectedClassName && ClassData?.student_count > 0 && <div className="rubFiltMainDiv">
                        <div className='lessonDetailTxtDiv'>
                            <p className='rubricNumb'> Plan Count: {rubricsCount}</p>
                            <div className="vrLineNoRub"></div>
                            <div className="wrapperSearchDiv">
                                <div className="iconDiv"><i className="fa fa-search searchFilterIcon"></i></div>
                                <input icon='Search'
                                    className="inputBoxComp"
                                    placeholder='Enter Keywords…'
                                    onChange={(e) => searchItems(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="subDivs">
                            <div className="calDiv">
                                <img src={calclrIcon} className="calclrIcons" onClick={() => { setShowViewDateRange(true) }} alt="" width="18" height="18" />
                            </div>
                            <div className="vrLineCalRub"></div>
                            <div className={allFiltDiv.join(' ')}>
                                <div className='filterTimeDiv' onClick={() => { changeFilterType("all") }}>
                                    <p className='filterText'>List All</p>
                                </div>
                            </div>
                            <div className={drftDiv.join(' ')}>
                                <div className='filterTimeDiv' onClick={() => { changeFilterType("drafted") }}>
                                    <p className='filterText'>Drafted</p>
                                </div>
                            </div>
                            <div className={cmpltDiv.join(' ')}>
                                <div className='filterTimeDiv' onClick={() => { changeFilterType("completed") }}>
                                    <p className='filterText'>Completed</p>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {loader && <div>
                        <img src={loaderImag} alt='' className='loaderIconCss' />
                    </div>}

                    {!selectedClassName && <div className='rubContainersDiv'>
                        <img src={arrow} alt='' className='rubricsArrowImage' />
                        <div className='rubContainerTextDiv'>
                            <span className='rubContainerText'>Please select class to view or create rubrics</span>
                        </div>
                    </div>}
                    {selectedClassName && ClassData?.student_count < 1 && <div className='rubContainersDiv'>
                        <p className='rubContainerTextNoStd'>Students not enrolled. Please enrol students to create Rubrics.</p>
                    </div>}
                    {selectedClassName && ClassData?.student_count > 0 && getRubricListState && rubricsListAll.length < 1 && <div className='rubContainersDiv'>
                        <p className='rubContainerTextNoStd'>Get your Rubrics started by clicking the "+ Rubrics" above!</p>
                    </div>}
                    <Row className="mainColRubRow">
                        <Col md="12" className="mainColRubrics">
                            <Row className="groupPanels">
                                {selectedClass !== "" && ClassData?.student_count > 0 && getRubricListState && rubricsListAll && rubricsListAll.length > 0 && rubricsListAll.map((item, index) => {
                                    return (
                                        <Col md="4" lg="3" className="mainCardDivsRub" key={index}>
                                            <div className="classCardDivs">
                                                <div className='classDetailsCards'>
                                                    <div className="topDiv">
                                                        <div className='textLeft'
                                                            onClick={() => { if(((props.location.state.BackPage == "Academic Year" && classPermissionShared == 'read' ) || history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read')) {
                                                                return;
                                                            } else {
                                                                openEditRubric(item) 
                                                            } }}
                                                            style={{cursor : ((props.location.state.BackPage == "Academic Year" && classPermissionShared == 'read' ) || history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read') && 'default'}}
                                                            >
                                                            <div>
                                                                <p className='lessonTitle'>{_.truncate(item.cc_rubric_title, {
                                                                    'length': 22,
                                                                    'omission': '...'
                                                                })}</p>
                                                                <div className='headingTitles'>
                                                                    <p className='attTitleName'> {getFormattedDate(item.cc_rubric_date, formatDate, signDate)} </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='textRight'>
                                                            <div style={{ display: 'flex' }}>
                                                                <div id={`PopoverLegacy${index}`} type="button" className='classMenuIcon'
                                                                    onClick={() => editLesson(index)}
                                                                >
                                                                    <i className="material-icons">more_vert</i>
                                                                    <UncontrolledPopover
                                                                        isOpen={item.editOpen}
                                                                        target={`PopoverLegacy${index}`}
                                                                        trigger="legacy"
                                                                        placement="left"
                                                                        className='popoverRubrics'
                                                                        style={{ width: 100 }}>
                                                                        <PopoverBody className='popoverRubrics'>
                                                                            {item.cc_rubric_status === "drafted" &&
                                                                                <div>
                                                                                    <div className='editClasspopOver'
                                                                                        onClick={() => { 
                                                                                            if((props.location.state.BackPage == "Academic Year" && classPermissionShared == 'read' ) || history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read') {
                                                                                                return;
                                                                                            } else {
                                                                                                openEditRubric(item)
                                                                                            }
                                                                                             }}
                                                                                             style={{opacity : ((props.location.state.BackPage == "Academic Year" && classPermissionShared == 'read' ) || history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read') && '0.5'}}
                                                                                    >
                                                                                        <i className="editClassIcon material-icons">edit</i>
                                                                                        <span className='editTextClassPopOver'>Edit</span>
                                                                                    </div>

                                                                                    <div onClick={() => {
                                                                                        if((props.location.state.BackPage == "Academic Year" && classPermissionShared == 'read' ) || history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read') {
                                                                                            return;
                                                                                        } else {
                                                                                            deleteRubricValue(item, index)
                                                                                        }
                                                                                        
                                                                                    }} className='deleteClassPopOver'
                                                                                    style={{opacity : ((props.location.state.BackPage == "Academic Year" && classPermissionShared == 'read' ) || history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read') && '0.5'}}
                                                                                    >
                                                                                        <i className="editClassIcon material-icons">delete</i>
                                                                                        <span className='deleteTextClassPopOver'>Delete</span>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {item.cc_rubric_status === "completed" &&
                                                                                <div>
                                                                                    <div className='editClasspopOver'
                                                                                        onClick={() => { 
                                                                                            if(((props.location.state.BackPage == "Academic Year" && classPermissionShared == 'read' ) || history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read')) {
                                                                                                return;
                                                                                            } else {
                                                                                                viewAttendanceClick("particular", item)
                                                                                            }
                                                                                             }}
                                                                                             style={{opacity : ((props.location.state.BackPage == "Academic Year" && classPermissionShared == 'read' ) || history?.location?.state?.classDetailsdata?.cc_class_share_permission == 'read') && '0.5'}}
                                                                                    >
                                                                                        <img className='viewLpIcons' src={shareLesson} alt='' />
                                                                                        <span className='editTextClassPopOver'>Share</span>
                                                                                    </div>

                                                                                    <div className='downloadClasspopOver'
                                                                                        onClick={() => { downnloadRubrics("particular", item) }}
                                                                                    >
                                                                                        <img className='viewLpIcons' src={downloads} alt='' />
                                                                                        <span className='editTextClassPopOver'>Download</span>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </PopoverBody>
                                                                    </UncontrolledPopover>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bottomDiv">
                                                        <div className='classTimeline'>
                                                            <div className='headingTitles'>
                                                                <p className='attTitleName'> Last Edit: </p>
                                                                <p className='head'>{getFormattedDate(item.cc_rubric_updated_at, formatDate, signDate)}</p>
                                                            </div>
                                                            <div className='headingTitlesRight'>
                                                                {item.cc_rubric_status === "drafted" ?
                                                                    <div className='lessonPlan'><p className='statusTextPlan'>{item.cc_rubric_status}</p></div>
                                                                    : item.cc_rubric_status === "completed" ? <div className='lessonEval'><p className='statusTextEval'>{item.cc_rubric_status}</p></div>
                                                                        : <div className='lessonPlan'><p className='statusTextPlan'>{item.cc_rubric_status}</p></div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Modal centered className="rubrics-modal-warning br-8" show={showViewDateRange} >
                    <Modal.Header>
                        <Modal.Title>Select Date </Modal.Title>
                    </Modal.Header>
                    <div className="dateBlock">
                        <div>
                            <div className="dateSubBlock">
                                <div className="dayDateTextDiv" ><p className="dayDateText">{startDate}</p></div>
                                <DatePicker
                                    onChange={date => { setRangeFirstDate(date) }}
                                    customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                                />
                            </div>
                            <div className="dateSubBlocks">
                                <div className="dayDateTextDiv" ><p className="dayDateText">{endDate}</p></div>
                                <DatePicker
                                    onChange={date => { addRangeSecondDate(date) }}
                                    customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                                />
                            </div>
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button variant="default" className="cancelAtt-btn"
                            onClick={cancelViewAtt}
                        >
                            Cancel
                        </Button>
                        <Button variant="default" className="getReport-btn"
                            onClick={confirmationViewReport}
                        >
                            Apply
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal centered className="att-modal-warning br-8" show={showViewAtt} >
                    <Modal.Header>
                        <Modal.Title>Select Date </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    </Modal.Body>
                    <div className="dateBlock">
                        <div>
                            <div className="dateSubBlock">
                                <div className="dayDateTextDiv" ><p className="dayDateText">{firstDate}</p></div>
                                <DatePicker
                                    onChange={date => { setRangeFirstDateReport(date) }}
                                    customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                                />
                            </div>
                            <div className="dateSubBlocks">
                                <div className="dayDateTextDiv" ><p className="dayDateText">{rangeSecondDate}</p></div>
                                <DatePicker
                                    onChange={date => { addRangeSecondDateReport(date) }}
                                    customInput={<img src={calendarIcon} alt="" width="18" height="18" />}
                                />
                            </div>
                        </div>
                    </div>
                    <Modal.Footer>

                        <Button variant="default" className="cancelAtt-btn"
                            onClick={cancelViewRub}
                        >
                            Cancel
                        </Button>
                        <Button variant="default" className="getReport-btn"
                            onClick={confirmationViewRubReport}
                        >
                            Get
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* class Confirmation Modal */}
                <Modal centered className="rubricBackClass-modal-warning br-8" show={showClassConfirm} onHide={() => { setShowClassConfirm(false) }}>
                    <Modal.Body>
                        <div>
                            <img src={warningIcon} alt='' className='warningIcon' />
                            <p className="waitTitle">Wait!</p>
                            <div className="modalText"><p>Do you want to change the class, your modified data would be lost.</p></div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className="cancelAtt-btn"
                            onClick={cancelClassWarnModal}
                        >
                            Cancel
                        </Button>
                        <Button variant="default" className="getReport-btn"
                            onClick={closeClassWarnModal}
                        >
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* class Confirmation Modal */}
                {/* Confirmation Modal */}
                <Modal centered className="rubricBack-modal-warning br-8" show={showConfirm} onHide={() => { setShowConfirm(false) }}>
                    <Modal.Body>
                        <div>
                            <img src={warningIcon} alt='' className='warningIcon' />
                            <p className="waitTitle">Wait!</p>
                            <div className="modalText"><p>Do you want to go back, your data will be lost.</p></div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className="cancelAtt-btn"
                            onClick={cancelWarnModal}
                        >
                            Cancel
                        </Button>
                        <Button variant="default" className="getReport-btn"
                            onClick={closeWarnModal}
                        >
                            Go Back
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Confirmation Modal */}
            </div>}
            {!showImportPage && showPreview && <div>
                <PreviewRubricsReport
                    showPreviewType={showPreviewType}
                    fileUrl={fileUrl}
                    history={history}
                    forwardRubricsReport={forwardRubricsReport}
                    setShowPreviewType={setShowPreviewType}
                    setFileUrl={setFileUrl}
                    setShowPreview={setShowPreview}
                    upoadFile={upoadFile}
                    recordFound={recordFound}
                    setRecordFound={setRecordFound}
                    setUpoadFile={setUpoadFile}
                /> </div>}
            {showImportPage && <div>
                <ImportRubrics
                    setShowImportPage={setShowImportPage}
                    classeslist={classList}
                    getRubricList={getRubricList}
                    getRubricListState={getRubricListState}
                    loader={loader}
                    history={history}
                    AcademicData={AcademicData}
                /> </div>}
        </div>)
}
export default ClassRubricsDashboard;
import React from "react";
import "./standardDashboard.scss";
import { Card, CardBody, CardHeader, CardText, CardTitle } from "reactstrap";
const StandardContent = ({ descriptionObj }) => {


  return (
    <React.Fragment>
      <Card className="text-left standardContentCard mb-3">
        <CardHeader className="bg-white">
          {descriptionObj?.section_title}
        </CardHeader>
        <CardBody>
          {descriptionObj?.arr?.map((item, idx) => {
            return (
              <>
                <CardText key={item?.standard_id}>{item?.description}</CardText>
              </>
            );
          })}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default StandardContent;

import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button , UncontrolledPopover , PopoverBody } from 'reactstrap';
import CommonTextField from 'Components/CommonTextField/CommonTextField';
import Modal from 'react-bootstrap/Modal';
import pencilIcon from "Assets/StudentGradeBookModule/pencil.png";
import shareIcon from "Assets/ClassModule/Share.png";
import trashIcon from "Assets/NoteModule/trash-alt.png";
import gradebookIcon from 'Assets/ClassModule/gradebook.png';
import deleteIcon from 'Assets/ClassModule/delete.png';
import deleteIcon2 from "Assets/RosterModule/deleteIcon.png";
import tick from "Assets/ClassModule/tick.svg";
import localFolderIcon from "Assets/RosterModule/localFolder.png";
import ReactFileReader from "react-file-reader";
import tickIcon from "Assets/NoteModule/tick.svg";
import UpdateBtn from 'Assets/ClassModule/UpdateBtn.png';
import { storage } from "../../../firebase/firebase";
import editIcon from "Assets/ClassModule/pencil-alt.png";
import noteIcon from 'Assets/ClassModule/note.png';
import grpnoteIcon from 'Assets/ClassModule/GroupNotes.png';
import FAIcon from 'Assets/ClassModule/FAIcon.png';
import GradeBookIcon from 'Assets/ClassModule/GradeBookIcon.png';
import cancelBlack from 'Assets/RosterModule/cancelBlack.png'
import cardList from 'Assets/ClassModule/cardList.png';
import GroupIcon from 'Assets/ClassModule/Group.png';
import GroupActiveIcon from 'Assets/ClassModule/GroupActive.png';
import tagIcon from 'Assets/ClassModule/tag.png';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import standardIcon from 'Assets/ClassModule/standard.png';
import userShareIcon from 'Assets/ClassModule/userShare.png';
import userRubrics from 'Assets/ClassModule/userRubrics.svg';
import attendance from 'Assets/AttendanceModule/attendance.svg';
import DivLoader from 'Components/LoadingComponent/DivLoader';
import iconAtt from "Assets/RosterModule/icon-att.png";
import iconNotes from "Assets/RosterModule/icon-notes.png";
import iconClass from "Assets/RosterModule/icon-class.png";
import iconLessonPlan from "Assets/RosterModule/icon-lesson.png";
import iconRubric from "Assets/RosterModule/icon-rubric.png";
import iconGradeBook from "Assets/RosterModule/icon-gradebook.png";
import iconShare from "Assets/RosterModule/icon-share.png";
// import { gapi } from "gapi-script";
import { gapi } from "gapi-script";
import InfiniteScroll from "react-infinite-scroll-component";
import iconProfile from "Assets/RosterModule/icon-profile.png";
import classIcon from "Assets/RosterModule/classIcon.png";
import camera from "Assets/RosterModule/camera.png";
import * as routes from "Router/RoutesURL";
import { createNotification } from 'Config/notificationtoast';
import { getClassList, addClass, editClass, deleteClass, editClassColorWheel, setClassSidebarOpen } from 'Actions/ClassAction/classAction';
import { getClassRosterList, deleteClassRoster, updateRosterLevel, addClassRoster, setClassRosterState } from 'Actions/ClassAction/classRosterAction';
import { getStudentGradebookNullRequest } from 'Actions/GradebookAction/studentGradebookAction';
import './ViewSingleClass.scss';
import { getRosterImage } from 'Config/commonFirebaseImage';
import back from "Assets/CommonComponent/back.png";
import AddStudent from "Assets/ClassModule/AddStudent.png"
import blueplus from "Assets/ClassModule/blueplus.png"
import SelectStudent from "Assets/ClassModule/SelectStudent.png"
import Selectroster from "Assets/ClassModule/Selectroster.svg"
import ImportRosterIcon from "Assets/ClassModule/importRosterIcon.png"
import Import from "Assets/ClassModule/Import.png"
import googleDriveIcon from "Assets/RosterModule/googleDrive.png";
import { importRoster , addRoster} from "Actions/RosterAction/rosterAction";
import driveCSVIcon from "Assets/RosterModule/filecsv_icon.png";
import driveFolderIcon from "Assets/RosterModule/folder.png";
import closeIcon from "Assets/NoteModule/close.svg";
import iconSearch from "Assets/RosterModule/icon-search.png";
import warningIcon from 'Assets/NoteModule/warning-icon.png'
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';



import {
  FirebseStroageFolder,
  API_KEY,
  CLIENT_ID,
  DISCOVERY_DOCS,
  SCOPES,
} from "Config/Constant";
import { addSelectedRosters, getNotSelectedRosters } from 'Actions/ClassAction/classSelectRosterAction';
import { getRandomName } from 'Utils/Helper';
const ViewSingleClass = (props) => {
    const {
        history,
        editClass,
        location,
        editClassColorWheel,
        getClassRosterList,
        rosterData,
        getStudentGradebookNullRequest,
        updateRosterLevel,
        deleteClassRoster,
        loader,
        P
    } = props;
    const ref = useRef();
  const [UploadFrom, setUploadFrom] = useState("drive");
  const [Email, setEmail] = useState("");
  const [UserProfileURL, setUserProfileURL] = useState("");
  const modalRef = useRef(null);
    const { data, AcademicData } =  location?.state || {};
    
    const [loadMoreCount, setLoadMoreCount] = useState(1);
    const [studentRosterData, setStudentRosterData] = useState([]);
    const [StudentOverAllCount, setStudentOverAllCount] = useState([]);
    const [loadMoreCountStatus, setLoadMoreCountStatus] = useState(false);
    const [removeRoster, setRemoveRoster] = useState("");
    const [editModal, setEditModal] = useState(false);
    const [selectedEditRoster, setSelectedEditRoster] = useState("");
    const [selectedEditRosterIndex, setSelectedEditRosterIndex] = useState("");
    const [modalLevel, setModalLevel] = useState("");
    const [modalScore, setModalScore] = useState("");
    const [isAddStudentSubmit, setIsAddStudentSubmit] = useState(false);
    const [className, setClassName] = useState("");
    const [classSection, setClassSection] = useState("");
    const [classGrade, setClassGrade] = useState("");
    const [addClassType, setAddClassType] = useState("");
  const [LastName, setLastName] = useState("");
  const [isDeleted, setIsDeleted] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)

  const [isDeleted2, setIsDeleted2] = useState(false)
  const [deleteItem2, setDeleteItem2] = useState(null)

  const [isDeleted3, setIsDeleted3] = useState(false)
  const [displayLevelScorePopup, setDisplayLevelScorePopup] = useState(false)
  const [deleteItem3, setDeleteItem3] = useState(null)
    const [class_id, setClass_id] = useState("");
    const [, updateState] = React.useState();
    const [studentRosterDataa, setstudentRosterDataa] = useState([]);
    const forceUpdate = React.useCallback(() => updateState({}), []);
  const [SelectFileModal, setSelectFileModal] = useState(false);
  const [GetFileLoader, setGetFileLoader] = useState(false);
  const [googleSelectedFile, setGoogleSelectedFile] = useState("");
  const [GoogleFiles, setGoogleFiles] = useState([]);
  const [importLoader, setImportLoader] = useState(false);
  const [updateLevelScore, setUpdateLevelScore] = useState(false)
  const [schoolPopOverId, setSchoolPopOverId] = React.useState(null);
  const [hasMore, setHasMore] = useState(true)
  const [allDataLength, setAllDataLength] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [statesData, setStatesData] = useState([])
  const [searchText,setSearchText] = useState(null)
  const [searchUnselectStudentText, setSearchUnselectStudentText] = useState(null)
  const [numBoxes, setNumBoxes] = useState(100);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false)
  const [studentDivHeight, setStudentDivHeight] = useState(0)
  const [FirstName, setFirstName] = useState("");
  const saveButtonNW = [(FirstName != "" && LastName != "") ? 'saveButton align-items-center' : 'saveButton saveButtonlessOpac align-items-center'];
  const addAnotherButtonNW = [(FirstName != "" && LastName != "") ? 'addAnotherButton align-items-center' : 'addAnotherButton saveButtonlessOpac align-items-center'];
  const noDataRef = useRef(null);
  const DataRef = useRef(null);
  const [driveEmail, setDriveEmail] = useState('')
  const dispatch = useDispatch();

  let locaStorageData = JSON.parse(sessionStorage.getItem('UserData'))
  const deleteClassLoaderComp = useSelector((state) => state?.class?.deleteClassLoader)
  useOnClickOutside(ref, () => setSchoolPopOverId(null));

  function useOnClickOutside(ref, handler) {

    useEffect(
      () => {
        
        const listener = (event) => {
          
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
         
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      [ref, handler, isDeleted3]
    );
  }

  useEffect(() => {
    forceUpdate()
    
   if(!schoolPopOverId) {
    setUpdateLevelScore(false)
    setSchoolPopOverId(null)
    setDisplayLevelScorePopup(false)
   }
  },[schoolPopOverId])

  useEffect(() => {
    window.sessionStorage.removeItem('classIDForNotes')
    window.sessionStorage.removeItem('classNameForNotes')
    // const boxHeight = 80; // height of each box in pixels
    // const windowHeight = window.innerHeight;
    // const numBoxes = Math.floor(windowHeight / boxHeight);
    // setNumBoxes(numBoxes);
    const boxWidth = 393; // width of each box in pixels
    const boxHeight = 76; // height of each box in pixels
    const divWidth = noDataRef?.current?.offsetWidth;
    // const divHeight = window.innerHeight
    const divHeight = noDataRef?.current?.offsetHeight;
    const numBoxesWidth = Math.floor(divWidth / boxWidth);
    // const numBoxesWidth = 403
    const numBoxesHeight = Math.floor(divHeight / boxHeight);
    // const numBoxes = numBoxesWidth * numBoxesHeight;
    // setNumBoxes(numBoxes);
  }, []);

  useEffect(() => {
    if(numBoxes != 'NaN' && numBoxes > 0) {
      const value = {
        class_id: data?.cc_class_id,
        filter_by: "by_class",
        page_no: loadMoreCount,
        // academic_year: AcademicData.academic_year_id,
        // academic_year: locaStorageData?.user_type == 'school_teacher' ? AcademicData.academic_year_id : AcademicData?.year_ids,
        academic_year : AcademicData?.year_ids,
        search_data : searchText || '',
        limit: numBoxes
    }
    getClassRosterList(value);
    // const { data = "" } = history.location.state;
    // let classId = "";
    // if (data && data.class_data && data.class_data.length > 0) {
    //   classId = data.class_data[0].cc_class_id;
    // }
    // setAcademicYear(data.academic_year);
    // setAcademicYearId(data.academic_year_id);
    // setClassIdForGradebook(classId);
    // // this.setState({
    // //     academicYear: data.academic_year,
    // //     academic_year_id: data.academic_year_id,
    // //     classIdForGradebook: classId
    // // })
    // const value = {
    //   class_id: "",
    //   filter_by: filter,
    //   name_filter_by: nameFilter,
    //   page_no: loadMoreCount > 1 ? "full" : loadMoreCount,
    //   limit : numBoxes,
    //   // academic_year: data.year_ids,
    //   academic_year: data.academic_year_id,
    // };
    // dispatch(getRosterList(value));
  }
  },[numBoxes])

  const handleParentScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = DataRef?.current;
  if(rosterData?.roster_Data?.data?.[0] && rosterData?.roster_Data?.data?.[0]?.length > 0) {
    if(studentRosterData && studentRosterData.length != StudentOverAllCount?.[0]?.count) {
    if (parseInt(scrollTop ) + clientHeight == scrollHeight) {
     
         let pageNoData = loadMoreCount;
      pageNoData++;
      
      setLoadMoreCount(pageNoData)
      setLoadMoreCountStatus(true);
      setLoadMoreLoader(true)
      // this.setState({
      //     academicYear: data.academic_year,
      //     academicYearId: data.academic_year_id
      // })
      // const { filter, loadMoreCount } = this.state
      
      const value = {
        class_id: data.cc_class_id,
        filter_by: "by_class",
        page_no: pageNoData,
        // academic_year: AcademicData.academic_year_id,
        academic_year: AcademicData?.year_ids,
        search_data : searchText || '',
        limit: numBoxes
    }
    
    getClassRosterList(value);
     
    }
  }
}
  }
 
  let noteStrinf = "(Home>Classes>Roster)";
    const [GoogleAuth, setGoogleAuth] = useState(
      JSON.parse(sessionStorage.getItem("GoogleAuth"))
    );
    const [GoogleAuthItem, setGoogleAuthItem] = useState(
      JSON.parse(sessionStorage.getItem("GoogleAuthItem"))
    );
    const getFolderFiles = (value) => {
      try {
        gapi.load("client:auth2", async () => {
          let query = `'${value.id}'  in parents and trashed=false`;
          let path = {
            name: value.name,
            id: value.id,
          };
          let googleDrivePathData = googleDrivePath;
          googleDrivePathData.push(path);
          const uniqueArray = Array.from(
            new Set(googleDrivePathData.map((item) => item.id))
          ).map((id) => {
            return googleDrivePathData.find((item) => item.id === id);
          });
          const finalBreadCrumbArr = uniqueArray.slice(
            0,
            uniqueArray.findIndex((item) => item.id === path.id) + 1
          );
          setSelectFileModal(true);
          setGetFileLoader(true);
          setGoogleSelectedFile("");
          setGoogleFiles([]);
          setGoogleDrivePath(finalBreadCrumbArr);
          await gapi.client
            .init({
              apiKey: API_KEY,
              clientId: CLIENT_ID,
              scope: SCOPES,
              discoveryDocs: DISCOVERY_DOCS,
            })
            .then(() => {
              gapi.client.drive.files
                .list({
                  pageSize: 1000,
                  fields:
                    "nextPageToken, files(id,size, name, mimeType, fileExtension, modifiedTime, webViewLink)",
                  q: query,
                })
                .then((res) => {
                  let GoogleFiles = [];
                  for (let item of res.result.files) {
                    if (item.fileExtension) {
                      if (item.fileExtension === "csv") {
                        GoogleFiles.push(item);
                      }
                    }
                    const type = item.mimeType;
                    const typeCheck = type.split(".");
                    if (typeCheck[typeCheck.length - 1] === "folder") {
                      item.fileExtension = "folder";
                      GoogleFiles.push(item);
                    }
                  }
                  setGoogleFiles(GoogleFiles);
                  setGetFileLoader(false);
                });
            });
        });
      } catch (e) {
        setGoogleFiles([]);
        setGetFileLoader(false);
      }
    };
useEffect(() => {
  const value = {
    class_id: data?.cc_class_id,
    filter_by: "not_in_current_class",
    page_no: loadMoreCount,
    academic_year: AcademicData?.academic_year_id,
    sort_by: "sort_by_first_name",
    // tag_id: tag_id,
    search_text: "",
    by_date: "",
    date_flag: "",

}

dispatch(getNotSelectedRosters(value))
}, [])



const states = useSelector((state) => state?.classRosterSelect?.notSelectedRoster?.student_data)

const notSelectedStudentsCount = useSelector((state) => state?.classRosterSelect?.notSelectedRoster?.total_count)




const fetchRosterImages = async (data, count) => {
  let dataArr = data;
  for (let rosterItem of dataArr) {
    if (rosterItem.cc_student_pic_url) {
      if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
        rosterItem.cc_student_pic_url = await getRosterImage(
          rosterItem.cc_student_pic_url
        );
      }
    }
  }
  
  
   setStatesData(dataArr);
  // setAllDataLength(notSelectedStudentsCount)
  // setIsLoading(false)

  // setStudentList(dataArr)
  // setStudentList(dataArr);
  // setStudentsWithoutSearch(dataArr);
  // setStudentOverAllCount(count ? count : rosterData?.roster_Data?.data?.[1]);
};

useEffect(() => {
  
  
  // setStatesData(states)
  if(states && notSelectedStudentsCount) {
    let newdata = [...statesData, ...states]
    
    const uniqueSchools = Array.from(
      new Set(newdata.map((a) => a.cc_student_id))
    ).map((id) => {
      return newdata.find((a) => a.cc_student_id === id);
    });
    

    fetchRosterImages(uniqueSchools);
    // let allData = getAllStudentImage(uniqueSchools)



  // setStatesData(uniqueSchools);
  setAllDataLength(notSelectedStudentsCount)
  setIsLoading(false)
  }
},[states, notSelectedStudentsCount])

useEffect(() => {
  
  if(statesData && statesData.length == notSelectedStudentsCount) {
    setHasMore(false)
  }
},[statesData])


  //   const getRosterList = () => {
  //     const { location = {} } = this.props
  //     const { state = {} } = location;
  //     const { ClassDetails = '', academicYearId = '' } = state;
  //     this.setState({
  //         ClassDetails: ClassDetails,
  //         academicYearId: academicYearId
  //     })
  //     const { filter, loadMoreCount, sort_by, tag_id } = this.state
  //     const value = {
  //         class_id: data.cc_class_id,
  //         filter_by: "by_class",
  //         page_no: loadMoreCount,
  //         academic_year: AcademicData.academic_year_id,
  //         sort_by: sort_by,
  //         tag_id: tag_id,
  //         search_text: this.state.searchText,
  //         by_date: this.state.by_date,
  //         date_flag: this.state.date_flag,

  //     }
 
  //     this.props.getNotSelectedRosters(value)
  // }
  const [googleDrivePath, setGoogleDrivePath] = useState([]);

    const handleFileLoad = () => {
      window.$("#myModalupload").modal("hide");
      const path = {
        name: "My drive",
        id: "root",
      };
      setGoogleDrivePath([]);
      getFolderFiles(path);
      // this.setState({
      //     googleDrivePath: []
      // }, () => {
      //     this.getFolderFiles(path);
      // })
    };
    const editStudent = (value) => {
      
      setStudentRosterData((prevState) => {
          return prevState.map((item) => {
            //gets everything that was already in item, and updates "done"
            //else returns unmodified item
            return item.cc_student_id === value
              ? { ...item, editOpen: !item.editOpen }
              : item;
          });
        }) 
      //   setSchoolPopOverId((prev) =>
      //   prev !== value ? value : null
      // );
      
      };
    const getFolderFilesPath = (item) => {
      let GoogleDrivePath = googleDrivePath;
      let CreatePath = [];
      for (let value of GoogleDrivePath) {
        if (value.id === item.id) {
          break;
        } else {
          const path = {
            name: value.name,
            id: value.id,
          };
          CreatePath.push(path);
        }
      }
      setGoogleDrivePath(CreatePath);
      getFolderFiles(item);
    };
    const importCSVFile = (value) => {
      window.$("#myModalupload").modal("hide");
      setSelectFileModal(false);
      setGetFileLoader(false);
      setGoogleSelectedFile("");
      setGoogleFiles([]);
      setGoogleDrivePath([]);
      let data = value
      data.limit = numBoxes
      
      dispatch(importRoster(data));
    };
    // useEffect(() => {
    //     const value = {
    //         class_id: data.cc_class_id,
    //         filter_by: "by_class",
    //         page_no: loadMoreCount,
    //         academic_year: AcademicData.year_ids,
    //         search_data : searchText,
    //         limit: numBoxes
    //     }
    //     getClassRosterList(value);
    // }, [])
    
    // useEffect(() => {
    //     if (rosterData && rosterData.roster_Data && !rosterData.loader) {
    //         setStudentRosterData(rosterData.roster_Data.data[0]);
    //     }
    // }, [rosterData])
  const [filter, setFilter] = useState("both");
  const [InvelidMessageEmail, setInvelidMessageEmail] = useState("");
  const [showLoaderUpload, setShowLoaderUpload] = useState(false);
  const [tabButton, setTabButton] = useState("");
  const [UserProfileSize, setUserProfileSize] = useState("");
  const [UserProfile, setUserProfile] = useState("");

  const emailHandler = (e) => {
    setEmail(e.target.value);
    setInvelidMessageEmail("");
  };

  const createNewRosters = (tab) => {
    if (showLoaderUpload) {
      createNotification("error", "The image is still uploading.");
      return;
    }
    // const { location = {} } = this.props
    // const { state = {} } = location;
    // const { data = '' } = state;
    const { data } = history.location.state;
    setIsAddStudentSubmit(true);
    setTabButton(tab);
    // this.setState({
    //     isAddStudentSubmit: true,
    //     tabButton: tab
    // })
    if (!FirstName || !LastName) {
      return false;
    }
    if (Email) {
      const valid = /^(?![.])[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(?:\.[A-Za-z]{2,5})?$/;
      if (!valid.test(Email)) {
        setInvelidMessageEmail("Please enter valid email*");
        // this.setState({
        //     InvelidMessageEmail: 'Please enter valid email*',
        // });
        return false;
      }
    }
    // const { UserProfile, FirstName, LastName, Email, filter, UserProfileSize } = this.state;
    const value = {
      academic_year_id: data.academic_year_id,
      student_pic_size: UserProfileSize,
      student_first_name: FirstName,
      student_last_name: LastName,
      student_registration_email_id: Email,
      student_secondary_email_id: "",
      student_pic_url: UserProfile,
      student_id: "",
      class_id: "",
      by_class_sharing: "",
      filter_by: filter,
      page_no: 1,
      academic_year: data.academic_year_id,
    };
    
    setLoadMoreCount(1);
    setLoadMoreCountStatus(false);
    // this.setState({
    //     loadMoreCount: 1,
    //     loadMoreCountStatus: false,
    // })
    window.$("#myModal2").modal("hide");
    // this.props.addRoster(value)
    dispatch(addRoster(value));
    setLoadMoreCount(1)
  };

  const handleChangeFile = (e) => {
    if (e.length === 0) {
      return false;
    }
    const data = JSON.parse(sessionStorage.getItem("UserData"));
    const imageAsFile = e[0];
    setIsAddStudentSubmit(false);
    setInvelidMessageEmail("");
    setShowLoaderUpload(true);
    // this.setState({
    //     isAddStudentSubmit: false,
    //     InvelidMessageEmail: '',
    //     showLoaderUpload: true
    // })

    // ---------Image Type-------
    const ImageTypeArray = imageAsFile.name.split(".");
    const ImageType = ImageTypeArray[ImageTypeArray.length - 1];
    const media_Size = imageAsFile.size / 1000000;
    // ---------Image Type-------

    // ---------DummyName-------
    const GetRendomName = getRandomName();
    const DummyName = GetRendomName + "." + ImageType;
    // ---------DummyName-------

    // ---------FolderName-------
    const FinfFolderNameArray = data.cc_user_email_id.split("@");
    const FolderName = FinfFolderNameArray[0];
    // ---------FolderName-------

    const uploadTask = storage
      .ref(`${FirebseStroageFolder}/${FolderName}/roster/${DummyName}`)
      .put(imageAsFile);
    uploadTask.on(
      "state_changed",
      (snapShot) => {},
      (err) => {
        setShowLoaderUpload(false);
        // this.setState({
        //     showLoaderUpload: false
        // })
        createNotification("success", "The image was not uploaded. Please try again.");
      },
      () => {
        storage
          .ref(`${FirebseStroageFolder}/${FolderName}/roster/`)
          .child(DummyName)
          .getDownloadURL()

          .then((fireBaseUrl) => {
            setShowLoaderUpload(false);
            // this.setState({
            //     showLoaderUpload: false
            // })
            createNotification("success", "Image Upload Successfully");
            setUserProfileURL(fireBaseUrl);
            setUserProfile(`${FirebseStroageFolder}/${FolderName}/roster/${DummyName}`);
            setUserProfileSize(media_Size);
            // forceUpdate()
            // this.setState({
            //     UserProfileURL: fireBaseUrl,
            //     UserProfile: DummyName,
            //     UserProfileSize: media_Size,
            // }, () => {
            // })
          });
      }
    );
  };
  const pattern2 = /^[a-zA-Z]*$/;
const [mockval, setmockval] = useState(false)
const [mockval2, setmockval2] = useState(false)
  const createNewRoster = (tab) => {
    setmockval("true")
    setmockval2("true")
    if (showLoaderUpload) {
      createNotification("error", "The image is still uploading.");
      return;
    }
    // const { location = {} } = this.props
    // const { state = {} } = location;
    // const { data = '' } = state;
    const { data } = history?.location?.state || {};
    setIsAddStudentSubmit(true);
    setTabButton(tab);
    // this.setState({
    //     isAddStudentSubmit: true,
    //     tabButton: tab
    // })
    if ((!FirstName || (FirstName && FirstName.trim().length <= 0)) || (!LastName || (LastName && LastName.trim().length <= 0))) {
      return false;
    }
    const pattern2 = /^[a-zA-Z]*$/;
    if ((!pattern2.test(FirstName)) || !pattern2.test(LastName)) {
      return;
    }
    if (Email) {
      const valid = /^(?![.])[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(?:\.[A-Za-z]{2,5})?$/;
      if (!valid.test(Email)) {
        setInvelidMessageEmail("Please enter valid email*");
        // this.setState({
        //     InvelidMessageEmail: 'Please enter valid email*',
        // });
        return false;
      }
    }
    const value = {
        "academic_year_id": AcademicData.academic_year_id,
        "student_pic_size": UserProfileSize,
        "student_first_name": FirstName,
        "student_last_name": LastName,
        "student_registration_email_id": Email,
        "student_secondary_email_id": '',
        "student_pic_url": UserProfile,
        "student_id": '',
        "class_id": data.cc_class_id,
        "by_class_sharing": '',
        "filter_by": filter,
        "page_no": 1,
        "academic_year": AcademicData.academic_year,
        "addByViewSingleClass" : true,
        "limit": numBoxes
    }
    
    setIsAddStudentSubmit(false);
    setLoadMoreCount(1);
    setLoadMoreCountStatus(false);
    setInvelidMessageEmail('')
    setFirstName('')
    setLastName('')
    setEmail('')
    setUserProfile('')
    setUserProfileURL('')
    forceUpdate()
    dispatch(addClassRoster(value))
    // this.setState({
    //     loadMoreCount: 1,
    //     loadMoreCountStatus: false,
    // })
 
    if (tab == "addAnother") {
      
      window.$("#myModal2").modal("show");
    }
    else {
    window.$("#myModal2").modal("hide");
    }
}

    const handleImportFiles = (files) => {
      try {
        var reader = new FileReader();
        // const { location = {} } = this.props
        // const { state = {} } = location;
        // const { data = '' } = state;
        const { data } = history.location.state;
       
        reader.onload = (e) => {
          if (
            reader.result === undefined ||
            reader.result === "undefined" ||
            reader.result === ""
          ) {
            createNotification(
              "error",
              "Selected file is empty. Please select another csv file having content in it"
            );
            return;
          }
          const str = reader.result;
          const reg = new RegExp(/(\r\n?|\n|\t)/g);
          const student_Data = str.replace(reg, ",#*#");
          setLoadMoreCount(1);
          setLoadMoreCountStatus(false);
          // this.setState({
          //     loadMoreCount: 1,
          //     loadMoreCountStatus: false,
          // }, () => {
          // const { filter, loadMoreCount } = this.state;
          const value = {
            class_id: props.history.location.state.data.cc_class_id,
            filter_by: filter,
            page_no: loadMoreCount,
            academic_year_id: props.history.location.state.data.cc_ref_academic_year_id,
            student_data: student_Data,
            importFromViewSingleClass : true
          };
          importCSVFile(value);
          // })
        };
        reader.readAsText(files[0]);
      } catch (e) {
        createNotification(
          "error",
          "Something Went Wrong, Not able to read File"
        );
        return;
      }
    };

   

    useEffect(() => {
        if (rosterData.action === 'GET_CLASS_ROSTER_LIST_SUCCESS') {
            if (rosterData.roster_Data && rosterData.roster_Data.status === 200) {
                if (rosterData.roster_Data.data[0] && rosterData.roster_Data.data[0].length > 0) {
                    let getRosterData = []
                    if (loadMoreCount > 1) {
                        // ---get Roster LoadMore---
                        if (loadMoreCountStatus) {
                            getRosterData = studentRosterData;
                            for (let value of rosterData.roster_Data.data[0]) {
                                getRosterData.push(value)
                            }
                            
                            setStudentRosterData(getRosterData);
                            setStudentOverAllCount(rosterData.roster_Data.data[1]);
                            setLoadMoreCountStatus(false)
                            setLoadMoreLoader(false)

                        } else {
                            getRosterData = studentRosterData;
                        }
                        // ---get Roster LoadMore---
                    } else {
                        setStudentRosterData(rosterData.roster_Data.data[0]);
                        setStudentOverAllCount(rosterData.roster_Data.data[1]);
                        getRosterData = rosterData.roster_Data.data[0];
                    }
                    // ---Fatch Firebase Image---
                    const fetchImageData = async () => {
                        let DummyRoster = loadMoreCount > 1 && loadMoreCountStatus ? studentRosterData : rosterData.roster_Data.data[0];
                        if (DummyRoster && DummyRoster.length > 0) {
                            for (let rosterItem of DummyRoster) {
                                if (rosterItem.cc_student_pic_url) {
                                    if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                                        rosterItem.cc_student_pic_url = await getRosterImage(rosterItem.cc_student_pic_url);
                                    }
                                }
            
                            }
                        }
                        // setRosterDataFinal(DummyRoster);
                        
                        forceUpdate()
                        setStudentRosterData(DummyRoster);
                    }
                    fetchImageData()
                        .catch(console.error);
                    // for (let rosterItem of getRosterData) {

                    //     if (rosterItem.cc_student_pic_url) {
                    //         if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                    //             rosterItem.cc_student_pic_url = await getImage(rosterItem.cc_student_pic_url);
                    //         }
                    //     }
                    // }
                    // ---Fatch Firebase Image---
                    // setStudentRosterData(getRosterData);
                    setStudentOverAllCount(rosterData.roster_Data.data[1]);
                }
                else {
                    setStudentRosterData([]);
                    setStudentOverAllCount([]);
                }
            }
        }
    }, [rosterData]);

    // useEffect(() => {
    //     if (rosterData && rosterData.loader) {
    //         setStudentRosterData([]);
    //     }
    // }, [rosterData]);

    const editColorWheel = (value) => {
      // console.log(props)
      // console.log(value)
      // console.log(data)
      if (value.cc_class_color_wheel_display === 'yes') {
        data.cc_class_color_wheel_display = 'no'
        sessionStorage.setItem('classColorWheel', 'no')
    } else {
        data.cc_class_color_wheel_display = 'yes'
        sessionStorage.setItem('classColorWheel', 'yes')
    }
    // console.log(909,data)
        const apiData = {
            // "class_color_wheel": props?.location?.state?.data?.cc_class_color_wheel_display === 'yes' ? 'no' : 'yes',
            "class_color_wheel": data?.cc_class_color_wheel_display,
            "class_id": props?.location?.state?.data?.cc_class_id,
            "class_academic_year": AcademicData.academic_year,
            "class_academic_year_Id": AcademicData.academic_year_id,
            "filter_by": 'both',
            "page_no": 1,
            "fromSingleClassView" : true,
            "history": history
        }
        editClassColorWheel(apiData)
    }
    const handleClientLoad = async () => {
      let GoogleAuth = "";
      gapi.load("client:auth2", () => {
        gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            scope: SCOPES,
            discoveryDocs: DISCOVERY_DOCS,
          })
          .then(() => {
            GoogleAuth = gapi.auth2.getAuthInstance();
            GoogleAuth.isSignedIn.listen(updateSigninStatus());
          });
      });
    };
    const updateSigninStatus = (isSignedIn) => {
      try {
        if (isSignedIn) {
          setSignedInUser(gapi.auth2.getAuthInstance().currentUser.je);
        } else {
          handleAuthClick();
        }
      } catch (e) {
        createNotification("error", "Something Went Wrong, Not able Login");
        return;
      }
    };
    const setSignedInUser = (value) => {
      try {
        setGoogleAuth(true);
        setGoogleAuthItem(value);
        sessionStorage.setItem("GoogleAuth", true);
        sessionStorage.setItem("GoogleAuthItem", JSON.stringify(value));
      } catch (e) {
        createNotification("error", "Something Went Wrong, Not able Login");
        return;
      }
    };

    const handleAuthClick = (event) => {
      try {
        gapi.auth2
          .getAuthInstance()
          .signIn()
          .then((res) => {
            setSignedInUser(res);
          });
      } catch (e) {
        createNotification("error", "Something Went Wrong, Not able Login");
        return;
      }
    };
    const handleSignOutClick = (event) => {
      try {
        gapi.load("client:auth2", () => {
          gapi.client
            .init({
              apiKey: API_KEY,
              clientId: CLIENT_ID,
              scope: SCOPES,
              discoveryDocs: DISCOVERY_DOCS,
            })
            .then(() => {
              var auth2 = gapi.auth2.getAuthInstance();
              auth2.disconnect();
              setGoogleAuth(false);
              setGoogleAuthItem(false)
              sessionStorage.setItem("GoogleAuth", false);
              sessionStorage.setItem("GoogleAuthItem", false);
            });
        });
      } catch (e) {
        createNotification("error", "Something Went Wrong, Not able Logout");
        return;
      }
    };
    const getFileDetails = () => {
      try {
        setImportLoader(true);
        // this.setState({
        //     importLoader: true
        // })
        gapi.load("client:auth2", () => {
          let data = googleSelectedFile;
          gapi.client
            .init({
              apiKey: API_KEY,
              clientId: CLIENT_ID,
              scope: SCOPES,
              discoveryDocs: DISCOVERY_DOCS,
            })
            .then(async () => {
              const file = data;
              const url = `https://www.googleapis.com/drive/v2/files/${data.id}?alt=media`;
              if (file.webViewLink) {
                var accessToken = gapi.auth.getToken().access_token;
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url);
                xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
                xhr.onload = () => {
                  if (xhr.status === 200) {
                    const str = xhr.responseText;
                    setImportLoader(false);
                    // this.setState({
  
                    //     importLoader: false
                    // })
                    try {
                      if (
                        str === undefined ||
                        str === "undefined" ||
                        str === ""
                      ) {
                        createNotification(
                          "error",
                          "Selected file is empty. Please select another csv file having content in it"
                        );
                        setGoogleSelectedFile("");
                        // this.setState({
                        //     googleSelectedFile: ''
                        // })
                        return;
                      }
  
                      const reg = new RegExp(/(\r\n?|\n|\t)/g);
                      const student_Data = str.replace(reg, ",#*#");
                      setLoadMoreCount(1);
                      setLoadMoreCountStatus(false);
                      const value = {
                        class_id: props.history.location.state.data.cc_class_id,
                        filter_by: filter,
                        page_no: loadMoreCount,
                        academic_year_id: AcademicData.academic_year_id,
                        student_data: student_Data,
                        importFromViewSingleClass : true
                      };
                      importCSVFile(value);
                      // this.setState({
                      //     loadMoreCount: 1,
                      //     loadMoreCountStatus: false,
                      // }, () => {
                      //     const { filter, loadMoreCount, academic_year_id } = this.state;
                      //     const value = {
                      //         class_id: '',
                      //         filter_by: filter,
                      //         page_no: loadMoreCount,
                      //         academic_year_id: academic_year_id,
                      //         student_data: student_Data
                      //     }
                      //     this.importCSVFile(value)
                      // })
                    } catch (e) {
                      createNotification(
                        "error",
                        "Something Went Wrong, Not able to read File"
                      );
                      return;
                    }
                  } else {
                    createNotification(
                      "error",
                      "Something Went Wrong, Not able to fetch file Detail"
                    );
                    setGetFileLoader(false);
                    setImportLoader(false);
                    // this.setState({
                    //     GetFileLoader: false,
                    //     importLoader: false
                    // })
                    return;
                  }
                };
                xhr.onerror = function () {};
                xhr.send();
              } else {
                createNotification(
                  "error",
                  "Something Went Wrong, Not able to fetch file Detail"
                );
                setGetFileLoader(false);
                setImportLoader(false);
                // this.setState({
                //     GetFileLoader: false,
                //     importLoader: false
                // })
                return;
              }
            });
        });
      } catch (e) {
        createNotification(
          "error",
          "Something Went Wrong, Not able to fetch file Detail"
        );
        setGetFileLoader(false);
        setImportLoader(false);
        // this.setState({
        //     GetFileLoader: false,
        //     importLoader: false
        // })
        return;
      }
    };
    const [AllRoster, setAllRoster] = useState([]);
    const [seletedAllRoster, setseletedAllRoster] = useState([]);
    const [selectedRosterId, setselectedRosterId] = useState([]);
  
    const CheckValue = (item, index) => {
      
      let AllRoster = statesData;
    
      AllRoster[index].isSeleted = !AllRoster[index].isSeleted;

      pushInArray(item.cc_student_id)
      // this.setState({
        //     AllRoster,
        //     seletedAllRoster: false
        // })
        setAllRoster(AllRoster)
        forceUpdate()
      setseletedAllRoster(false)
      // document.getElementById("a").innerHTML = "check_circle"
      
  }

  
  // ---------Check/Uncheck For One-------

  // ---------Check/Uncheck in API Send Array-------
  const pushInArray = (value) => {
      let selectedRosterIds = selectedRosterId;


      let idFound = false;
      let fixedIndex = ''
      if (selectedRosterIds.length > 0) {
      
          let index = 0
          for (let item of selectedRosterIds) {
              if (item === value) {
                  idFound = true;
                  fixedIndex = index
              }
              index++
          }
          if (idFound) {
            
              selectedRosterIds.splice(fixedIndex, 1)
          } else {
           
              selectedRosterIds.push(value)
     

          }

      } else {
       
          selectedRosterId.push(value)
      
      }

      // this.setState({
      //     selectedRosterId
      // }, () => {
      // })
      // setselectedRosterId(selectedRosterIds);

  }

    const deleteRoster = (id) => {
        setLoadMoreCount(1);
        const value = {
            "student_id": id,
            "class_id": data.cc_class_id,
            "filter_by": "by_class",
            "page_no": 1,
            "academic_year": AcademicData.academic_year_id,
            "fromViewSingleClass" : true,
            "limit": numBoxes
        }
      
        setRemoveRoster('');
        deleteClassRoster(value);
        setIsDeleted3(false)
    }


    const EditLevel = (item, index) => {
        setEditModal(!editModal);
        setSelectedEditRoster(item.cc_student_id);
        setSelectedEditRosterIndex(index);
        setModalLevel(item.cc_note_level);
        setModalScore(item.cc_note_score);
        setIsAddStudentSubmit(false);
    }

    const openNotes = (item) => {
        const { history } = props;
        sessionStorage.setItem("selectedprofile", JSON.stringify(item));
        sessionStorage.setItem("selectedYear", JSON.stringify(AcademicData.academic_year));
        sessionStorage.setItem('classNameForNotes',data?.cc_class_name)
        history.push(routes.STUDENTNOTE, { studentInfo: item, academicYear: AcademicData.academic_year, academicYearIDFromClassNote : props?.history?.location?.state?.AcademicData?.academic_year_id, rosterClassId: data.cc_class_id, pageBYClassRoster: true, pageBYClass: true, BackPage: 'Class Roster', classPermission: data.cc_class_share_permission, ClassData: data, directAdd: 'directAddNo' });
       
    }
   
    // ---------Update Level and Score-------
    const updateScoreLevel = (item) => {
    
      // if (!modalLevel || !modalScore) {
      //   createNotification('error', "Please enter Level and Score.");
      // } else {
        setDisplayLevelScorePopup(false)
        if(modalLevel?.length != 0 || modalScore?.length !=0) {
      setLoadMoreCount(1);
      const apiData = {
          "student_id": selectedEditRoster,
          "student_details_array": [{
              "student_score": modalScore || '',
              "student_level": modalLevel || ''
          }],
          "screen_name": "class_roster",

          "class_id": data.cc_class_id,
          "filter_by": "by_class",
          "page_no": loadMoreCount,
          "academic_year": AcademicData.academic_year_id,
          "limit": numBoxes
      }
      // setEditModal(!editModal)
      updateRosterLevel(apiData)
      // setSchoolPopOverId(null)
      setSchoolPopOverId((prev) =>
      prev !== item.cc_student_id ? item.cc_student_id : null
    );
      setSelectedEditRoster(0);
      forceUpdate()
    // }
        }
  }


    const [RefVal, setRefVal] = useState("")
    const handleChangeScore = (event) => {
      // const scoreValue = /^[0-9]+$/;
      const scoreValue = /^[0-9]{0,2}$/;
      if (event.target.value.length < 3 && (!event.target.value || event.target.value.match(scoreValue))) {
        // console.log('event.target.value', event.target.value)
        setRefVal(event.target.value)
        setModalScore(event.target.value) 
      }
      else {
        // if (RefVal) {
          
        // }
        setModalScore(RefVal) 
      }
        // if (event.target.value.length < 3 && (!event.target.value || event.target.value.match(scoreValue))) {
        //     setModalScore(event.target.value);
        // }
        // setModalScore(event.target.value);
    }
    
    const handleChangeLevel = (event) => {
       
        const scoreValue = /^[a-zA-Z0-9]+$/;
       
        if (event.target.value.length < 3 && (!event.target.value || event.target.value.match(scoreValue))) {
            setModalLevel(event.target.value);
        }
        // if (event.target.value.length < 3) {
        //     setModalLevel(event.target.value);
        // }
    }

    const handleGradeChange = (event) => {
        setClassGrade(event.target.value);
    }

    const handleChangeSection = (event) => {
        setClassSection(event.target.value);
    }

    const handleChangeClassName = (event) => {
        setClassName(event.target.value);
    }

    // ---------Edit Class-------
    const editClassOpen = (value) => {
        setClassName(value.cc_class_name);
        setClassSection(value.cc_class_section);
        setClassGrade(value.cc_class_grade);
        setAddClassType('edit');
        setClass_id(value.cc_class_id);
        window.$('#addClassModal').modal('show');
    }
    // ---------Edit Class-------
const deleteCls = () => {
  // alert("Fdsfdsf")
  const datas = {
    class_id: data.cc_class_id,
    class_academic_year: AcademicData.academic_year,
    class_academic_year_Id: AcademicData.academic_year_id,
    filter_by: "by_class",
    page_no: 1,
    fromViewSingleClass : true,
    history : history
  };
  // this.props.deleteClass(data)
 
  dispatch(deleteClass(datas));

}
const [isAddClassSubmit, setIsAddClassSubmit] = useState(false);

const pattern = /^[a-zA-Z ]*$/;

    // ---------Add New Class-------
    const addNewClass = () => {
    setIsAddClassSubmit(true);
        setLoadMoreCount(1);
        if (!className) {
            return;
            }
            const pattern = /^[a-zA-Z ]*$/;
        if (!pattern.test(className)) {
          return;
        }
        if (classSection && classSection.length > 2) {
          // setInvelidMessageEmail('Section has a maximum of 2characters');
          return false;
         } else if (classGrade && classGrade.length > 2) {
          // setInvelidMessageEmail('Grade name should be of 2 characters');
          return false; }
        else {

      let apiData = {
          "class_section": classSection,
          "class_name": className,
          "class_grade": classGrade,
          "class_academic_year": AcademicData.academic_year,
          "class_academic_year_Id": AcademicData.academic_year_id,
          "filter_by": "by_class",
          "page_no": 1,
          "class_id": class_id,
          "fromViewSingleClass" : true,
          "history" : history
      }
      if (addClassType === 'edit') {
          editClass(apiData)
      }
      window.$('#addClassModal').modal('hide');
      history.goBack();
    }
    }
    // ---------Add New Class-------

    const loadMore = () => {
        let value = loadMoreCount;
        value++
        setLoadMoreCount(value);
        setLoadMoreCountStatus(true);
        const values = {
            class_id: data.cc_class_id,
            filter_by: "by_class",
            page_no: value,
            academic_year: AcademicData.academic_year_id,
            search_data : searchText || '',
            limit: numBoxes
        }
        getClassRosterList(values);
    }

    const loadMoreClass = () => {
      let value = loadMoreCount;
      value++
      setLoadMoreCount(value);
      setLoadMoreCountStatus(true);
     
      const { data = "" } = history.location.state;
      const values = {
        class_id: data.cc_class_id,
        filter_by: "by_class",
        page_no: value,
        academic_year: AcademicData.academic_year_id,
        search_data : searchText || '',
        limit: numBoxes
    }
    
    getClassRosterList(values);
    };
    const goBackPrev = () => {
        setStudentRosterData([]);
        setStudentOverAllCount([])
        setLoadMoreCountStatus(false)
        dispatch(setClassRosterState())
        history.goBack();
    }
    useEffect(() => {
      if (GoogleAuthItem?.hasOwnProperty("wt")) {
        setDriveEmail(GoogleAuthItem.wt.cu)
      } else if (GoogleAuthItem?.hasOwnProperty("Qt")) {
        setDriveEmail(GoogleAuthItem.Qt.zu)
      }
    }, [GoogleAuthItem]);
    const standardOpacity = ['stdDiv', data?.class_core_standard === 'yes' ? 'opacityProp' : ''];
    // const disAddClassRoster = ["addNewClassRoster", data.cc_class_share_permission !== null && data.cc_class_share_permission === "read" ? "opacityProp" : ""]
    // const deSelectedRosterButton = ["selectedRosterButton", data.cc_class_share_permission !== null && data.cc_class_share_permission === "read" ? "opacityProp" : ""]
    // const disImportNewClassRoster = ["importNewClassRoster", data.cc_class_share_permission !== null && data.cc_class_share_permission === "read" ? "opacityProp" : ""]
    const disClassRosterOptionRemove = ["classRosterOptionRemove", data?.cc_class_share_permission !== null && data?.cc_class_share_permission === "read" ? "opacityProp" : ""]
    const disClassRosterOptionShare = ["classRosterOptionShare", data?.cc_class_share_permission !== null && data?.cc_class_share_permission === "read" ? "opacityProp" : ""]
    const editSingle = ["editSingle", data?.cc_class_share_permission !== null && (data?.cc_class_share_permission === "read") ? "opacityProp" : ""];
    const shareSingle = ["editSingle", data?.cc_class_share_permission !== null && (data?.cc_class_share_permission === "read" || data?.cc_class_share_permission == "collaborate") ? "opacityProp" : ""];
    const _ = require('lodash');
    const BackTOHome = () => {
        history.goBack();
      };
      const handleClick = (event) => {
        const { target = {} } = event || {};
        target.value = "";
      };

// useEffect(() => {

//   const value = {
//     class_id: data.cc_class_id,
//     filter_by: "by_class",
//     page_no: loadMoreCount,
//     academic_year: AcademicData.academic_year_id
// }
// forceUpdate()
// getClassRosterList(value);
// }, [])

const fetchMoreData = () => {
 
  let page = loadMoreCount + 1
  setLoadMoreCount(page)
  const value = {
    class_id: data.cc_class_id,
    filter_by: "not_in_current_class",
    page_no: page,
    academic_year: AcademicData.academic_year_id,
    sort_by: "sort_by_first_name",
    // tag_id: tag_id,
    search_text: "",
    by_date: "",
    date_flag: "",

}


dispatch(getNotSelectedRosters(value))

// if(statesData && statesData.length == notSelectedStudentsCount) {
//   setHasMore(false)
// }
// setHasMore(false)
}

const handleScroll = () => {

  const { scrollTop, clientHeight, scrollHeight } = modalRef?.current;
  
  if(statesData && statesData.length != notSelectedStudentsCount) {
  if (parseInt(scrollTop) + clientHeight == scrollHeight && !isLoading) {
    setIsLoading(true);
    fetchMoreData()
    
    // const fetchMoreData = async () => {
    //   // Fetch more data from API or generate some dummy data
    //   // const moreData = await someAPI.fetchData();
    //   // setData(prevData => [...prevData, ...moreData]);
    //   let page = loadMoreCount + 1
    //   // setLoadMoreCount(page)
    //   const value = {
    //     class_id: data.cc_class_id,
    //     filter_by: "not_in_current_class",
    //     page_no: loadMoreCount + 1,
    //     academic_year: AcademicData.academic_year_id,
    //     sort_by: "sort_by_first_name",
    //     // tag_id: tag_id,
    //     search_text: "",
    //     by_date: "",
    //     date_flag: "",
    
    // }

    // dispatch(getNotSelectedRosters(value))
    //   setIsLoading(false);
    // };
  }
}
  //  else {
  //   setIsLoading(false)
  // }
}


      const selectRoster = () => {
        // alert("dsada")
        if (selectedRosterId.length === 0) {
            createNotification('error', 'Please select at least one Roster/student.');
            return;
        }
        // const { location = {} } = this.props
        // const { state = {} } = location;
        // const { ClassDetails = '', academicYearId = '' } = state;
        // const { selectedRosterId, filter, loadMoreCount, sort_by, tag_id } = this.state
        const datas = {
            "student_id": `${selectedRosterId}`,
            "academic_year_id": AcademicData.academic_year_id,
            "class_id": data.cc_class_id,
            "by_class_sharing": 'yes',
            "filter_by": filter,
            "page_no": loadMoreCount,
            "sort_by": "sort_by_first_name",
            // "tag_id": tag_id,
            history: history,
            limit: numBoxes
        }
   
        // this.setState({
        //     importRoster: true
        // })
        // this.props.addSelectedRosters(data)
        dispatch(addSelectedRosters(datas))
        setLoadMoreCount(1)
        setLoadMoreCountStatus(false)
        setStatesData([])
    }

    const handleSearchText = (event) => {
  
      setSearchText(event.target.value)
      // setIsAddStudentSubmit(false);
      // setInvelidMessageEmail("");
      // if (event.target.value.length > 2) {
      //   let value = {
      //     search_string: event.target.value,
      //     academic_year_id: data.academic_year_id,
      //     page: loadMoreCount > 1 ? "full" : loadMoreCount,
      //     limit: 500,
      //     platform: "web",
      //   };
      //   dispatch(searchRoster(value));
      // } else {
      //   setStudentList(StudentsWithoutSearch);
      // }
    };

    useEffect(() => {
      if(searchText && searchText != 'null') {
      if(searchText.length > 2) {
        const delayDebounceFn = setTimeout(() => {
            // Send Axios request here
            if(searchText.length > 2) {
              const value = {
                class_id: data.cc_class_id,
                filter_by: "by_class",
                page_no: 1,
                // academic_year: AcademicData.academic_year_id,
                academic_year: AcademicData?.year_ids,
                search_data : searchText,
                limit: numBoxes
            }
            getClassRosterList(value);
            } else {
              const value = {
                class_id: data.cc_class_id,
                filter_by: "by_class",
                page_no: 1,
                // academic_year: AcademicData.academic_year_id,
                academic_year: AcademicData?.year_ids,
                search_data : searchText,
                limit: numBoxes
            }
            getClassRosterList(value);
            }
          }, 2000)

          return () => clearTimeout(delayDebounceFn)
        } else {
          const value = {
            class_id: data.cc_class_id,
            filter_by: "by_class",
            page_no: 1,
            // academic_year: AcademicData.academic_year_id,
            academic_year: AcademicData?.year_ids,
            search_data : searchText || '',
            limit: numBoxes
        }
        getClassRosterList(value);
        }
      }
    },[searchText])


    const handleUnselectStudentSearchText = (event) => {
   
      setSearchUnselectStudentText(event.target.value)
      // setIsAddStudentSubmit(false);
      // setInvelidMessageEmail("");
      // if (event.target.value.length > 2) {
      //   let value = {
      //     search_string: event.target.value,
      //     academic_year_id: data.academic_year_id,
      //     page: loadMoreCount > 1 ? "full" : loadMoreCount,
      //     limit: 500,
      //     platform: "web",
      //   };
      //   dispatch(searchRoster(value));
      // } else {
      //   setStudentList(StudentsWithoutSearch);
      // }
    };


    useEffect(() => {
      if(searchUnselectStudentText) {
      if(searchUnselectStudentText.length > 2) {
        setStatesData([])
        setIsLoading(true)
        const value = {
          class_id: data.cc_class_id,
          filter_by: "not_in_current_class",
          page_no: 1,
          academic_year: AcademicData.academic_year_id,
          sort_by: "sort_by_first_name",
          // tag_id: tag_id,
          search_text: searchUnselectStudentText,
          by_date: "",
          date_flag: "",
      
      }
      
      dispatch(getNotSelectedRosters(value))
      } else {
        setStatesData([])
        setIsLoading(true)
        setSearchUnselectStudentText(null)
        if(searchUnselectStudentText == '') {
          const value = {
            class_id: data.cc_class_id,
            filter_by: "not_in_current_class",
            page_no: 1,
            academic_year: AcademicData.academic_year_id,
            sort_by: "sort_by_first_name",
            // tag_id: tag_id,
            search_text: searchUnselectStudentText,
            by_date: "",
            date_flag: "",
        
        }
        
        dispatch(getNotSelectedRosters(value))
        } else if(searchUnselectStudentText.length < 2 || searchUnselectStudentText.length == 0) {
          const value = {
            class_id: data.cc_class_id,
            filter_by: "not_in_current_class",
            page_no: 1,
            academic_year: AcademicData.academic_year_id,
            sort_by: "sort_by_first_name",
            // tag_id: tag_id,
            search_text: "",
            by_date: "",
            date_flag: "",
        
        }
        
        dispatch(getNotSelectedRosters(value))
        } else {
        const value = {
          class_id: data.cc_class_id,
          filter_by: "not_in_current_class",
          page_no: 1,
          academic_year: AcademicData.academic_year_id,
          sort_by: "sort_by_first_name",
          // tag_id: tag_id,
          search_text: "",
          by_date: "",
          date_flag: "",
      
      }
      
      dispatch(getNotSelectedRosters(value))
      }
      }
    } else {
      const value = {
        class_id: data?.cc_class_id,
        filter_by: "not_in_current_class",
        page_no: 1,
        academic_year: AcademicData?.academic_year_id,
        sort_by: "sort_by_first_name",
        // tag_id: tag_id,
        search_text: "",
        by_date: "",
        date_flag: "",
    
    }
    
    dispatch(getNotSelectedRosters(value))
    }
    },[searchUnselectStudentText])

    return (
        <div className="viewSingleClassContainer container">
          <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          background: "#f0f0f0",
          height: 176,
        }}
      >
            <div className="ClassRosterYearMainDiv">
                <div className='classRosterDetailsMain'>
                    {/* <div className="rosterClassBackDiv"
                        onClick={goBackPrev}
                    >
                        <i className="rosterClassBackIcon material-icons">chevron_left</i>
                        <p className='rosterClassBactText'>{history.location.state.BackPage}</p>
                    </div>
                    <div className='rosterClassDetailTextDiv'>
                        <p className='rosterClassName'>
                            {data?.cc_class_name.replace(/(.{13})..+/, "$1…")}
                        </p>
                        <div className='displayFlexClassRoster'>
                            <p className='rosterClassGrade'>
                                Section: {data.cc_class_section}
                            </p>
                            <p className='rosterClassGrade'>
                                Grade: {data.cc_class_grade}
                            </p>
                        </div>
                    </div> */}
                    <div className="display-inline">
          <div className="rosterYearBackDiv-RD">
            <img
              className="calIcon-RD"
              src={back}
              alt=""
              width="60"
              height="40"
              onClick={() => {
                goBackPrev();
              }}
            />
          </div>
          <div className="totalStudentDiv-RD">
            <div style={{whiteSpace: "nowrap"}} className="attRosterYearDiv-RD singleClassYearDiv">
              <p
                className="attClassGrade-RD"
                onClick={() => {
                  goBackPrev();
                }}
              >
             {AcademicData?.academic_year}
              </p>
              <i className="rosterForIcon-RD material-icons ">chevron_right</i>
              {/* {props?.history?.location?.state?.isComingFromClassDashboard &&
                                        <>
                                            <p className='attClassGrade-RD' onClick={() => {props?.history?.goBack()}}>Class</p>
                                            <i className="rosterForIcon-RD material-icons">chevron_right</i>
                                        </>
                                    } */}
                                    <div className='dis'>
              <p className="attClassGrade2-RD" style={{position : 'relative', top : 1}}> Class:  {data?.cc_class_name?.replace(/(.{13})..+/, "$1…")}</p>
              </div>
            </div>
          </div>
        </div>
                </div>
                <div 
                // className={JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? "buttonMarginTop d-none" : "buttonMarginTop"}
                className={"buttonMarginTop"}
                >
                    <Button className={`${editSingle.join(" ")} ${JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? 'edit-d-none' : ''}`} 
                     disabled={data?.cc_class_share_permission !== null && (data?.cc_class_share_permission === "read" || data?.cc_class_share_permission == "collaborate") ? true : false}
                    onClick={() => { 
                    
                      editClassOpen(data)
                     
                      }}
                      >
                        <img className='editSingleIcon' src={pencilIcon} alt='' />
                        <span className='editSingleText'>Edit</span>
                    </Button>
                    <Button 
                    // className={shareSingle.join(" ")} 
                    className='editSingle'
                    onClick= {() => {
                      // data?.cc_class_share_permission == null && data?.cc_class_share_permission !== "read" && data?.cc_class_share_permission !== "collaborate" &&
                            history.push(routes.CLASSSHARE, {
                                classDetail: data
                            }) 
                          } }
                            >
                        <img className='editSingleIcon' src={shareIcon} alt='' />
                        <span className='editSingleText'>Share</span>
                    </Button>
                    <Button value={isDeleted} data-testid = "deletemockbtn" className= {`deleteSingle ${JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? 'delete-d-none' : ''}`} onClick={() => {
                                                // setDeleteDiv(item.cc_class_id);
                                                if(data?.cc_class_share_permission !== null && (data?.cc_class_share_permission === "read" || data?.cc_class_share_permission == "collaborate")) {
                                                  return ;
                                                } else {
                                                setIsDeleted(true)
                                                setDeleteItem()
                                                }
                                                // this.setState({
                                                //     deleteDiv: item.cc_class_id
                                                // })
                                              }}
                                               disbaled={data?.cc_class_share_permission !== null && (data?.cc_class_share_permission === "read" || data?.cc_class_share_permission == "collaborate") ? true : false}
                                               style={{cursor : data?.cc_class_share_permission !== null && (data?.cc_class_share_permission === "read" || data?.cc_class_share_permission == "collaborate") ? 'default' : 'pointer',
                                               opacity : data?.cc_class_share_permission !== null && (data?.cc_class_share_permission === "read" || data?.cc_class_share_permission == "collaborate") && '0.5'
                                              }}
                                              >
                        <img className='deleteSingleIcon' src={trashIcon} alt='' />
                        <span className='deleteSingleText'>Delete</span>
                    </Button>
                </div>
                
            </div>
            <div className="buttonsAllMain">
              <div className='d-flex'>
              <div style={{borderRight  :'1px solid lightgray', height : 34, marginRight : 15}} className='d-flex align-items-center'>
              <p style={{    whiteSpace: "nowrap", paddingRight: "9px", marginRight: "6px"}} className='rosterClassGrade rosterClassGradeMargin'>
                                Section: {data?.cc_class_section} <span className='ml-3'> Grade: {data?.cc_class_grade}</span>
                            </p>
                </div>
                <div
                  className="singleClassSearchFilter"
                  onClick={() => {
                    // this.setState({
                    //     // searchBar: true,
                    //     // searchText: ''
                    // })
                  }}
                >
                  <img
                    className="searchFilterIcon-RD"
                    src={iconSearch}
                    alt=""
                  />
                  <CommonTextField
                  dataTestId = "searchmocktest"
                    margin="normal"
                    // variant="outlined"
                    type="FirstName"
                    value={searchText}
                    onChange={(event) => {
                      handleSearchText(event);
                      // setSearchText(event.target.value);
                    }}
                    name="searchText"
                    className="searchFilterInputBox-RD"
                    placeholder="Enter Keywords…"
                  />
                </div>
                </div>
               <div className='d-flex overflow-auto singleClassScroll' style={{marginLeft : 30}}>                               
                <div className="colorBall">
                    {data?.cc_class_share_permission !== null && data?.cc_class_share_permission === "read" ?
                     <img src={
                      (sessionStorage.getItem('classColorWheel') ? (sessionStorage.getItem('classColorWheel') === 'yes' ? GroupActiveIcon : GroupIcon) : (data?.cc_class_color_wheel_display === 'yes' ? GroupActiveIcon : GroupIcon))} alt='' className='disabledGroupActiveIcon' />
                        : <img onClick={() => { editColorWheel(data) }} 
                        src={
                          (sessionStorage.getItem('classColorWheel') ? (sessionStorage.getItem('classColorWheel') === 'yes' ? GroupActiveIcon : GroupIcon) : (data?.cc_class_color_wheel_display === 'yes' ? GroupActiveIcon : GroupIcon))} alt='' className='groupActiveIcon' />}
                </div>
                <div className="attDiv" onClick={() => {
                    history.push(routes.CLASSATTENDANCE
                        , {
                            classDetail: data, className: data, academicYear: AcademicData?.academic_year,  isClasses: true
                        }
                    );
                }}>
                    <img src={attendance} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Attendance</p>
                </div>
                <div className="NotesDiv"
                 onClick={() => {
                  history.push(
                    
                    routes.CLASSNOTE, {
                    Class_id: data.cc_class_id,
                    academicYearId: AcademicData.academic_year_id,
                    academicYear: AcademicData.academic_year,
                    BackPage: 'ClassDB',
                    ClassData: data,
                    classDetail: data.cc_class_name,
                    // selectedClass: item,
                    classListData: props?.location?.state?.AcademicData?.class_data,

                  
                })
         

              }}
               >
                    <img src={noteIcon} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Notes</p>
                </div>
                <div className="GrpNotesDiv"   onClick={() => {
                //   history.push(
                    
                //     routes.CLASSNOTE, {
                //     Class_id: data.cc_class_id,
                //     academicYearId: AcademicData.academic_year_id,
                //     academicYear: AcademicData.academic_year,
                //     BackPage: 'ClassDB',
                //     ClassData: data,
                //     classDetail: data.cc_class_name,
                //     // selectedClass: item,
                //     classListData: props?.location?.state?.AcademicData?.class_data,
                //     link_type : "Group Notes"
                  
                // })
                let routingData = {
                  details : data,
                  groupId : "-0",
                  groupName : "",
                  name : data.cc_class_name,
                  value : data.cc_class_id
                }
                 history.push(routes.CLASSADDGROUPNOTE, {
                    // class_id: classDetails.cc_class_id,
                    class_id: data.cc_class_id,
                    pageNo: 1,
                    // academicYear: AcademicDetails.academic_year,
                    academicYear: AcademicData.academic_year,
                    // classDetails: data.cc_class_name,
                    classDetails : routingData,
                    ClassData: data,
                    // selectedClass: args,
                    classListData: props?.location?.state?.AcademicData?.class_data,
                    BackPage: 'ClassDB',
                    backpage : "singlecls",
                    academicYearId: AcademicData.academic_year_id
                })
             
              }}
               >
                    <img src={grpnoteIcon} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Group Notes</p>
                </div>
                <div className="lessonDiv" onClick={() => {
                  let classAfterRosterAddData = {...data}
                  classAfterRosterAddData.student_count = studentRosterData?.length;
                    history.push(routes.CLASSLESSONPLAN
                        , {
                            classDetail: studentRosterData && studentRosterData.length > 0 ? classAfterRosterAddData : data, academicYearId: AcademicData.academic_year_id,
                            academicYear: AcademicData.academic_year, classId: data.cc_class_id,
                            classPermission: data.cc_class_share_permission
                        }
                    );
                }}>
                    <img src={cardList} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Lesson Plan</p>
                </div>
                <div className="rubDiv" onClick={() => {
                    let classAfterRosterAddData = {...data}
                    classAfterRosterAddData.student_count = studentRosterData?.length;
                    if (data.student_count === 0 && classAfterRosterAddData.student_count === 0) {
                        createNotification('error', "No students enrolled. Please enrol students to create Rubrics.");
                        return;
                    }
                    sessionStorage.setItem("rubricsSelectedClass", studentRosterData && studentRosterData.length > 0 ? JSON.stringify(classAfterRosterAddData) : JSON.stringify(data));
                    history.push(routes.CLASSRUBRICS
                        , {
                            AcademicData: AcademicData,
                            classDetailsdata: studentRosterData && studentRosterData.length > 0 ? classAfterRosterAddData : data,
                            backpage: "classes",
                            classDetail: data, academicYearId: AcademicData.academic_year_id,
                            academicYear: AcademicData.academic_year, classId: data.cc_class_id,
                            classPermission: data.cc_class_share_permission
                        }
                    );
                }}>
                    <img src={userRubrics} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Rubrics</p>
                </div>

                <div className="FADiv" 

                onClick={() => {
                  let classAfterRosterAddData = {...data}
                  classAfterRosterAddData.student_count = studentRosterData?.length;
                    if (data.student_count === 0 && classAfterRosterAddData.student_count === 0) {
                        createNotification('error', "Students not enrolled. Please enrol students to create Rubrics.");
                        return;
                    }
                    sessionStorage.setItem("rubricsSelectedClass", studentRosterData && studentRosterData.length > 0 ? JSON.stringify(classAfterRosterAddData) : JSON.stringify(data));
                 
                      history.push(routes.FORMATIVEASSESSMENT, { AcademicData: AcademicData, BackPage: 'class',classDetailsdata: studentRosterData && studentRosterData.length > 0 ? classAfterRosterAddData : data,  BackPage: 'ClassDB', })
                 
                }}
                >
                    <img src={FAIcon} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Formative Assesment</p>
                </div>


                <div className="GradeBookDiv"
                onClick={() => {
                  let classAfterRosterAddData = {...data}
                  classAfterRosterAddData.student_count = studentRosterData?.length;
                  sessionStorage.setItem("cLsIdForGBSC" , data.cc_class_id)
                  // getStudentGradebookNullRequest()
                  history.push(routes.CLASSGRADEBOOK, { id: AcademicData.academic_year_id, academicYear: AcademicData.academic_year, classId: data.cc_class_id, classPermission: data.cc_class_share_permission , BackPage: 'class', AcademicData: AcademicData , classDetailsdata : studentRosterData && studentRosterData.length > 0 ? classAfterRosterAddData : data})
              }}
                >
                    <img src={GradeBookIcon} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Gradebook</p>
                </div>



                <div className={standardOpacity.join(' ')} onClick={() => {
                   let classAfterRosterAddData = {...data}
                   classAfterRosterAddData.student_count = studentRosterData?.length;
                    history.push(routes.CLASSCORESTANDARDS, {
                        classDetail: studentRosterData && studentRosterData.length > 0 ? classAfterRosterAddData : data, academicYearId: AcademicData.academic_year_id,
                        academicYear: AcademicData.academic_year, classId: data.cc_class_id,
                        classPermission: data.cc_class_share_permission
                    })
                }}>
                    <img src={standardIcon} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Standards</p>
                </div>
              
               
                <div className="tagDiv" onClick={() => {
                   let classAfterRosterAddData = {...data}
                   classAfterRosterAddData.student_count = studentRosterData?.length;
                    history.push(routes.CLASSTAG, {
                        classDetail: studentRosterData && studentRosterData.length > 0 ? classAfterRosterAddData : data, academicYearId: AcademicData.academic_year_id,
                        classPermission: data.cc_class_share_permission
                    })
                }}>
                    <img src={tagIcon} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Tag</p>
                </div>
              
                {/* <div className="shareDiv">
                    {data.cc_class_share_permission !== null && data.cc_class_share_permission === "read" ?
                        <div className='opacityProp'>
                            <img src={userShareIcon} alt='' className='classOptionIcons' />
                            <p className='classOptionText'>Share</p>
                        </div>
                        : <div className="shareSubDiv" onClick={() => {
                            history.push(routes.CLASSSHARE, {
                                classDetail: data
                            });
                        }}>
                            <img src={userShareIcon} alt='' className='classOptionIcons' />
                            <p className='classOptionText'>Share</p>
                        </div>}
                </div> */}
                </div> 
                
            {/* <div className='displayFlexClassRosters'>
                            <p className='rosterClassGrade'>
                                Section: {data.cc_class_section} <span> Grade: {data.cc_class_grade}</span>
                            </p>
                        </div> */}

                    {/* <div className='d-flex w-100 justify-content-end flex-wrap'>    
                <div className="colorBall">
                    {data.cc_class_share_permission !== null && data.cc_class_share_permission === "read" ? <img src={data.cc_class_color_wheel_display === 'yes' ? GroupActiveIcon : GroupIcon} alt='' className='disabledGroupActiveIcon' />
                        : <img onClick={() => { editColorWheel(data) }} src={data.cc_class_color_wheel_display === 'yes' ? GroupActiveIcon : GroupIcon} alt='' className='groupActiveIcon' />}
                </div>
                <div className="attDiv" onClick={() => {
                    history.push(routes.CLASSATTENDANCE
                        , {
                            classDetail: data, className: data, academicYear: AcademicData.academic_year
                        }
                    );
                }}>
                    <img src={attendance} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Attendance</p>
                </div>
                <div className="NotesDiv"
                 onClick={() => {
                  history.push(
                    
                    routes.CLASSNOTE, {
                    Class_id: data.cc_class_id,
                    academicYearId: AcademicData.academic_year_id,
                    academicYear: AcademicData.academic_year,
                    BackPage: 'ClassDB',
                    ClassData: data,
                    classDetail: data.cc_class_name,
                    // selectedClass: item,
                    classListData: props?.location?.state?.AcademicData?.class_data,

                  
                })
           

              }}
               >
                    <img src={noteIcon} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Notes</p>
                </div>
                <div className="GrpNotesDiv"   onClick={() => {
                //   history.push(
                    
                //     routes.CLASSNOTE, {
                //     Class_id: data.cc_class_id,
                //     academicYearId: AcademicData.academic_year_id,
                //     academicYear: AcademicData.academic_year,
                //     BackPage: 'ClassDB',
                //     ClassData: data,
                //     classDetail: data.cc_class_name,
                //     // selectedClass: item,
                //     classListData: props?.location?.state?.AcademicData?.class_data,
                //     link_type : "Group Notes"
                  
                // })
                 history.push(routes.CLASSADDGROUPNOTE, {
                    // class_id: classDetails.cc_class_id,
                    class_id: data.cc_class_id,
                    pageNo: 1,
                    // academicYear: AcademicDetails.academic_year,
                    academicYear: AcademicData.academic_year,
                    classDetails: data.cc_class_name,
                    ClassData: data,
                    // selectedClass: args,
                    classListData: props?.location?.state?.AcademicData?.class_data,
                })
    

              }}
               >
                    <img src={grpnoteIcon} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Group Notes</p>
                </div>
                <div className="lessonDiv" onClick={() => {
                    history.push(routes.CLASSLESSONPLAN
                        , {
                            classDetail: data, academicYearId: AcademicData.academic_year_id,
                            academicYear: AcademicData.academic_year, classId: data.cc_class_id,
                            classPermission: data.cc_class_share_permission
                        }
                    );
                }}>
                    <img src={cardList} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Lesson Plan</p>
                </div>
                
                <div className="rubDiv" onClick={() => {
                    if (data.student_count === 0) {
                        createNotification('error', "No students enrolled. Please enrol students to create Rubrics.");
                        return;
                    }
                    sessionStorage.setItem("rubricsSelectedClass", JSON.stringify(data));
                    history.push(routes.CLASSRUBRICS
                        , {
                            AcademicData: AcademicData,
                            classDetailsdata: data,
                            backpage: "classes",
                            classDetail: data, academicYearId: AcademicData.academic_year_id,
                            academicYear: AcademicData.academic_year, classId: data.cc_class_id,
                            classPermission: data.cc_class_share_permission
                        }
                    );
                }}>
                    <img src={userRubrics} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Rubrics</p>
                </div>

                <div className="FADiv" 

                onClick={() => {
                    if (data.student_count === 0) {
                        createNotification('error', "Students not enrolled. Please enrol students to create Rubrics.");
                        return;
                    }
                    sessionStorage.setItem("rubricsSelectedClass", JSON.stringify(data));
                 
                      history.push(routes.FORMATIVEASSESSMENT, { AcademicData: AcademicData, BackPage: 'class',classDetailsdata: data })
                 
                }}
                >
                    <img src={FAIcon} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Formative Assesment</p>
                </div>


                <div className="GradeBookDiv"
                onClick={() => {

                
                  // getStudentGradebookNullRequest()
                  history.push(routes.CLASSGRADEBOOK, { id: AcademicData.academic_year_id, academicYear: AcademicData.academic_year, classId: data.cc_class_id, classPermission: data.cc_class_share_permission , BackPage: 'class', AcademicData: AcademicData , classDetailsdata : data})
              }}
                >
                    <img src={GradeBookIcon} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Gradebook</p>
                </div>



                <div className={standardOpacity.join(' ')} onClick={() => {
                    history.push(routes.CLASSCORESTANDARDS, {
                        classDetail: data, academicYearId: AcademicData.academic_year_id,
                        academicYear: AcademicData.academic_year, classId: data.cc_class_id,
                        classPermission: data.cc_class_share_permission
                    })
                }}>
                    <img src={standardIcon} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Standards</p>
                </div>
              
               
                <div className="tagDiv" onClick={() => {
                    history.push(routes.CLASSTAG, {
                        classDetail: data, academicYearId: AcademicData.academic_year_id,
                        classPermission: data.cc_class_share_permission
                    })
                }}>
                    <img src={tagIcon} alt='' className='classOptionIcons' />
                    <p className='classOptionText'>Tag</p>
                </div>
              
                <div className="shareDiv">
                    {data.cc_class_share_permission !== null && data.cc_class_share_permission === "read" ?
                        <div className='opacityProp'>
                            <img src={userShareIcon} alt='' className='classOptionIcons' />
                            <p className='classOptionText'>Share</p>
                        </div>
                        : <div className="shareSubDiv" onClick={() => {
                            history.push(routes.CLASSSHARE, {
                                classDetail: data
                            });
                        }}>
                            <img src={userShareIcon} alt='' className='classOptionIcons' />
                            <p className='classOptionText'>Share</p>
                        </div>}
                </div>
                </div> */}
            </div>
            </div>


            <div  style={{display : "flex" , justifyContent : "end"}} className="addstudent">
                {/* <img style={{cursor : "pointer", display : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ||  data.cc_class_share_permission !== null && data.cc_class_share_permission === "read") && 'none'}}  className="addStudentImg"
              color="primary"
              data-toggle="modal"
              data-target="#myModal2"
              onClick={() => {
              // this.props.setClassSidebarOpen(true)
              // history.push(routes.CLASS, { data: data, BackPage: 'Academic Year' });
              }}  src= {AddStudent} alt="" srcset="" /> */}
              <div data-testid = "addStudentMockBtn" style={{cursor : "pointer", display : (JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ||  data?.cc_class_share_permission !== null && data?.cc_class_share_permission === "read") && 'none'}} data-toggle="modal"  data-target="#myModal2" className='addstudentdiv'>
                <img   className="blueplus"
              color="primary"
              
              onClick={() => {
              // this.props.setClassSidebarOpen(true)
              // history.push(routes.CLASS, { data: data, BackPage: 'Academic Year' });
              }}  src= {blueplus} alt="" srcset="" width={28}/>
                <span>Student</span>
                </div>
             
                {/* <img data-toggle="modal"
              data-target="#myModal3" style={{cursor : "pointer", display : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ||  data.cc_class_share_permission !== null && data.cc_class_share_permission === "read") && 'none'}} className='selectStudentImg' src= {SelectStudent} alt="" srcset="" 
              // onClick={() => checkModalHeight()}
              /> */}

<div style={{cursor : "pointer", padding : "0 20px", display : (JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ||  data?.cc_class_share_permission !== null && data?.cc_class_share_permission === "read") && 'none'}} 
data-toggle="modal"  data-target="#myModal3" className='addstudentdiv'>
                <img   className="blueplus"
              color="primary"
              
              onClick={() => {
              // this.props.setClassSidebarOpen(true)
              // history.push(routes.CLASS, { data: data, BackPage: 'Academic Year' });
              }}  src= {Selectroster} alt="" srcset="" width={20}/>
                <span style={{fontSize : 17}}>Select Student(s)</span>
                </div>  
              

                {/* <img data-toggle="modal"
              data-target="#myModalupload" style={{cursor : "pointer", display : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ||  data.cc_class_share_permission !== null && data.cc_class_share_permission === "read") && 'none'}} className='importImg' src= {Import} alt="" srcset="" /> */}
            <div style={{cursor : "pointer", padding : "0 20px", display : (JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ||  data?.cc_class_share_permission !== null && data?.cc_class_share_permission === "read") && 'none'}} 
data-toggle="modal"  data-target="#myModalupload" className='addstudentdiv'>
                <img   className="blueplus"
              color="primary"
              
              onClick={() => {
              // this.props.setClassSidebarOpen(true)
              // history.push(routes.CLASS, { data: data, BackPage: 'Academic Year' });
              }}  src= {ImportRosterIcon} alt="" srcset="" width={29}/>
                <span style={{fontSize : 17}}>Import</span>
                </div> 
            
            </div>
            {((loader || rosterData.loader) && !loadMoreLoader) && <div>
                <DivLoader />
            </div>}
            {studentRosterData === undefined && <p className="noStudentTagline">No students added in this class</p>}
            {studentRosterData && studentRosterData.length > 0 ? 
            <div
            ref={DataRef}
            style={{
              height : studentRosterData.length > 0 ? "calc(100vh - 200px)" : "82vh",
          overflowY : 'scroll',
          overflowX : 'hidden'
            }}
            onScroll={handleParentScroll}
            >
            <div className='classRosterCardMainDiv row singleParentCard'>
                {studentRosterData && studentRosterData.length > 0 && studentRosterData.map((item, index) => (
         
                <React.Fragment key={index}>
                    {removeRoster === item.cc_student_id ? 
                    <div className='deleteClassRosterCard'>
                        <p className='deleteClassRosterText'>Remove Roster</p>
                        <p className='deleteWorningClassRosterText'>Are you sure want to Remove all Roster data associated with the Class?</p>
                        <div className='deleteButtonsClassRosterDiv'>
                            <div className='cancelButtonClassRosterText' onClick={() => { setRemoveRoster('') }}>
                                Cancel
                            </div>
                            <div>
                                <Button onClick={() => { deleteRoster(item.cc_student_id) }} className='deleteButtonClassRoster' color="primary" >
                                    <span className='deleteButtonClassRosterText' > Remove</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                        : 
                        <div className=' col-xs-12 col-sm-12 col-lg-3 col-md-3'>
                        <div style={{height : "4.8rem"}} className='classRosterCardDiv singleClassRosterCardDiv'>
                            <div className='classRosterDetailsCard' style={{padding : 0}}>
                                <div className='classRosterProfileImageDiv d-flex justify-content-center align-items-center'>
                                    {item?.cc_student_pic_url?.includes('https') ? <img src={item?.cc_student_pic_url} alt='' className='studentProfileImage' /> :
                                        <div className='imageDummyDivProfile d-flex justify-content-center align-items-center' style={{marginLeft : 0, marginTop : 0}}>
                                            <h5 className='profileImageDummyText mb-0 pt-0'>{item?.cc_student_first_name?.charAt(0)}{item?.cc_student_last_name?.charAt(0)}</h5>
                                        </div>}
                                </div>
                                <div className='classRosterTextInfoDiv d-flex flex-column justify-content-center flex-wrap mt-0'>
                                <p 
                                    // style={item.cc_student_email_id == "" ? {paddingTop : "8px"} : {paddingTop : "0px"}} 
                                    className='classRosterName'>{item.cc_student_last_name?.replace(/(.{10})..+/, "$1…")}, {item.cc_student_first_name?.replace(/(.{10})..+/, "$1…")}</p>
                                    {item.cc_student_email_id.length > 30 ? <p data-title={item.cc_student_email_id}>
                                        {_.truncate(item.cc_student_email_id, {
                                            'length': 30,
                                            'omission': '...'
                                        })}</p> : <p className='classRosterEmail'>{item.cc_student_email_id}</p>}
                                    {/* <div onClick={() => { EditLevel(item, index) }} className='displayFlexClassRoster'>
                                        <i className="classRosterEditicon material-icons">edit</i>
                                        <p className='classRosterEmail'>Level: {item.cc_note_level === '' ? 0 : item.cc_note_level} | Score: {item.cc_note_score === '' ? 0 : item.cc_note_score}</p>
                                    </div> */}
                                </div>
                                <div
                              id={`PopoverLegacy${index}`}
                              type="button"
                              // onClick={() => editStudent(item.cc_student_id)}
                              onClick={() => {
                                setSchoolPopOverId(item.cc_student_id)
                                setSelectedEditRoster(item.cc_student_id);
                                setDisplayLevelScorePopup(true)
                              }}
                              className="studentMenuIcon-RD"
                              // style={{ display: this.state.isHovered[index] ? this.state.isHovered[index] : 'none' }}
                            >
                              <i className="material-icons iconhover">more_vert</i>
                              {/* <UncontrolledPopover
                                // trigger="legacy"
                                placement="right-start"
                                isOpen={item.editOpen}
                                target={`PopoverLegacy${index}`}
                                toggle={() => editStudent(item.cc_student_id)}
                                style={{ borderRadius: 20 }}
                                className="popover academicPopOver-RD"
                              >
                                <PopoverBody> */}
                                {
                                  item.cc_student_id == schoolPopOverId &&
                                <div className='singleClassPopoverParentDiv'>
                                  <div
                                  
                                  // className={`popover academicPopOver-RD ${isDeleted3 || ref?.current?.style?.display == 'none' ? '' : 'singlClassPopover-RD'}`}
                                  className={`popover academicPopOver-RD ${(ref?.current?.style?.display == 'none' || isDeleted3 || !displayLevelScorePopup) ? '' :  (`${(index + 1) % 4 == 0 ? `singleClassPopoverParentDiv-CR` : 'singlClassPopover-RD'}`)}
                                   
                                   
                                   `}
                                  >
                                  <div className="editInputDiv-RD" ref={ref}>
                                    <div className="inputMaskUpdateParentDiv-RD">
                                      <div className="inputMaskUpdateDiv-RD">
                                        <div className="studentOptionDiv-RD">
                                        <div style={{    width: updateLevelScore ? "249px" : "238px"}} className='levelEditmainFieldDiv align-items-center singleClassLevelEditField'>
                                          {
                                            !updateLevelScore ? 
                                            <>
                                            <p className='inputFieldLabelLevelEditText'>Level : {item.cc_note_level}</p>
                                <p className='inputFieldLabelLevelEditText'>Score : {item.cc_note_score}</p>
                                            </> : 
                                            <>
                                            <div className='d-flex align-items-center'>Level: <CommonTextField
                                    style={{marginLeft : 11, marginBottom : 0}}
                                    margin="normal"
                                    variant="outlined"
                                    type='text'
                                    value={modalLevel}
                                    defaultValue={item.cc_note_level ? item.cc_note_level : ""}
                                    onChange={(event) => handleChangeLevel(event)}
                                    name="ModalLevel"
                                    // className="InfoInputType"
                                    className="InfoInputTypee"
                                    // placeholder="Enter Level" onClick={() => {setSchoolPopOverId(item.cc_student_id)}}
                                    />
                                    {/* {(!modalLevel ) && <p className="errormessageRegister">Please enter level*</p>} */}
                                     </div>

                                     <div className='d-flex align-items-center'>Score:  <CommonTextField
                                    margin="normal"
                                    variant="outlined"
                                    type='text'
                                    value={modalScore}
                                    defaultValue={item.cc_note_score ? item.cc_note_score : ""}
                                    onChange={handleChangeScore}
                                    name="ModalScore"
                                    // className="InfoInputType"
                                    className="InfoInputTypee"
                                    // placeholder="Enter Score"
                                     />
                                     <hr />
                                    {/* {(!modalScore ) && <p className="errormessageRegister">Please enter score*</p>} */}
                                    </div>
                                            </>
                                          }
                                          <div className='d-flex justify-content-around' style={{width : !updateLevelScore ? 'auto' : 60 ,     height: "32px" ,  alignItems: "center" ,     borderLeft: updateLevelScore ? "1px solid #DDDDDD" : "none"}}>
                                            {
                                              !updateLevelScore ?  
                                              <i className="editClassIcon material-icons" style={{fontSize : 20}} onClick={() => { setUpdateLevelScore(true); setModalScore(item.cc_note_score); setModalLevel(item.cc_note_level) }}>edit</i>
                                                :
                                                <>
                                                <ClearIcon onClick={() => { setUpdateLevelScore(false); setModalLevel(""); setModalScore(""); setDisplayLevelScorePopup(false) }} style={{color : 'red'}}/>
                                     <CheckIcon onClick={() => { updateScoreLevel(item); ref.current.style.display = 'none'; setDisplayLevelScorePopup(false) }} style={{color  :"#08B596"}}/>
                                                </>
                                            }
                                
                                        
                                    
                                     
                                      </div>
                            {/* <div className='inputFieldAdditionalModal'> */}
                                {/* <CommonTextField
                                    
                                    margin="normal"
                                    variant="outlined"
                                    // type='number'
                                    value={modalLevel || item.cc_note_level}
                                    onChange={handleChangeLevel}
                                    name="ModalLevel"
                                    // className="InfoInputType"
                                    className="InfoInputTypee"
                                    placeholder="Enter Level" onClick={() => {setSchoolPopOverId(item.cc_student_id)}}/> */}
                                {/* {(!modalLevel && isAddStudentSubmit) && <p className="errormessageRegister">Please enter level*</p>} */}
                            {/* </div> */}

                            {/* <div className='inputFieldLabelLevelEdittt'> */}
                                {/* <p className='inputFieldLabelLevelEditText'>Score : 22</p> */}
                            {/* </div> */}
                            {/* <div className='inputFieldAdditionalModal'>
                                <CommonTextField
                                    margin="normal"
                                    variant="outlined"
                                    type='number'
                                    value={modalScore || item.cc_note_score}
                                    onChange={handleChangeScore}
                                    name="ModalScore"
                                    // className="InfoInputType"
                                    className="InfoInputTypee"
                                    placeholder="Enter Score" />
                                {(!modalScore && isAddStudentSubmit) && <p className="errormessageRegister">Please enter score*</p>}
                            </div> */}
                        </div>
                  
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                           

                                            {/* ------------Attendance Div----------  */}

                                            {/* ------------Notes Div----------  */}
                                            <div
                                              className="optionBtnDiv-RD noteOptionBtn-RD"
                                              onClick={() => {
                                                openNotes(item)
                                            }}
                                            >
                                              <img
                                                src={iconNotes} 
                                                alt=""
                                                className="optionIcons-RD"
                                              />
                                              <p className="optionText-RD">
                                                Notes
                                              </p>
                                            </div>

                                            {/* ------------Notes Div----------  */}

                                            {/* ------------Class Div----------  */}
                                          

                                            {/* ------------Class Div----------  */}

                                            {/* ------------GradeBook Div----------  */}
                                            <div
                                              className="optionBtnDiv-RD gradebookOptionBtn-RD"
                                              onClick={() => {

                                                if (item.is_associated_with_class === 'no') {
                                                    createNotification('error', 'This student is not associated with any Class.');
                                                    return;
                                                }
                                                getStudentGradebookNullRequest()
                                                history.push(routes.STUDENTGRADEBOOK, { id: item.cc_student_id, academicYear: AcademicData.academic_year, classId: data.cc_class_id, studentInfo: item, classPermission: data.cc_class_share_permission })
                                            }}
                                            >
                                              <img
                                                src={iconGradeBook}
                                                alt=""
                                                className="optionGreadeBookIcons-RD"
                                              />
                                              <p className="optionText-RD">
                                                GradeBook
                                              </p>
                                            </div>
                                            {/* {(item.cc_student_share_permission !==
                                              null &&
                                            item.cc_student_share_permission ===
                                              "read" || data.cc_class_share_permission !== null && (data.cc_class_share_permission === "read" || data.cc_class_share_permission == 'collaborate')) ? (
                                              <div
                                                className="disShareOptionDiv-RD"
                                                style={{ margin: "5px", opacity : (JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" || data.cc_class_share_permission !== null && data.cc_class_share_permission === "read") && '0.5', cursor : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'not-allowed' }}
                                                disabled={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? true : false}
                                              >
                                                <img
                                                  src={iconShare}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p className="optionText-RD">
                                                  Share
                                                </p>
                                              </div>
                                            ) : ( */}
                                              <div
                                                className="shareOptionDiv-RD"
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                  // opacity : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && '0.5',
                                                  // cursor : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'not-allowed'
                                                }}
                                                onClick= {() => {
                                                  // data.cc_class_share_permission == null && data.cc_class_share_permission !== "read" &&
                                                history.push(routes.CLASSSHARE, {
                                                    classDetail: data
                                                }) 
                                              } }
                                                // disabled={JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" ? true : false}
                                              >
                                                <img
                                                  src={iconShare}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p className="optionText-RD">
                                                  Share
                                                </p>
                                              </div>
                                             {/* )} */}
                                            {/* ------------GradeBook Div----------  */}
                                          </div>
                                        
                                          {/* </div> */}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "start",
                                              // paddingLeft : "8px"
                                            }}
                                          >
                                            {/* ------------Share Div----------  */}
                                            {/* {item.cc_student_share_permission !==
                                              null &&
                                            item.cc_student_share_permission ===
                                              "read" ? (
                                              <div
                                                className="disShareOptionDiv-RD"
                                                style={{ margin: "5px" }}
                                              >
                                                <img
                                                  src={iconShare}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p className="optionText-RD">
                                                  Share
                                                </p>
                                              </div>
                                            ) : (
                                              <div
                                                className="shareOptionDiv-RD"
                                                style={{
                                                  margin: "5px",
                                                  height: "3.98rem",
                                                }}
                                                onClick= {() => {data.cc_class_share_permission == null && data.cc_class_share_permission !== "read" &&
                                                history.push(routes.CLASSSHARE, {
                                                    classDetail: data
                                                }) } }
                                              >
                                                <img
                                                  src={iconShare}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p className="optionText-RD">
                                                  Share
                                                </p>
                                              </div>
                                            )} */}
                                            {/* ------------Share Div----------  */}

                                            {/* ------------Profile Div----------  */}
                                            {/* <div
                                              className="optionBtnDiv-RD profileOptionBtn-RD"
                                            //   onClick={() => {
                                            //     // if (item.is_associated_with_class === 'no') {
                                            //     //     createNotification('error', 'This student is not associated with any Class.');
                                            //     //     return;
                                            //     // }
                                            //     history.push(
                                            //       routes.STUDENTPROFILE,
                                            //       {
                                            //         id: item.cc_student_id,
                                            //         studentPermission:
                                            //           item.cc_student_share_permission,
                                            //       }
                                            //     );
                                            //   }}
                                            >
                                              <img
                                                src={iconProfile}
                                                alt=""
                                                className="optionIcons-RD"
                                              />
                                              <p
                                                className="optionText-RD"
                                                style={{ color: "#386CB5" }}
                                              >
                                                Profile
                                              </p>
                                            </div> */}

                                            {/* ------------Profile Div----------  */}

                                            {/* ------------Delete Div----------  */}
                                            {(item.cc_student_share_permission !==
                                              null &&
                                            item.cc_student_share_permission ===
                                              "read" || data.cc_class_share_permission !== null && data.cc_class_share_permission === "read") ? (
                                              <div className="optionBtnDiv-RD deleteOptionBtn-RD"
                                              style={{display : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'none'}}
                                              >
                                                <img
                                                  src={deleteIcon2}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                  style={{display : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'none',
                                                  opacity : (item.cc_student_share_permission !==
                                                    null &&
                                                  item.cc_student_share_permission ===
                                                    "read" || data.cc_class_share_permission !== null && data.cc_class_share_permission === "read") && '0.5',
                                                    cursor : ((item.cc_student_share_permission !==
                                                      null &&
                                                    item.cc_student_share_permission ===
                                                      "read") || (data.cc_class_share_permission !== null && data.cc_class_share_permission === "read")) && 'pointer'
                                                }}
                                                />
                                                <p
                                                  className="optionText-RD"
                                                  style={{ color: "#FF4444" }}
                                                >
                                                  Delete
                                                </p>
                                              </div>
                                            ) : (
                                              <div
                                                // onClick={() => {
                                                //   deletePopoverHandler(
                                                //     item.cc_student_id
                                                //   );
                                                //   setSelectStudentId(
                                                //     item.cc_student_id
                                                //   );
                                                //   setSelectIndex(index);
                                                  // this.setState({selectStudentId: item.cc_student_id});
                                                  // this.setState({selectIndex: index}); }}
                                                // }}
                                                // onClick={() => {
                                                //     // setIsDeleted3(true)
                                                //     // setDeleteItem3(item.cc_student_id)
                                                //     // setSchoolPopOverId(null);
                                                //     //     forceUpdate()
                                  
                                                // }} 
                                                onClick={() => { ref.current.style.display = 'none'; setIsDeleted3(true); setDeleteItem3(item.cc_student_id) }}
                                                className="optionBtnDiv-RD deleteOptionBtn-RD"
                                                style={{display : JSON.parse(sessionStorage.getItem('UserData')).user_type == "school_teacher" && 'none'}}
                                              >
                                                <img
                                                  src={deleteIcon2}
                                                  alt=""
                                                  className="optionIcons-RD"
                                                />
                                                <p
                                                  className="optionText-RD"
                                                  style={{ color: "#FF4444" }}
                                                >
                                                  Deletee
                                                </p>
                                              </div>
                                            )}

                                            {/* ------------Delete Div----------  */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                                  </div>
                                }
                                {/* </PopoverBody>
                              </UncontrolledPopover> */}
                            </div>
                            </div>
                            
                            {/* <div className='classRosterAllOptionDiv'>
                                <div className='classRosterOptionNote' onClick={() => {
                                    openNotes(item)
                                }}>
                                    <img src={noteIcon} alt='' className='classRosterOptionIcons' />
                                    <p className='classRosterOptionText'>Notes</p>
                                </div>

                                <div className='classRosterOptionGradebook' onClick={() => {

                                    if (item.is_associated_with_class === 'no') {
                                        createNotification('error', 'This student is not associated with any Class.');
                                        return;
                                    }
                                    getStudentGradebookNullRequest()
                                    history.push(routes.STUDENTGRADEBOOK, { id: item.cc_student_id, academicYear: AcademicData.academic_year, classId: data.cc_class_id, studentInfo: item, classPermission: data.cc_class_share_permission })
                                }}>
                                    <img src={gradebookIcon} alt='' className='classRosterOptionIcons' />
                                    <p className='classRosterOptionText'>Gradebook</p>
                                </div>

                                {data.cc_class_share_permission !== null && data.cc_class_share_permission === "read" ?
                                    <div className={disClassRosterOptionShare.join(' ')}>
                                        <img src={userShareIcon} alt='' className='classRosterOptionIcons' />
                                        <p className='classRosterOptionText'>Share</p>
                                    </div> :
                                    <div className='classRosterOptionShare' onClick={() => {
                                        history.push(routes.STUDENTSHARE, {
                                            studentInfo: item.cc_student_id
                                        });
                                    }}>
                                        <img src={userShareIcon} alt='' className='classRosterOptionIcons' />
                                        <p className='classRosterOptionText'>Share</p>
                                    </div>}
                                {data.cc_class_share_permission !== null && data.cc_class_share_permission === "read" ?
                                    <div className={disClassRosterOptionRemove.join(' ')} >
                                        <img src={deleteIcon} alt='' className='classRosterOptionIcons' />
                                        <p className='classRosterOptionText'>Remove</p>
                                    </div> :
                                    <div className='classRosterOptionRemove' onClick={() => {
                                        setRemoveRoster(item.cc_student_id)
                                    }} >
                                        <img src={deleteIcon} alt='' className='classRosterOptionIcons' />
                                        <p className='classRosterOptionText'>Remove</p>
                                    </div>}

                            </div> */}
                        </div>
                        </div>
                        }
                </React.Fragment>))}
            </div>
            {loadMoreLoader && <div>
                <DivLoader />
            </div>}
            </div>
            
            : 
            <div
            ref={noDataRef}
            style={{height : '100vh'}}
            >

            </div>
            }
            {/* {(StudentOverAllCount.length > 0 && studentRosterData.length > 0) &&
                <div style={{ padding: 5 }}>
                    {loadMoreCountStatus ? <Button className='loadMoreRoster' color="primary" >

                        <img src={loaderImag} alt='' className='loaderIconCssAlreadySelected' />

                    </Button>
                        :
                        <div>
                            {StudentOverAllCount && StudentOverAllCount[0].count > studentRosterData.length && <Button onClick={() => { loadMore() }} className='loadMoreRoster' color="primary" >
                                <i style={{ fontSize: 20 }} className="material-icons">autorenew</i>
                                <span className='LoadButtonText' > Load More</span>


                            </Button>}
                        </div>}
                </div>} */}
            <div>
                <Modal size="sm" show={editModal} centered={true}>
                    <Modal.Body className='levelModalMain'>
                        <div className='levelEditModalHeader'>
                            <p className='levelEditModalHeadind'>Update Level & Score</p>
                        </div>
                        <div className='levelEditmainFieldDiv'>
                            <div className='inputFieldLabelLevelEdit'>
                                <p className='inputFieldLabelLevelEditText'>Level : </p>
                            </div>
                            <div className='inputFieldAdditionalModal'>
                                <CommonTextField
                                    margin="normal"
                                    variant="outlined"
                                    // type='number'
                                    value={modalLevel}
                                    onChange={handleChangeLevel}
                                    name="ModalLevel"
                                    // className="InfoInputType"
                                    className="InfoInputTypee"
                                    placeholder="Enter Level" />
                                {(!modalLevel ) && <p className="errormessageRegister">Please enter level*</p>}
                            </div>
                        </div>
                        <div className='levelEditmainFieldDiv'>
                            <div className='inputFieldLabelLevelEdit'>
                                <p className='inputFieldLabelLevelEditText'>Score : </p>
                            </div>
                            <div className='inputFieldAdditionalModal'>
                                <CommonTextField
                                    margin="normal"
                                    variant="outlined"
                                    // type='number'
                                    value={modalScore}
                                    onChange={handleChangeScore}
                                    name="ModalScore"
                                    // className="InfoInputType"
                                    className="InfoInputTypee"
                                    placeholder="Enter Score" />
                                {(!modalScore ) && <p className="errormessageRegister">Please enter score*</p>}
                            </div>
                        </div>
                        <div className='levelMainDivForCenter'>
                            <div className='levelModalCancelButtonDiv'>
                                <div onClick={() => { setEditModal(!editModal) }} className='linkCancelButton'>
                                    Cancel
                                </div>
                                <div>
                                    <Button onClick={updateScoreLevel} className='levelModalUpdateButton' color="primary" >
                                        <span className='levelEditUpdateButtonText' > Update</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {/* ---------------Modal Add Class------------ */}
            <div className="modal right fade addOfClass" id="addClassModal" tabIndex="-1" role="dialog" aria-labelledby="addClassModal" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="addClassModalHeaderTitle" id="addClassModal">Edit Class</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span className='cancelClassText d-flex flex-wrap justify-content-center align-items-center rounded-circle' aria-hidden="true" style={{width : 30, height : 30}}>  
                            <img src={cancelBlack} alt='' className='closeIconInClassModal' style={{marginTop : 0}}/> </span></button>
                        </div>
                        <div className="modal-body">
                            {/* -----------All Input Type----------- */}
                            <div className='allInputClassMainDiv'>
                                <div className='classAddInputDiv'>
                                    {/* <h5 className='classInputLableInfo'>Class name</h5> */}
                                    <CommonTextField
                                    dataTestId = "editclsnamemock"
                                        margin="normal"
                                        variant="outlined"
                                        type='ClassName'
                                        value={className}
                                        onChange={handleChangeClassName}
                                        name="ClassName"
                                        className="classAddInput"
                                        placeholder="Enter Class name" />
                                    {(!className) && <p className="errormessageRegister">Please enter class name</p>}

                                    {className && className && className.trim().length != 0 && !pattern.test(className) && isAddClassSubmit && (
                             <p className="errormessageRegister">Please enter only letters and numbers</p>
                                                            )}
                                </div>
                                <div className='classAddInputDiv'>
                                    {/* <h5 className='classInputLableInfo'>Section</h5> */}
                                    <CommonTextField
                                    dataTestId = "editclssecmock"
                                        margin="normal"
                                        variant="outlined"
                                        type='ClassSection'
                                        value={classSection}
                                        onChange={handleChangeSection}
                                        name="ClassSection"
                                        className="classAddInput"
                                        placeholder="Enter Section" />

{( (classSection && ( classSection.length > 2 )) )&& <p className="errormessageRegister">Section has a maximum of 2 characters</p>}
                                </div>
                                <div className='classAddInputDiv'>
                                    {/* <h5 className='classInputLableInfo'>Grade</h5> */}
                                    <CommonTextField
                                       dataTestId = "editclsgrademock"
                                        margin="normal"
                                        variant="outlined"
                                        type='ClassGrade'
                                        value={classGrade}
                                        onChange={handleGradeChange}
                                        name="ClassGrade"
                                        className="classAddInput"
                                        placeholder="Enter Grade" />
                                            {( classGrade && (classGrade.length > 2) ) && <p className="errormessageRegister">Grade name should be of 2 characters</p>}
                                </div>
                                {/* -----------All Input Type----------- */}
                                {/* -----------All Button----------- */}
                                <div className="modalButtonClassPage">
                                    <div>
                                        <Button onClick={addNewClass} className='saveButtonClass' color="primary" >
                                            <span className='saveTextClass' > Save</span>
                                        </Button>
                                    </div>

                                </div>
                                {/* -----------All Button----------- */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ---------------Modal Add Class------------ */}
            <div
            data-testid = "importmockmodal"
        className="modal right fade"
        id="myModalupload"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div  className="uploadDocHeaderDiv" style={{alignItems : "baseline"}}>
                <div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{paddingTop: "0px" , paddingLeft : "0px"}}
                  >
                    <span className="cancelText" aria-hidden="true">
                      {" "}
                      {/* <img
                      style={{paddingRight : "5px" , width : "16px"}}
                        src={cancelBlack}
                        alt=""
                        className="closeIconInModal"
                      />{" "} */}
                      Import Roster
                    </span>
                  </button>
                </div>
                <button
                    style={{ marginRight: "-7px",  marginBottom: "-7px", position : 'relative', bottom : 2}}
                    type="button"
                    className="close closeround"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span className="cancelText" aria-hidden="true">
                      {" "}
                      <img
                       style={{width : "10px", position : 'relative', bottom : 2}}
                        src={cancelBlack}
                        alt=""
                        className="closeIconInModal"
                      />{" "}
                     
                    </span>
                  </button>
              </div>
            </div>
           

            <div className="modal-body">
              {/* ---------------Note option------------ */}
              <div className="uploadNodeDiv">
                <p
                  className="uploadNoteText"
                  style={{ fontWeight: 600, marginBottom: 10 }}
                >
                  Note:
                </p>
                <p className="uploadNoteText">1. Open and name a spreadsheet</p>
                <p className="uploadNoteText">
                  2. Input students (first column = First Name, Second column =
                  Last Name)
                </p>
                <p className="uploadNoteText">
                  3. Download spreadsheet as a CSV file (comma separated values)
                </p>
                <p className="uploadNoteText">
                  4. Return here, authorize your account, choose the CSV file,
                  and import!
                </p>
                <p style={{ marginTop: 15 }} className="uploadNoteText">
                  P.S. - You can also import your roster directly into a class{" "}
                  {noteStrinf}
                </p>
              </div>
              {/* ---------------Note option------------ */}

              {/* --------------- Upload option------------ */}
              <div className="selectMainDivforUpload">
                <div className="selectOptionInTag">
                  <div
                    className="checkbozCircle"
                    onClick={() => {
                      // this.setState({ UploadFrom: 'drive' })
                      setUploadFrom("drive");
                    }}
                  >
                    {UploadFrom === "drive" && (
                      <div className="checkbozCircleSelected"></div>
                    )}
                  </div>
                  <img
                    src={googleDriveIcon}
                    alt=""
                    className="selectoptionUploadImage"
                  />
                  {!GoogleAuth ? (
                    <p className="selectTagText">Google Drive</p>
                  ) : (
                    <p className="loginEmailText">{driveEmail}</p>
                  )}
                  <div>
                    {!GoogleAuth ? (
                      <Button
                        disabled={UploadFrom === "drive" ? false : true}
                        className="autharizedButton"
                        color="primary"
                        onClick={() => handleClientLoad()}
                      >
                        <span className="autharizedText"> Authorize</span>
                      </Button>
                    ) : (
                      <Button
                        disabled={UploadFrom === "drive" ? false : true}
                        className="logoutButton"
                        color="primary"
                        onClick={() => handleSignOutClick()}
                      >
                        <span className="logoutText"> Logout</span>
                      </Button>
                    )}
                  </div>
                </div>

                <div className="selectOptionInTag">
                  <div
                    className="checkbozCircle"
                    onClick={() => {
                      //  this.setState({ UploadFrom: 'drop' })
                      setUploadFrom("drop");
                    }}
                  >
                    {UploadFrom !== "drive" && (
                      <div className="checkbozCircleSelected"></div>
                    )}
                  </div>
                  <img
                    src={localFolderIcon}
                    alt=""
                    className="selectoptionUploadImageforDrop"
                  />
                  <p className="selectTagText">File App</p>
                  <div>
                    <ReactFileReader
                      disabled={UploadFrom !== "drive" ? false : true}
                      handleFiles={handleImportFiles}
                      fileTypes={".csv"}
                    >
                      <Button
                        disabled={UploadFrom !== "drive" ? false : true}
                        className="autharizedButton"
                        color="primary"
                      >
                        <span className="autharizedText"> Choose</span>
                      </Button>
                    </ReactFileReader>
                  </div>
                </div>

                
              </div>
              <div style={{position : 'absolute', top : '-10px', left : "-15px", width : "100%"}}>
                  {UploadFrom !== "drive" ? (
                    <Button
                      disabled={true}
                      className="importRosterButton importdrivebtn"
                      color="primary"
                      style={{
                        position : 'absolute',
                      }}
                    >
                      <span className="importRosterText"> Import</span>
                    </Button>
                  ) : (
                    <Button
                      disabled={GoogleAuth ? false : true}
                      onClick={() => {
                        handleFileLoad();
                      }}
                      className="importRosterButton importdrivebtn"
                      color="primary"
                      style={{
                        position : 'absolute',
                      }}
                    >
                      <span className="importRosterText"> Import</span>
                    </Button>
                  )}
                </div>
              {/* ---------------Upload option------------ */}
            </div>
          </div>
        </div>
      </div>
      {/* ---------------Modal Add Student------------ */}

      {/* ---------------Import File From Drive Modal------------ */}
      <Modal
        centered
        className="select-DriveFile-modal br-8"
        show={SelectFileModal}
      >
        <Modal.Header>
          <Modal.Title>Google Drive Import</Modal.Title>
          {googleSelectedFile && (
            <Button
              className="modalSaveTagButton"
              variant="success"
              onClick={() => {
                getFileDetails();
              }}
            >
              <img src={tickIcon} alt="" height={20} width={20} />
            </Button>
          )}
        </Modal.Header>
        <Modal.Body>
          {importLoader ? (
            <div style={{ width: "100%", textAlign: "center" }}>
              <img src={loaderImag} alt="" className="importDriveLoader" />
            </div>
          ) : (
            <div>
              <div className="inline-Path">
                <ul className="breadcrumb">
                  {googleDrivePath.map((value, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        getFolderFilesPath(value);
                      }}
                    >
                      <span className="cursor">{value.name}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="modal-list-body">
                {GetFileLoader && (
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={loaderImag} alt="" className="loaderIconCss" />
                  </div>
                )}
                {GoogleFiles && GoogleFiles.length > 0 ? (
                  <>
                    {GoogleFiles &&
                      GoogleFiles.length > 0 &&
                      GoogleFiles.map((item, index) => (
                        <div className="file_listDiv" key={index}>
                          {item.fileExtension === "csv" ? (
                            <div style={{ display: "inline-flex" }}>
                              <img
                                src={driveCSVIcon}
                                alt=""
                                className="selectoptionUploadFolder"
                              />

                              <p className="fileName">{item.name}</p>
                            </div>
                          ) : (
                            <div
                              className="selectFileCursor"
                              onClick={() => {
                                getFolderFiles(item);
                              }}
                            >
                              <img
                                src={driveFolderIcon}
                                alt=""
                                className="selectoptionUploadFolder"
                              />

                              <p className="fileName">{item.name}</p>
                            </div>
                          )}
                          {item.fileExtension === "csv" && (
                            <i
                              onClick={() => {
                                setGoogleSelectedFile(item);
                              }}
                              className={`${
                                googleSelectedFile.id === item.id
                                  ? "checkBoxSelect"
                                  : "unCheckBoxSelect"
                              } material-icons`}
                            >
                              {googleSelectedFile.id === item.id
                                ? "check_circle"
                                : "radio_button_unchecked"}
                            </i>
                          )}
                        </div>
                      ))}
                  </>
                ) : (
                  <>
                    {GetFileLoader ? (
                      <div></div>
                    ) : (
                      <div style={{ textAlign: "center", marginTop: 20 }}>
                        <p className="unSelectedText">No File Avaliable</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
          
            className="modalcancleTagButton"
            variant="danger"
            onClick={() => {
              setSelectFileModal(false);
            }}
          >
            <img style={{    cursor: "pointer"
          }} src={closeIcon} alt="" height={40} width={30} />
          </Button>
        </Modal.Footer>
      </Modal>
            <div
        className="modal right fade ClassRosterModal"
        id="myModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title Addstudentmodal" id="myModalLabel2">
                Add Student
              </h4>
              <button
                type="button"
                className="close closeround"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="cancelText" aria-hidden="true">
                  {" "}
                  <img
                    src={cancelBlack}
                    alt=""
                    className="closeIconInModal"
                  />{" "}
                 
                </span>
              </button>
            </div>

            <div className="modal-body">
              {/* -----------Add Student Profile----------- */}
              {UserProfileURL === "" || UserProfileURL === undefined ? (
                <div className="RosterImageMainDiv">
                  <div className="addStudentImageDiv">
                    {showLoaderUpload ? (
                      <img src={loaderImag} alt="" className="loaderIconCss" />
                    ) : (
                      <label htmlFor="file">
                        <img src={camera} alt="" className="cameraIconCss" />
                      </label>
                    )}
                  </div>
                  <input
                    className="custom-file-input"
                    onChange={(e) => handleChangeFile(e.target.files)}
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    type="file"
                    id="file"
                    name="file"
                    accept="image/*"
                  />
                </div>
              ) : (
                <div
                  style={{ backgroundImage: `url(${UserProfileURL})` }}
                  className="RosterImageMainSelectedDiv"
                >
                  <div className="addStudentImageDiv">
                    <img
                      src={UserProfileURL}
                      alt=""
                      className="RosterImageWhenSelect"
                    />
                  </div>
                  <Button className="changeButton">
                    {showLoaderUpload ? (
                      <img
                        src={loaderImag}
                        alt=""
                        className="loaderIconCssAlreadySelected"
                      />
                    ) : (
                      <label htmlFor="file" className="changeButtonText">
                        Change
                      </label>
                    )}
                  </Button>
                  <input
                    className="custom-file-input"
                    onChange={(e) => handleChangeFile(e.target.files)}
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    type="file"
                    id="file"
                    name="file"
                    accept="image/*"
                  />
                </div>
              )}

              {/* -----------Add Student Profile----------- */}

              {/* -----------All Input Type----------- */}
              <div className="allInputMainDiv">
                <div className="studentAddInputDiv mt-1" style={{marginTop: "12px"}}>
                  {/* <h5 className="studentInputLableInfo">First name*</h5> */}
                  <CommonTextField
                  dataTestId = "addStudentMockModal"
                    margin="normal"
                    variant="outlined"
                    type="FirstName"
                    value={FirstName}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    name="FirstName"
                    className="studentAddInput studentAddInputt"
                    placeholder="First Name*"
                  />
                  {(!FirstName || (FirstName && FirstName.trim().length <= 0)) && isAddStudentSubmit && (
                    <p className="errormessageRegister">
                      Please enter first name
                    </p>
                  )}
                  {FirstName && (FirstName && FirstName.trim().length != 0) && !pattern2.test(FirstName) && isAddStudentSubmit && (
                   <p className="errormessageRegister">Please enter only letters</p>
                     )}
                </div>
                <div className="studentAddInputDiv mt-1">
                  {/* <h5 className="studentInputLableInfo">Last name*</h5> */}
                  <CommonTextField
                  dataTestId = "addStudentMockModal2"
                  style = {{display : "flex" , justifyContent : "center"}}
                    margin="normal"
                    variant="outlined"
                    type="LastName"
                    value={LastName}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    name="LastName"
                    className="studentAddInput studentAddInputt"
                    placeholder="Last Name*"
                  />
                {(!LastName || (LastName && LastName.trim().length <= 0)) && isAddStudentSubmit && (
                    <p className="errormessageRegister">
                      Please enter last name
                    </p>
                  )}
                  {LastName &&(LastName.trim().length != 0) && !pattern2.test(LastName) && isAddStudentSubmit && (
                   <p className="errormessageRegister">Please enter only letters</p>
                     )}
                </div>
                <div className="studentAddInputDiv mt-1">
                  {/* <h5 className="studentInputLableInfo">Email ID (Optional)</h5> */}
                  <CommonTextField
                    dataTestId = "addStudentMockModalemail"
                    // dataTestId = "saveMockBtn"
                    margin="normal"
                    variant="outlined"
                    type="Email"
                    value={Email}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      emailHandler(e);
                    }}
                    name="Email"
                    className="studentAddInput studentAddInputt"
                    placeholder="Email ID"
                  />
                  {Email && InvelidMessageEmail && (
                    <p className="errormessageRegister">
                      Please enter valid email*
                    </p>
                  )}
                </div>
                {/* -----------All Input Type----------- */}

                {/* -----------All Button----------- */}
                <div className="modalButtonClass addRosterModalButtonClass modalNW">
                  <div>
                    <Button
                    data-testid = "saveMockBtn"
                    value={mockval}
                      onClick={() => {
                        createNewRoster("no");
                      }}
                      className={saveButtonNW.join(' ')}
                      color="primary"
                    >
                      <span className="saveText"> Save</span>
                    </Button>
                  </div>
                  <div>
                    <Button
                      data-testid = "saveMockBtn2"
                      value={mockval2}
                      onClick={() => {
                        createNewRoster("addAnother");
                      }}
                      className={addAnotherButtonNW.join(' ')} 
                      color="primary"
                    >
                      <span className="saveText"> Save and Add Another</span>
                    </Button>
                  </div>
                </div>
                {/* -----------All Button----------- */}
              </div>
            </div>
          </div>           
        </div>
      </div>



       <div
       data-testid = "selectmodalmock"
        className="modal right fade ClassRosterModal"
        id="myModal3"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog" role="document">
           
          <div className="modal-content"
            ref={modalRef} 
            onScroll={handleScroll}
        
          >
             {/* <InfiniteScroll
                  dataLength={statesData && statesData.length}
                  //  next={fetchMoreData}
                 next={() => fetchMoreData()}
                  // hasMore={hasMore}
                  hasMore={statesData && statesData.length == notSelectedStudentsCount ? false : true}
                  // height={800}
                  // height={`calc(100% - 90px)`}
                  loader={<h4>Loading...</h4>}
                  //  height={400}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                > */}
                 <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          background: "#fff",
          // height: 176,
        }}
      >
            <div className="modal-header"
            // ref={modalRef} 
            // onScroll={handleScroll}
            >
            {/* <img
            style={{marginLeft: "0px" , paddingTop: "4px", width: "48px"}}
            //  className=""
             data-dismiss="modal"
             aria-label="Close"
                    src={cancelBlack}
                    alt=""
                    className="closeIconInModal close closeround"
                  />{" "} */}
               <button
                type="button"
                className="close closeround"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="cancelText" aria-hidden="true">
                  {" "}
                  <img
                    src={cancelBlack}
                    alt=""
                    className="closeIconInModal"
                  />{" "}
                 
                </span>
              </button>
              <button
              style={{paddingTop: "0px", paddingRight: "0px"}}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <h4 style={{paddingRight : "65px" , paddingTop : "4px", fontSize : 15}} className="modal-title" id="myModalLabel2">
                Select Students
              </h4>
                <span className="cancelText" aria-hidden=" true">
                  {" "}
                  {/* <img
                  onClick={() => selectRoster()}
                  style={{width: "100px" , height: "40px"}}
                    src={UpdateBtn}
                    alt=""
                    className="closeIconInModal"
                  />{" "} */}
                  <button onClick={() => selectRoster()} className='btn  updatebtnsinglecls'>
                  <i style={{color : "white" , marginRight : "5px" , fontSize : "22px"}} className='confirmIconsLesson material-icons'>check</i>Update
                  </button>
                  
                  <div>

                  </div>
                  {/* Cancel */}
                </span>
              </button>
            </div>

            <div style={{padding : '5px 15px'}}>
            <div
                  className="singleClassSearchFilterSelectStudent"
                  onClick={() => {
                    // this.setState({
                    //     // searchBar: true,
                    //     // searchText: ''
                    // })
                  }}
                >
                  <img
                    className="searchFilterIcon-RD"
                    src={iconSearch}
                    alt=""
                  />
                  <CommonTextField
                    margin="normal"
                    // variant="outlined"
                    type="FirstName"
                    value={searchUnselectStudentText}
                    onChange={(event) => {
                      handleUnselectStudentSearchText(event)
                      // handleSearchText(event);
                      // setSearchText(event.target.value);
                    }}
                    name="searchText"
                    className="searchFilterInputBox-RD"
                    placeholder="Enter Keywords…"
                  />
                </div>
            </div>
            <div style={{height : 40}}></div>
            </div>
            <div className="modal-body">
              {/* -----------Add Student Profile----------- */}
              {"UserProfileURL" == "UserProfileURL"  ? (
              <>
                <div className="studentList studentListContainer"  
                
                >
                 

                  {statesData && statesData.length > 0 ? 
                  <div className='classRosterSelectCardMainDiv classRosterSelectCardDivScrollbar' 
                  // ref={modalRef} 
                  // // onScroll={handleScroll}
         
                  >
                    {statesData && statesData.map((item, index) => (
                        <div key={index} className='classRosterSelectCardDiv' style={{paddingTop : '2px', alignItems : 'baseline'}}>
                            <div className='selectRosterImageDiv'>
                                {item.cc_student_pic_url ? <img src={item.cc_student_pic_url} alt='' className='selectRosterProfileImage' /> :
                                    <div className='selectRosterProfileDummy'>
                                        <h5 className='profileSelectDummyText'>{item.cc_student_first_name.charAt(0)}{item.cc_student_last_name.charAt(0)}</h5>
                                    </div>}
                            </div>
                            <div className='selectRosterTextInfoDiv' style={{position : item.cc_student_email_id && item.cc_student_email_id.length > 0 && "relative", bottom : item.cc_student_email_id && item.cc_student_email_id.length > 0 && '8px'}}>
                                <p className='selectRosterName'>{item.cc_student_last_name},{item.cc_student_first_name}</p>
                                <p className='selectRosterEmail'>{item.cc_student_email_id}</p>

                            </div>
                            <div className='selectRosterCheckBoxDiv selectRosterCheckBoxDivRight' style={{top : '17px'}}>
                                <i 
                                onClick={() => {
                                  
                                  CheckValue(item, index)
                                }}
                                // onClick={() => {CheckValue(item, index)
                                
                     
                                 
                                //  }}
                                  id = "a"
                                  className="checkBoxSelectRoster material-icons">{item.isSeleted ? 'check_circle' : 'radio_button_unchecked'}</i>
                                
                            </div>
                        </div>))}
                </div> :
                    <div className='noRoster'>
                        {loader || rosterData.loader || (isLoading && searchUnselectStudentText && searchUnselectStudentText.length > 0) ? <div>
                <DivLoader />
            </div> : <div data-toggle="modal" data-target="#addClassRosterModal">
                            <p className='tapToAddText'>{`${notSelectedStudentsCount == 0 ? 'All Rosters Added in class' : ''} `}</p>

                        </div>}

                    </div>
                }
                 {(isLoading && searchUnselectStudentText && searchUnselectStudentText.length == 0) &&  <div>
                <DivLoader />
            </div>}
                  {/* <input
                    className="custom-file-input"
                    // onChange={(e) => handleChangeFile(e.target.files)}
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    type="file"
                    id="file"
                    name="file"
                    accept="image/*"
                  /> */}
                   <div class="cover-bar"></div>
                </div>
                </>
                
              ) : (
                <div
                  style={{ backgroundImage: `url(${UserProfileURL})` }}
                  className="RosterImageMainSelectedDiv"
                >
                  <div className="addStudentImageDiv">
                    <img
                      src={UserProfileURL}
                      alt=""
                      className="RosterImageWhenSelect"
                    />
                  </div>
                  <Button className="changeButton">
                    {showLoaderUpload ? (
                      <img
                        src={loaderImag}
                        alt=""
                        className="loaderIconCssAlreadySelected"
                      />
                    ) : (
                      <label htmlFor="file" className="changeButtonText">
                        Change
                      </label>
                    )}
                  </Button>
                  <input
                    className="custom-file-input"
                    onChange={(e) => handleChangeFile(e.target.files)}
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    type="file"
                    id="file"
                    name="file"
                    accept="image/*"
                  />
                </div>
                
              )}

              {/* -----------Add Student Profile----------- */}

              {/* -----------All Input Type----------- */}
              {/* <div className="allInputMainDiv">
                <div className="studentAddInputDiv">
                  <h5 className="studentInputLableInfo">First name*</h5>
                  <CommonTextField
                    margin="normal"
                    variant="outlined"
                    type="FirstName"
                    value={FirstName}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    name="FirstName"
                    className="studentAddInput"
                    placeholder="Enter first name"
                  />
                  {!FirstName && isAddStudentSubmit && (
                    <p className="errormessageRegister">
                      Please enter first name
                    </p>
                  )}
                </div>
                <div className="studentAddInputDiv">
                  <h5 className="studentInputLableInfo">Last name*</h5>
                  <CommonTextField
                    margin="normal"
                    variant="outlined"
                    type="LastName"
                    value={LastName}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    name="LastName"
                    className="studentAddInput"
                    placeholder="Enter last name"
                  />
                  {!LastName && isAddStudentSubmit && (
                    <p className="errormessageRegister">
                      Please enter last name
                    </p>
                  )}
                </div>
                <div className="studentAddInputDiv">
                  <h5 className="studentInputLableInfo">Email ID (Optional)</h5>
                  <CommonTextField
                    margin="normal"
                    variant="outlined"
                    type="Email"
                    value={Email}
                    // onChange={this.handleChange}
                    onChange={(e) => {
                      emailHandler(e);
                    }}
                    name="Email"
                    className="studentAddInput"
                    placeholder="Enter email id"
                  />
                  {Email && InvelidMessageEmail && (
                    <p className="errormessageRegister">
                      Please enter valid email*
                    </p>
                  )}
                </div>
                {/* -----------All Input Type----------- */}

                {/* -----------All Button----------- */}
                {/* <div className="modalButtonClass addRosterModalButtonClass">
                  <div>
                    <Button
                      onClick={() => {
                        createNewRoster("no");
                      }}
                      className="saveButton"
                      color="primary"
                    >
                      <span className="saveText"> Save</span>
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        createNewRoster("addAnother");
                      }}
                      className="addAnotherButton"
                      color="primary"
                    >
                      <span className="saveText"> Save and add another</span>
                    </Button>
                  </div>
                </div> */}
                {/* -----------All Button----------- */}
              {/* </div> } */}
            </div>
            {/* </InfiniteScroll> */}
          </div>
          {/* </InfiniteScroll> */}
        </div>
      </div>



      { isDeleted === true &&
                    <Modal centered className="delete-modal-warning br-8" show={isDeleted} >
                        <Modal.Header>
                            <Modal.Title>
                                <div style={{display : 'flex', flexDirection: 'column'}}>
                                    <img src={warningIcon} alt="" style={{ width: "60px", height: "60px"}} />
                                    <span style={{fontSize: '24px', color: '#ff1f1f', marginTop: '10px'}}>Wait!</span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure want to delete all Data associated with the class?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" className="delete-modal-warning cancel-btn"
                            onClick={() => { 
                              // editStudent(selectIndex);
                                // this.setState({ isDelete: false })
                                setIsDeleted(false)
                            }}
                            >
                                Cancel
                            </Button>
                            <Button variant="default" className="delete-modal-warning delete-btn" style={{borderRadius: "8px"}}
                            onClick={() => { 
                              // deleteStudentHandler(selectStudentId, selectIndex)
                              deleteCls();
                             }}
                             disabled={deleteClassLoaderComp}
                            >
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }

{ isDeleted3 === true &&
                    <Modal centered className="delete-modal-warning br-8" show={isDeleted3} >
                        <Modal.Header>
                            <Modal.Title>
                                <div style={{display : 'flex', flexDirection: 'column'}}>
                                    <img src={warningIcon} alt="" style={{ width: "60px", height: "60px"}} />
                                    <span style={{fontSize: '24px', color: '#ff1f1f', marginTop: '10px'}}>Wait!</span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure want to delete all Data associated with the roster?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" className="delete-modal-warning cancel-btn"
                            onClick={() => { 
                              // editStudent(selectIndex);
                                // this.setState({ isDelete: false })
                                setIsDeleted3(false)
                            }}
                            >
                                Cancel
                            </Button>
                            <Button variant="default" className="delete-modal-warning delete-btn" style={{borderRadius: "8px"}}
                            onClick={() => { deleteRoster(deleteItem3) }} 
                     
                            >
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }


{ isDeleted2 === true &&
                    <Modal centered className="delete-modal-warning br-8" show={isDeleted2} >
                        <Modal.Header>
                            <Modal.Title>
                                <div style={{display : 'flex', flexDirection: 'column'}}>
                                    <img src={warningIcon} alt="" style={{ width: "60px", height: "60px"}} />
                                    <span style={{fontSize: '24px', color: '#ff1f1f', marginTop: '10px'}}>Wait!</span>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure want to delete all Data associated with the class?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" className="delete-modal-warning cancel-btn"
                            onClick={() => { 
                              // editStudent(selectIndex);
                                // this.setState({ isDelete: false })
                                setIsDeleted(false)
                            }}
                            >
                                Cancel
                            </Button>
                            <Button variant="default" className="delete-modal-warning delete-btn" style={{borderRadius: "8px"}}
                            onClick={() => { 
                              // deleteStudentHandler(selectStudentId, selectIndex)
                              deleteCls();
                             }}
                            >
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
        </div>
    );
}
const mapStateToProps = state => ({
    state: state,
    classData: state.class,
    loader: state.class.loader,
    rosterData: state.classRoster,
    editClassResponse: state.class
});

const mapDispatchToProps = dispatch => {
    return {
        getClassList: (data) => dispatch(getClassList(data)),
        addClass: (data) => dispatch(addClass(data)),
        editClass: (data) => dispatch(editClass(data)),
        deleteClass: (data) => dispatch(deleteClass(data)),
        editClassColorWheel: (data) => dispatch(editClassColorWheel(data)),
        setClassSidebarOpen: (data) => dispatch(setClassSidebarOpen(data)),
        getClassRosterList: (data) => dispatch(getClassRosterList(data)),
        deleteClassRoster: (data) => dispatch(deleteClassRoster(data)),
        updateRosterLevel: (data) => dispatch(updateRosterLevel(data)),
        getStudentGradebookNullRequest: (data) => dispatch(getStudentGradebookNullRequest(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ViewSingleClass
);

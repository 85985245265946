// import React, { Component } from 'react';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { forgot } from 'Actions/AuthAction/forgotAction';
import { Button } from 'reactstrap';
import Logo from 'Assets/LoginModule/mainLogo.png';
import email from 'Assets/LoginModule/email.png';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import * as routes from "Router/RoutesURL";
import './ForgotPage.scss';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core/styles'
import TextField from "@material-ui/core/TextField";

const ForgotPage = (props) => {
    const { history, forgot, userStatusData } = props;
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');
    const [show, setShow] = useState('Password');
    const [errorEmail, setErrorEmail] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        if (userStatusData) {
            if (userStatusData?.forgot_Data?.message === 'New password has been sent to your registered Email Id.') {
                // history.replace(routes.LOGIN);
                // history.push(routes.VERIFICATION, { email: Email });
            }
        }
    }, [userStatusData])

    const handleChange = () => {
        setErrorMessage('')
        setIsSubmit(false)
    };

    const loginUser = () => {
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        if (Email === '') {
            setErrorEmail(true);
            setEmailErrorMessage('*Email ID is required');
        } else
            if (!emailRegex.test(Email)) {
                setErrorEmail(true);
                setEmailErrorMessage('*Please enter valid email address');
                return false;
            } else {
                setIsSubmit(true);
                const sendRequest = {
                    errorEmail: false,
                    // teacher_email: Email,
                    user_email_id: Email,
                };
                forgot(sendRequest);
                history.replace(routes.LOGIN);
            }
    }

    const theme = createTheme({
        overrides: {
            MuiFilledInput: {
                root: {
                    backgroundColor: "transparent",
                    marginLeft: '-10px !important',
                    // paddingBottom: "5px !important",
                    "&:hover": {
                        backgroundColor: "transparent",
                        marginLeft: '-10px !important',
                    },
                    "&$focused": {
                        backgroundColor: "transparent",
                        marginLeft: '-10px !important',
                    }
                },
                underline: {
                    "&:before": {
                        borderBottomColor: "transparent",
                        width: '106%',
                        marginBottom: '1px !important'
                    },
                    "&:hover:not(.Mui-focused):before": {
                        borderBottomColor: "transparent",
                        width: '106%',
                        marginBottom: '1px !important'
                    },
                    "&:after": {
                        borderBottomColor: "#386CB5",
                        width: '106%',
                        marginBottom: '1px !important'
                    }
                }
            },
            MuiInputLabel: {

                filled: {
                    color: "#222222",
                    marginLeft: '-10px !important',
                    top: "-2px !important",
                    "&$focused": {
                        color: "#386CB5",
                        marginLeft: '-10px !important',
                    },
                    ".MuiFormControl-root:hover &:not($focused)": {
                        color: "#222222",
                        marginLeft: '-10px !important',
                    }
                }
            },
            MuiFormHelperText: {
                contained: {
                    marginLeft: '-44px',
                    marginTop: '1px'
                },
            },
        }
    });

    return (
        <div className='ForgetMainContainer'>
            <div className='ForgetSubContainer'>
                <div>
                    <img alt="" src={Logo} className="img-div" />
                </div>
            </div>
            <div className='ForgetSubContainer'>
                <div className='ForgetCardDiv'>
                    <div className="ForgetTextDiv">
                        <p className="ForgetText"> FORGOT PASSWORD</p>
                        <p className="ProcessText">Enter your registered email address. We will email you a new password for your account</p>
                    </div>
                    {/*  Input Email Div First*/}
                    <div className="ForgetInputDiv">

                        <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            {/* <MailOutlineIcon sx={{ color: 'action.active', mr: 1, my: 0.25, mx: 1 }} style={{ marginBottom: errorEmail ? '33px' : '12px' }} /> */}
                            <img alt="" src={email} className="img-div" sx={{ color: 'action.active', mr: 1, my: 0.25, mx: 1 }}
                                style={{
                                    marginBottom: errorEmail ? '23px' : '2px',
                                    // margin: errorEmail ? '15px' : '12px'
                                    marginRight: '15px',
                                    marginLeft: '15px',
                                    // marginBottom: '5px',
                                }}
                            />
                            <FormControl sx={{ mr: 1, my: 0.25, mx: 1, width: '36ch' }} variant="standard">
                                <MuiThemeProvider theme={theme}>
                                    {/* <InputLabel htmlFor="component-helper">Email ID</InputLabel> */}
                                    <TextField
                                        size="small"
                                        id="input-with-sx"
                                        label="Email ID"
                                        variant="filled"
                                        autoComplete='off'
                                        shrink={true}
                                        value={Email}
                                        onChange={event => { setEmail(event.target.value); handleChange() }}
                                        name="Email"
                                        disableUnderline={isVisible}
                                        onFocus={() => setIsVisible(false)}
                                        onBlur={() => setIsVisible(true)}
                                        error={errorEmail === true ? true : false}
                                        helperText={errorEmail === true ? emailErrorMessage : ''}
                                    />
                                </MuiThemeProvider>
                            </FormControl>
                        </Box>

                    </div>
                    <div className="errorDiv">
                        {!Password && <div className="errorDiv">
                            <p className="errormessage">{ErrorMessage}</p>
                        </div>}

                    </div>
                    {/*  Input Email Div First*/}

                    {/*  Password Button*/}
                    <div className="RetrivePassBtn">
                        <Button
                            disabled={Email !== '' ? false : true}
                            onClick={() => loginUser()}
                            color="primary" size="lg" block>RETRIEVE PASSWORD</Button>
                    </div>
                    {/*  Password Button*/}
                </div>
                {/* <div className='bottomDiv'>
                    <p className='text-1'>© 2022 All rights reserved | Built for Chronicle Cloud.</p>
                    <p className='text-2'>Powered with Synapse Xtreme Engine (SXE)</p>
                </div> */}

            </div>
        </div>
    );
    // }

};
const mapStateToProps = state => ({
    state: state,
    userStatusData: state.forgot,
});
const mapDispatchToProps = dispatch => {
    return {
        forgot: data => dispatch(forgot(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    ForgotPage
);



